<h1 mat-dialog-title>{{duplicate ? 'Duplicate this' : 'Create a'}} page object</h1>

<div mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <label *ngIf="!duplicate"> {{duplicate ? 'New page object ' : 'Page Object '}} name</label>
  <div *ngIf="!duplicate" class="mb-3">
    <input [(ngModel)]="case?.name?.includes('.utam') ? case.name.replace('.utam', '') : case.name" [disabled]="saveSpinner"
           type="text"
    >
  </div>
  <label *ngIf="!duplicate">Type</label>
  <div *ngIf="!duplicate" class="mb-3">
    <mat-radio-group (change)="case.caseType = $event.value; getTemplatesFor('pageObjects', case.caseType, '')"
                     [disabled]="saveSpinner || createFromFile || blockType"
                     [value]="case.caseType != null ? case.caseType : null"
                     placeholder="">
      <mat-radio-button value="JAVA">Java</mat-radio-button>
      <br>
      <mat-radio-button value="PYTHON">Python</mat-radio-button>
      <br>
      <mat-radio-button value="JAVASCRIPT">JavaScript</mat-radio-button>
      <br>
      <mat-radio-button value="UTAM">Utam</mat-radio-button>
      <br>
      <!--      <mat-option value="CSHARP">C#</mat-option>-->
      <!--      <mat-option value="ROBOT_FRAMEWORK">Robot Framework</mat-option>-->
      <mat-radio-button *ngIf="isLicensed" value="MXML">Mxml</mat-radio-button>
    </mat-radio-group>
  </div>

  <!--  <div *ngIf="!duplicate" class="mb-3">-->
  <!--    <label>Choose testing framework</label>-->
  <!--    <mat-select (selectionChange)="getTemplatesFor(case.caseType, case.testingFramework)" placeholder=""-->
  <!--                [disabled]="saveSpinner"-->
  <!--                [(value)]="case.testingFramework">-->
  <!--      <mat-option *ngFor="let testingFramework of filteredTestingFrameworks" [value]="testingFramework">-->
  <!--        {{parseTestingFrameworkName(testingFramework)}}-->
  <!--      </mat-option>-->
  <!--    </mat-select>-->
  <!--  </div>-->

  <div *ngIf="!duplicate && !createFromFile && !fromWorkspace">
    <label>Choose template</label>
    <mat-select [(value)]="selectedTemplate">
      <mat-option *ngFor="let template of filteredTemplates" [value]="template.name">
        {{template.name}}
        <span [attr.data-content]="template.description" class="option-description"></span>
      </mat-option>
    </mat-select>
  </div>

  <label *ngIf="duplicate && setNewFileLocation && !advanceFileLocation">File location name</label>
  <div *ngIf="duplicate && setNewFileLocation && !advanceFileLocation">
    <input [(ngModel)]="fileLocationName" type="text">
  </div>

  <br>
  <div
    *ngIf="duplicate && setNewFileLocation"
    style="display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;">
    <button (click)="openAdvance()" color="primary"
            mat-raised-button>{{ advanceFileLocation ? 'Update File location' : 'Change file location'}}</button>
  </div>


  <br>

</div>

<div mat-dialog-actions>
  <button *ngIf="!saveSpinner" [mat-dialog-close] id="cancelButton" mat-button>Cancel</button>

  <button (click)="do()" *ngIf="!saveSpinner" color="primary" id="createButton" mat-button>
    {{duplicate ? 'Duplicate' : 'Create'}}
  </button>
  <button *ngIf="saveSpinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>
