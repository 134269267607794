import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserPreferenceParamsOptionsComponent} from './user-preference-params-options.component';
import {UserPreferenseParamsFilterModule} from "../user-preferense-params-filter/user-preferense-params-filter.module";
import {MxOptionsModule} from "../../../mx/options/mx-options/mx-options.module";
import {MatButtonModule} from "@angular/material/button";
import {IconModule} from "../../../mx/icon/icon.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FilterByModule} from "../../../mx/filter-by/filter-by.module";
import {MatMenuModule} from "@angular/material/menu";


@NgModule({
  declarations: [UserPreferenceParamsOptionsComponent],
  imports: [
    CommonModule,
    UserPreferenseParamsFilterModule,
    CommonModule,
    MxOptionsModule,
    MatButtonModule,
    IconModule,
    MatTooltipModule,
    FilterByModule,
    MatMenuModule
  ],
  exports: [
    UserPreferenceParamsOptionsComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class UserPreferenceParamsOptionsModule {
}
