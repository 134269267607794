import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MxVideoViewerComponent} from './mx-video-viewer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [MxVideoViewerComponent],
  exports: [
    MxVideoViewerComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class MxVideoViewerModule {
}
