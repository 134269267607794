import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddEditUserParamDialogComponent} from './add-edit-user-param-dialog.component';
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [AddEditUserParamDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule
  ], exports: [
    AddEditUserParamDialogComponent
  ]
})
export class AddEditUserParamDialogModule {
}
