import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StorageConfigService} from '../../../../shared/services/storage-config.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private http: HttpClient,
    private storageConfigService: StorageConfigService
  ) {
  }

  getWorkspaceImage(pathWithName: string) {
    return this.http.get(
      this.storageConfigService.getImageFile(pathWithName),
      {responseType: 'blob'}
    );
  }

}
