import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonacoEditorLspReferenceListComponent } from './monaco-editor-lsp-reference-list.component';
import {ErrorModule} from "../../../mx/error/error.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatListModule} from "@angular/material/list";
import {NavTreeFileIconModule} from "../nav-tree-file-icon/nav-tree-file-icon.module";



@NgModule({
  declarations: [MonacoEditorLspReferenceListComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        ErrorModule,
        MatCheckboxModule,
        MatListModule,
        NavTreeFileIconModule
    ]
})
export class MonacoEditorLspReferenceListModule { }
