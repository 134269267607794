import {Component, Input, OnInit} from '@angular/core';
import {StorageConfigService} from '../../../../shared/services/storage-config.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-mx-html-viewer',
  templateUrl: './mx-html-viewer.component.html',
  styleUrls: ['./mx-html-viewer.component.css']
})
export class MxHtmlViewerComponent implements OnInit {
  @Input('pathWithName') pathWithName: string;
  htmlUrl: string;
  safeSrc: SafeResourceUrl;

  constructor(
    private storageConfigService: StorageConfigService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.htmlUrl = this.storageConfigService.getHtmlFile(this.pathWithName);
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.htmlUrl);
  }

}
