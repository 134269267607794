/* tslint:disable:no-bitwise */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewRef
} from '@angular/core';
import {CloseAction, createConnection, ErrorAction, MonacoLanguageClient, MonacoServices} from 'monaco-languageclient';
import {listen, MessageConnection} from 'vscode-ws-jsonrpc';
import {ConfigService} from '../../../../shared/services/config.service';
import {Uri} from 'vscode';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {GlobalEventsManagerService} from '../../../../shared/services/global-events-manager.service';
import {ConvergenceDomain} from '@convergence/convergence';
import {editor} from 'monaco-editor';
import {AngularFireAuth} from '@angular/fire/auth';
import {interval, Subscription} from 'rxjs';
import {TabsWorkspaceComponent} from '../tabs-workspace/tabs-workspace.component';
import {CaseTabsComponent} from '../../../cases/components/case-tabs/case-tabs.component';
import {MatDialog} from '@angular/material/dialog';
import {AskQuestionDialogComponent} from '../../../ai/components/ask-question-dialog/ask-question-dialog.component';
import {first} from 'rxjs/operators';
import {Case} from "../../../cases/shared/models/case";
import {Router} from "@angular/router";
import {RemoteFilesService} from "../../shared/services/remote-files.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LspDefinitionResult} from "../../shared/models/lspDefinitionResult";
import {
  MonacoEditorLspReferenceListComponent
} from "../monaco-editor-lsp-reference-list/monaco-editor-lsp-reference-list.component";
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import EndOfLineSequence = editor.EndOfLineSequence;

const ReconnectingWebSocket = require('reconnecting-websocket');

declare const monaco: any;

export enum EditorType {
  Text = 1,
  Image,
  Video,
  Pdf,
  Html
}

@Component({
  selector: 'app-editor-workspace',
  templateUrl: './editor-workspace.component.html',
  styleUrls: ['./editor-workspace.component.css']
})
export class EditorWorkspaceComponent implements OnInit, OnDestroy, AfterViewInit {

  scrollLevelFromCaseTabs = 0;
  currentScrollLevel = 0;

  currentTabIndex = 0;
  @Input('testCase') testCase: Case = undefined;
  showEditor = false;
  width: string;
  height: string;
  @Input('showDetailsLine') showDetailsLine = false;
  @Input('isCurrentlyBeingCompiled') isCurrentlyBeingCompiled = false;
  @Input('errorLineDecoration') errorLineDecoration = [];
  edit = true;
  init = false;
  /********************* monaco editor *******************/

  @ViewChild('editor') editorRef: ElementRef;
  editorInteractionTriggered = false;
  connectionTimeoutChecker: Subscription;
  timeout = 0;
  // languageServerStatus: string = 'inactive';
  lspTimeoutInSeconds = 60;
  webSocket: WebSocket;
  javaGradleImportFailedTimeoutChecker;
  @Input('focusToBottom') focusToBottom = false;
  @Input('pathWithName') pathWithName: string;
  allowLSP = false;
  // We need file location in order for Java LSP to work
  fileLocation: string;
  fileContent = '';
  editorOptions = undefined;
  @Output('compile') compileEvent = new EventEmitter<string>();
  @Output('save') saveEvent = new EventEmitter<string>();
  @Output('run') runEvent = new EventEmitter<string>();
  @Output('tryEditReadOnly') tryEditReadOnlyEvent = new EventEmitter<null>();
  @Output('fileChanges') fileChanges = new EventEmitter<string>();
  @Output('openNewFile') openNewFileEvent = new EventEmitter<LspDefinitionResult>();
  language: string;
  editorType: EditorType = EditorType.Text;
  tabSwitchedListenerForTabsWorkspaceComponent;
  // @Input('options') editorOptions;
  tabSwitchedListenerForCaseTabsComponent;
  addActionOptions = false;
  defaultEditor = true;
  private codeEditor: IStandaloneCodeEditor;
  private convergenceDomain: ConvergenceDomain;

  constructor(
    private zone: NgZone,
    private configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private maxtafTokensStorageService: MaxtafTokensStorageService,
    private globalEventsManager: GlobalEventsManagerService,
    private auth: AngularFireAuth,
    private router: Router,
    private remoteFileService: RemoteFilesService,
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private tabsWorkspaceComponent: TabsWorkspaceComponent,
    private caseTabsComponent: CaseTabsComponent
  ) {
    (window as any).require.config({
      paths: {
        vs: '/vs',
        Convergence: '/convergence.amd.js',
        ConvergenceColorAssigner: '/color-assigner.js',
        rxjs: 'https://unpkg.com/rxjs@6.4.0/bundles/rxjs.umd.js'
      }
    });

    this.globalEventsManager.showNavBarEmitter.subscribe(() => {
      this.setEditorWidth();
    });

    this.globalEventsManager.workspaceNavBarHideEmitter.subscribe(() => {
      this.setEditorWidth();
    });

    this.globalEventsManager.sideNavModEmitter.subscribe(() => {
      this.setEditorWidth();
    });
    if (this.caseTabsComponent.params !== undefined) {
      this.currentTabIndex = this.caseTabsComponent.params.selectedTab.value - 1;
      if (this.caseTabsComponent.params.tabs[this.currentTabIndex].scrollLevel !== undefined) {
        this.scrollLevelFromCaseTabs = this.caseTabsComponent.params.tabs[this.currentTabIndex].scrollLevel;
      }
    }
  }


  ngOnInit(): void {
    document.addEventListener('contextmenu', () => {
      setTimeout(() => {
        document.querySelectorAll('.action-item .action-label').forEach((item: HTMLElement) => {
          // if (item.getAttribute('aria-label') === 'Go to Type Definition') {
          //   this.renderer.setStyle(item.parentElement, 'display', 'none');
          // }
          // if (item.getAttribute('aria-label') === 'Go to Implementations') {
          //   this.renderer.setStyle(item.parentElement, 'display', 'none');
          // }
          // if (item.getAttribute('aria-label') === 'Go to Definition') {
          //   this.renderer.setStyle(item.parentElement, 'display', 'none');
          // }
          // if (item.getAttribute('aria-label') === 'Go to References') {
          //   this.renderer.setStyle(item.parentElement, 'display', 'none');
          // }
          // if (item.getAttribute('aria-label') === 'Peek') {
          //   this.renderer.setStyle(item.parentElement, 'display', 'none');
          // }
        });
      }, 100);
    });
  }


  public get editorTypes(): typeof EditorType {
    return EditorType;
  }

  @Input('width') set setWidth(width: string) {
    setTimeout(() => {
      this.width = width;
      this.changeDetectorRef.detectChanges();
    }, 300);
  }

  @Input('height') set setHeight(height: string) {

    setTimeout(() => {
      this.height = height;

      this.changeDetectorRef.detectChanges();
    }, 300);
  }

  @Input('height2') set setHeight2(height: string) {

    setTimeout(() => {
      this.height = height;
      this.changeDetectorRef.detectChanges();
    }, 300);
  }

  @Input('allowLSP') set setFileLocation(fileLocation: string) {
    if (fileLocation != undefined || fileLocation == 'false') {
      this.allowLSP = true;
      this.fileLocation = fileLocation;
    }
  }

  @Input('options') set setEditorOptions(editorOptions) {
    this.editorOptions = editorOptions;
  }

  @Input('addActionOptions') set setAddActionOptions(addActionOptions) {
    this.addActionOptions = addActionOptions;
  }

  @Input('allowEdit') set setEdit(edit: boolean) {
    this.edit = edit;
    this.updateMonacoEditorOptions();
  }

  @Input('content') set content(content: string) {
    this.setContent(content);
  }

  @Input('typeOfFile') set typeOfFile(typeOfFile: string) {
    if (typeOfFile == null) {
      typeOfFile = 'text';
    }
    this.setLanguage(typeOfFile.toLowerCase().trim());

    if (this.editorOptions == undefined) {
      this.editorOptions = {
        theme: 'vs-light',
        language: this.language,
        automaticLayout: true,
        fixedOverflowWidgets: true,
        scrollBeyondLastLine: false,
        fontSize: 12,
        lineNumbers: 'on',
        minimap: {enabled: false},
        glyphMargin: false
      };
    }


    this.updateMonacoEditorOptions();
    this.showEditor = true;
  }

  @Input('editorType') set setEditorType(editorType: EditorType) {
    this.editorType = editorType;
  }

  public changeTextToHtmlAndHtmlToText() {
    if (this.editorType == EditorType.Text) {
      this.editorType = EditorType.Html;
      return;
    }

    if (this.editorType == EditorType.Html) {
      this.editorType = EditorType.Text;
      return;
    }
  }

  setContent(content) {
    if (content == undefined) {
      content = '';
    }
    this.fileContent = content;
    this.setCodeEditorContent(this.fileContent);


    if (!this.edit) {
      if (monaco != undefined && monaco.editor != undefined && this.editorRef != undefined) {
        this.codeEditor?.getModel()?.dispose();
        this.codeEditor = monaco.editor.create(this.editorRef.nativeElement, this.editorOptions);
        this.codeEditor?.getModel()?.setEOL(EndOfLineSequence.LF);
      }
      this.setCodeEditorContent(this.fileContent);

      this.updateMonacoEditorOptions();

      // this.codeEditor.addAction({
      //   id: "something-neat",
      //   label: "Something Neat",
      //   run: this.logMessage,
      // })

      // const myAction: this.codeEditor.IActionDescriptor = {
      //   id: "something-neat",
      //   label: "Something Neat",
      //   run: doSomethingNeat,
      // }
      // editor.addAction(myAction)
    }

    if (this.focusToBottom) {
      this.focusToTheBottomLog();
    }
  }

  // monaco-editor no-user-select  showUnused vs
  public printDetails() {
    this.printHeight();
  }


  ngAfterViewInit() {
    if (!this.codeEditor) {

      this.refreshEditorConfig();
    } else {

    }
    this.printHeight();
    // const hostElem = this.editorRef.nativeElement;
    //
    // for (var i = 0; i < hostElem.children.length; i++) {
    //
    // }

    // // var doc = document.getElementById("test");
    // var notes = null;
    // for (var i = 0; i < this.editorRef.childNodes.length; i++) {
    //   if (doc.childNodes[i].className == "4") {
    //     notes = doc.childNodes[i];
    //     break;
    //   }
    // }
  }

  public printHeight() {

    // setTimeout(() => {
    //   const hostElem = this.editorRef.nativeElement;

    //
    //   for (var i = 0; i < hostElem.children.length; i++) {
    //     //id monaco-editor
    //     //class code-editor-test ng-star-inserted
    //     for (var j = 0; i < hostElem.children[i].children.length; j++) {

    //     }
    //   }
    // }, 2000);


  }

  setEditorWidth() {
    if (this.height != undefined) {
      const newHeight = this.height;
      // tslint:disable-next-line:radix
      const h: number = parseInt(this.height.replace('px', ''));
      this.height = (h - 1) + 'px';
      setTimeout(() => {
        this.height = newHeight;
      }, 400);
    }
  }


  ngOnDestroy() {
    // Dispose LSP connections
    if (this.webSocket !== undefined) {
      this.webSocket.close();
    }
    if (this.caseTabsComponent.params !== undefined) {
      if (this.caseTabsComponent.params.tabs[this.currentTabIndex] !== undefined) {
        this.caseTabsComponent.params.tabs[this.currentTabIndex].scrollLevel = this.currentScrollLevel;
      }
    }

    this.setLanguageServerStatus('inactive');

    // Trigger the visual update for the lspStatus above manually
    if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }

    if (this.convergenceDomain) {
      this.convergenceDomain.disconnect();
    }

    if (this.connectionTimeoutChecker) {
      this.connectionTimeoutChecker.unsubscribe();
    }
    // clearInterval(this.connectionTimeoutChecker);

    this.codeEditor?.getModel()?.dispose();

    // monaco.editor.getModels().forEach(model => {
    //   if (model.id == this.codeEditor.getModel().id) {
    //     model.dispose();
    //   }
    // });

    // this.codeEditor
    // if(this.editorRef != null){
    //   this.editorRef.
    // }

    if (this.tabSwitchedListenerForTabsWorkspaceComponent != undefined) {
      this.tabSwitchedListenerForTabsWorkspaceComponent.unsubscribe();
    }
    if (this.tabSwitchedListenerForCaseTabsComponent != undefined) {
      this.tabSwitchedListenerForCaseTabsComponent.unsubscribe();
    }
  }

  public refreshEditorConfig(newCode?: string, changedTab = false) {
    this.codeEditor?.getModel()?.dispose();
    this.codeEditor = monaco.editor.create(this.editorRef.nativeElement, this.editorOptions);

    this.addOptionsInMenu();

    if (newCode != undefined) {
      this.setContent(newCode);
    }

    this.setCodeEditorContent(this.fileContent, changedTab);

    this.monacoEditorInitialized(this.codeEditor);

    this.codeEditor.setScrollTop(this.scrollLevelFromCaseTabs);

    this.changeDetectorRef.detectChanges();

  }

  setCodeEditorContent(content: string, changedTab?) {

    if (this.codeEditor != undefined) {

      if (this.codeEditor.getModel() != undefined) {
      }
    }


    // if (!disable) {
    if (this.codeEditor != undefined && content && this.codeEditor.getModel() != undefined && content != this.codeEditor?.getModel()?.getValue()) {
      // if (!changedTab) {
      this.codeEditor?.getModel()?.setValue(content);
      // }
    }
    // }
  }

  public selectLine(selectLine: number) {
    let line: number = +selectLine;
    if (line != undefined && line > 0) {
      const lineStart = this.codeEditor.getModel().getLineFirstNonWhitespaceColumn(line);
      const lineEnd = this.codeEditor.getModel().getLineLastNonWhitespaceColumn(line);

      this.errorLineDecoration = this.codeEditor.deltaDecorations(this.errorLineDecoration, [
        {range: new monaco.Range(line, lineStart, line, lineEnd), options: {inlineClassName: 'code-error-line'}},
      ]);

      this.codeEditor.revealLineInCenter(line);
      document.querySelector('.mat-drawer-content.mat-sidenav-content').scrollTo({top: 10000, behavior: 'smooth'});
    }
  }

  focusToTheBottomLog() {
    if (this.codeEditor != null && this.codeEditor.getModel() != null) {
      this.codeEditor.revealLine(this.codeEditor.getModel().getLineCount());
      this.codeEditor.setPosition({column: 1, lineNumber: this.codeEditor.getModel().getLineCount()});
    }
  }

  setLanguageServerStatus(lspStatus) {
    this.globalEventsManager.setLspStatus(lspStatus);
  }

  monacoEditorInitialized(thisEditor: IStandaloneCodeEditor) {
    this.auth.user.pipe(first()).subscribe(
      user => {
        if (user.isAnonymous) {
          this.codeEditor.updateOptions({readOnly: true});
        }
      }
    );
    // When you switch tabs, close connections to LSP
    this.tabSwitchedListenerForTabsWorkspaceComponent = this.tabsWorkspaceComponent.tabSwitched.subscribe(() => {
      this.closeLanguageServerConnection();
    });
    this.tabSwitchedListenerForCaseTabsComponent = this.caseTabsComponent.tabSwitched.subscribe(() => {
      this.closeLanguageServerConnection();
    });

    const messageContribution = this.codeEditor.getContribution('editor.contrib.messageController');
    (this.codeEditor as any).onDidAttemptReadOnlyEdit(() => {
      // (messageContribution as any).showMessage(null, this.codeEditor.getPosition());
      this.tryEditReadOnlyEvent.emit();

    });

    this.codeEditor.onDidScrollChange(e => {
      this.currentScrollLevel = e.scrollTop;
    });

    if (!this.edit) {

      this.codeEditor.onKeyDown((e) => {
        if (!e.ctrlKey && !e.altKey && !e.metaKey) {
          if (this.usuallyProducesCharacter(e.keyCode)) {
            thisEditor.trigger('', 'type', {text: ''});
          }
        }
      });
    }

    // When content changes, show save button if the code is different than the one in the beginning
    this.codeEditor.onDidChangeModelContent(() => {
      this.zone.run(() => {
        this.onChangeCode();
      });
    });

    // Update tab size to 2 spaces instead of 4 spaces
    this.codeEditor.getModel().updateOptions({tabSize: 2});

    // Add custom key binding Ctrl + S for saving the document
    this.codeEditor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => {
      // TODO: Fix snackbar glitch
      if (!this.isCurrentlyBeingCompiled) {
        this.saveCode();
      }
    }, null);

    // Add custom key binding Alt + S for saving the document
    this.codeEditor.addCommand(monaco.KeyMod.Alt | monaco.KeyCode.KEY_S, () => {
      // TODO: Fix snackbar glitch
      if (!this.isCurrentlyBeingCompiled) {
        this.saveCode();
      }
    }, null);

    // Add custom key binding Alt + C for compiling the document
    this.codeEditor.addCommand(monaco.KeyMod.Alt | monaco.KeyCode.KEY_C, () => {
      // TODO: Fix snackbar glitch
      if (!this.isCurrentlyBeingCompiled) {
        this.compile();
      }
    }, null);

    // Add custom key binding Alt + R for running case
    this.codeEditor.addCommand(monaco.KeyMod.Alt | monaco.KeyCode.KEY_R, () => {
      // TODO: Fix snackbar glitch
      if (!this.isCurrentlyBeingCompiled) {
        this.run();
      }
    }, null);

    this.updateMonacoEditorOptions();

    if (this.language == 'xml') {
      this.monacoXmlEditorInitialized();
    } else if (this.language == 'java') {
      this.monacoJavaEditorInitialized();
    } else if (this.language == 'python') {
      this.monacoPythonEditorInitialized();
    } else if (this.language == 'javascript') {
      this.monacoJsEditorInitialized();
    } else if (this.language == 'json') {
      this.monacoJsonEditorInitialized();
    }

    this.connectToConvergenceServerIfNeeded();
  }

  usuallyProducesCharacter(keyCode) {
    if (keyCode >= monaco.KeyCode.KEY_0 && keyCode <= monaco.KeyCode.KEY_9) {
      return true;
    }
    if (keyCode >= monaco.KeyCode.NUMPAD_0 && keyCode <= monaco.KeyCode.NUMPAD_9) {
      return true;
    }
    if (keyCode >= monaco.KeyCode.KEY_A && keyCode <= monaco.KeyCode.KEY_Z) {
      return true;
    }
    switch (keyCode) {
      // case monaco.KeyCode.Tab:
      case monaco.KeyCode.Enter:
      case monaco.KeyCode.Space:
      case monaco.KeyCode.Delete:
      case monaco.KeyCode.US_SEMICOLON:
      case monaco.KeyCode.US_EQUAL:
      case monaco.KeyCode.US_COMMA:
      case monaco.KeyCode.US_MINUS:
      case monaco.KeyCode.US_DOT:
      case monaco.KeyCode.US_SLASH:
      case monaco.KeyCode.US_BACKTICK:
      case monaco.KeyCode.US_OPEN_SQUARE_BRACKET:
      case monaco.KeyCode.US_BACKSLASH:
      case monaco.KeyCode.US_CLOSE_SQUARE_BRACKET:
      case monaco.KeyCode.US_QUOTE:
      case monaco.KeyCode.OEM_8:
      case monaco.KeyCode.OEM_102:
        return true;
    }
    return false;
  }

  updateMonacoEditorOptions() {
    this.codeEditor?.getModel()?.setEOL(EndOfLineSequence.LF);
    if (this.codeEditor != undefined && this.codeEditor.getModel() != undefined && this.language != undefined && monaco.editor != undefined) {
      monaco.editor.setModelLanguage(this.codeEditor.getModel(), this.language);
      this.codeEditor.updateOptions({
        readOnly: !this.edit || this.isCurrentlyBeingCompiled
      });
      fetch('https://json.schemastore.org/utam-page-object.json').then(result => result.json()).then(schema =>
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
          validate: true,
          schemas: [
            {
              uri: 'https://json.schemastore.org/utam-page-object.json', // id of the first schema
              fileMatch: ['*.utam.json'], // associate with our model
              schema
            }
          ]
        }));
    }
  }

  monacoXmlEditorInitialized() {
    // Register a listener for activity/inactivity
    this.codeEditor.onKeyDown(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToXmlLanguageServer();
      }

      this.removeMonacoCodeEditorHighlighedErrorLines();
    });
    this.codeEditor.onMouseMove(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToXmlLanguageServer();
      }
    });
  }

  connectToXmlLanguageServer() {
    this.setLanguageServerStatus('initializing');

    // Trigger the visual update for the lspStatus above manually
    if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }

    // check if Monaco language client services are already installed and install them
    try {
      MonacoServices.get();
    } catch (ignored) {
      MonacoServices.install(this.codeEditor);
    }
    // create the web socket
    const url = this.createUrl();
    if (this.webSocket === undefined) {
      this.webSocket = this.createReconnectingWebSocket(url);
    }
    const webSocket = this.webSocket;
    // listen when the web socket is opened
    listen({
      webSocket,
      onConnection: (connection: MessageConnection) => {
        // create and start the language client
        const languageClient = this.createLanguageClient('xml', connection);
        const disposable = languageClient.start();
        connection.onClose(() => {
          disposable.dispose();
        });

        // If user hasn't touched the editor for 1 minute, close the connection (this way we save up resources)
        this.setTimeoutChecker();

        this.setLanguageServerStatus('active');

        // Trigger the visual update for the lspStatus above manually
        if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  monacoJavaEditorInitialized() {
    // This is to make LSP work, we need to explicitly set textDocumentUri
    if (this.allowLSP) {
      try {
        monaco.editor.createModel(this.codeEditor.getModel().getValue(), 'java', Uri.parse('file://' + this.fileLocation));
      } catch (ignored) {
      }
      this.codeEditor.setModel(monaco.editor.getModel(Uri.parse('file://' + this.fileLocation)));
    }

    // Register a listener for activity/inactivity
    this.codeEditor.onKeyDown(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToJavaLanguageServer();
      }

      this.removeMonacoCodeEditorHighlighedErrorLines();
    });
    this.codeEditor.onMouseMove(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToJavaLanguageServer();
      }
    });
  }

  connectToJavaLanguageServer() {
    // check if Monaco language client services are already installed and install them
    try {
      MonacoServices.get();
    } catch (ignored) {
      MonacoServices.install(this.codeEditor);
    }
    // create the web socket
    const url = this.createUrl();

    if (this.webSocket === undefined) {
      this.webSocket = this.createReconnectingWebSocket(url);
    }

    const webSocket = this.webSocket;

    // listen when the web socket is opened
    listen({
      webSocket,
      onConnection: (connection: MessageConnection) => {
        // create and start the language client
        const languageClient = this.createLanguageClient('java', connection);
        const disposable = languageClient.start();
        connection.onClose(() => {
          disposable.dispose();
        });

        // If user hasn't touched the editor for 1 minute, close the connection (this way we save up resources)
        this.setTimeoutChecker();

        // Listen for websocket messages so we can change the active status of language server
        this.webSocket.addEventListener('message', event => {
          if (event.data.includes('>> watchers registered')) {
            this.setLanguageServerStatus('active');

            // Trigger the visual update for the lspStatus above manually
            if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
              this.changeDetectorRef.detectChanges();
            }
          } else if (event.data.includes('>> initialized')) {
            this.javaGradleImportFailedTimeoutChecker = setTimeout(() => {
              // this.closeJavaLanguageServerConnection();
              // this.connectToJavaLanguageServer();
            }, 10000);
          } else if (event.data.includes('Importing Gradle project(s)')) {
            clearTimeout(this.javaGradleImportFailedTimeoutChecker);
          }
        });

        this.setLanguageServerStatus('initializing');

        // Trigger the visual update for the lspStatus above manually
        if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
          this.changeDetectorRef.detectChanges();
        }

      }
    });
  }

  monacoPythonEditorInitialized() {
    // This is to make LSP work, we need to explicitly set textDocumentUri
    if (this.allowLSP) {
      try {
        monaco.editor.createModel(this.codeEditor.getModel().getValue(), 'python', Uri.parse('file://' + this.fileLocation));
      } catch (ignored) {
      }
      this.codeEditor.setModel(monaco.editor.getModel(Uri.parse('file://' + this.fileLocation)));
    }

    // Register a listener for activity/inactivity
    this.codeEditor.onKeyDown(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToPythonLanguageServer();
      }

      this.removeMonacoCodeEditorHighlighedErrorLines();
    });
    this.codeEditor.onMouseMove(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToPythonLanguageServer();
      }
    });
  }

  connectToPythonLanguageServer() {
    this.setLanguageServerStatus('initializing');

    // Trigger the visual update for the lspStatus above manually
    if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }

    // check if Monaco language client services are already installed and install them
    try {
      MonacoServices.get();
    } catch (ignored) {
      MonacoServices.install(this.codeEditor);
    }
    // create the web socket
    const url = this.createUrl();
    if (this.webSocket === undefined) {
      this.webSocket = this.createReconnectingWebSocket(url);
    }
    const webSocket = this.webSocket;
    // listen when the web socket is opened
    listen({
      webSocket,
      onConnection: (connection: MessageConnection) => {
        // create and start the language client
        const languageClient = this.createLanguageClient('python', connection);
        const disposable = languageClient.start();
        connection.onClose(() => {
          disposable.dispose();
        });

        // If user hasn't touched the editor for 1 minute, close the connection (this way we save up resources)
        this.setTimeoutChecker();

        this.setLanguageServerStatus('active');

        // Trigger the visual update for the lspStatus above manually
        if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  monacoJsEditorInitialized() {
    // This is to make LSP work, we need to explicitly set textDocumentUri
    if (this.allowLSP) {
      try {
        monaco.editor.createModel(this.codeEditor.getModel().getValue(), 'javascript', Uri.parse('file://' + this.fileLocation));
      } catch (ignored) {
      }
      this.codeEditor.setModel(monaco.editor.getModel(Uri.parse('file://' + this.fileLocation)));
    }

    // Register a listener for activity/inactivity
    this.codeEditor.onKeyDown(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToJsLanguageServer();
      }

      this.removeMonacoCodeEditorHighlighedErrorLines();
    });
    this.codeEditor.onMouseMove(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToJsLanguageServer();
      }
    });
  }

  connectToJsLanguageServer() {
    this.setLanguageServerStatus('initializing');

    // Trigger the visual update for the lspStatus above manually
    if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }

    // check if Monaco language client services are already installed and install them
    try {
      MonacoServices.get();
    } catch (ignored) {
      MonacoServices.install(this.codeEditor);
    }
    // create the web socket
    const url = this.createUrl();
    if (this.webSocket === undefined) {
      this.webSocket = this.createReconnectingWebSocket(url);
    }
    const webSocket = this.webSocket;
    // listen when the web socket is opened
    listen({
      webSocket,
      onConnection: (connection: MessageConnection) => {
        // create and start the language client
        const languageClient = this.createLanguageClient('javascript', connection);
        const disposable = languageClient.start();
        connection.onClose(() => {
          disposable.dispose();
        });

        // If user hasn't touched the editor for 1 minute, close the connection (this way we save up resources)
        this.setTimeoutChecker();

        this.setLanguageServerStatus('active');

        // Trigger the visual update for the lspStatus above manually
        if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  monacoJsonEditorInitialized() {
    // This is to make LSP work, we need to explicitly set textDocumentUri
    if (this.allowLSP) {
      try {
        monaco.editor.createModel(this.codeEditor.getModel().getValue(), 'json', Uri.parse('file://' + this.fileLocation));
      } catch (ignored) {
      }
      this.codeEditor.setModel(monaco.editor.getModel(Uri.parse('file://' + this.fileLocation)));
    }

    // Register a listener for activity/inactivity
    this.codeEditor.onKeyDown(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToJsLanguageServer();
      }

      this.removeMonacoCodeEditorHighlighedErrorLines();
    });
    this.codeEditor.onMouseMove(() => {
      this.editorInteractionTriggered = true;
      if (this.allowLSP && this.webSocket === undefined) {
        this.connectToJsonLanguageServer();
      }
    });
  }

  connectToJsonLanguageServer() {
    this.setLanguageServerStatus('initializing');

    // Trigger the visual update for the lspStatus above manually
    if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }

    // check if Monaco language client services are already installed and install them
    try {
      MonacoServices.get();
    } catch (ignored) {
      MonacoServices.install(this.codeEditor);
    }
    // create the web socket
    const url = this.createUrl();
    if (this.webSocket === undefined) {
      this.webSocket = this.createReconnectingWebSocket(url);
    }
    const webSocket = this.webSocket;
    // listen when the web socket is opened
    listen({
      webSocket,
      onConnection: (connection: MessageConnection) => {
        // create and start the language client
        const languageClient = this.createLanguageClient('json', connection);
        const disposable = languageClient.start();
        connection.onClose(() => {
          disposable.dispose();
        });

        // If user hasn't touched the editor for 1 minute, close the connection (this way we save up resources)
        this.setTimeoutChecker();

        this.setLanguageServerStatus('active');

        // Trigger the visual update for the lspStatus above manually
        if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  closeLanguageServerConnection() {
    if (this.webSocket !== undefined) {
      this.webSocket.close();
      this.setLanguageServerStatus('inactive');
    }

    this.webSocket = undefined;
    this.timeout = 0;

    // Trigger the visual update for the lspStatus above manually
    if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }

    // clearInterval(this.connectionTimeoutChecker);
    if (this.connectionTimeoutChecker) {
      this.connectionTimeoutChecker.unsubscribe();
    }
  }

  removeMonacoCodeEditorHighlighedErrorLines() {
    if (this.errorLineDecoration.length !== 0) {
      this.errorLineDecoration = this.codeEditor.deltaDecorations(this.errorLineDecoration, []);
    }
  }

  public refreshCodeEditorLayout() {
    this.codeEditor.layout();
  }

  public refreshEditor(changedTab?) {
    this.refreshEditorConfig(undefined, changedTab);

    // setTimeout(() => {
    //   this.changeDetectorRef.detectChanges();
    // }, 400);
  }

  insertNewCode(newCode: string) {
    const currentSelection = this.codeEditor.getSelection();
    const currentPosition = this.codeEditor.getPosition();
    const trimmedCode = newCode.replace(/^\s+|\s+$/g, '');
    const indentationLevel = (currentPosition.column - 1) / 4;
    const lines = trimmedCode.split('\n');
    const modifiedLines = [];

    for (const line of lines) {
      const indent = '\t'.repeat(indentationLevel);
      const brojRazmaka = (line.match(/ /g) || []).length;
      const brojTabova = (line.match(/\t/g) || []).length;
      modifiedLines.push(indent + line);
    }

    const modifiedCode = modifiedLines.join('\n');
    const newCursorPosition = new monaco.Position(currentPosition.lineNumber + lines.length - 1, lines[lines.length - 1].length + 1);
    this.codeEditor.executeEdits('', [{range: currentSelection, text: modifiedCode}]);
    this.codeEditor.setPosition(newCursorPosition);
  }

  addOptionsInMenu() {

    if (this.addActionOptions) {
      this.addActionGoToImplementation();
      this.addActionDeclarationOrUsages();

      this.addActionAIFindBugs();
      this.addActionAIOptimize();
      this.addActionAIExplain();
      this.addActionAIAddComments();
      this.addActionAICompleteCode();
      this.addActionAIAskAnything();
      this.addActionAIInsertCode();


    }
  }

  private addActionDeclarationOrUsages() {
    const myAction = monaco.editor.IActionDescriptor = {
      id: 'go-to-references',
      label: 'Go to Reference',
      contextMenuOrder: 0, // choose the order
      contextMenuGroupId: 'aaCode', // create a new grouping
      keybindings: [
        monaco.KeyMod.Shift | monaco.KeyCode.F12,
      ],
      run: this.actionGoToDeclarationOrUsages.bind(this),
    };

    this.codeEditor.addAction(myAction);

  }

  addActionGoToImplementation() {
    const myAction = monaco.editor.IActionDescriptor = {
      id: 'go-to-implementation',
      label: 'Go To Implementation',
      contextMenuOrder: 0, // choose the order
      contextMenuGroupId: 'aaCode', // create a new grouping
      keybindings: [
        // eslint-disable-next-line no-bitwise
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.F12,
      ],
      run: this.actionGoToImplementation.bind(this),
    };

    this.codeEditor.addAction(myAction);

    this.codeEditor.onMouseDown((e) => {
      if (e.event.ctrlKey && e.event.buttons === 1) {
        this.actionGoToImplementation();
      }
    });

    // this.codeEditor.getAction('editor.action.goToImplementation').
  }

  actionGoToDeclarationOrUsages() {
    let position = this.codeEditor.getPosition();
    if (position.lineNumber == undefined || position.column == undefined)
      return;

    let line = position.lineNumber - 1;
    let character = position.column;

    this.webSocket.send('{"jsonrpc":"2.0","id":-2,"method":"textDocument/references","params":{"textDocument":{"uri":"' + this.codeEditor.getModel().uri + '"},"context":{"includeDeclaration": true}, "position":{"line":' + line + ',"character":' + character + '}}}');
    this.webSocket.onmessage = (event) => {

      if (event.data.includes('"id":-2')) {
        try {
          let result = JSON.parse(event.data);

          if (result != undefined && result.result[0] != undefined) {
            this.formatResponseArray(result);

            if (result.result.length == 0) {
              this.fileNotFoundFileMessage();
              return;
            } else if (result.result.length == 1) {
              this.openInWorkspace(result.result[0]);
            } else {

              console.log('3313 result.result: ', result.result);
              const dialogRef = this.dialog.open(MonacoEditorLspReferenceListComponent, {
                width: '700px',
                panelClass: 'custom-dialog-container',
                data: {
                  list: result.result
                }
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result == null) {
                  return;
                } else {
                  this.openInWorkspace(result);
                }
              });
            }
            // this.getFileAndOpenInWorkspace(result.result[0]);
          } else {
            this.fileNotFoundFileMessage();
          }
        } catch (e) {
          this.fileNotFoundFileMessage();
        }
      }
    }
  }

  private formatResponseArray(result) {
    result.result.forEach((lspDefinitionResult: any) => {
      if (
        lspDefinitionResult.uri.startsWith('file:///mnt/project') ||
        (this.language == 'javascript' &&
          (lspDefinitionResult.uri.startsWith('file:///javascript') ||
            lspDefinitionResult.uri.startsWith('file://javascript')))
      ) {
        if (this.language == 'javascript') {
          lspDefinitionResult.uri = lspDefinitionResult.uri.replace('file:///', '');
          lspDefinitionResult.uri = lspDefinitionResult.uri.replace('file://', '');
        } else {
          lspDefinitionResult.uri = lspDefinitionResult.uri.replace('file:///mnt/project', '');
        }

        lspDefinitionResult.range.start.line = lspDefinitionResult.range.start.line + 1;
        lspDefinitionResult.range.end.line = lspDefinitionResult.range.end.line + 1;
      } else if (
        lspDefinitionResult.uri.startsWith('file:///app/resources/lsp/java/workspace/jdt.ls-java-project/')
      ) {
        console.log('1212 else if ');
        lspDefinitionResult.uri = lspDefinitionResult.uri.replace(
          'file:///app/resources/lsp/java/workspace/jdt.ls-java-project/src/',
          ''
        );
        lspDefinitionResult.range.start.line = lspDefinitionResult.range.start.line + 1;
        lspDefinitionResult.range.end.line = lspDefinitionResult.range.end.line + 1;

        let pathWithNameTest = '/java/src/test/java/' + lspDefinitionResult.uri;
        let pathWithNameMain = '/java/src/main/java/' + lspDefinitionResult.uri;

        this.remoteFileService.getFile(pathWithNameTest, false, null).subscribe(
          details => {
            if (details != null) {
              lspDefinitionResult.uri = pathWithNameTest;
            } else {
              this.remoteFileService.getFile(pathWithNameMain, false, null).subscribe(
                details => {
                  if (details != null) {
                    lspDefinitionResult.uri = pathWithNameMain;
                  } else {
                    this.fileNotFoundTriggered(lspDefinitionResult);
                  }
                }, error => {
                  this.fileNotFoundTriggered(lspDefinitionResult);

                }
              );
            }


          }, error => {

            this.remoteFileService.getFile(pathWithNameMain, false, null).subscribe(
              details => {
                if (details != null) {
                  lspDefinitionResult.uri = pathWithNameMain;
                } else {
                  this.fileNotFoundTriggered(lspDefinitionResult);
                }
              }, error => {
                this.fileNotFoundTriggered(lspDefinitionResult);
              }
            );


          }
        );
      }
    });
    this.removeElementsWithEmptyUri(result);
  }

  removeElementsWithEmptyUri(result: any) {
    result.result = result.result.filter((lspDefinitionResult: any) => lspDefinitionResult.uri !== '');
  }

  fileNotFoundTriggered(lspDefinitionResult: any) {
    lspDefinitionResult.uri = '';
  }

  transformElements(inputArray: any[]): LspDefinitionResult[] {
    console.log('5511 inputArray: ', inputArray);
    return inputArray.map(element => {
      try {
        let lsp = new LspDefinitionResult();

        console.log('5511 inputArray: ', element.targetRange.start.line);
        console.log('5511 inputArray: ', element.targetRange.start.character);
        console.log('5511 inputArray: ', element.targetRange.end.line);
        console.log('5511 inputArray: ', element.targetRange.end.character);
        lsp.uri = element.targetUri;
        lsp.range.start.line = element.targetRange.start.line;
        lsp.range.start.character = element.targetRange.start.character;

        lsp.range.end.line = element.targetRange.end.line;
        lsp.range.end.character = element.targetRange.end.character;

        return lsp;
      } catch (e) {
        console.log('5511 e: ', e);
      }

    });
  }

  actionGoToImplementation() {
    console.log('331 this.codeEditor.getSupportedActions(): ', 'background: red; color: white; ', this.codeEditor.getSupportedActions());
    let position = this.codeEditor.getPosition();
    if (position.lineNumber == undefined || position.column == undefined)
      return;

    let line = position.lineNumber - 1;
    let character = position.column;


    // this.webSocket.send('{"jsonrpc":"2.0","id":-1,"method":"textDocument/hover","params":{"textDocument":{"uri":"' + this.codeEditor.getModel().uri + '"},"position":{"line":' + line + ',"character":' + character + '}}}');
    this.webSocket.send('{"jsonrpc":"2.0","id":-1,"method":"textDocument/definition","params":{"textDocument":{"uri":"' + this.codeEditor.getModel().uri + '"},"position":{"line":' + line + ',"character":' + character + '}}}');
    this.webSocket.onmessage = (event) => {
      console.log('121 EVENT DATA: ', event.data);
      if (event.data.includes('"id":-1')) {
        // console.log('1212 EVENT DATA INCLUDES -1: ', JSON.parse(event.data).result.contents.value);
        console.log('1212 EVENT DATA INCLUDES -1: ', JSON.parse(event.data));
        try {
          let result = JSON.parse(event.data);

          if (this.language == 'javascript' || this.language == 'js')
            result.result = this.transformElements(result.result);

          if (result != undefined && result.result[0] != undefined) {

            this.formatResponseArray(result);

            if (result.result.length == 0) {
              this.fileNotFoundFileMessage();
              return;
            } else if (result.result.length == 1) {
              this.openInWorkspace(result.result[0]);
            } else {

              const dialogRef = this.dialog.open(MonacoEditorLspReferenceListComponent, {
                width: '700px',
                panelClass: 'custom-dialog-container',
                data: {
                  list: result.result
                }
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result == null) {
                  return;
                } else {
                  this.openInWorkspace(result);
                }
              });
            }

            // this.getFileAndOpenInWorkspace(result.result[0]);
          } else {
            this.fileNotFoundFileMessage();
          }
        } catch (e) {
          this.fileNotFoundFileMessage();
        }
      }
    }
  }

  private getFileAndOpenInWorkspace(lspDefinitionResult: LspDefinitionResult) {


    if (lspDefinitionResult.uri.startsWith('file:///mnt/project') || (this.language == 'javascript' && (lspDefinitionResult.uri.startsWith('file:///javascript') || lspDefinitionResult.uri.startsWith('file://javascript')))) {
      // if (this.language == 'javascript') {
      //   lspDefinitionResult.uri = lspDefinitionResult.uri.replace('file:///', '');
      //   lspDefinitionResult.uri = lspDefinitionResult.uri.replace('file://', '');
      // } else {
      //   lspDefinitionResult.uri = lspDefinitionResult.uri.replace('file:///mnt/project', '');
      // }
      //
      //
      // lspDefinitionResult.range.start.line = lspDefinitionResult.range.start.line + 1;
      // lspDefinitionResult.range.end.line = lspDefinitionResult.range.end.line + 1;
      console.log('121212: path', lspDefinitionResult.uri);
      this.remoteFileService.getFile(lspDefinitionResult.uri, false, null).subscribe(
        details => {
          console.log('121212 details: ', details);
          if (details != null) {

            this.openInWorkspace(lspDefinitionResult);
          } else {
            this.fileNotFoundFileMessage();
          }
        }, error => {
          this.fileNotFoundFileMessage();
        }
      );
    } else if (lspDefinitionResult.uri.startsWith('file:///app/resources/lsp/java/workspace/jdt.ls-java-project/')) {
      console.log('1212 else if ');
      lspDefinitionResult.uri = lspDefinitionResult.uri.replace('file:///app/resources/lsp/java/workspace/jdt.ls-java-project/src/', '');
      lspDefinitionResult.range.start.line = lspDefinitionResult.range.start.line + 1;
      lspDefinitionResult.range.end.line = lspDefinitionResult.range.end.line + 1;

      let pathWithNameTest = '/java/src/test/java/' + lspDefinitionResult.uri;
      let pathWithNameMain = '/java/src/main/java/' + lspDefinitionResult.uri;

      this.remoteFileService.getFile(pathWithNameTest, false, null).subscribe(
        details => {
          console.log('1212 pathWithNameTest details: ', details);
          if (details != null) {
            lspDefinitionResult.uri = pathWithNameTest;
            this.openInWorkspace(lspDefinitionResult);
          } else {
            this.remoteFileService.getFile(pathWithNameMain, false, null).subscribe(
              details => {
                if (details != null) {
                  lspDefinitionResult.uri = pathWithNameMain;
                  this.openInWorkspace(lspDefinitionResult);
                } else {
                  this.fileNotFoundFileMessage();
                }
              }, error => {
                this.fileNotFoundFileMessage();

              }
            );
          }


        }, error => {
          console.log('1212 pathWithNameTest error: ');

          this.remoteFileService.getFile(pathWithNameMain, false, null).subscribe(
            details => {
              console.log('1212 pathWithNameMain details: ', details);
              if (details != null) {
                lspDefinitionResult.uri = pathWithNameMain;
                this.openInWorkspace(lspDefinitionResult);
              } else {
                this.fileNotFoundFileMessage();
              }
            }, error => {
              this.fileNotFoundFileMessage();
              console.log('1212 pathWithNameMain error: ');
            }
          );


        }
      );
    }
  }

  private fileNotFoundFileMessage() {
    this.snackBar.open('Access to the file is currently not allowed.', 'Close', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  openInWorkspace(lspDefinitionResult: LspDefinitionResult) {
    console.log('1231 0 pathWithName lsp: ', lspDefinitionResult.uri);
    console.log('1231 0 pathWithName inp: ', this.pathWithName);
    if (lspDefinitionResult.uri == this.pathWithName) {
      this.selectLine(lspDefinitionResult.range.start.line)
    } else if (this.router.url.includes('/files')) {
      this.openNewFileEvent.emit(lspDefinitionResult);
    } else {
      this.router.navigate(['files'], {
        queryParams: {
          path: lspDefinitionResult.uri,
          selectLine: lspDefinitionResult.range.start.line
        }
      });
    }
  }

//   setTimeout(() => {
//   this.webSocket.send('{"jsonrpc":"2.0","id":-1,"method":"textDocument/hover","params":{"textDocument":{"uri":"' + this.codeEditor.getModel().uri + '"},"position":{"line":41,"character":15}}}');
//   this.webSocket.onmessage = (event) => {
//     console.log('EVENT DATA: ', event.data);
//     if (event.data.includes('"id":-1')) {
//       console.log('EVENT DATA INCLUDES -1: ', JSON.parse(event.data).result.contents.value);
//     }
//   }
// }, 5000);
  addActionAIFindBugs() {
    const myAction = monaco.editor.IActionDescriptor = {
      id: 'ai-chat-find-bugs',
      label: 'AI Chat: Find Bugs',
      contextMenuOrder: 0, // choose the order
      contextMenuGroupId: 'AI Chat', // create a new grouping
      // keybindings: [
      //   // eslint-disable-next-line no-bitwise
      //   monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
      // ],
      run: this.actionAiFindBugs.bind(this),
    };

    this.codeEditor.addAction(myAction);
    console.log('ACTIONS: ', this.codeEditor.getSupportedActions());
  }

  actionAiFindBugs() {
    this.doActionAI('Find problems with the following code');
  }

  actionAIOptimize() {
    this.doActionAI('Optimize the following code');
  }

  actionAIExplain() {
    this.doActionAI('Explain the following code');
  }

  actionAIAddComments() {
    this.doActionAI('Add comments to the following code: ');
  }

  actionAICompleteCode() {
    this.doActionAI('Complete the following code');
  }

  doActionAI(question) {
    const selectionText = window.getSelection().toString();
    let selectedCode = '';
    const selection = window.getSelection();

    selectedCode = this.getSelectedText();

    let code = '';

    if (selectedCode != null && selectedCode.trim() != '') {
      code = selectedCode;
    } else {
      code = this.codeEditor.getModel().getValue();
    }

    const message = question + ' \n\n```' + this.language + '\n' + code + '\n```';
    this.globalEventsManager.askAI(message, this.testCase?.id, this.testCase?.name);
  }

  getSelectedText(): string {
    const selection = this.codeEditor.getSelection();
    const model = this.codeEditor.getModel();
    if (selection && model) {
      const startLineNumber = selection.startLineNumber;
      const endLineNumber = selection.endLineNumber;
      const startColumn = selection.startColumn;
      const endColumn = selection.endColumn;
      const range = new monaco.Range(startLineNumber, startColumn, endLineNumber, endColumn);
      return model.getValueInRange(range);
    }
    return '';
  }

  actionAIAdHocPrompt() {
    this.openEditDialog();

  }

  actionAIInsertToPrompt() {
    this.insertCodeToAI();
  }

  openEditDialog() {

    const dialogRef = this.dialog.open(AskQuestionDialogComponent, {
      width: '700px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return;
      }
      this.doActionAI(result);
    });
  }

  private onChangeCode() {

    this.fileChanges.emit(this.codeEditor.getModel().getValue());
  }

  private setLanguage(typeOfFile) {
    if (
      typeOfFile == 'xsd' ||
      typeOfFile == 'mxml' ||
      typeOfFile == 'xsl' ||
      typeOfFile == 'xslt' ||
      typeOfFile == 'xhtml' ||
      typeOfFile == 'dom' ||
      typeOfFile == 'kml' ||
      typeOfFile == 'kmz'
    ) {
      typeOfFile = 'xml';
    }

    if (typeOfFile == 'template') {
      typeOfFile = 'json';
    }

    if (typeOfFile == 'py') {
      typeOfFile = 'python';
    }

    if (typeOfFile == 'robot') {
      typeOfFile = 'text';
    }

    if (typeOfFile == 'js') {
      typeOfFile = 'javascript';
    }

    if (typeOfFile == 'cs') {
      typeOfFile = 'csharp';
    }

    this.editorType = EditorType.Text;

    if (typeOfFile == 'jpg' || typeOfFile == 'jpeg' || typeOfFile == 'png') {
      this.editorType = EditorType.Image;
    }

    if (typeOfFile == 'mp4') {
      this.editorType = EditorType.Video;
    }

    if (typeOfFile == 'html') {
      this.editorType = EditorType.Html;
    }

    this.language = typeOfFile;

  }

  /******************* shortcut ******************/

  private saveCode() {
    this.saveEvent.emit(this.codeEditor.getModel().getValue());
  }

  private compile() {
    this.compileEvent.emit(this.codeEditor.getModel().getValue());
  }

  private run() {
    this.runEvent.emit(this.codeEditor.getModel().getValue());
  }

  private connectToConvergenceServerIfNeeded() {
    // this.auth.user.pipe(first()).subscribe(
    //   user => {
    //     if (this.edit) {
    //       Convergence.connectAnonymously(this.configService.getConvergenceServer(), user.displayName).then((convergenceDomain) => {
    //         this.convergenceDomain = convergenceDomain;
    //
    //         let maxtafEnvironment;
    //         if (window.location.hostname === 'localhost') {
    //           maxtafEnvironment = 'dev';
    //         } else if (window.location.hostname === '192.168.205.86') {
    //           maxtafEnvironment = 'dev';
    //         } else if (window.location.hostname === 'test17.maxtaf.com') {
    //           maxtafEnvironment = 'test17';
    //         } else if (window.location.hostname === 'test18.maxtaf.com') {
    //           maxtafEnvironment = 'test18';
    //         } else if (window.location.hostname === 'mx1.maxtaf.com') {
    //           maxtafEnvironment = 'mx1';
    //         } else {
    //           throw new Error('WRONG ENVIRONMENT: ' + window.location.hostname);
    //         }
    //
    //         convergenceDomain.models().openAutoCreate({
    //           id: maxtafEnvironment + '_' + this.maxtafTokensStorageService.getProjectId() + '_' + this.fileLocation,
    //           collection: 'maxtaf',
    //           data: {
    //             content: this.codeEditor.getModel().getValue()
    //           }
    //         }).then((model) => {
    //           const value = model.elementAt('content').value();
    //           this.codeEditor.setValue(value);
    //           const monacoConvergenceAdapter = new MonacoConvergenceAdapter(this.codeEditor, model.elementAt('content'));
    //           monacoConvergenceAdapter.bind();
    //         }).catch((error) => {
    //           console.error('Could not open the model: ', error);
    //         });
    //         console.log('Convergence connection successful');
    //       }).catch((error) => {
    //         console.error('Convergence connection failure: ', error);
    //       });
    //     }
    //   }
    // );
  }

  private createUrl(): string {
    let k8sFormattedProjectId = this.maxtafTokensStorageService.getProjectId().replace(/_/g, '-');
    switch (this.language) {
      case 'xml':
        return this.configService.getLspXml() + '/' + k8sFormattedProjectId + '?token=' + this.maxtafTokensStorageService.getFireToken();
      case 'java':
        return this.configService.getLspJava() + '/' + k8sFormattedProjectId + '?token=' + this.maxtafTokensStorageService.getFireToken();
      case 'python':
        return this.configService.getLspPython() + '/' + k8sFormattedProjectId + '?token=' + this.maxtafTokensStorageService.getFireToken();
      case 'javascript':
        return this.configService.getLspJs() + '/' + k8sFormattedProjectId + '?token=' + this.maxtafTokensStorageService.getFireToken();
      case 'json':
        return this.configService.getLspJson() + '/' + k8sFormattedProjectId + '?token=' + this.maxtafTokensStorageService.getFireToken();
    }
  }

  private setTimeoutChecker() {
    // If user hasn't touched the editor for 1 minute, close the connection (this way we save up resources)
    this.connectionTimeoutChecker = interval(1000)
      .subscribe(res => {
        if (!this.editorInteractionTriggered) {
          if (this.timeout >= this.lspTimeoutInSeconds) {
            this.closeLanguageServerConnection();
          } else {
            this.timeout++;
          }
        } else {
          this.timeout = 0;
          this.editorInteractionTriggered = false;
        }
      });

    //   setInterval(() => {
    //
    // }, 1000);
  }

  private createReconnectingWebSocket(socketUrl: string): WebSocket {
    const socketOptions = {
      maxReconnectionDelay: 10000,
      minReconnectionDelay: 1000,
      reconnectionDelayGrowFactor: 1.3,
      connectionTimeout: 10000,
      maxRetries: Infinity,
      debug: false
    };
    return new ReconnectingWebSocket.default(socketUrl, [], socketOptions);
  }

  private createLanguageClient(language: string, connection: MessageConnection): MonacoLanguageClient {
    return new MonacoLanguageClient({
      name: `${language.toUpperCase()} Client`,
      clientOptions: {
        // use a language id as a document selector
        documentSelector: [language],
        // disable the default error handler
        errorHandler: {
          error: () => ErrorAction.Continue,
          closed: () => CloseAction.DoNotRestart
        }
      },
      // create a language client connection from the JSON RPC connection on demand
      connectionProvider: {
        get: (errorHandler, closeHandler) => {
          return Promise.resolve(createConnection(connection as any, errorHandler, closeHandler));
        }
      }
    });
  }

  private addActionAIOptimize() {
    const myAction = monaco.editor.IActionDescriptor = {
      id: 'ai-chat-optimize-code',
      label: 'AI Chat: Optimize',
      contextMenuOrder: 1, // choose the order
      contextMenuGroupId: 'AI Chat', // create a new grouping
      // keybindings: [
      //   // eslint-disable-next-line no-bitwise
      //   monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
      // ],
      run: this.actionAIOptimize.bind(this),
    };

    this.codeEditor.addAction(myAction);
  }

  private addActionAIExplain() {
    const myAction = monaco.editor.IActionDescriptor = {
      id: 'ai-chat-explain',
      label: 'AI Chat: Explain',
      contextMenuOrder: 2, // choose the order
      contextMenuGroupId: 'AI Chat', // create a new grouping
      // keybindings: [
      //   // eslint-disable-next-line no-bitwise
      //   monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
      // ],
      run: this.actionAIExplain.bind(this),
    };

    this.codeEditor.addAction(myAction);
  }

  private addActionAIAddComments() {
    const myAction = monaco.editor.IActionDescriptor = {
      id: 'ai-chat-add-comments',
      label: 'AI Chat: Add Comments',
      contextMenuOrder: 3, // choose the order
      contextMenuGroupId: 'AI Chat', // create a new grouping
      // keybindings: [
      //   // eslint-disable-next-line no-bitwise
      //   monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
      // ],
      run: this.actionAIAddComments.bind(this),
    };

    this.codeEditor.addAction(myAction);
  }

  private addActionAICompleteCode() {
    const myAction = monaco.editor.IActionDescriptor = {
      id: 'ai-chat-complete-code',
      label: 'AI Chat: Complete Code',
      contextMenuOrder: 4, // choose the order
      contextMenuGroupId: 'AI Chat', // create a new grouping
      // keybindings: [
      //   // eslint-disable-next-line no-bitwise
      //   monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
      // ],
      run: this.actionAICompleteCode.bind(this),
    };

    this.codeEditor.addAction(myAction);
  }

  private addActionAIAskAnything() {
    const myAction = monaco.editor.IActionDescriptor = {
      id: 'ai-chat-ask-anything',
      label: 'AI Chat: Ad-hoc prompt',
      contextMenuOrder: 5, // choose the order
      contextMenuGroupId: 'AI Chat', // create a new grouping
      // keybindings: [
      //   // eslint-disable-next-line no-bitwise
      //   monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
      // ],
      run: this.actionAIAdHocPrompt.bind(this),
    };

    this.codeEditor.addAction(myAction);
  }

  private addActionAIInsertCode() {
    const myAction = monaco.editor.IActionDescriptor = {
      id: 'ai-chat-ask-insert-code',
      label: 'AI Chat: Insert code',
      contextMenuOrder: 5, // choose the order
      contextMenuGroupId: 'AI Chat', // create a new grouping
      // keybindings: [
      //   // eslint-disable-next-line no-bitwise
      //   monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
      // ],
      run: this.actionAIInsertToPrompt.bind(this),
    };

    this.codeEditor.addAction(myAction);
  }

  private insertCodeToAI() {
    const selectionText = window.getSelection().toString();
    let selectedCode = '';
    const selection = window.getSelection();

    selectedCode = this.getSelectedText();

    let code = '';

    if (selectedCode != null && selectedCode.trim() != '') {
      code = selectedCode;
    } else {
      code = this.codeEditor.getModel().getValue();
    }


    const message = ' \n```' + this.language + '\n' + code + '\n```';


    this.globalEventsManager.addToAiPrompt(message, this.testCase?.id, this.testCase?.name);
  }


}
