import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectMainTabOptionsComponent} from './project-main-tab-options.component';
import {MxOptionsModule} from '../../../mx/options/mx-options/mx-options.module';
import {MatButtonModule} from '@angular/material/button';
import {IconModule} from '../../../mx/icon/icon.module';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [ProjectMainTabOptionsComponent],
  imports: [
    CommonModule,
    MxOptionsModule,
    MatButtonModule,
    IconModule,
    MatTooltipModule
  ],
  exports: [
    ProjectMainTabOptionsComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class ProjectMainTabOptionsModule {
}
