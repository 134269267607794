import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RemoteFilesService} from '../../shared/services/remote-files.service';
import {MaxtafFileNode} from '../nav-workspace/shared/models/MaxtafFileNode';

@Component({
  selector: 'app-zip-dialog',
  templateUrl: './zip-dialog.component.html',
  styleUrls: ['./zip-dialog.component.css']
})
export class ZipDialogComponent implements OnInit {

  createZipFileLocation = '/';
  overwrite = false;
  header = 'Unzip';

  showErrorAlert = false;
  error;

  spinner = false;
  node: MaxtafFileNode;

  constructor(public dialog: MatDialog,
              public storageService: RemoteFilesService,
              public dialogRef: MatDialogRef<ZipDialogComponent>,
              private remoteFileService: RemoteFilesService,
              @Inject(MAT_DIALOG_DATA) public data: {
                node: MaxtafFileNode,
              }) {

    if (data.node != undefined) {
      this.node = data.node;
      this.createZipFileLocation = (data.node.level != 0 ? this.node.pathWithName : data.node.projectId) + '.zip';
    }
  }

  ngOnInit(): void {
  }

  zip() {
    this.zipFileRequest();
  }

  zipFileRequest() {
    this.startLoadingSpinner();
    this.remoteFileService.zip(this.createZipFileLocation, this.node.pathWithName)
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          this.dialogRef.close(true);

        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );
  }

  startLoadingSpinner() {
    this.spinner = true;
  }

  stopLoadingSpinner() {
    this.spinner = false;
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }
}
