import {NgModule} from '@angular/core';
import {SpinnerForTableComponent} from './spinner-for-table.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    SpinnerForTableComponent
  ],
  exports: [
    SpinnerForTableComponent
  ]
})

export class SpinnerForTableModule {
}
