<section class="main-container">

  <div class="row">
    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <mat-select [(value)]="search.exactSearch"
                    placeholder="Filter done as exact match, or not">
          <mat-option value="Contains">Contains</mat-option>
          <mat-option value="Exact">Exact</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md">
      <button (click)="filterRecords()" class="mr-1 mb-1" color="primary" mat-raised-button>
        <mx-icon marginBottom="true" mx="filter"></mx-icon>
        <span class="pl-1">Filter</span>
      </button>
      <button (click)="clearCompileFilter()" class="mr-1 mb-1" color="primary" mat-raised-button>
        <mx-icon marginBottom="true" mx="clear"></mx-icon>
        <span class="pl-1">Clear</span>
      </button>
      <button (click)="clickedClose()" class="mb-1" color="primary"
              mat-raised-button>
        <mx-icon marginBottom="true" mx="close"></mx-icon>
        <span class="pl-1">Close</span>
      </button>
    </div>

  </div>


  <div class="row">

    <div class="col-md">
      <mat-form-field class="w-100">
        <mat-label>Created date</mat-label>
        <input #fromInput (dateInput)="setMinRunDate($event.value)" [matDatepicker]="datepickerMin" matInput>
        <mat-datepicker-toggle [for]="datepickerMin" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datepickerMin></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <mat-label>Last update date</mat-label>
        <input #toInput (dateInput)="setMaxRunDate($event.value)" [matDatepicker]="datepickerMax" matInput>
        <mat-datepicker-toggle [for]="datepickerMax" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datepickerMax></mat-datepicker>
      </mat-form-field>
    </div>

  </div>

  <div class="row">

    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <mat-select [(value)]="search.completed"
                    placeholder="Is done">
          <mat-option value=""></mat-option>
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <input [(ngModel)]="search.errors" [disabled]="lockSearchParams.includes('errors')" matInput
               placeholder="Errors.."
               type="text">
      </mat-form-field>
    </div>

  </div>
</section>
