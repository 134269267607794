<h1 class="text-center" mat-dialog-title>You Have Been Idle!</h1>
<div mat-dialog-content>
  <p>You will time out in {{seconds}} seconds!</p>
</div>
<div mat-dialog-actions>
  <button (click)="logout()" color="warn" id="noButton" mat-button>
    Logout
  </button>

  <button (click)="reset()" color="primary" id="yesButton" mat-button>
    Stay
  </button>
</div>
