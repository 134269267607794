<mx-options
  #mxOptions
  [allowShadow]="allowShadow"
  [defaultColors]="defaultColors"
  [iconMarginBottom]="iconMarginBottom"
  [showButtonText]="showButtonText"
  [showButtons]="showButtons"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
>

  <mx-options-header>

    <!--      delete-->
    <button (click)="prev()" *ngIf="showButtons"
            [matTooltip]="!showTooltip? undefined : 'Prev'"
            id="deleteButton"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="up"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Prev</span>
    </button>

    <!--      refresh-->
    <button (click)="next()"
            *ngIf="showButtons"
            [matTooltip]="!showTooltip? undefined : 'Next'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="down"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Next</span>
    </button>

  </mx-options-header>

  <mx-options-body>

  </mx-options-body>
</mx-options>
