import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditMessageDialogComponent} from './edit-message-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {EditorWorkspaceModule} from '../../../storage/components/editor-workspace/editor-workspace.module';


@NgModule({
  declarations: [EditMessageDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    EditorWorkspaceModule
  ]
})
export class EditMessageDialogModule {
}
