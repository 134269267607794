<!--<h4 mat-dialog-title-->
<!--    style="font-size: medium; text-align: center;">{{(compileErrors == null || compileErrors.length == 0) ? 'Are you sure you want to compile cases' : 'Compile errors'}}</h4>-->
<h4 mat-dialog-title
    style="font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;">{{(!this.startCompile) ? 'Are you sure you want to compile these cases' : (this.endCompile) ? 'Completed case compilation' : 'Compiling cases'}}</h4>

<div class="fs-12" mat-dialog-content>

  <div [@.disabled]="disableAnimation" class="example-headers-align mb-1">

    <div *ngIf="!this.startCompile" style="font-size: 16px; margin-left: 3rem; margin-top: 1rem">
      <mat-radio-group [(ngModel)]="compileType" color="primary">
        <mat-radio-button [disabled]="this.ids.length == 0" [value]="1">Only selected</mat-radio-button>
        <br>
        <mat-radio-button [value]="2">Use Filter</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="this.startCompile" style=" max-width: 100%;">

      <!--      <div class="ml-1" style="font-size: 16px;">-->
      <!--        Compiled: {{compileInfo.currentNumber}}/{{compileInfo.maxNumber}}-->
      <!--      </div>-->

      <!--      <br>-->

      <div class="ml-3 mr-3 mb-2">
        <div style="text-align: center">{{compileInfo.currentNumber}}/{{compileInfo.maxNumber}}</div>
        <mat-progress-bar [value]="compileInfo.currentNumber/compileInfo.maxNumber*100" class=" w-100" mode="determinate"
                          style="height: 10px"></mat-progress-bar>
        <!--    <div style="position:relative; top: -10px; left: 30px;">-->
        <!--      {{compileInfo.currentNumber}}/{{compileInfo.maxNumber}}-->
        <!--    </div>-->
      </div>

      <mat-expansion-panel *ngIf="compileInfo.skipped != null && compileInfo.skipped != ''"
                           style="border-radius: 0;">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Skipped compiles
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="compileInfo.skipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
        </mat-form-field>
      </mat-expansion-panel>

      <!--      <mat-expansion-panel style="border-radius: 0;"-->
      <!--                           *ngIf="compileInfo.errors != null && compileInfo.errors != ''">-->
      <!--        <mat-expansion-panel-header>-->
      <!--          <mat-panel-title>-->
      <!--            Compile errors-->
      <!--          </mat-panel-title>-->
      <!--        </mat-expansion-panel-header>-->

      <!--        <mat-form-field class="w-100">-->
      <!--          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"-->
      <!--                    matAutosizeMinRows="2"-->
      <!--                    [contentEditable]="false"-->
      <!--                    [matTextareaAutosize]="true"-->
      <!--                    [(ngModel)]="compileInfo.errors"-->
      <!--                    (ngModelChange)="paramsChangeValue()"></textarea>-->
      <!--        </mat-form-field>-->
      <!--      </mat-expansion-panel>-->

      <mat-expansion-panel *ngIf="compileInfo.error != null && compileInfo.error != ''"
                           style="border-radius: 0;">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Pre compile error
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="compileInfo.error" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
        </mat-form-field>
      </mat-expansion-panel>

      <app-compile-info-case-info-options
        (filter)="filter($event)"
        (refresh)="refreshCompileInfo();"
        *ngIf="compileInfo.errors != null && compileInfo.errors != ''"

        [(searchParams)]="params.search"
        [checkPaths]="getCompileInfoCaseInfoListOptionsCheckPathObject()">

      </app-compile-info-case-info-options>
      <app-compile-info-case-info-list
        #list
        (clickedItem)="clickedCompileInfo($event)"
        (compiledError)="compiledError($event)"
        (pageParamsChange)="pageParamsChange($event)"
        (pagesInfoChanges)="setPageInfoChanges($event)"
        (transpiledError)="transpiledError($event)"

        *ngIf="compileInfo.errors != null && compileInfo.errors != ''"
        [autoSetItems]="true"
        [compileInfoId]="this.compileInfo.id"
        [liveRefresh]="this.compileInfo.currentNumber != this.compileInfo.maxNumber"

        [pageParams]="params.page"
        [pageSizeOptions]="[5]"

        [searchParams]="params.search">

      </app-compile-info-case-info-list>

      <br>
    </div>


    <!--        <mat-expansion-panel *ngFor="let compileError of compileErrors">-->
    <!--          <mat-expansion-panel-header>-->
    <!--            <mat-panel-title>-->
    <!--              {{compileError.testCase.name}}-->
    <!--            </mat-panel-title>-->
    <!--          </mat-expansion-panel-header>-->


    <!--          <app-show-compile-error [compileResult]="compileError">-->

    <!--          </app-show-compile-error>-->

    <!--        </mat-expansion-panel>-->

  </div>
  <!--  <br>-->
</div>

<div class="d-flex float-right px-4 py-2" mat-dialog-actions>
  <button [mat-dialog-close] mat-button>Close</button>
  <button (click)="compile()" *ngIf="!spinner && !this.startCompile" color="primary" mat-button>
    Compile
  </button>
  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>
