import {ApiService} from '../../../../shared/services/api.service';
import {Injectable} from '@angular/core';
import {RunRequestDetails} from '../models/runRequestDetails';
import {HttpHeaders} from '@angular/common/http';
import {Params} from '../../../mx/util/params/params';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {RunSearchParams} from './run-const.service';
import {Run} from '../models/run';

@Injectable()
export class RunService {

  constructor(
    private apiService: ApiService,
    private cloudConfigService: CloudConfigService
  ) {
  }

  runCase(caseId: string, runDetails?: RunRequestDetails, dataDrivenFile?: File) {
    const formdata: FormData = new FormData();
    if (dataDrivenFile) {
      formdata.append('dataDrivenFile', dataDrivenFile);
    }
    if (runDetails) {
      formdata.append('runDetails', new Blob([JSON.stringify(runDetails)], {
        type: 'application/json'
      }));
    }

    const headers = new HttpHeaders({
      Accept: 'application/json'
    });

    return this.apiService.post(this.cloudConfigService.runCaseWithId(caseId), formdata, headers);
  }

  runSuite(suiteId: string, runDetails?: RunRequestDetails, dataDrivenFile?: File) {
    const formdata: FormData = new FormData();

    if (dataDrivenFile) {
      formdata.append('dataDrivenFile', dataDrivenFile);
    }
    if (runDetails) {
      formdata.append('runDetails', new Blob([JSON.stringify(runDetails)], {
        type: 'application/json'
      }));
    }

    const headers = new HttpHeaders({
      Accept: 'application/json'
    });

    return this.apiService.post(this.cloudConfigService.runSuiteWithId(suiteId), formdata, headers);
  }

  getRuns(searchParams?: any, pageParams?: any) {

    let params = {...searchParams, ...pageParams};

    params = new Params(params);

    return this.apiService.get(this.cloudConfigService.getRunResults(), params);
  }

  changePreventDeletionField(runId: any, preventDeletion: boolean) {
    return this.apiService.put(this.cloudConfigService.changePreventDeletionField(runId) + "?preventDeletion=" + preventDeletion, '{}');
  }

  deleteRunResult(runResultId: any) {
    return this.apiService.delete(this.cloudConfigService.deleteRunResult(runResultId));
  }

  deleteRunResultsFilter(searchParams: RunSearchParams, stopAndDelete: boolean) {
    return this.apiService.delete(
      this.cloudConfigService.deleteRunResults() + '?stopAndDelete=' + stopAndDelete,
      this.generatePublicSearchFromSearch(searchParams)
    );
  }

  public generatePublicSearchFromSearch(searchParams: RunSearchParams) {
    var result = {
      id: '', //+
      minRunId: '', //+
      maxRunId: '', //+

      parentId: '',
      scheduleJobId: null,
      failedAttemptsKey: '',

      type: '', //+
      caseOrSuiteId: '',
      name: '', //+

      userEmail: '',  //+

      minRunDate: '',
      maxRunDate: '',

      minCompletedDate: '',
      maxCompletedDate: '',

      showPassed: false, //+
      showFailed: false, //+
      showStopped: false, //+
      showFailedToStart: false, //+

      showStarted: false, //+
      showQueued: false, //+
      showWaiting: false, //+

      log: '', //+
      console: '', //+
      params: '', //+

      minFailureCount: '', //+
      failureCount: '', //+
      maxFailureCount: '', //+

      failureMessage: '', //+
      failureStackTrace: '', //+

      searchEverywhere: '', //+

      exactSearch: 'Contains',
    };

    Object.keys(searchParams).forEach(key => {
      result[key] = searchParams[key].value;
      if (searchParams[key].value == '') {
        result[key] = null;
      }
    });

    return result;
  }

  deleteRunResultsSelected(selected: Run[], searchParams: RunSearchParams) {
    let queryParams = '';
    for (const runDetails of selected) {
      queryParams = queryParams + 'runIds%5B%5D=' + runDetails.id + '&';
    }
    return this.apiService.delete(
      this.cloudConfigService.deleteRunResults() + '?' + queryParams,
      this.generatePublicSearchFromSearch(searchParams)
    );
  }

  getRunsActive(searchParams?: any, pageParams?: any) {
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getActiveRunResult(), params);
  }

  getRunsActiveList(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getActiveRunResultList(), params);
  }

  getRun(id) {
    return this.apiService.get(this.cloudConfigService.getRunResult(id));
  }

  getRunChildren(id, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.cloudConfigService.getRunChildren(id), params);
  }

  getSuiteRuns(suiteId: string, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.cloudConfigService.getSuiteRuns(suiteId), params);
  }

  getCaseRunsArchived(caseId: string, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.cloudConfigService.getCaseRunsArchived(caseId) + '/' + caseId + '/runs', params);
  }

  getSuiteRunsArchived(suiteId: string, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.cloudConfigService.getSuiteRunsArchived(suiteId), params);
  }

  stopRun(runId) {
    return this.apiService.put(this.cloudConfigService.stopRunResult(runId), '');
  }

  stopAllRun(searchParams?: any, selected = []) {
    let params = {...searchParams};

    params = new Params(params);

    let queryParams = '';

    let isFirst = true;
    for (const id of selected) {
      if (isFirst) {
        queryParams = queryParams + '?ids%5B%5D=' + id + '';
        isFirst = false;
      } else {
        queryParams = queryParams + '&ids%5B%5D=' + id + '';
      }
    }

    return this.apiService.put(this.cloudConfigService.stopAllRunResult() + queryParams, params);
  }

  selfStopAllRun(searchParams?: any, selected = []) {
    let params = {...searchParams};

    params = new Params(params);

    let queryParams = '';

    let isFirst = true;
    for (const run of selected) {
      if (isFirst) {
        queryParams = queryParams + '?ids%5B%5D=' + run.id + '';
        isFirst = false;
      } else {
        queryParams = queryParams + '&ids%5B%5D=' + run.id + '';
      }
    }

    return this.apiService.put(this.cloudConfigService.selfStopAllRunResult() + queryParams, params);
  }

  updateVncStatus(runResultId: string) {
    return this.apiService.put(this.cloudConfigService.updateVncStatus(runResultId), '');
  }

  getRunStopInfo(id) {
    return this.apiService.get(this.cloudConfigService.getRunStopInfo(id), {});
  }

  getRunDeleteInfo(id) {
    return this.apiService.get(this.cloudConfigService.getDeleteRunInfo(id), {});
  }

  getRunDeleteInfos(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getDeleteRunInfos(), params);
  }

  deleteRunDeleteInfos(ids, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    let queryParams = '';

    for (const id of ids) {
      queryParams = queryParams + '&ids%5B%5D=' + id + '';
    }

    return this.apiService.delete(this.cloudConfigService.getDeleteRunInfos() + '?a=b' + queryParams, params);
  }


}
