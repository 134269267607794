import {Component, Inject, OnInit} from '@angular/core';
import {RunSearchParams} from '../../../runs/shared/services/run-const.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {YesNoDialogComponent} from '../../../dialogs/yes-no-dialog/yes-no-dialog.component';
import {CaseService} from '../../shared/services/case.service';
import {Case} from '../../shared/models/case';

@Component({
  selector: 'app-delete-case-dialog',
  templateUrl: './delete-case-dialog.component.html',
  styleUrls: ['./delete-case-dialog.component.css']
})
export class DeleteCaseDialogComponent implements OnInit {

  title = '';
  disableAnimation = true;
  disableSecondButton = false;
  hideAdvancedSettings = false;

  selected: Case[] = [];
  searchParams: RunSearchParams = undefined;

  runId = undefined;
  spinner = false;
  error = undefined;
  showErrorAlert = false;
  deleteType = 1;
  runCount = 1;
  archived = false;
  restore = true;

  constructor(
    public dialogRef: MatDialogRef<DeleteCaseDialogComponent>,
    private caseService: CaseService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      disableSecondButton: boolean,
      runId: string,
      searchParams: RunSearchParams,
      selected: Case[],
      archived: boolean,
      restore: boolean,
      runCount: number,
    }
  ) {
    if (data.runId && data.runId != null) {
      this.runId = data.runId;
    }

    if (data.archived != null) {
      this.archived = data.archived;
    }

    if (data.restore != null) {
      this.restore = data.restore;
    }

    if (data.searchParams && data.searchParams != null) {
      this.searchParams = data.searchParams;
      this.deleteType = 2;
    }


    if (data.selected && data.selected != null) {
      this.selected = data.selected;

      if (this.selected.length > 0) {
        this.deleteType = 1;
      }
    }

    if (data.runCount && data.runCount != null) {
      this.runCount = data.runCount;
    }

    if (data.title && data.title != null) {
      this.title = data.title;
    }

    if (data.disableSecondButton && data.disableSecondButton != null) {
      this.disableSecondButton = data.disableSecondButton;
    }


    if (this.selected.length == 0 && this.searchParams == undefined) {
      this.hideAdvancedSettings = true;
    }

  }


  // ESC shortcut for closing the window
  noShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('noButton').click();
    }
  };

  // ENTER shortcut for running the test
  yesShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('yesButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.noShortcutListener, false);
    document.addEventListener('keydown', this.yesShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.noShortcutListener, false);
    document.removeEventListener('keydown', this.yesShortcutListener, false);
  }

  deleteRunResult() {
    this.spinner = true;
    if (this.hideAdvancedSettings) {
      if (this.searchParams) {
        this.doAction();
      } else {
        this.dialogRef.close('single');
      }

    } else {
      if (this.selected.length > 0) {
        if (this.deleteType == 1) {
          this.dialogRef.close('single');
        }

        if (this.deleteType == 2) {
          this.doAction();

        }

      } else {
        this.doAction();
      }
    }
  }

  doAction() {

    if (this.archived) {
      if (this.restore) {
        this.caseService.restoreCases(this.searchParams).subscribe(
          res => {
            // this.dialogRef.close();
            this.dialogRef.close('filter');
          },
          error => {
            this.spinner = false;
            // this.showError(error);
            // this.router.navigate(['/runs']);
            // this.dialogRef.close();
          }
        );

      } else {
        this.caseService.deleteCasesArchived(this.searchParams).subscribe(
          res => {
            // this.dialogRef.close();
            this.dialogRef.close('filter');
          },
          error => {
            this.spinner = false;
            this.showError(error);
            // this.router.navigate(['/runs']);
            // this.dialogRef.close();
          }
        );
      }
    } else {
      this.caseService.deleteCases(this.searchParams).subscribe(
        res => {
          // this.dialogRef.close();
          this.dialogRef.close('filter');
        },
        error => {
          this.spinner = false;
          this.showError(error);
          // this.router.navigate(['/runs']);
          // this.dialogRef.close();
        }
      );
    }
  }

  deleteDeleteDialog(): void {
    if (this.archived && !this.restore) {

      const deleteCount = ((this.deleteType == 1) ? this.selected.length : this.runCount);
      const dialogRef = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          body: '',
          title: deleteCount == 1 ? 'Confirm that you want to delete ' + deleteCount + ' case? This action is irreversible. ' : 'Confirm that you want to delete ' + deleteCount + ' cases? This action is irreversible. ',
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteRunResult();
        }
      });
    } else {
      this.deleteRunResult();
    }
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

}
