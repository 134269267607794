import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ProjectSearchParams} from '../../shared/services/project-const.service';
import {CheckPath} from '../../../mx/options/CheckPath';
import {MxOptionAbstract} from '../../../mx/options/MxOptionAbstract';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';

export class ProjectOptionsPaths {
  filter: CheckPath;
  create: CheckPath;
  refresh: CheckPath;
}


@Component({
  selector: 'app-project-options',
  templateUrl: './project-options.component.html',
  styleUrls: ['./project-options.component.css']
})
export class ProjectOptionsComponent extends MxOptionAbstract<ProjectOptionsPaths> implements OnInit {


  @Input('searchParams') searchParams: ProjectSearchParams;
  @Output('searchParamsChange') searchParamsChange = new EventEmitter<ProjectSearchParams>();
  @Input('lockFilterParams') lockFilterParams = [];
  @Input('hasSelected') hasSelected = true;
  @Input('focusFilter') focusFilter = true;

  @Input('allowShadow') allowShadow = true;
  @Input('allowEdit') edit = true;
  @Input('showButtons') showButtons = true;
  @Input('showButtonText') showButtonText = true;
  @Input('showTooltip') showTooltip = false;
  @Input('smallHeight') smallHeight = false;
  @Input('defaultColors') defaultColors = true;
  @Input('iconMarginBottom') iconMarginBottom = true;

  @Output('filter') filterEvent = new EventEmitter<ProjectSearchParams>();
  @Output('create') createEvent = new EventEmitter<null>();
  @Output('refresh') refreshEvent = new EventEmitter<null>();


  constructor(public dialog: MatDialog,
              public checkRolesService: CheckRolesService) {
    super(checkRolesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  create(): void {
    this.createEvent.emit();
  }

  refresh(): void {
    this.refreshEvent.emit();
  }

  filter(): void {
    this.filterEvent.emit(this.searchParams);
  }

  newParams(searchParams: ProjectSearchParams): void {
    this.searchParams = {...searchParams};
    this.searchParamsChange.emit(this.searchParams);
  }

}
