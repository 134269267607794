import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DownloadFileDialogComponent} from './download-file-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [DownloadFileDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  exports: [
    DownloadFileDialogComponent
  ]
})
export class DownloadFileDialogModule {
}
