import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ChatSession} from '../../shared/models/chatSession';
import {AiChat} from '../../shared/models/aiChat';
import {Observable, Subscription} from 'rxjs';
import {AiChatStreamDelta} from '../../shared/models/aiStreamChat';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-ngx';
import {AiConfigService} from '../../shared/services/ai-config.service';
import {AiChatService} from '../../shared/services/ai-chat.service';
import {CaseService} from '../../../cases/shared/services/case.service';
import {Case} from '../../../cases/shared/models/case';
import {EditSessionDialogComponent} from '../edit-session-dialog/edit-session-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-chat-session',
  templateUrl: './chat-session.component.html',
  styleUrls: ['./chat-session.component.css']
})
export class ChatSessionComponent implements OnInit, OnDestroy {
  @ViewChild('pageScroll') public pageScroll: OverlayScrollbarsComponent;

  session: ChatSession;
  @Input('activeChat') activeChat: AiChat;
  chatRecords: AiChat[] = [];
  errorMessage = '';
  @Input('showLoadResponseDiv') showLoadResponseDiv = false;
  aiRequest: Subscription;
  @Input('isRunActive') isRunActive = false;
  @Input('activeSpinner') activeSpinner = false;
  maxHeight: string;
  runId: string;
  @Output('startQuestion') startQuestionEvent = new EventEmitter<void>();
  @Output('completedQuestion') completedQuestionEvent = new EventEmitter<void>();

  private response: string;
  private esAIChatAskQuestion: EventSource;
  private esAIChat: EventSource;
  private esAIChatRequest: Subscription;

  private intervalIdAskAI: any;
  private intervalIdGetChatRecords: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private aiConfigService: AiConfigService,
    public dialog: MatDialog,
    private aiChatService: AiChatService,
    private caseService: CaseService,
    public tokensService: MaxtafTokensStorageService,
  ) {
  }

  @Input('chatSession') set setChatSession(session: ChatSession) {
    this.session = session;
    if (this.session == null || this.session.id == null || this.session.id == '') {
      this.chatRecords = [];
    } else {
      this.getChatRecordsForSession();
    }
  }

  @Input('maxHeight') set setScrollHeight(maxHeight: number) {
    this.maxHeight = (maxHeight) + 'px';
  }

  refreshDataAndAsk() {
    this.getChatRecordsForSession(true);
  }

  ngOnInit(): void {
  }

  public askQuestion(question: string, sessionId: string = null, caseId: string) {
    const sessionIdForQuestion = sessionId != null ? sessionId : this.session.id;

    if (caseId != null) {
      this.caseService.getCase(caseId).subscribe(
        (testCase: Case) => {

          question += '\n\nHere is the current code:\n```' + testCase.caseType.toLowerCase() + '\n' + testCase.code + '\n```\n';
          this.realAskQuestion(question, sessionId);
        }, error => {
          console.error('error: ', error);
          this.realAskQuestion(question, sessionId);
        });
    } else {
      this.realAskQuestion(question, sessionId);
    }

  }

  public realAskQuestion(question: string, sessionId: string = null) {
    const sessionIdForQuestion = sessionId != null ? sessionId : this.session.id;

    this.aiChatService.saveQuestion(
      sessionIdForQuestion,
      question
    ).subscribe(
      (aiChat: AiChat) => {
        this.addNewChatRecordToChatRecordsAndSort(aiChat);
        // setTimeout(() => {
        // this.scrollToBottom(0);
        this.askQuestionWithoutContent();
        // }, 500);

      }, error => {
        console.error('error: ', error);
      });

  }

  ngOnDestroy() {
    this.stopGetChatRecordsForSessionRequest();
    this.stopAIRequest();
  }

  askQuestionWithoutContent() {
    this.stopAIRequest();

    this.startQuestionEvent.emit();

    this.activeChat = null;
    this.showLoadResponseDiv = true;
    this.isRunActive = true;

    // setTimeout(() => {
    //   this.scrollToBottom();
    // }, 500);


    const user = this.tokensService.getUserId();
    const project = this.tokensService.getProjectId();
    const userProject = this.tokensService.getUserProjectID();
    const fireToken = this.tokensService.getFireToken();
    const refreshToken = this.tokensService.getFireRefreshToken();


    this.esAIChatAskQuestion = new EventSource(
      '/api/ai/ask/session/' + this.session.id +
      '?' + this.tokensService.USER_PROJECT_TOKEN + '=' + userProject + '&' +
      this.tokensService.PROJECT_TOKEN + '=' + project + '&' +
      this.tokensService.USER_TOKEN + '=' + user + '&' +
      this.tokensService.FIREBASE_TOKEN + '=' + fireToken + '&' +
      this.tokensService.FIREBASE_REFRESH_TOKEN + '=' + refreshToken + '&' +
      this.tokensService.USER_TIMEZONE_ID + '=' + Intl.DateTimeFormat().resolvedOptions().timeZone + ''
    );

    this.response = '';
    this.errorMessage = '';

    console.log('%c 151 pre setInterval', 'background: black; color: white;');
    this.intervalIdAskAI = setInterval(() => {
      console.log('%c 151 setInterval', 'background: gray');
      this.scrollToBottom(1000);
    }, 1000);

    this.aiRequest = this.askMaxtafAI().subscribe({
      next: (result) => {
        this.errorMessage = '';
        this.isRunActive = true;
        this.showLoadResponseDiv = false;

        let objResponse: AiChatStreamDelta;
        try {
          objResponse = JSON.parse(result);
          if (objResponse.error) {
            this.isRunActive = false;
            this.showLoadResponseDiv = false;
            this.errorMessage = objResponse.content;

            this.closeAskAIEvent();
          }

          this.runId = objResponse.runId;

          if (objResponse.content != null) {
            this.response += objResponse.content;
          }
          this.activeChat = new AiChat('id', 'assistant', this.response, this.session.userProjectId, true, this.session.id);
        } catch (e) {
          console.error('error: ', e);
          this.showLoadResponseDiv = false;
          this.closeAskAIEvent();
        }

        // this.scrollToBottomLive();
      },
      error: (error) => {
        console.error('error: ', error);
        this.isRunActive = false;
        this.showLoadResponseDiv = false;
        this.closeAskAIEvent();
      },
      complete: () => {
        this.isRunActive = false;
        this.showLoadResponseDiv = false;
        this.clearScrollInterval();
      }
    });
  }

  clearScrollInterval() {
    if (this.intervalIdAskAI) {
      clearInterval(this.intervalIdAskAI);
      this.intervalIdAskAI = null;
    }
    console.log('%c 151 clearScrollInterval', 'background: gray; color: red');
    this.scrollToBottom(1000);
  }

  clearScrollIntervalGetChatRecords() {
    if (this.intervalIdGetChatRecords) {
      clearInterval(this.intervalIdGetChatRecords);
      this.intervalIdGetChatRecords = null;
    }
    console.log('%c 151 clearScrollInterval', 'background: pink; color: red');
    this.scrollToBottom(1000);
  }

  closeAskAIEvent() {
    this.esAIChatAskQuestion.close();
    this.clearScrollInterval();
    this.addNewChatRecordToChatRecordsAndSort(this.activeChat);
    this.activeChat = null;

    this.getChatRecordsForSession();
    this.completedQuestionEvent.emit();
    // this.runId = null;
  }

  isOverflowingTitle(element: HTMLElement): boolean {
    return element.offsetWidth < element.scrollWidth;
  }

  stopGetChatRecordsForSessionRequest() {
    try {
      if (this.esAIChatRequest) {
        this.esAIChatRequest.unsubscribe();
      }
    } catch (e) {
      console.error(e);
    }
  }

  closeEventGetChat(askAI = false) {
    this.esAIChat.close();
    this.activeSpinner = false;

    this.zone.run(() => {
      this.scrollToBottom(0);
      this.cdr.detectChanges();
    });
    // this.sortChatRecordList();
    console.log('151 closeEventGetChat');
    this.clearScrollIntervalGetChatRecords();
    if (askAI) {
      this.askQuestionWithoutContent();
    }
  }

  editSessions(session: ChatSession) {

    const dialogRef = this.dialog.open(EditSessionDialogComponent, {
      width: '500px',
      data: {
        session
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  getChatRecordsForSession(askAI = false) {
    this.stopGetChatRecordsForSessionRequest();

    this.activeChat = null;

    if (this.session == null || this.session.id == null || this.session.id == '' || (this.chatRecords != null && this.chatRecords.length > 0 && this.session.id != null && this.session.id != this.chatRecords[0].sessionId)) {
      this.chatRecords = [];
    }

    if (this.chatRecords == null || this.chatRecords.length == 0) {
      this.activeSpinner = true;
    }


    // if (this.session == null || this.session.id == null || this.session.id == '') {
    //   this.chatRecords = [];
    // }

    // setTimeout(() => {
    //   this.scrollToBottom();
    // }, 500);


    const user = this.tokensService.getUserId();
    const project = this.tokensService.getProjectId();
    const userProject = this.tokensService.getUserProjectID();
    const fireToken = this.tokensService.getFireToken();
    const refreshToken = this.tokensService.getFireRefreshToken();


    this.esAIChat = new EventSource(
      '/api/ai' + '/chat/session/' + this.session.id +
      '?' + this.tokensService.USER_PROJECT_TOKEN + '=' + userProject + '&' +
      this.tokensService.PROJECT_TOKEN + '=' + project + '&' +
      this.tokensService.USER_TOKEN + '=' + user + '&' +
      this.tokensService.FIREBASE_TOKEN + '=' + fireToken + '&' +
      this.tokensService.FIREBASE_REFRESH_TOKEN + '=' + refreshToken + '&' +
      this.tokensService.USER_TIMEZONE_ID + '=' + Intl.DateTimeFormat().resolvedOptions().timeZone + ''
    );
    // if (this.chatRecords.length > 0) {
    //   this.chatRecords[0].sessionId == this.session.id
    // }

    // this.chatRecords = [];
    this.response = '';

    let lastAddedChatRecordId = null;

    // const intervalId = setInterval(() => {
    //   this.scrollToBottom(500);
    // }, 500);

    console.log('%c 151 getChatRecordsForSession: ', 'background: gold; color: black');

    // this.intervalIdGetChatRecords = setInterval(() => {
    //   console.log('%c 151 intervalIdGetChatRecords', 'background: orange');
    //   // this.scrollToBottom(1000);
    // }, 1000);


    this.esAIChatRequest = this.getMaxtafAI().subscribe({
      next: (result) => {
        let newChatRecord: AiChat;
        try {
          this.activeSpinner = false;
          newChatRecord = JSON.parse(result);

          if (newChatRecord != null) {

            // this.addToChatArray([objResponse]);]
            this.addNewChatRecordToChatRecordsArray(newChatRecord, lastAddedChatRecordId);

            lastAddedChatRecordId = newChatRecord.id;

            // console.log('%c 151 clearScrollInterval getChatRecordsForSession', 'background: gray; color: blue', newChatRecord);
            // this.scrollToBottom(1000);

            // this.scrollToBottomLive();
          }

        } catch (e) {
          console.log('151 catch');
          this.closeEventGetChat(askAI);
        }


      },
      error: (error) => {
        console.log('151 error');
        this.closeEventGetChat(askAI);


      },
      complete: () => {
        console.log('151 complete');
        this.closeEventGetChat(askAI);
      }
    });
  }

  scrollToBottomLive() {
    const osInstance = this.pageScroll.osInstance();
    const scrollInfo = osInstance.scroll();

    if (scrollInfo.max.y - scrollInfo.position.y < 100) {
      osInstance.scroll({y: '100%'}, 0);
    }
  }

  scrollToBottom(duration = 500) {
    console.log('%c 151 scrollToBottom: ', 'background: blue; color: white');
    const osInstance = this.pageScroll.osInstance();
    osInstance.scroll({y: '100%'}, duration);
  }

  scrollY(y: number, duration: number = 0) {
    this.pageScroll.osInstance().scroll(
      {
        x: this.pageScroll.osInstance().getState().overflowAmount.x,
        y
      },
      duration
    );
  }

  getMaxtafAI(): Observable<string> {
    return new Observable<string>((observer) => {
      this.esAIChat.onmessage = (event) => {
        observer.next(event.data);
      };

      this.esAIChat.onerror = (error) => {
        observer.error(error);
      };
      return () => {
        console.log('151 return');
        // this.closeEventGetChat();
      };
    });
  }


  public stopAIRequest() {
    try {
      this.completedQuestionEvent.emit();
      if (this.aiRequest) {
        this.aiRequest.unsubscribe();
      }

      this.clearScrollInterval();

    } catch (e) {
      console.error('error', e);
    } finally {
      this.cancelAiRequest();
    }
  }

  public cancelAiRequest() {
    if (this.runId != null) {

      this.aiChatService.cancelAiRequest(this.session.id, this.runId)
        .subscribe(
          chatSession => {
            this.runId = null;
          },
          error => {
            console.error(error);
          }
        );
    } else {
    }

  }

  askMaxtafAI(): Observable<string> {
    return new Observable<string>((observer) => {
      this.esAIChatAskQuestion.onmessage = (event) => {
        observer.next(event.data);
      };

      this.esAIChatAskQuestion.onerror = (error) => {
        observer.error(error);
        this.clearScrollInterval();
        // this.runId = null;
      };
      return () => {
        this.esAIChatAskQuestion.close();
        this.clearScrollInterval();
        // this.runId = null;
      };
    });
  }

  private addNewChatRecordToChatRecordsArray(newChatRecord: AiChat, lastAddedChatRecordId: string) {

    if (newChatRecord == null || newChatRecord.id == null) {
      return;
    }
    const exists = this.chatRecords.some(item => item.id === newChatRecord.id);

    if (lastAddedChatRecordId === null) {
      if (this.chatRecords.length > 0 && this.chatRecords[0].id !== newChatRecord.id) {
        this.chatRecords = [];
      }
    } else {
      const lastAddedIndex = this.chatRecords.findIndex(item => item.id === lastAddedChatRecordId);
      if (lastAddedIndex > -1) {
        if (exists) {
          const newChatRecordIndex = this.chatRecords.findIndex(item => item.id === newChatRecord.id);
          if (newChatRecordIndex > lastAddedIndex + 1) {
            this.chatRecords = this.chatRecords.slice(0, lastAddedIndex + 1);
          }
        } else {
          if (lastAddedIndex < this.chatRecords.length - 1) {
            this.chatRecords = this.chatRecords.slice(0, lastAddedIndex + 1);
          }
        }
      }
    }
    this.addNewChatRecordToChatRecordsAndSort(newChatRecord, exists);
  }


  private addNewChatRecordToChatRecordsAndSort(newChatRecord: AiChat, exists = null) {
    if (newChatRecord == null || newChatRecord.id == null) {
      return;
    }

    exists = this.chatRecords.some(item => item.id === newChatRecord.id);
    if (!exists) {
      this.chatRecords.push(newChatRecord);
    }
    this.sortChatRecordList();
  }


  private sortChatRecordList() {
    try {
      this.chatRecords.sort((a, b) => new Date(a.lastModifiedDate).getTime() - new Date(b.lastModifiedDate).getTime());
    } catch (e) {
      console.log('%c 151 sortChatRecordList Error', 'background: red;', e);
    }
    // console.log('%c 151 clearScrollInterval sortChatRecordList newChatRecord:', 'background: gray; color: white');


    // this.zone.run(() => {
    //   this.scrollToBottom(1000);
    //   this.cdr.detectChanges();
    // });

  }

  deleteChatRecords(chatRecordId: string) {
    const lastAddedIndex = this.chatRecords.findIndex(item => item.id === chatRecordId);
    this.chatRecords = this.chatRecords.slice(0, lastAddedIndex);
  }

  editChatRecord(chatRecordId) {
    const lastAddedIndex = this.chatRecords.findIndex(item => item.id === chatRecordId);
    this.chatRecords = this.chatRecords.slice(0, lastAddedIndex);
  }
}
