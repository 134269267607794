<h1 mat-dialog-title>Upload to <b>{{ data.uploadPath }}</b></h1>
<div mat-dialog-content>

  <overlay-scrollbars
    #navTreeScrolling
    [ngStyle]="{'max-height': '470px'}"
    [options]="{ scrollbars: { autoHide: 'l' } }"
  >

    <div class="dialog-content">

      <div (fileDropped)="onFileDropped($event)" appDnd class="container">
        <input #fileDropRef (change)="fileBrowseHandler($event.target.files)" id="fileDropRef" multiple type="file"/>

        <mx-icon class="mx-icon-upload" color="primary" mx="upload"></mx-icon>

        <h3>Drag and drop file here</h3>
        <h3>or</h3>
        <label for="fileDropRef">Browse for file</label>
      </div>


      <div class="files-list">
        <div *ngFor="let file of files; let i = index" class="single-file-container">

          <div class="single-file">
            <app-nav-tree-file-icon [color]="file?.status == 'fail'?'warn':'primary'" [extension]="file?.fileType"
                                    class="file-icon"></app-nav-tree-file-icon>
            <div class="info">
              <h4 class="name">
                {{ file?.name }}
              </h4>
              <p class="size">
                {{ formatBytes(file?.size, 2) }}
              </p>
              <mat-progress-bar
                [color]="file?.status == 'fail'?'warn':'primary'"
                [mode]="'determinate'"
                [value]="file?.progress"
                class="example-margin">
              </mat-progress-bar>
            </div>
            <div style="flex-direction: column;">
              <!--              <mat-checkbox-->
              <!--                *ngIf="file.fileType == 'java' || file.fileType == 'py'"-->
              <!--                color="primary"-->
              <!--                [disabled]="file.status != 'wait'"-->
              <!--                [checked]="file?.uploadAsCase"-->
              <!--                (change)="file.uploadAsCase = true"-->
              <!--                style="margin-left: 0.7rem;"-->
              <!--                matTooltip="Upload As Case."-->
              <!--              ></mat-checkbox>-->
              <div style="width: 20px; height: 20px;"></div>

              <mx-icon (click)="deleteFile(i)" class="delete" color="accent" hoverColor="warn" mx="delete"></mx-icon>
            </div>
          </div>

          <app-error-message *ngIf="file.error != undefined" [cleanDesign]="true" [error]="file.error"
                             [showInDialog]="true"
                             style="width: 100%;"></app-error-message>

        </div>
      </div>
      <!--  </div>-->
      <div class="mb-2 w-100">
        <mat-checkbox (change)="overwrite = !overwrite" [checked]="overwrite" id="overwriteCheck"
                      type="checkbox">Overwrite data with import file?
        </mat-checkbox>
      </div>

    </div>
  </overlay-scrollbars>
</div>
<div mat-dialog-actions>
  <button [mat-dialog-close] mat-button>Close</button>

  <button (click)="uploadFiles()" *ngIf="!spinner" color="primary" mat-button>
    <span>Upload</span>
  </button>

  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>
