import {NgModule} from '@angular/core';
import {UserRequestsListComponent} from './user-requests-list.component';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UserFilterModule} from '../user-filter/user-filter.module';
import {CommonModule} from '@angular/common';
import {SpinnerForTableModule} from '../../../../shared/util-components/spinner-for-table/spinner-for-table.module';
import {UserListOptionsModule} from '../user-list-options/user-list-options.module';
import {IconModule} from '../../../mx/icon/icon.module';
import {MatSortModule} from '@angular/material/sort';
import {YesNoDialogModule} from '../../../dialogs/yes-no-dialog/yes-no-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSnackBarModule,
    UserFilterModule,
    SpinnerForTableModule,
    UserListOptionsModule,
    IconModule,
    MatSortModule,
    YesNoDialogModule
  ],
  declarations: [
    UserRequestsListComponent
  ],
  exports: [
    UserRequestsListComponent
  ]
})

export class UserRequestsListModule {
}
