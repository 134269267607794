<div mat-dialog-content>
  <label>Ask Anything...</label>
  <!--  <div *ngIf="type == 'input'">-->
  <!--    <input type="text" [(ngModel)]="value">-->
  <!--  </div>-->
  <div>
    <mat-form-field class="w-100">
      <overlay-scrollbars
        [options]="{ scrollbars: { autoHide: 'n' } }"
        style="max-height: 200px !important;"
      >
        <textarea [(ngModel)]="value" [matTextareaAutosize]="true" class="overflow-hidden" matInput
                  placeholder=""></textarea>
      </overlay-scrollbars>
    </mat-form-field>
  </div>
  <!--  <div *ngIf="type == 'number'">-->
  <!--    <input OnlyNumber="true" matInput type="number" maxlength="6" placeholder=""-->
  <!--           [(ngModel)]="value">-->
  <!--  </div>-->
</div>

<div mat-dialog-actions>
  <!--  <button mat-button *ngIf="name=='Params:'" color="primary">-->
  <!--    <a target="_blank" href="https://www.maxtaf.com/guides/mtc-guide/?version=Cloud#caseParams">-->
  <!--      <mx-icon mx="information"></mx-icon>-->
  <!--    </a>-->
  <!--  </button>-->

  <button (click)="cancel()" mat-button>Cancel</button>
  <button [mat-dialog-close]="value" color="primary" id="okButton" mat-button>Ask</button>
</div>

