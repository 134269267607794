import {NgModule} from '@angular/core';
import {LinkComponent} from './link.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {IconModule} from '../icon/icon.module';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    IconModule,
    CommonModule
  ],
  declarations: [
    LinkComponent
  ],
  exports: [
    LinkComponent
  ]
})

export class LinkModule {
}
