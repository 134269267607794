import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiKeyService} from "../../shared/services/api-key.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-create-api-key',
  templateUrl: './create-api-key.component.html',
  styleUrls: ['./create-api-key.component.css']
})
export class CreateApiKeyComponent implements OnInit {

  apiKey = '';
  @ViewChild('message') messageElement: ElementRef;

  constructor(
    private apiKeyService: ApiKeyService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateApiKeyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {}) {
    dialogRef.disableClose = true;
    this.createApiKey();
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  createApiKey() {
    this.apiKeyService.createApiKey().subscribe(
      res => {
        this.apiKey = res.apiKey;
      }, error => {
        this.showError(error);
      })
  }

  ngAfterViewInit() {
    this.messageElement.nativeElement.focus();
  }

  showError(error) {
    console.error(error);
  }

  copyLogToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.apiKey;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.openSnackBar('Successfully copied to clipboard', 'OK', 5);
  }

  openSnackBar(message: string, action: string, time: number) {
    this.snackBar.open(message, action, {
      duration: time
    });
  }


  downloadApiKey() {

    const blob = new Blob([this.apiKey], {type: 'text/plain'});
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'api-key.txt';

    link.click();

    window.URL.revokeObjectURL(url);

  }
}
