import {Component, OnDestroy, OnInit} from '@angular/core';
import {CaseFileLocation} from '../../shared/models/case-file-location';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MaxtafFileNode} from '../../../storage/components/nav-workspace/shared/models/MaxtafFileNode';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {Case} from '../../shared/models/case';
import {CaseService} from '../../shared/services/case.service';
import {RemoteFilesService} from "../../../storage/shared/services/remote-files.service";
import {HttpEvent, HttpResponse} from "@angular/common/http";

@Component({
  selector: 'app-create-case-or-page-object',
  templateUrl: './generate-from-html.component.html',
  styleUrls: ['./generate-from-html.component.css']
})
export class GenerateFromHtmlComponent implements OnInit, OnDestroy {
  showErrorAlert = false;
  error;
  redirect = true;

  case = new Case();

  method = '0';

  saveSpinner = false;
  startFileLocationFullPath = '';

  fileLocation: CaseFileLocation = undefined;

  openCreatedCase = true;

  localSelectedFiles: any[] = [];
  localFileName = 'Choose file';

  isCopy = true;
  copyNode: MaxtafFileNode = undefined;
  rootWorkspaceNode = [];
  expandWorkspace = true;

  workspaceFilePath = '';
  workspaceFileSelected = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenerateFromHtmlComponent>,
    private tokensService: MaxtafTokensStorageService,
    private caseService: CaseService,
    private remoteFileService: RemoteFilesService,
  ) {

    this.rootWorkspaceNode = [this.createInitMaxtafFileNode(this.tokensService.getProjectId())];

    dialogRef.disableClose = true;

    this.case.caseType = 'UTAM';
    this.case.pageObject = true;
  }

// ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  }

  // ENTER shortcut for running the test
  runShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('createButton').click();
    }
  }

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.runShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.runShortcutListener, false);
  }


  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  hideErrorAlert() {
    this.error = null;
    this.showErrorAlert = false;
  }

  closeDialog(value: boolean = false): void {
    this.dialogRef.close(value);
  }

  generate() {
    this.addCase();
  }

  addCase() {
    this.saveSpinner = true;

    this.case.caseType = 'UTAM';

    this.case.fileLocation = new CaseFileLocation();
    this.case.fileLocation.projectParamPart = '/.';
    switch (this.method.toString()) {
      case '0':
        this.remoteFileService.uploadFile(this.localSelectedFiles[0], '/utam/').subscribe(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.case.name = this.localFileName.substring(0, this.localFileName.indexOf('.'));
              this.case.fileLocation.packageCasePart = '/utam/' + this.localFileName;
              this.generateUtamCase();
            }
          },
          (error) => {
            this.saveSpinner = false;
            alert('error: ' + error.toString())
          }
        );
        this.case.fileLocation.packageCasePart = null;
        break;
      case '1':
        this.case.name = this.workspaceFilePath.substring(this.workspaceFilePath.lastIndexOf('/') + 1, this.workspaceFilePath.indexOf('.'));
        this.case.fileLocation.packageCasePart = this.workspaceFilePath;
        this.generateUtamCase();
        break;
      default:
        this.case.fileLocation = null;
        break;
    }
  }

  selectedFile(node: MaxtafFileNode) {
    this.hideErrorAlert();
    if (node.name.toLowerCase().match('.*\.html|.*\.xhtml')) {
      this.workspaceFilePath = node.pathWithName;
      this.workspaceFileSelected = true;
    } else {
      this.showError({
        name: 'Invalid format!',
        message: 'Supported extensions are xhtml and html - please select one of these for import.'
      });
    }
  }

  selectLocalFile(event) {
    this.hideErrorAlert();
    const file: File = event.target.files.item(0);

    if (file.name.toLowerCase().match('.*\.html|.*\.xhtml')) {
      this.localSelectedFiles = this.prepareFilesList(event.target.files);

      this.localFileName = this.localSelectedFiles[0].name;

    } else {
      this.showError({
        name: 'Invalid format!',
        message: 'Supported extensions are xhtml and html - please select one of these for import.'
      });
      this.localSelectedFiles = null;
      this.localFileName = 'Choose file';
    }
  }

  prepareFilesList(files: Array<any>) {
    const preparedFiles: any[] = [];
    for (const item of files) {
      item.progress = 0;
      item.status = 'wait';
      item.error = undefined;
      item.fileType = item.name.toString().substr(item.name.toString().lastIndexOf('.') + 1);
      preparedFiles.push(item);
    }

    return preparedFiles;
  }

  private generateUtamCase() {
    this.caseService.generateUtam(this.case)
      .subscribe(
        res => {
          this.saveSpinner = false;
          if (this.redirect) {
            this.openCase(res.id, res.pageObject == null || res.pageObject == true);
          }
          this.closeDialog(res);
        },
        error => {
          this.showError(error);
          this.saveSpinner = false;
        }
      );
  }

  private openCase(id, isPageObject) {
    if (this.openCreatedCase) {
      this.router.navigate(
        ['cases-workspace'],
        {queryParams: {caseId: id}}
      );
    }
  }

  private createInitMaxtafFileNode(projectId: string): MaxtafFileNode {
    return new MaxtafFileNode(
      projectId,
      '/',
      '/',
      '',

      0,

      true,
      false,
      projectId
    );

  }
}
