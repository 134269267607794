<h1 *ngIf="title != ''" class="text-center" mat-dialog-title>{{title}}</h1>
<div *ngIf="selectedChatSession == undefined" mat-dialog-content>
  <mat-checkbox [(ngModel)]="useFilter">Use filter</mat-checkbox>
</div>
<div mat-dialog-actions>

  <button [mat-dialog-close]="undefined" id="cancelButton" mat-button>
    Cancel
  </button>


  <button (click)="deleteSessions()" color="primary" id="yesButton" mat-button>
    Yes
  </button>

</div>
