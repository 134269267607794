import {ApiService} from '../../../../shared/services/api.service';
import {Injectable} from '@angular/core';
import {Params} from '../../../mx/util/params/params';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';

@Injectable()
export class SuiteService {

  constructor(
    private apiService: ApiService,
    private cloudConfigService: CloudConfigService
  ) {
  }

  getAll(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.cloudConfigService.getSuites(), params);
  }

  getAllArchived(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.cloudConfigService.getSuitesFromTrash(), params);
  }

  getSuite(id: string) {
    return this.apiService.get(this.cloudConfigService.getSuite(id));
  }

  getSuiteArchived(id: string) {
    return this.apiService.get(this.cloudConfigService.getSuiteArchived(id));
  }

  addSuite(body: any) {
    return this.apiService.post(this.cloudConfigService.addSuite(), body);
  }

  duplicateSuite(suiteId: string, suiteName?: string) {
    return this.apiService.post(this.cloudConfigService.duplicateSuite(suiteId, suiteName), '{}');
  }

  updateSuite(id: string, body: any) {
    return this.apiService.put(this.cloudConfigService.updateSuite(id), body);
  }

  restoreSuite(id: string, newSuiteName?: string) {
    return this.apiService.put(this.cloudConfigService.unTrashSuite(id, newSuiteName), {});
  }

  restoreSuites(filter) {
    filter = new Params(filter);

    const params = {...filter};
    return this.apiService.put(this.cloudConfigService.unTrashSuites(), params);
  }

  moveInTrashSuite(id: string) {
    return this.apiService.delete(this.cloudConfigService.moveInTrashSuite(id));
  }

  moveInTrashSuites(filter?) {

    filter = new Params(filter);

    const params = {...filter};
    return this.apiService.delete(this.cloudConfigService.moveInTrashSuites(), params);
  }

  deleteSuitesArchived(filter?) {
    filter = new Params(filter);

    const params = {...filter};
    return this.apiService.delete(this.cloudConfigService.deleteSuites(), params);
  }

  deleteSuiteArchived(id: string) {
    return this.apiService.delete(this.cloudConfigService.deleteSuite(id));
  }

  replaceWithActiveSuite(archivedSuiteId: string) {
    return this.apiService.put(this.cloudConfigService.replaceWithActiveSuite(archivedSuiteId), {});
  }

}
