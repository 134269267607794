import {Injectable, Injector} from '@angular/core';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {ApiService} from '../../../../shared/services/api.service';
import {ConfigService} from '../../../../shared/services/config.service';
import {StorageConfigService} from '../../../../shared/services/storage-config.service';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {catchError, filter, map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalExportService {

  private router;

  constructor(private tokens: MaxtafTokensStorageService,
              private apiService: ApiService,
              private config: ConfigService,
              private http: HttpClient,
              private injector: Injector,
              private storageConfigService: StorageConfigService
  ) {
  }

  getImportInfo(id) {
    return this.apiService.get(this.storageConfigService.globalImportInfo(id), {});
  }

  globalImport(file: File,
               overwriteProjectParams: boolean,
               overwriteCases: boolean,
               compile: boolean,
               overwriteSuites: boolean,
               overwriteSchedules: boolean,
               overwriteWorkspace: boolean
  ) {

    let queryParams = '';

    queryParams = queryParams + 'overwriteProjectParams' + '=' + overwriteProjectParams + '&';
    queryParams = queryParams + 'overwriteCases' + '=' + overwriteCases + '&';
    queryParams = queryParams + 'overwriteSuites' + '=' + overwriteSuites + '&';
    queryParams = queryParams + 'overwriteSchedules' + '=' + overwriteSchedules + '&';
    queryParams = queryParams + 'overwriteWorkspace' + '=' + overwriteWorkspace + '&';
    queryParams = queryParams + 'compile' + '=' + compile + '&';


    const formdata: FormData = new FormData();

    formdata.append('importFile', file);

    const headers = new HttpHeaders({
      Accept: 'application/json'
    });

    const req = new HttpRequest('POST', this.storageConfigService.globalImport() + '?' + queryParams, formdata, {
      headers,
      withCredentials: true,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req).pipe(
      catchError(error => {
        console.error('error: ', error);

        if (error && error.status === 401) {
          this.redirectIfUnauthorized();
        }

        throw error;
      }));
  }

  globalExport(): Observable<any> {

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    });


    const req = new HttpRequest('GET', this.storageConfigService.globalExport(), {}, {
      headers,
      withCredentials: true,
      responseType: 'blob'
    });

    return this.http.request(req).pipe(
      filter(response => response instanceof HttpResponse),
      map((response: HttpResponse<any>) => response),
      catchError(error => {
        console.error('error: ', error);

        if (error && error.status === 401) {
          this.redirectIfUnauthorized();
        }

        throw error;
      })
    );
  }

  private redirectIfUnauthorized() {
    if (this.router == null) {
      this.router = this.injector.get(Router);
    }
    console.log('%c 11212 logout ');
    const currentUrl = this.router.url;
    this.router.navigate(['/logout'], {queryParams: {returnUrl: currentUrl}}).then();

  }


}
