<section>
  <div class="responsive-table-wrapper">
    <table (matSortChange)="sortData($event)" [dataSource]="items"
           [matSortActive]="pageParams.sort.value.split(',')[0]"
           [matSortDirection]="pageParams.sort.value.split(',')[1]"
           [ngClass]="{
              'list-with-select': multiSelect
           }"
           mat-table
           matSort>


      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox (change)="selectAllToggle($event)"
                        [aria-label]="checkboxLabel()"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        style="margin-top: 8px;">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell style="padding-top: 8px;">
          <mat-checkbox (change)="$event ? selectedChange(row) : null"
                        (click)="$event.stopPropagation();"
                        [checked]="isSelectedRow(row)">
          </mat-checkbox>

        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="compile-date">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Start date</th>
        <td *matCellDef="let element" mat-cell>
          {{setFormat(element.createdDate)}}
        </td>
      </ng-container>


      <ng-container matColumnDef="current-max">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Current/Max</th>
        <td *matCellDef="let element" mat-cell> {{element.currentNumber}}/{{element.maxNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="completed">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Done</th>
        <td *matCellDef="let element" [matTooltip]="" mat-cell> {{element.completed ? 'yes' : 'no'}}</td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr (click)="clickedItem(row, i);"
          *matRowDef="let row; columns: displayedColumns; let i = index"
          [ngClass]="{ 'highlight': selection.isSelected(row)}"
          mat-row></tr>

    </table>
  </div>

  <spinner-for-table [loading]="isLoading"></spinner-for-table>

  <div class="container mr-0">
    <div class="row justify-content-end"
         style="flex-direction: row;justify-content: space-between;align-items: center;">
      <div *ngIf="selection.selected.length > 0" class="col-xs-4">
        <p style="font-size:13px; color:grey; margin-top:auto; margin-bottom:auto">
          Selected: {{selection.selected.length}} </p>
      </div>
      <div class="col-xs-8">
        <mat-paginator
          (page)="setPageSizeOrIndex($event)"
          [length]="pagesInfo.totalElements"
          [pageIndex]="pageParams.page.value"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageParams.size.value"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>

  </div>
</section>
