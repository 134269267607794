import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'spinner-for-table',
  templateUrl: './spinner-for-table.component.html',
  styleUrls: ['./spinner-for-table.component.css']
})
export class SpinnerForTableComponent implements OnInit {

  @Input('loading') isLoading = false;
  @Input('fullScreen') fullScreen = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
