import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateDialogProjectComponent} from './create-dialog-project.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ErrorModule} from '../../../mx/error/error.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ErrorModule,
    MatExpansionModule,
    MatCheckboxModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    CreateDialogProjectComponent
  ],
  exports: [
    CreateDialogProjectComponent
  ]
})

export class CreateDialogProjectModule {
}
