<h1 *ngIf="!spinner" mat-dialog-title>Create a {{type}}</h1>
<h1 *ngIf="spinner" mat-dialog-title>Creating your project..</h1>

<div *ngIf="spinner" mat-dialog-content style="padding: 32px">
  <mat-spinner style="margin:auto;"></mat-spinner>
</div>

<div *ngIf="!spinner" mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <label>{{type}} name</label>
  <div class="mb-3">
    <input [(ngModel)]="project.name" [disabled]="spinner" type="text">
  </div>
  <mat-expansion-panel [disabled]="spinner" [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>Advanced settings</span>
      </mat-panel-title>
      <mat-panel-description style="color: black;">


      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-checkbox [(ngModel)]="automaticallyGenerateId">Automatically generate id.</mat-checkbox>
    <br>
    <label *ngIf="!automaticallyGenerateId">{{type}} id</label>
    <div *ngIf="!automaticallyGenerateId">
      <input [(ngModel)]="project.id" [disabled]="spinner" type="text">
    </div>
  </mat-expansion-panel>
  <br>
</div>

<div *ngIf="!spinner" mat-dialog-actions>
  <button *ngIf="!spinner" [mat-dialog-close]="null" id="cancelCreateProjectButton" mat-button>Cancel</button>

  <button (click)="save()" *ngIf="!spinner" color="primary" id="createProjectConfirmButton" mat-button>Create</button>

  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>

</div>
