<div
  *ngIf="!spinner"
  [ngClass]="{'margin-bottom-icon': marginBottom}" class="mx-icon">
  <span
    (mouseenter)="hoverAdd($event)"
    (mouseleave)="hoverRemove($event)"
    [ngClass]="[icon]"
    [ngStyle]="{'color': color}"
    class="mdi"
  >
  </span>
</div>

<div *ngIf="spinner" class="mx-spinner">
  <mat-spinner
    [diameter]="diameter"
    [ngStyle]="{'color': color}">

  </mat-spinner>
</div>
