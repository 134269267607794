import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../shared/services/user.service';
import {UserProject} from '../../shared/models/userProject';
import {MxTable} from '../../../mx/util/list/MxTable';
import {PageParams} from '../../../mx/util/params/PageParams';
import {UserSearchParams} from '../../shared/services/user-const.service';
import {YesNoDialogComponent} from '../../../dialogs/yes-no-dialog/yes-no-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';

class UsersListCheckPaths {
  remove = new CheckPath();
}

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent extends MxTable<UserProject> implements OnInit {

  checkPaths: UsersListCheckPaths;

  displayedColumns = ['email', 'securityGroups', 'actions'];
  idName = 'id';

  pageParams: PageParams;
  searchParams: UserSearchParams;

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private userProjectConfigService: UserProjectConfigService,
    public checkRolesService: CheckRolesService,
    private snackBar: MatSnackBar
  ) {
    super();

    this.checkPaths = this.getUsersListCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getItems() {
    this.startLoadingSpinner();
    this.setItems([]);
    this.userService.getUserProjects(this.searchParams, this.pageParams)
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          if (res) {
            this.setItems(res.content);
            this.setPageInfo(res);
          } else {
            this.setItems([]);
          }
        },
        error => {
          this.stopLoadingSpinner();
          this.showError(error);
        }
      );
  }

  removeUserDialog(userProject: UserProject): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        title: 'Remove user',
        body: 'Are you sure you want to remove this user?',
        label1: 'YES'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deleteUserFromProject(userProject.id).subscribe(
          res => {
            this.openSnackBar('Removed', 'OK');
            this.refreshItems();
          },
          error => {
            this.showError(error);
            this.openSnackBar('Request failed. Try again later.', 'OK');
          }
        );
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  private getUsersListCheckPaths(): UsersListCheckPaths {
    return {
      remove: new CheckPath('DELETE', this.userProjectConfigService.deleteUserProject())
    };
  }
}
