<div mat-dialog-content>

  <app-error-message *ngIf="showErrorAlert" [error]="error"></app-error-message>

  <label>New {{isCopy ? 'Copy' : 'Cut'}} file name:</label>
  <div class="pb-3">
    <input [(ngModel)]="pasteFileName" type="text">
  </div>

  <label>Paste location</label>
  <div class="pb-3">
    <input [(ngModel)]="pastePath" type="text">
  </div>
  <div class="pb-3" style="margin-left: 5px; padding-bottom: 0;">
    <mat-checkbox (change)="overwrite = !overwrite" [checked]="overwrite" [disabled]="spinner" id="overwriteCheck"
                  type="checkbox">Overwrite data if there is a same file.
    </mat-checkbox>
  </div>
</div>

<div mat-dialog-actions>
  <button [mat-dialog-close] mat-button>Cancel</button>
  <button (click)="paste()" *ngIf="!spinner" color="primary" mat-button>Paste</button>
  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>
