import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreatePageObjectDialogComponent} from './create-page-object-dialog.component';
import {ErrorModule} from '../../../mx/error/error.module';
import {FormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';


@NgModule({
  declarations: [CreatePageObjectDialogComponent],
  imports: [
    CommonModule,
    ErrorModule,
    FormsModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatRadioModule
  ],
  exports: [
    CreatePageObjectDialogComponent
  ]
})
export class CreatePageObjectDialogModule {
}
