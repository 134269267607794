import {Injectable} from '@angular/core';
import {ApiService} from '../../../../../../shared/services/api.service';
import {MaxtafTokensStorageService} from '../../../../../../shared/services/maxtaf-tokens-storage.service';
import {StorageConfigService} from '../../../../../../shared/services/storage-config.service';

@Injectable({
  providedIn: 'root'
})
export class NavMaxtafFileService {

  constructor(
    private apiService: ApiService,
    private storageConfigService: StorageConfigService,
    private tokensStorageService: MaxtafTokensStorageService,
  ) {
  }

  getMaxtafFileNodes(params, projectId?: string) {
    if (projectId != undefined) {
      params[this.tokensStorageService.PROJECT_TOKEN] = projectId;
    }
    return this.apiService.get(this.storageConfigService.getMaxtafFileNodes(), params);
  }

}
