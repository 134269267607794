<h1 mat-dialog-title>Generate From HTML</h1>

<div class="content-no-overflow" mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <label>Method</label>
  <div class="mb-3">
    <mat-button-toggle-group (change)="method = $event.value;" [disabled]="saveSpinner" [value]="0">
      <mat-button-toggle [value]="0">
        <app-nav-tree-file-icon
          [extension]="'desktop'"
          class="toggle-language-icon language-icon-pos"
          color="none"
        ></app-nav-tree-file-icon>
        From Computer Files
      </mat-button-toggle>

      <mat-button-toggle [value]="1">
        <app-nav-tree-file-icon
          [extension]="'file-system-icon'"
          class="toggle-language-icon language-icon-pos"
          color="none"
        ></app-nav-tree-file-icon>
        From Project Files
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div *ngIf="method == '0'">
    <label>File Upload</label>
    <div class="container file-input-container">
      <div class="row justify-content-md-center">
        <div class="col">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input (change)="selectLocalFile($event)" class="custom-file-input" id="inputGroupFile01" type="file">
              <label class="custom-file-label" for="inputGroupFile01">{{localFileName}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="method == '1'">
    <label>Project Files</label>
    <label *ngIf="workspaceFileSelected">{{workspaceFilePath}}</label>
    <div class="container file-input-container">
      <app-nav-workspace-tree (selected)="selectedFile($event)"
                              *ngIf="!workspaceFileSelected"
                              [(copyNode)]="copyNode"
                              [(isCopy)]="isCopy"
                              [height]="'calc(100% - 30px)'"
                              [initialData]="rootWorkspaceNode"
                              [maxHeight]="'35vh'"
                              [ngClass]="{'display-none': !expandWorkspace}">
      </app-nav-workspace-tree>
    </div>
    <button (click)="workspaceFileSelected = false" *ngIf="workspaceFileSelected && !saveSpinner" id="selectNewFile"
            mat-button>Select new file
    </button>
  </div>

  <div mat-dialog-actions>
    <button *ngIf="!saveSpinner" [mat-dialog-close] id="cancelButton" mat-button>Cancel</button>

    <button (click)="generate()" *ngIf="!saveSpinner" color="primary" id="createButton" mat-button>
      Generate
    </button>
    <button *ngIf="saveSpinner" color="primary" mat-button>
      <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
        <mat-spinner [diameter]="20"
                     class="whiteSpinner"
                     style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
      </mat-icon>
    </button>
  </div>
</div>
