import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-secure-confirmation-dialog',
  templateUrl: './secure-confirmation-dialog.component.html',
  styleUrls: ['./secure-confirmation-dialog.component.css']
})
export class SecureConfirmationDialogComponent {

  projectId: string;
  title: string;
  body: string;
  submitButtonText: string;

  securityMessage;
  securityMessageError = false;
  error = {
    error: {
      errorMessage: ''
    }
  };

  constructor(
    public dialogRef: MatDialogRef<SecureConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      projectId: string,
      title: string,
      body: string,
      submitButtonText: string
    }
  ) {
    if (data.projectId) {
      this.projectId = data.projectId;
    }
    if (data.title) {
      this.title = data.title;
    }
    if (data.body) {
      this.body = data.body;
    }
    if (data.submitButtonText) {
      this.submitButtonText = data.submitButtonText;
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  submit() {
    if (this.securityMessage !== this.projectId) {
      this.error.error.errorMessage = 'Please enter the correct project id.';
      this.securityMessageError = true;
    } else {
      this.dialogRef.close(true);
    }
  }

}
