import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiSessionsListComponent} from './ai-sessions-list.component';
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {IconModule} from "../../../mx/icon/icon.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {OverlayscrollbarsModule} from "overlayscrollbars-ngx";
import {EditSessionDialogModule} from "../edit-session-dialog/edit-session-dialog.module";
import {YesNoDialogModule} from "../../../dialogs/yes-no-dialog/yes-no-dialog.module";


@NgModule({
  declarations: [AiSessionsListComponent],
  exports: [
    AiSessionsListComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    IconModule,
    MatTooltipModule,
    OverlayscrollbarsModule,
    EditSessionDialogModule,
    YesNoDialogModule
  ]
})
export class AiSessionsListModule {
}
