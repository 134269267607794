import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalImportDialogComponent} from './global-import-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ErrorModule} from '../../../mx/error/error.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';


@NgModule({
  declarations: [GlobalImportDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ErrorModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatCheckboxModule
  ]
})
export class GlobalImportDialogModule {
}
