import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {

  if (window) {
    console.log = function() {
    };
    console.debug = function() {
    };
    console.warn = function() {
    };
    console.info = function() {
    };

    console.error = function() {
    };
    console.assert = function() {
    };

    window.console.log = function() {
    };
    window.console.debug = function() {
    };
    window.console.warn = function() {
    };
    window.console.info = function() {
    };
    window.console.error = function() {
    };
    window.console.assert = function() {
    };
  }

  enableProdMode();
}

// if (window) {
//   console.log = function() {
//   };
//   console.debug = function() {
//   };
//   console.warn = function() {
//   };
//   console.info = function() {
//   };
//
//   console.error = function() {
//   };
//   console.assert = function() {
//   };
//
//
//   window.console.log = function() {
//   };
//   window.console.debug = function() {
//   };
//   window.console.warn = function() {
//   };
//   window.console.info = function() {
//   };
//   window.console.error = function() {
//   };
//   window.console.assert = function() {
//   };
//
//   console.clear();
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => {});
