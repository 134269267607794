import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MxTable} from '../../../mx/util/list/MxTable';
import {PageParams} from '../../../mx/util/params/PageParams';
import {CaseSearchParams} from '../../shared/services/case-const.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {CompileCaseInfo} from '../../shared/models/CompileCaseInfo';
import {CompileInfoCaseInfoConstService} from '../compile-info-details-dilaog/compile-info-case-info-const.service';
import {CompileService} from '../../shared/services/compile.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-compile-info-case-info-list',
  templateUrl: './compile-info-case-info-list.component.html',
  styleUrls: ['./compile-info-case-info-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CompileInfoCaseInfoListComponent extends MxTable<CompileCaseInfo> implements OnInit, OnDestroy {

  // expandedElement: CompileCaseInfo | null;
  idName: string = 'id';
  @Input('displayedColumns') displayedColumns: string[] = ['name', 'message'];
  columnsToDisplayWithExpand = ['expand', ...this.displayedColumns];
  @Input('multiSelect') multiSelect = true;
  @Input('compileInfoId') compileInfoId = '';
  @Output('compiledError') compiledErrorEmitter = new EventEmitter<any>();
  @Output('transpiledError') transpiledErrorEmitter = new EventEmitter<any>();
  pageParams: PageParams;
  searchParams: CaseSearchParams;
  @Input('refreshIntervalInMs') refreshInterval = 2000;

  constructor(
    private router: Router,
    private compileService: CompileService,
    public dialog: MatDialog
  ) {
    super();
    this.pageParams = CompileInfoCaseInfoConstService.getDefaultPageParams();
  }

  @Input('liveRefresh') set setLiveReload(liveRefresh: boolean) {
    this.stopLiveRefresh();
    if (liveRefresh == true) {
      this.startLiveRefresh();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  setFormat(runDate: Date) {
    const startFormat = moment.localeData(navigator.language).longDateFormat('lll');
    const format = startFormat.substr(0, startFormat.substr(0, startFormat.indexOf(':')).lastIndexOf(' ')) + ', h:mm:ss a';
    return moment(runDate).format(format);
  }

  getItems() {

    // this.startLoadingSpinner();
    // this.setItems([]);
    this.compileService.compileInfoCasesInfos(this.compileInfoId, this.searchParams, this.pageParams)
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          if (res) {
            this.setItems(res.content);
            this.setPageInfo(res);
          } else {
            this.setItems([]);
          }

        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );
  }

  setItems(newItems: CompileCaseInfo[]) {
    // this.items.data.forEach(
    //   (element) => {
    //     const found = newItems.find((obj) => {
    //       return obj.id === element.id;
    //     });
    //     // element.expended = found.expended;
    //     // element.bodyExpended = found.bodyExpended;
    //   }
    // );

    newItems.forEach(
      (element) => {
        const found = this.items.data.find((obj) => {
          return obj.id === element.id;
        });
        if (found != undefined) {
          element.expended = found.expended;
          element.bodyExpended = found.bodyExpended;
        }
      }
    );

    this.items = new MatTableDataSource<CompileCaseInfo>(newItems);
    // this.cases.sort = this.sortTable;
  }


  compiledErrorLine(compiledError: { line, path }, compileCaseInfo: CompileCaseInfo) {
    this.compiledErrorEmitter.emit(
      {
        compiledError,
        compileCaseInfo
      }
    );
  }

  transpiledErrorLine(transpiledError: { line, path }, compileCaseInfo: CompileCaseInfo) {
    this.transpiledErrorEmitter.emit(
      {
        transpiledError,
        compileCaseInfo
      });
  }
}
