import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RemoteFilesService} from '../../shared/services/remote-files.service';

@Component({
  selector: 'app-unzip-dialog',
  templateUrl: './unzip-dialog.component.html',
  styleUrls: ['./unzip-dialog.component.css']
})
export class UnzipDialogComponent implements OnInit {

  unzipLocation = '/';
  zipLocation = '/';
  overwrite = false;
  header = 'Unzip';

  showErrorAlert = false;
  error;

  spinner = false;

  constructor(
    public dialog: MatDialog,
    public storageService: RemoteFilesService,
    public dialogRef: MatDialogRef<UnzipDialogComponent>,
    private remoteFileService: RemoteFilesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      zipLocation: string,
      unzipLocation: string,
      header: string,
      overwrite: boolean
    }
  ) {
    if (data.unzipLocation != undefined) {
      this.unzipLocation = data.unzipLocation;
    }

    if (data.zipLocation != undefined) {
      this.zipLocation = data.zipLocation;
    }

    if (data.header != undefined) {
      this.header = data.header;
    }

    if (data.overwrite != undefined) {
      this.overwrite = data.overwrite;
    }

  }

  ngOnInit(): void {

  }

  unzip() {
    this.unzipFileRequest();
  }

  unzipFileRequest() {
    this.startLoadingSpinner();
    this.remoteFileService.unzip(this.zipLocation, this.unzipLocation, this.overwrite)
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          this.dialogRef.close(true);

        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );
  }

  startLoadingSpinner() {
    this.spinner = true;
  }

  stopLoadingSpinner() {
    this.spinner = false;
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }
}
