import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiCodeComponent} from './ai-code.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IconModule} from '../../../mx/icon/icon.module';
import {EditorWorkspaceModule} from '../../../storage/components/editor-workspace/editor-workspace.module';
import {MarkdownModule} from "ngx-markdown";
import {OverlayscrollbarsModule} from "overlayscrollbars-ngx";
import {MatExpansionModule} from "@angular/material/expansion";
import {AiDiffModule} from "../ai-diff/ai-diff.module";


@NgModule({
  declarations: [AiCodeComponent],
  exports: [
    AiCodeComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    EditorWorkspaceModule,
    MatTooltipModule,
    MarkdownModule,
    OverlayscrollbarsModule,
    MatExpansionModule,
    AiDiffModule
  ]
})
export class AiCodeModule {
}
