import {Case} from './case';
import {CompileResult} from './compile-result';
import {CaseParams} from '../services/case-const.service';

export class CaseTab {

  constructor(public testCase: Case = new Case(),
              public compileResult: CompileResult = null,
              public compileError = null,
              public activeCode: string = '',
              public selectLine: number = null,
              public isTransferCase: boolean = false,
              public caseParams: CaseParams = new CaseParams(),
              public projectId?: string,
              public isSaved: boolean = true,
              public scrollLevel: number = 0


              // public compileDetails: CompileTabDetails,
              // public projectId: string,
              // public canBeReloaded?: boolean,
              // public allowEdit: boolean = true,
              // public childTabs: Case[] = [],
              // public runId: number = undefined
  ) {
  }

  //
  // isAllowCompile(): boolean {
  //   if (this.details == undefined) {
  //     return false;
  //   }
  //
  //   if (
  //     this.details.typeOfFile == 'java'
  //     || (this.isCase()
  //       && this.details.testCase.caseType != undefined
  //       && (this.details.testCase.caseType.toLowerCase() == 'mxml'
  //         || this.details.testCase.caseType.toLowerCase() == 'java')
  //     )
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  //
  // }
  //
  // isCase(): boolean {
  //   if (this.details == undefined) {
  //     return false;
  //   }
  //
  //   if (this.details.testCase != undefined && this.details.testCase.id != null) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }


}
