import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiTitleComponent} from './ai-title.component';
import {IconModule} from '../../../mx/icon/icon.module';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [AiTitleComponent],
  exports: [
    AiTitleComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatTooltipModule,
  ]
})
export class AiTitleModule {
}
