<mx-options
  #mxOptions
  [allowShadow]="allowShadow"
  [defaultColors]="defaultColors"
  [iconMarginBottom]="iconMarginBottom"
  [showButtonText]="showButtonText"
  [showButtons]="showButtons"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
>

  <mx-options-header>

    <!--    &lt;!&ndash;      filter&ndash;&gt;-->
    <app-filter-by
      (filter)="filter()"
      (isSearchCollapsedChange)="mxOptions.toggleBody();"
      (searchParamsChange)="newParams($event)"
      *ngIf="showButtons"
      [filterBy]="'testCaseName'"
      [isSearchCollapsed]="mxOptions.isSearchCollapsed"
      [searchParams]="searchParams"
      class="filter-by mr-1 ml-2"
    ></app-filter-by>

    <button (click)="mxOptions.toggleBody();"
            *ngIf="showButtons"
            [matTooltip]="!showTooltip? undefined : 'Filter'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="filter"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Filter</span>
    </button>

    <!--      refresh-->
    <button (click)="refresh()"
            *ngIf="showButtons"
            [matTooltip]="!showTooltip? undefined : 'Refresh'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="refresh"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Refresh</span>
    </button>

    <!--    &lt;!&ndash;      delete&ndash;&gt;-->
    <!--    <button id="deleteButton" mat-button-->
    <!--            *ngIf="showButtons"-->
    <!--            (click)="delete()"-->
    <!--            [disabled]="!checkPaths.delete.enabled"-->
    <!--            [matTooltip]="!showTooltip? undefined : 'Delete'">-->
    <!--      <mx-icon [marginBottom]="iconMarginBottom" mx="delete"></mx-icon>-->
    <!--      <span class="px-1" *ngIf="showButtonText">Delete</span>-->
    <!--    </button>-->

  </mx-options-header>

  <mx-options-body>
    <div class="filter">
      <app-compile-info-case-info-filter
        (close)="mxOptions.closeBody();"
        (filter)="filter()"
        (searchParamsChange)="newParams($event)"
        [lockSearchParams]="lockFilterParams"
        [searchParams]="searchParams"
      ></app-compile-info-case-info-filter>
    </div>

  </mx-options-body>
</mx-options>
