<div class="pt-2" mat-dialog-content>

  <!--  <mat-tab-group-->
  <!--    [selectedIndex]="params.tabIndex.value"-->
  <!--    (selectedIndexChange)="changeTabIndex($event)">-->
  <!--    <mat-tab [label]="showPageObjects? 'Page Objects': 'Cases'">-->


  <app-case-list-options
    (create)="openCreateDialog()"
    (createPageObjectEvent)="openCreatePageObjectEventDialog()"
    (filter)="caseList.refreshItems()"
    (refresh)="caseList.refreshItems()"
    (searchParamsChange)="caseSearchParamsChange($event)"
    [checkPaths]="getCaseListOptionsCheckPathObject()"
    [forDialog]="true"
    [searchParams]="params.caseSearch"
    [showCase]="showCreateCases"
    [showCheckboxes]="true"
    [showPageObject]="showCreatePageObjects"
  >

  </app-case-list-options>

  <app-list-case #caseList
                 (clickedItem)="clickedItem($event)"
                 (clickedScriptItem)="clickedScriptItem($event)"

                 (pageParamsChange)="casePageParamsChange($event)"
                 (pagesInfoChanges)="pagesInfoChanges($event)"
                 (selected)="caseSelectedItems($event)"
                 [autoSetItems]="true"


                 [displayedColumns]="['select', 'openScript', 'name', 'category', 'caseType', 'description']"
                 [multiSelect]="multiSelect"
                 [pageParams]="params.casePage"
                 [searchParams]="params.caseSearch"
  ></app-list-case>
  <!--    </mat-tab>-->
  <!--    <mat-tab label="Suites" *ngIf="!removeSuites">-->

  <!--      <app-suite-options-->
  <!--        [checkPaths]="getSuiteOptionsCheckPathObject()"-->
  <!--        [forDialog]="true"-->
  <!--        [searchParams]="params.suiteSearch"-->
  <!--        (searchParamsChange)="suiteSearchParamsChange($event)"-->

  <!--        (refresh)="suiteList.refreshItems()"-->
  <!--        (filter)="suiteList.refreshItems()">-->
  <!--      </app-suite-options>-->

  <!--      &lt;!&ndash;table&ndash;&gt;-->
  <!--      <app-list-suite-->
  <!--        [autoSetItems]="true"-->
  <!--        [multiSelect]="multiSelect"-->

  <!--        [searchParams]="params.suiteSearch"-->
  <!--        [pageParams]="params.suitePage"-->
  <!--        (pageParamsChange)="suitePageParamsChange($event)"-->
  <!--        (pagesInfoChanges)="suitePagesInfoChanges($event)"-->

  <!--        (clickedItem)="clickedItem($event)"-->
  <!--        (selected)="suiteSelectedItems($event)"-->
  <!--        #suiteList-->
  <!--      ></app-list-suite>-->
  <!--    </mat-tab>-->
  <!--  </mat-tab-group>-->

</div>
<div class="d-flex float-right px-4 py-2" mat-dialog-actions>
  <button [mat-dialog-close]="false" id="cancelButton" mat-button style="float:right">Cancel</button>
  <button (click)="returnSelectedIds()" color="primary" id="addButton" mat-button style="float:right;font-weight:bold;">
    Add
  </button>
</div>
