import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ai-load-response',
  templateUrl: './ai-load-response.component.html',
  styleUrls: ['./ai-load-response.component.css']
})
export class AiLoadResponseComponent implements OnInit {
  @Input('ai') ai = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
