import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ZipDialogComponent} from './zip-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ErrorModule} from '../../../mx/error/error.module';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';


@NgModule({
  declarations: [ZipDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ErrorModule,
    FormsModule,
    MatCheckboxModule
  ],
  exports: [ZipDialogComponent]
})
export class ZipDialogModule {
}
