import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiMessageComponent} from './ai-message.component';
import {IconModule} from "../../../mx/icon/icon.module";
import {MatButtonModule} from "@angular/material/button";
import {MarkdownModule} from "ngx-markdown";
import {MatTooltipModule} from "@angular/material/tooltip";
import {AiCodeModule} from "../ai-code/ai-code.module";
import {EditMessageDialogModule} from '../edit-message-dialog/edit-message-dialog.module';


@NgModule({
  declarations: [AiMessageComponent],
  exports: [
    AiMessageComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MarkdownModule.forRoot(),
    MatTooltipModule,
    AiCodeModule,
    EditMessageDialogModule
  ]
})
export class AiMessageModule {
}
