import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChatSession} from "../../shared/models/chatSession";
import {AiChatService} from "../../shared/services/ai-chat.service";

@Component({
  selector: 'app-edit-session-dialog',
  templateUrl: './edit-session-dialog.component.html',
  styleUrls: ['./edit-session-dialog.component.css']
})
export class EditSessionDialogComponent implements OnInit {

  session: ChatSession;
  oldTitle: string;

  constructor(public dialogRef: MatDialogRef<EditSessionDialogComponent>,
              private aiChatService: AiChatService,
              @Inject(MAT_DIALOG_DATA) public data: {
                session: ChatSession
              }) {
    this.session = data.session;
    this.oldTitle = this.session.title;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.session.title = this.oldTitle;
    this.dialogRef.close();
  }

  updateAiSessionTitle() {
    this.aiChatService.updateChatSession(this.session)
      .subscribe(res => {
          // this.getChatSessions();

          this.dialogRef.close();
        }, error => {
          console.error('error in creating new session: ', error);
        }
      );
  }
}
