import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Case} from '../../shared/models/case';
import {MatDialog} from '@angular/material/dialog';
import {CaseService} from '../../shared/services/case.service';
import {Router} from '@angular/router';
import {MxTable} from '../../../mx/util/list/MxTable';
import {PageParams} from '../../../mx/util/params/PageParams';
import {CaseConstService, CaseSearchParams} from '../../shared/services/case-const.service';
import {ClickedTableItem} from '../../../mx/util/list/ClickedTableItem';

@Component({
  selector: 'app-list-case',
  templateUrl: './list-case.component.html',
  styleUrls: ['./list-case.component.css']
})
export class ListCaseComponent extends MxTable<Case> implements OnInit, OnDestroy {

  @Output('clickedScriptItem') clickedScriptItemEmiter = new EventEmitter<ClickedTableItem<Case>>();
  @Input('fromCodeLookup') fromCodeLookup = false;
  @Input('nameMaxSize') nameMaxSize = 18;
  @Input('maximumLengthOfContinuousSizeName') maximumLengthOfContinuousSizeName = 60;
  @Input('descriptionMaxSize') descriptionMaxSize = 150;
  @Input('maximumLengthOfContinuousSizeDescription') maximumLengthOfContinuousSizeDescription = 60;
  @Input('displayedColumns') displayedColumns: string[] =
    ['select', 'name', 'category', 'caseType', 'description'];
  idName = 'id';
  @Input('multiSelect') multiSelect = true;
  pageParams: PageParams;
  searchParams: CaseSearchParams;

  constructor(
    private router: Router,
    private caseService: CaseService,
    public dialog: MatDialog
  ) {
    super();
    this.pageParams = CaseConstService.getDefaultPageParams();
  }

  clickedScriptItem(item: Case, index: number) {
    this.clickedScriptItemEmiter.emit(new ClickedTableItem<Case>(item, index));
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.multiSelect) {
      this.hideMultiSelect();
    }
  }

  hideMultiSelect(): void {
    const index = this.displayedColumns.indexOf('select', 0);
    if (index > -1) {
      this.displayedColumns.splice(index, 1);
    }
  }

  getItems(): void {
    this.startLoadingSpinner();
    this.setItems([]);
    this.caseService.getAll(this.searchParams, this.pageParams)
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          if (res) {
            this.setItems(res.content);
            this.setPageInfo(res);
          } else {
            this.setItems([]);
          }
        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );
  }


  getDescriptionForPrint(description: string) {
    if (description != undefined) {
      if ((!description.includes(' ') && !description.includes('-') && !description.includes('_')) && description.length > this.maximumLengthOfContinuousSizeDescription) {
        return this.replaceStringWithNewLines(description).substr(0, this.maximumLengthOfContinuousSizeDescription) + '...';
      } else if (
        (description.includes(' ') && description.substr(0, description.indexOf(' ')).length > this.maximumLengthOfContinuousSizeDescription) ||
        (description.includes('_') && description.substr(0, description.indexOf('_')).length > this.maximumLengthOfContinuousSizeDescription) ||
        (description.includes('-') && description.substr(0, description.indexOf('-')).length > this.maximumLengthOfContinuousSizeDescription)) {
        return this.replaceStringWithNewLines(description).substr(0, this.maximumLengthOfContinuousSizeDescription) + '...';
      } else if (description.length > this.descriptionMaxSize) {
        return this.replaceStringWithNewLines(description).substr(0, this.descriptionMaxSize) + '...';
      } else {
        return this.replaceStringWithNewLines(description);
      }
    } else {
      return description;
    }
  }

  getDescriptionForTooltip(description: string) {
    if (description != undefined) {
      if (!description.includes(' ') && !description.includes('-') && !description.includes('_') && description.length > this.maximumLengthOfContinuousSizeDescription) {
        return description;
      } else if (
        (description.includes(' ') && description.substr(0, description.indexOf(' ')).length > this.maximumLengthOfContinuousSizeDescription) ||
        (description.includes('_') && description.substr(0, description.indexOf('_')).length > this.maximumLengthOfContinuousSizeDescription) ||
        (description.includes('-') && description.substr(0, description.indexOf('-')).length > this.maximumLengthOfContinuousSizeDescription)) {
        return description;
      } else if (description.length > this.descriptionMaxSize) {
        return description;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getNameForPrint(name: string) {
    if (name != undefined) {
      if (!name.includes(' ') && !name.includes('-') && !name.includes('_') && name.length > this.maximumLengthOfContinuousSizeName) {
        return this.replaceStringWithNewLines(name).substr(0, this.maximumLengthOfContinuousSizeName) + '...';
      } else if (
        (name.includes(' ') && name.substr(0, name.indexOf(' ')).length > this.maximumLengthOfContinuousSizeName) ||
        (name.includes('_') && name.substr(0, name.indexOf('_')).length > this.maximumLengthOfContinuousSizeName) ||
        (name.includes('-') && name.substr(0, name.indexOf('-')).length > this.maximumLengthOfContinuousSizeName)) {
        return this.replaceStringWithNewLines(name).substr(0, this.maximumLengthOfContinuousSizeName) + '...';
      } else if (name.length > this.descriptionMaxSize) {
        return this.replaceStringWithNewLines(name);
      } else {
        return this.replaceStringWithNewLines(name);
      }
    } else {
      return name;
    }
  }

  getNameForTooltip(name: string) {
    if (name != undefined) {
      if (!name.includes(' ') && !name.includes('-') && !name.includes('_') && name.length > this.maximumLengthOfContinuousSizeName) {
        return name;
      } else if (
        (name.includes(' ') && name.substr(0, name.indexOf(' ')).length > this.maximumLengthOfContinuousSizeName) ||
        (name.includes('_') && name.substr(0, name.indexOf('_')).length > this.maximumLengthOfContinuousSizeName) ||
        (name.includes('-') && name.substr(0, name.indexOf('-')).length > this.maximumLengthOfContinuousSizeName)) {
        return name;
      } else if (name.length > this.nameMaxSize) {
        return null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  replaceStringWithNewLines(string: string): string {
    if (string == undefined) {
      return undefined;
    }
    string = string.replace(/_/g, '<wbr>_');
    string = string.replace(/-/g, '<wbr>-');
    string = string.replace(/ /g, '<wbr> ');
    return string;
  }

  logIndex(i) {
  }

  selectAllToggle($event) {
    if ($event) {
      this.masterToggle(this.pagesInfo.totalPages, this.pagesInfo.sizeOfElementsOnPage);
    } else {
      return null;
    }
  }
}
