import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../shared/services/user.service";
import {CloudConfigService} from "../../../../shared/services/cloud-config.service";
import {CheckRolesService} from "../../../../shared/services/check-roles.service";
import {CheckPath} from "../../../mx/options/CheckPath";
import {UserParam} from "../../shared/models/userParams";

class UserParamCheckPaths {
  update = new CheckPath();
}


@Component({
  selector: 'app-add-edit-user-param-dialog',
  templateUrl: './add-edit-user-param-dialog.component.html',
  styleUrls: ['./add-edit-user-param-dialog.component.css']
})
export class AddEditUserParamDialogComponent implements OnInit {

  checkPaths: UserParamCheckPaths;

  @Input('userPreferencesParam') userPreferencesParam: UserParam;
  create = false;
  title = '';
  createOkButton: string;
  backupUserPreferencesParam: UserParam;

  errorMessage = '';
  error = false;
  run = false;
  allowEdit = true;
  configuration = false;
  userParamsList: UserParam[] = [];
  nameCannotBeNull = false;
  doesNameExist = false;
  existingName = '';
  existingValue = '';

  constructor(
    public dialogRef: MatDialogRef<AddEditUserParamDialogComponent>,
    private userPreferencesParamService: UserService,
    private cloudConfigService: CloudConfigService,
    public checkRolesService: CheckRolesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      userParam: UserParam,
      edit: boolean,
      title: string,
      configuration: boolean,
      userParamsList: UserParam[]
    }
  ) {
    if (this.data.userParamsList) {
      this.userParamsList = this.data.userParamsList;
    }
    if (data.userParam == undefined) {
      this.create = true;
      this.userPreferencesParam = new UserParam();
      this.title = 'Add parameter';
      this.createOkButton = 'Add';
    } else {
      this.create = false;
      this.userPreferencesParam = data.userParam;
      this.backupUserPreferencesParam = new UserParam();
      this.backupUserPreferencesParam.name = data.userParam.name;
      this.backupUserPreferencesParam.value = data.userParam.value;
      this.title = 'Edit parameter';
      this.createOkButton = 'Save';
    }

    if (data.edit != undefined) {
      this.allowEdit = data.edit;
    }

    if (data.configuration != undefined) {
      this.configuration = data.configuration;
    }

    if (data.title != undefined) {
      this.title = data.title;
    }

    this.checkPaths = this.getProjectParamCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );
  }

  checkIfNameExists() {
    this.doesNameExist = false;
    for (let i = 0; i < this.data.userParamsList.length; i++) {
      if (this.data.userParamsList[i].name === this.userPreferencesParam.name) {
        this.doesNameExist = true;
        this.existingName = this.data.userParamsList[i].name;
        this.existingValue = this.data.userParamsList[i].value;
        break;
      }
    }
  }

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.addShortcutListener, false);
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for adding a parameter or entering a value after name
  addShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      if (this.userPreferencesParam.name && !(this.userPreferencesParam.value)) {
        document.getElementById('valueInput').focus();
      } else if (this.userPreferencesParam.name && this.userPreferencesParam.value) {
        document.getElementById('createButton').click();
      }
    }
  };

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.addShortcutListener, false);
  }


  add() {
    if (this.userPreferencesParam.name == null || this.userPreferencesParam.name == undefined || this.userPreferencesParam.name.trim() == '') {
      this.nameCannotBeNull = true;
    } else {
      this.dialogRef.close(this.userPreferencesParam);
    }
    // this.addProjectParams();
  }

  edit() {
    if (this.userPreferencesParam.name == null || this.userPreferencesParam.name == undefined || this.userPreferencesParam.name.trim() == '') {
      this.nameCannotBeNull = true;
    } else {
      this.dialogRef.close(this.userPreferencesParam);
    }

    // if(this.configuration) {
    //   this.editConfigurationParams();
    // } else {
    //   this.editProjectParams();
    // }
  }

  close() {
    if (!this.create) {
      this.userPreferencesParam.name = this.backupUserPreferencesParam.name;
      this.userPreferencesParam.value = this.backupUserPreferencesParam.value;
    }
    this.closeDialog(false);
  }

  closeDialog(res): void {
    this.dialogRef.close(res);
  }

  private addProjectParams() {
    // this.error = false;
    // this.userPreferencesParam.createProjectParams(this.userPreferencesParam)
    //   .subscribe(
    //     res => {
    //       this.error = false;
    //       this.closeDialog(true);
    //     },
    //     error => {
    //       console.error(error);
    //       this.errorMessage = error.error.errorMessage;
    //       this.error = true;
    //       // this.closeDialog(false);
    //     }
    //   );
  }

  private editProjectParams() {
    // this.error = false;
    // this.userPreferencesParamService.setUserPreferencesParam(this.userPreferencesParam.name, this.userPreferencesParam.value)
    //   .subscribe(
    //     res => {
    //       this.error = false;
    //       this.closeDialog(false);
    //     },
    //     error => {
    //       console.error(error);
    //       this.errorMessage = error.error.errorMessage;
    //       this.error = true;
    //       // this.closeDialog(false);
    //     }
    //   );
  }

  private editConfigurationParams() {
    this.error = false;
    this.userPreferencesParamService.setUserPreferencesParam(this.userPreferencesParam.name, this.userPreferencesParam.value)
      .subscribe(
        res => {
          this.error = false;
          this.closeDialog(false);
        },
        error => {
          console.error(error);
          this.errorMessage = error.error.errorMessage;
          this.error = true;
          // this.closeDialog(false);
        }
      );
  }

  private getProjectParamCheckPaths(): UserParamCheckPaths {
    return {
      update: new CheckPath('PUT', this.cloudConfigService.setProjectParams())
    };
  }

}
