import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NoticeBoardComponent} from './notice-board.component';
import {IconModule} from '../icon/icon.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
  declarations: [NoticeBoardComponent],
  imports: [
    CommonModule,
    IconModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatMenuModule,
    MatButtonModule
  ],
  exports: [
    NoticeBoardComponent
  ]
})
export class NoticeBoardModule {
}
