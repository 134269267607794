import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IdleDialogComponent} from './idle-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
  declarations: [
    IdleDialogComponent
  ],
  exports: [
    IdleDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class IdleDialogModule {
}
