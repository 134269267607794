import {Injectable} from '@angular/core';
import {ConfigService} from "../../../../shared/services/config.service";

@Injectable({
  providedIn: 'root'
})
export class AiConfigService {

  public AI_CHAT_SESSION_PATH = "/" + "session";
  private DEFAULT_PATH_VAR = this.configService.getDefaultPathVar();

  private API_PATH = this.configService.getHostURL() + this.configService.getPrefix() + '/ai';
  public AI_CHAT_SESSION_URL = this.API_PATH + "/" + "session";
  private ASK_PATH = "ask";
  private AI_CHAT_URL = this.API_PATH + "/" + "chat";
  private AI_CONFIG_URL = this.API_PATH + "/" + "config";
  private AI_CHAT_RECORD_URL = this.API_PATH + "/" + "chat";
  private AI_USER_PROJECT_SETTINGS_URL = this.API_PATH + "/project/" + "settings";
  private AI_ASK_URL = this.API_PATH + "/" + "ask";
  private AI_USER_PROJECT_CONFIG_URL = this.API_PATH + "/user/project/" + "config";
  private AI_CONFIG_PROJECT_URL = this.API_PATH + "/project/" + "params";

  constructor(
    private configService: ConfigService
  ) {
  }

  public aiController_GET_getAIChat() {
    return this.AI_CHAT_URL;
  }


  public aiController_POST_addQuestion() {
    return this.AI_CHAT_URL + "/question";
  }

  public aiController_PUT_updateQuestion(aiChatID: string) {
    return this.AI_CHAT_URL + "/" + aiChatID;
  }

  public aiController_GET_askQuestion() {
    return this.AI_CHAT_URL + "/" + this.ASK_PATH;
  }

  public aiController_DELETE_deleteAiChat() {
    return this.AI_CHAT_URL;
  }

  public aiController_GET_exportAiChat() {
    return this.AI_CHAT_URL + "/export";
  }

  public aiController_GET_config() {
    return this.API_PATH + "/config";
  }


  export() {
    return "";//this.EXPORT_AI_CHAT_URL;
  }


  chatRecordController_GET_getChatRecord(chatRecordId) {//chatRecordId
    return this.AI_CHAT_RECORD_URL + "/" + chatRecordId;
  }

  chatRecordController_GET_getChatRecords(chatSessionId) {//sessionId
    return this.AI_CHAT_RECORD_URL + "/session/" + chatSessionId;
  }

  chatRecordController_POST_addChatRecord(chatSessionId) {//sessionId
    return this.AI_CHAT_RECORD_URL + "/session/" + chatSessionId;
  }

  chatRecordController_PUT_updateChatRecordAndRemoveAllAfter(chatRecordId) {//chatRecordId
    return this.AI_CHAT_RECORD_URL + "/" + chatRecordId;
  }

  chatRecordController_DELETE_deleteChatRecordAndRemoveAllAfter(chatRecordId) {//chatRecordId
    return this.AI_CHAT_RECORD_URL + "/" + chatRecordId + "/andAllAfter";
  }

  chatRecordController_DELETE_deleteChatRecord(chatRecordId) {//chatRecordId
    return this.AI_CHAT_RECORD_URL + "/" + chatRecordId;
  }

  chatRecordController_DELETE_deleteChatRecords(chatSessionId) {//chatSessionId
    return this.AI_CHAT_RECORD_URL + "/session/" + chatSessionId;
  }


  chatSessionController_GET_getChatSession(chatSessionId) {//chatSessionId
    return this.AI_CHAT_SESSION_URL + "/" + chatSessionId;
  }

  chatSessionController_GET_getChatSessions() {
    return this.AI_CHAT_SESSION_URL;
  }

  chatSessionController_PUT_updateChatSession() {
    return this.AI_CHAT_SESSION_URL;
  }

  chatSessionController_POST_createChatSession() {
    return this.AI_CHAT_SESSION_URL;
  }

  chatSessionController_DELETE_deleteChatSession(chatSessionId) {//chatSessionId
    return this.AI_CHAT_SESSION_URL + "/" + chatSessionId;
  }

  chatSessionController_DELETE_deleteChatSessions() {//chatSessionId
    return this.AI_CHAT_SESSION_URL;
  }

  chatSessionController_PUT_clearChatSession(chatSessionId) {//chatSessionId
    return this.AI_CHAT_SESSION_URL + "/clear/" + chatSessionId;
  }


  aIController_GET_askQuestionAsync() {
    return this.AI_ASK_URL;
  }

  aIController_GET_askQuestionWithSession(chatSessionId) {
    return this.AI_ASK_URL + "/session/" + chatSessionId;
  }

  projectAIParamController_GET_getProjectAiConfig() {
    return this.AI_CONFIG_PROJECT_URL;
  }

  projectAIParamController_GET_getProjectAiConfigs() {
    return this.AI_CONFIG_PROJECT_URL + "/page";
  }

  projectAIParamController_PUT_updateProjectAiConfig() {
    return this.AI_CONFIG_PROJECT_URL;
  }

  userProjectAIConfigController_GET_getUserProjectAIConfigs() {
    return this.AI_USER_PROJECT_CONFIG_URL + "/page";
  }

  userProjectAIConfigController_GET_getUserProjectAIConfig() {
    return this.AI_USER_PROJECT_CONFIG_URL;
  }

  userProjectAIConfigController_PUT_updateUserProjectAIConfig() {
    return this.AI_USER_PROJECT_CONFIG_URL;
  }


  userProjectAIController_GET_getUserProjectAI() {
    return this.AI_USER_PROJECT_SETTINGS_URL;
  }

  userProjectAIController_GET_getCleanUserProjectAI() {
    return this.AI_USER_PROJECT_SETTINGS_URL + "/clean";
  }

  userProjectAIController_PUT_userProjectAIActiveVendor() {
    return this.AI_USER_PROJECT_SETTINGS_URL + "/vendor";
  }

  userProjectAIController_PUT_userProjectAIActiveSession() {
    return this.AI_USER_PROJECT_SETTINGS_URL + "/session";
  }


  userProjectAIController_GET_getChatSessionByPurpose(caseId: string) {
    return this.AI_CHAT_SESSION_URL + "/purpose/" + caseId;
  }

  userProjectAIController_GET_cancelAiRequest(sessionId: string, runId: string) {
    return this.AI_ASK_URL + "/session/" + sessionId + "/" + runId + "/stop/request";
  }
}
