import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserListOptionsComponent} from './user-list-options.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IconModule} from '../../../mx/icon/icon.module';
import {UserFilterModule} from '../user-filter/user-filter.module';
import {OverlayscrollbarsModule} from 'overlayscrollbars-ngx';
import {FilterByModule} from '../../../mx/filter-by/filter-by.module';
import {MxOptionsModule} from '../../../mx/options/mx-options/mx-options.module';


@NgModule({
  declarations: [UserListOptionsComponent],
  exports: [
    UserListOptionsComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    IconModule,
    UserFilterModule,
    OverlayscrollbarsModule,
    FilterByModule,
    MxOptionsModule
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class UserListOptionsModule {
}
