import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RenameDialogComponent} from './rename-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ErrorModule} from '../../../mx/error/error.module';
import {MatCheckboxModule} from '@angular/material/checkbox';


@NgModule({
  declarations: [RenameDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    ErrorModule,
    MatCheckboxModule
  ], exports: [
    RenameDialogComponent
  ]
})
export class RenameDialogModule {
}
