import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {GlobalEventsManagerService} from '../services/global-events-manager.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {first} from 'rxjs/operators';
import {NavigationTrackerService} from "../navigation-tracker.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private globalEventsManager: GlobalEventsManagerService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const path = route.routeConfig?.path;
      console.log('%c 11212 path AuthGuard ','background: green', path);
      const queryParams = route.queryParams;
      console.log('%c 11212 queryParams AuthGuard ','background: green', queryParams);

      const redirectQueryParams = {
        ...queryParams,
        returnUrl: path
      };

      console.log('%c 11212 redirectQueryParams AuthGuard ','background: green', redirectQueryParams);
      this.auth.authState.pipe(first()).subscribe(
        user => {
          if (user) {
            if (user.emailVerified || user.isAnonymous) {
              this.globalEventsManager.showNavBar(true);
              resolve(true);
            } else {
              this.router.navigate(['/verifyEmail']).then();
              resolve(true);
            }
          } else {
            console.log('%c 11212 path 2 AuthGuard ','background: green', path);
            this.router.navigate(['/logout'], {queryParams: redirectQueryParams}).then();
//http://localhost:4200/cases-workspace?caseId=7fd8b8f4-8301-47b1-a567-142cf31e8195&projectId=a1
            resolve(false);
          }
        },
        error => {
          console.error(error);
        }
      );
    });
  }

}
