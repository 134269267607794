import {NgModule} from '@angular/core';
import {DeleteDialogComponent} from './delete-dialog.component';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    MatListModule,
    MatDialogModule,
    RouterModule,
    MatButtonModule,
    CommonModule
  ],
  declarations: [
    DeleteDialogComponent
  ]
})

export class DeleteDialogModule {
}
