<h1 class="ml-5 mr-5 mt-3" mat-dialog-title style="text-align: center;">Compile info details</h1>
<div mat-dialog-content>
  <!--  <div class="data-wrapper data-wrapper-border-radius-bottom card-shadow">-->
  <!--    <div class="data">-->
  <!--      <label class="data_label">Id: </label>-->
  <!--      <div class="data_span">{{compileInfo.id}}</div>-->
  <!--    </div>-->
  <!--  <div class="data-wrapper data-wrapper-border-radius-bottom card-shadow">-->
  <!--    <div class="data">-->
  <!--      <label class="data_label">Completed: </label>-->
  <!--      <div class="data_span">{{compileInfo.completed ? 'yes' : 'no'}}</div>-->
  <!--    </div>-->

  <!--    <div class="data">-->
  <!--      <label class="data_label">Current/Max: </label>-->
  <!--      <div class="data_span">{{compileInfo.currentNumber}}/{{compileInfo.maxNumber}}</div>-->
  <!--    </div>-->

  <div class="ml-3 mr-3 mb-2">
    <div style="text-align: center">{{compileInfo.currentNumber}}/{{compileInfo.maxNumber}}</div>
    <mat-progress-bar [value]="compileInfo.currentNumber/compileInfo.maxNumber*100" class=" w-100" mode="determinate"
                      style="height: 10px"></mat-progress-bar>
    <!--    <div style="position:relative; top: -10px; left: 30px;">-->
    <!--      {{compileInfo.currentNumber}}/{{compileInfo.maxNumber}}-->
    <!--    </div>-->
  </div>

  <!--  </div>-->
  <!--    <div class="data">-->
  <!--      <label class="data_label">Created date: </label>-->
  <!--      <div class="data_span">{{setFormat(compileInfo.createdDate)}}</div>-->
  <!--    </div>-->

  <!--    <div class="data">-->
  <!--      <label class="data_label">Last updated date: </label>-->
  <!--      <div class="data_span">{{setFormat(compileInfo.updatedDate)}}</div>-->
  <!--    </div>-->

  <!--    <mat-expansion-panel style="border-radius: 0;"-->
  <!--                         *ngIf="compileInfo.skipped != null && compileInfo.skipped != ''">-->
  <!--      <mat-expansion-panel-header>-->
  <!--        <mat-panel-title>-->
  <!--          Skipped compiles-->
  <!--        </mat-panel-title>-->
  <!--      </mat-expansion-panel-header>-->

  <!--      <mat-form-field class="w-100">-->
  <!--          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"-->
  <!--                    matAutosizeMinRows="2"-->
  <!--                    [contentEditable]="false"-->
  <!--                    [matTextareaAutosize]="true" [(ngModel)]="compileInfo.skipped"-->
  <!--                    (ngModelChange)="paramsChangeValue()"></textarea>-->
  <!--      </mat-form-field>-->
  <!--    </mat-expansion-panel>-->

  <!--    <mat-expansion-panel style="border-radius: 0;"-->
  <!--                         *ngIf="compileInfo.errors != null && compileInfo.errors != ''">-->
  <!--      <mat-expansion-panel-header>-->
  <!--        <mat-panel-title>-->
  <!--          Compile errors-->
  <!--        </mat-panel-title>-->
  <!--      </mat-expansion-panel-header>-->

  <!--      <mat-form-field class="w-100">-->
  <!--          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"-->
  <!--                    matAutosizeMinRows="2"-->
  <!--                    [contentEditable]="false"-->
  <!--                    [matTextareaAutosize]="true"-->
  <!--                    [(ngModel)]="compileInfo.errors"-->
  <!--                    (ngModelChange)="paramsChangeValue()"></textarea>-->
  <!--      </mat-form-field>-->
  <!--    </mat-expansion-panel>-->

  <!--    <mat-expansion-panel style="border-radius: 0;"-->
  <!--                         *ngIf="compileInfo.error != null && compileInfo.error != ''">-->
  <!--      <mat-expansion-panel-header>-->
  <!--        <mat-panel-title>-->
  <!--          Pre compile error-->
  <!--        </mat-panel-title>-->
  <!--      </mat-expansion-panel-header>-->

  <!--      <mat-form-field class="w-100">-->
  <!--          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"-->
  <!--                    matAutosizeMinRows="2"-->
  <!--                    [contentEditable]="false"-->
  <!--                    [matTextareaAutosize]="true" [(ngModel)]="compileInfo.error"-->
  <!--                    (ngModelChange)="paramsChangeValue()"></textarea>-->
  <!--      </mat-form-field>-->
  <!--    </mat-expansion-panel>-->


  <app-compile-info-case-info-options
    (filter)="filter($event)"
    (refresh)="refreshCompileInfo();"

    [(searchParams)]="params.search"
    [checkPaths]="getCompileInfoCaseInfoListOptionsCheckPathObject()">

  </app-compile-info-case-info-options>
  <app-compile-info-case-info-list
    #list
    (clickedItem)="clickedCompileInfo($event)"
    (compiledError)="compiledError($event)"
    (pageParamsChange)="pageParamsChange($event)"

    (pagesInfoChanges)="setPageInfoChanges($event)"
    (transpiledError)="transpiledError($event)"
    [autoSetItems]="true"
    [compileInfoId]="this.compileInfo.id"

    [pageParams]="params.page"
    [pageSizeOptions]="[5]"

    [searchParams]="params.search">

  </app-compile-info-case-info-list>


  <!--  </div>-->
</div>
<div class="mr-3 ml-3" mat-dialog-actions>
  <button [mat-dialog-close]="false" mat-button>
    Close
  </button>
</div>
