<section [ngClass]="{'ai-color': ai, 'user-color': !ai}"
         class="main-container ai-title-wrapper"
>
  <div class="title-box">
    <mx-icon *ngIf="!ai" mx="user"></mx-icon>
    <mx-icon *ngIf="ai" mx="ai"></mx-icon>
    <span class="title">{{(ai ? 'AI Chat' : 'You')}}</span>
  </div>
  <div class="title-buttons">
    <button (click)="copyToClipboard(); $event.stopPropagation()"
            [matTooltip]="'Copy to clipboard'"
            mat-button
            style="margin: 0;padding: 0">
      <mx-icon mx="copy"></mx-icon>
      <!--    <span>Copy to clipboard</span>-->
    </button>
  </div>
</section>
