<section>

  <app-error-message *ngIf="showErrorAlert" [cleanDesign]="true" [error]="error" [fullWidth]="true"></app-error-message>

  <div class="responsive-table-wrapper">
    <table (matSortChange)="sortData($event)" [dataSource]="items"
           [matSortActive]="pageParams.sort.value.split(',')[0]"
           [matSortDirection]="pageParams.sort.value.split(',')[1]"
           mat-table
           matSort>

      <!-- Type Column -->
      <ng-container matColumnDef="email">
        <th *matHeaderCellDef mat-header-cell> Email</th>
        <td *matCellDef="let element" mat-cell>

          <div class="user-profile">
            <div class="user-avatar">
              <mx-icon mx="user-avatar">person</mx-icon>
            </div>
            <div class="profile-description">
              <div class="profile-primary-text">{{element.user.email}}</div>
              <div class="profile-secondary-text">{{element.user.displayName}}</div>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Run Date Column -->
      <ng-container matColumnDef="securityGroups">
        <th *matHeaderCellDef mat-header-cell> SecurityGroups</th>
        <td *matCellDef="let element" mat-cell>
          <span *ngFor="let securityGroup of element.securityGroups">{{securityGroup.publicName}} &nbsp;</span>
        </td>
      </ng-container>


      <!-- Remove Column -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>

          <button (click)="removeUserDialog(element);$event.stopPropagation();"
                  *ngIf="checkPaths.remove.enabled"
                  mat-icon-button>
            <mx-icon class="gray_mx_icon" marginBottom="true" matTooltip="Remove user from project"
                     mx="delete"></mx-icon>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr (click)="clickedItem(row, i)" *matRowDef="let row; columns: displayedColumns;let i = index" mat-row
          style="cursor: pointer;"></tr>

    </table>
  </div>

  <spinner-for-table [loading]="isLoading"></spinner-for-table>

  <mat-paginator
    (page)="setPageSizeOrIndex($event)"
    [length]="pagesInfo.totalElements"
    [pageIndex]="pageParams.page.value"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageParams.size.value"
    showFirstLastButtons></mat-paginator>

</section>
