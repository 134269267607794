export class ImportInfoDetails {
  casesId: string;

  maxCasesNumber: number;

  currentCasesNumber: number;

  casesCompleted: boolean;

  casesError: string;

  casesSkipped: string;

  casesErrors: string;

  casesCreatedDate: string;

  casesUpdatedDate: string;


  suitesId: string;

  maxSuitesNumber: number;

  currentSuitesNumber: number;

  suitesCompleted: boolean;

  connectingCasesWithSuitesStart = false;

  connectingCasesWithSuitesEnd = false;

  suitesError: string;

  suitesSkipped: string;

  suitesErrors: string;

  caseSuitesErrors: string;

  caseSuitesError: string;

  suitesCreatedDate: string;

  suitesUpdatedDate: string;


  schedulesId: string;

  maxScheduleNumber: number;

  currentScheduleNumber: number;

  scheduleCompleted: boolean;

  scheduleError: string;

  scheduleSkipped: string;

  scheduleErrors: string;

  scheduleCreatedDate: string;

  scheduleUpdatedDate: string;


  projectParamsId: string;

  maxProjectParamsNumber: number;

  currentProjectParamsNumber: number;

  projectParamsCompleted: boolean;

  projectParamsError: string;

  projectParamsSkipped: string;

  projectParamsErrors: string;

  projectParamsCreatedDate: string;

  projectParamsUpdatedDate: string;

  workspaceError: string;
  workspaceCompleted: string;
  workspaceStart: string;
}
