<a (click)="clickLink()" class="m-0 p-0" color="primary" mat-button>
  {{text}}
  <mx-icon
    *ngIf="icon != null && icon != ''"
    [mx]="icon"
    color="primary"
    hoverColor="primary"
    marginBottom="true"
    style="font-size:20px; width:20px; height:20px; line-height: 20px;"></mx-icon>
</a>
