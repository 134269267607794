import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CompileInfo} from '../../shared/models/compileInfo';
import {CompileInfoConstDialogParams} from '../compile-info-dialog/compile-info-const.service';
import {CompileService} from '../../shared/services/compile.service';

@Component({
  selector: 'app-compile-info-delete-dialog',
  templateUrl: './compile-info-delete-dialog.component.html',
  styleUrls: ['./compile-info-delete-dialog.component.css']
})
export class CompileInfoDeleteDialogComponent implements OnInit {

  title = '';
  disableAnimation = true;
  disableSecondButton = false;
  hideAdvancedSettings = false;

  id = undefined;
  spinner = false;
  error = undefined;
  showErrorAlert = false;

  params: CompileInfoConstDialogParams;
  selected: CompileInfo[] = [];
  deleteType = 1;

  constructor(
    public dialogRef: MatDialogRef<CompileInfoDeleteDialogComponent>,
    private router: Router,
    private compileService: CompileService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      id: string,
      params: CompileInfoConstDialogParams;
      selected: CompileInfo[],
    }
  ) {
    if (data.params && data.params != null) {
      this.params = data.params;
    }

    if (data.selected != null && data.selected.length > 0) {
      this.selected = data.selected;
      this.deleteType = 1;
    } else {
      this.deleteType = 2;
    }


    if ((this.selected == null || this.selected.length == 0) && this.params == undefined) {
      this.hideAdvancedSettings = true;
    }

  }

  ngOnInit(): void {
  }

  deleteDeleteDialog() {
    this.compileService.deleteCasesInfos(this.selected.map(e => e.id), this.params.search, this.params.page)
      .subscribe(
        res => {
          this.dialogRef.close();
        },
        error => {
          this.showError(error);
        }
      );
  }

  private showError(error: any) {
    console.error(error);
  }
}
