import {Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RemoteFilesService} from '../../shared/services/remote-files.service';

@Component({
  selector: 'app-create-new-folder-dialog',
  templateUrl: './create-new-folder-dialog.component.html',
  styleUrls: ['./create-new-folder-dialog.component.css']
})
export class CreateNewFolderDialogComponent implements OnInit, OnDestroy {

  showErrorAlert = false;
  error;

  saveSpinner = false;
  folderName;
  onNewFolderCreated = new EventEmitter();
  overwrite = false;

  constructor(
    private dialogRef: MatDialogRef<CreateNewFolderDialogComponent>,
    private dialog: MatDialog,
    private remoteFileService: RemoteFilesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      path: string
    }
  ) {
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for creating the test
  createShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('createButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.createShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.createShortcutListener, false);
  }

  createNewFolder() {
    if (this.folderName) {
      if (!this.folderName.includes('/')) {
        this.remoteFileService.createNewFolder(this.data.path, this.folderName, this.overwrite).subscribe(
          (data) => {
            this.onNewFolderCreated.emit();
            this.dialogRef.close(true);
          },
          (error) => {
            this.showError(error);
          }
        );
      } else {
        this.showError({error: {errorCode: 'Error', errorMessage: 'You can\'t use \'/\' in a folder name!'}});
      }
    } else {
      this.showError({error: {errorCode: 'Error', errorMessage: 'Type a folder name first!'}});
    }
  }

}
