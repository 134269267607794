import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

  @Input() type = '';

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string, caseId: any }
  ) {
    this.type = this.data.type;
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for deleting the test
  confirmDeleteShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('deleteConfirmButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.confirmDeleteShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.confirmDeleteShortcutListener, false);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
