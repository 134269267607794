<section class="main-container" style="height: 100%">
  <div *ngIf="isRunActive" class="stop-responding">
    <button (mousedown)="stopEvent()"
            [disabled]="disableInput"
            aria-label="Stop Responding"
            color="primary"
            mat-raised-button>
      Stop Responding
    </button>
  </div>

  <div *ngIf="!isRunActive" [matTooltip]="disableInput ? 'Maximum number of requests per project on a daily basis has been reached using the Maxtaf key. Please configure your key in the project parameters.': ''"
       class="input-box"
       style="height: 100%"
       tabindex="2">

    <mat-form-field class="fill-container" style="height: 100%">
      <mat-label>Send a message</mat-label>
      <textarea
        #textareaElement
        (blur)="focusOutInput()"
        (focus)="focusOnInput()"
        (keydown)="onKeydown($event)"
        (ngModelChange)="adjustTextareaHeight()"
        [(ngModel)]="value"
        [disabled]="disableInput"
        matInput
        placeholder="Send a message"
        rows="3"
      ></textarea>
    </mat-form-field>

    <div [ngStyle]="{ 'height': inputHeight + 'px' }" id="parentDiv" style="position: relative;">
      <!--      <div id="fixedButtonContainer" *ngIf="showLinkCase">-->
      <!--        <button mat-mini-fab-->
      <!--                style="background: none; box-shadow: none; color: #1e88e5;"-->
      <!--                [matTooltip]="this.linkCase ? 'Remove code from the question' : 'Add code to the question.'"-->
      <!--                [disabled]="disableInput"-->
      <!--                (mousedown)="clickedLinkCode()"-->
      <!--                aria-label="Send message" id="fixedButton">-->
      <!--          <mx-icon mx="unlinked-file" *ngIf="!this.linkCase"></mx-icon>-->
      <!--          <mx-icon mx="linked-file" *ngIf="this.linkCase"></mx-icon>-->
      <!--        </button>-->
      <!--      </div>-->
      <div id="centeredButtonContainer">
        <button (mousedown)="askQuestionEvent()"
                [disabled]="disableInput"
                aria-label="Send message"
                class="run-button"
                color="primary"
                id="centeredButton" mat-raised-button>
          Send
        </button>
      </div>
    </div>
  </div>
</section>
