import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddUserPreferenceParamDialogComponentComponent} from "./add-user-preference-param-dialog-component.component";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {NavTreeFileIconModule} from "../../../storage/components/nav-tree-file-icon/nav-tree-file-icon.module";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";


@NgModule({
  declarations: [AddUserPreferenceParamDialogComponentComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonToggleModule,
    NavTreeFileIconModule,
    MatOptionModule,
    MatSelectModule
  ], exports: [
    AddUserPreferenceParamDialogComponentComponent
  ]

})
export class AddUserPreferenceParamDialogComponentModule {
}
