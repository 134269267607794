<h1 mat-dialog-title>Import case info details</h1>
<div mat-dialog-content>
  <div class="data-wrapper data-wrapper-border-radius-bottom card-shadow">
    <!--    <div class="data">-->
    <!--      <label class="data_label">Id: </label>-->
    <!--      <div class="data_span">{{importInfoDetails.casesId}}</div>-->
    <!--    </div>-->

    <div class="data">
      <label class="data_label">Cases completed: </label>
      <div class="data_span">{{importInfoDetails.casesCompleted ? 'yes' : 'no'}}</div>
    </div>

    <div class="data">
      <label class="data_label">Cases current/Max: </label>
      <div class="data_span">{{importInfoDetails.currentCasesNumber}}/{{importInfoDetails.maxCasesNumber}}</div>
    </div>

    <!--    <div class="data">-->
    <!--      <label class="data_label">Cases created date: </label>-->
    <!--      <div class="data_span">{{setFormat(importInfoDetails.casesCreatedDate)}}</div>-->
    <!--    </div>-->

    <!--    <div class="data">-->
    <!--      <label class="data_label">Cases last updated date: </label>-->
    <!--      <div class="data_span">{{setFormat(importInfoDetails.casesUpdatedDate)}}</div>-->
    <!--    </div>-->

    <mat-expansion-panel *ngIf="importInfoDetails.casesSkipped != null && importInfoDetails.casesSkipped != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped cases
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.casesSkipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.casesErrors != null && importInfoDetails.casesErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cases errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.casesErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.casesError != null && importInfoDetails.casesError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cases error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.casesError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>


  </div>
</div>
<div mat-dialog-actions>
  <button [mat-dialog-close]="false" mat-button>
    Close
  </button>
</div>
