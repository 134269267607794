import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AngularFireStorage} from '@angular/fire/storage';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {RemoteFilesService} from '../../shared/services/remote-files.service';
import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.css']
})
export class UploadDialogComponent implements OnInit {

  onUpload = new EventEmitter();
  overwrite = false;

  files: any[] = [];

  spinner = false;

  constructor(
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    private dialog: MatDialog,
    private storage: AngularFireStorage,
    private tokensService: MaxtafTokensStorageService,
    private remoteFileService: RemoteFilesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      projectId: string,
      caseId: number,
      uploadPath: string,
      files: any[]
    }
  ) {

    if (data.files != undefined) {
      this.prepareFilesList(data.files);
    }
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  uploadFiles() {
    this.uploadFilesSimulator(0);
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  uploadFilesSimulator(index: number) {
    if (index === this.files.length) {
      return;
    }

    this.spinner = true;

    this.remoteFileService.uploadFile(this.files[index], this.data.uploadPath, this.overwrite).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.files[index].progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.onUpload.emit();
          this.files[index].progress = 100;
          this.files[index].status = 'pass';
          this.uploadFilesSimulator(index + 1);
          this.spinner = false;
        }
      },
      (error) => {
        this.spinner = false;
        this.files[index].error = error;
        this.files[index].progress = 100;
        this.files[index].status = 'fail';
        this.uploadFilesSimulator(index + 1);
      }
    );
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      item.status = 'wait';
      item.error = undefined;
      item.fileType = item.name.toString().substr(item.name.toString().lastIndexOf('.') + 1);
      this.files.push(item);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
