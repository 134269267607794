import {Directive, OnInit} from '@angular/core';

@Directive()
export abstract class MxBase implements OnInit {


  error = undefined;
  showErrorAlert = false;

  protected constructor() {

  }


  ngOnInit(): void {
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }


  hideError() {
    this.error = undefined;
    this.showErrorAlert = false;
  }
}
