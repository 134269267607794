import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CheckPath} from '../../../mx/options/CheckPath';
import {MxOptionAbstract} from '../../../mx/options/MxOptionAbstract';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';

export class UserListOptionsPaths {
  filter = new CheckPath();
  create = new CheckPath();
  refresh = new CheckPath();
  delete = new CheckPath();
}

@Component({
  selector: 'app-user-list-options',
  templateUrl: './user-list-options.component.html',
  styleUrls: ['./user-list-options.component.css']
})
export class UserListOptionsComponent extends MxOptionAbstract<UserListOptionsPaths> implements OnInit {


  @Input('searchParams') searchParams;
  @Output('searchParamsChange') searchParamsChange = new EventEmitter<any>();

  @Input('userRequests') userRequests = false;
  @Input('singe') single = false;
  @Input('hasSelected') hasSelected = false;
  @Input('lockFilterParams') lockFilterParams = [];

  @Input('allowShadow') allowShadow = true;
  @Input('allowEdit') edit = true;
  @Input('showButtons') showButtons = true;
  @Input('showButtonText') showButtonText = true;
  @Input('showTooltip') showTooltip = false;
  @Input('smallHeight') smallHeight = false;
  @Input('iconMarginBottom') iconMarginBottom = true;

  @Output('filter') filterEvent = new EventEmitter<null>();
  @Output('refresh') refreshEvent = new EventEmitter<null>();
  @Output('create') createEvent = new EventEmitter<null>();
  @Output('delete') deleteEvent = new EventEmitter<null>();

  constructor(public dialog: MatDialog, public checkRolesService: CheckRolesService) {
    super(checkRolesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  filter() {
    this.filterEvent.emit();
  }

  refresh() {
    this.refreshEvent.emit();
  }

  create() {
    this.createEvent.emit();
  }

  delete() {
    this.deleteEvent.emit();
  }

  newParams(searchParams) {
    this.searchParams = {...searchParams};
    this.searchParamsChange.emit(this.searchParams);
  }

}
