import {Component, Input, OnInit} from '@angular/core';
import {MxFilter} from '../../../mx/filter/MxFilter';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.css']
})
export class UserFilterComponent extends MxFilter implements OnInit {

  @Input('userRequests') userRequests = false;

  search: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  searchDefaultValues() {
    return {
      name: '',
      email: '',
      exactSearch: 'Contains',
    };
  }

}
