import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserPreferenceParamsListComponent} from './user-preference-params-list.component';
import {IconModule} from "../../../mx/icon/icon.module";
import {MatButtonModule} from "@angular/material/button";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SpinnerForTableModule} from "../../../../shared/util-components/spinner-for-table/spinner-for-table.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";


@NgModule({
  declarations: [UserPreferenceParamsListComponent],
  exports: [
    UserPreferenceParamsListComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    SpinnerForTableModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule
  ]
})
export class UserPreferenceParamsListModule {
}
