import {Injectable} from '@angular/core';
import {PageParams} from '../../../mx/util/params/PageParams';
import {SearchParam} from '../../../mx/util/params/searchParam';

@Injectable({
  providedIn: 'root'
})
export class ImportCaseInfoConstService {

  constructor() {
  }

  public static initImportCaseDialogParams(): CaseImportInfoDialogParams {
    return {
      page: ImportCaseInfoConstService.getDefaultPageParams(),
      search: ImportCaseInfoConstService.getDefaultSearchParams(),
    };
  }

  public static getDefaultSearchParams(): ImportCaseInfoConstParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      startDate: new SearchParam(null, null),
      endDate: new SearchParam(null, null),
      completed: new SearchParam(null, null),
      errors: new SearchParam(null, null),
      error: new SearchParam(null, null),
    };
  }

  public static getDefaultPageParams(size = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('id,desc', 'id,desc')
    };
  }
}

export class CaseImportInfoDialogParams {
  page: PageParams;
  search: ImportCaseInfoConstParams;
}

export class ImportCaseInfoConstParams {
  exactSearch: SearchParam;
  startDate: SearchParam;
  endDate: SearchParam;
  completed: SearchParam;
  errors: SearchParam;
  error: SearchParam;
}
