<h5 class="ml-4" mat-dialog-title>Properties</h5>

<div mat-dialog-content>

  <app-error-message *ngIf="updateError" [error]="error"></app-error-message>

  <!--          info-->
  <div class="data-wrapper">
    <div class="data">
      <label class="data_label">Name: </label>
      <span class="data_span">
        {{file.name}}
        <button (click)="openEditDialog('input','Name:','name')" *ngIf="edit" mat-icon-button>
          <mx-icon class="pencil-icons" mx="edit"></mx-icon>
        </button>
      </span>
    </div>

    <div class="data">
      <label class="data_label">File path: </label>
      <span class="data_span">{{file.pathWithName}}</span>
      <button (click)="openEditDialog('input','File path:','pathWithName')" *ngIf="edit" mat-icon-button>
        <mx-icon class="pencil-icons" mx="edit"></mx-icon>
      </button>
    </div>

    <div *ngIf="file.isFile" class="data">
      <label class="data_label">Type of file: </label>
      <span
        class="data_span">
        {{file.typeOfFile == undefined || file.typeOfFile == '' ? '' : file.typeOfFile.toLocaleUpperCase() + ' File (.' + file.typeOfFile.toLocaleLowerCase() + ')'}}
      </span>
      <button (click)="openEditDialog('input','Type of file:','typeOfFile')" *ngIf="edit" mat-icon-button>
        <mx-icon class="pencil-icons" mx="edit"></mx-icon>
      </button>
    </div>

    <div class="data">
      <label class="data_label">Project id: </label>
      <span class="data_span">
        {{projectId}}
      </span>
    </div>

    <div class="data">
      <label class="data_label">Size: </label>
      <span class="data_span">{{file.size}} bytes</span>
    </div>

    <div class="data">
      <label class="data_label">Created: </label>
      <span class="data_span">{{file.created}}</span>
    </div>

    <div class="data">
      <label class="data_label">Modified: </label>
      <span class="data_span">{{file.modified}}</span>
    </div>

    <div class="data">
      <label class="data_label">Accessed: </label>
      <span class="data_span">{{file.accessed}}</span>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button [mat-dialog-close] mat-button>Cancel</button>
  <button (click)="dialogRef.close()" *ngIf="!edit" color="primary" mat-button>OK</button>
  <button (click)="update()" *ngIf="edit" color="primary" mat-button>Save</button>
</div>
