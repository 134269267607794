import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {
  SuiteAddItemsDialogParams,
  SuiteConstService,
  SuiteSearchParams
} from '../../shared/services/suite-const.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {ClickedTableItem} from '../../../mx/util/list/ClickedTableItem';
import {CaseAndSuiteParent} from '../../shared/models/case-and-suite-parent';
import {PageParams} from '../../../mx/util/params/PageParams';
import {PageInfo} from '../../../../shared/models/pageInfo';
import {CaseSearchParams} from '../../../cases/shared/services/case-const.service';
import {CaseListOptionsPaths} from '../../../cases/components/case-list-options/case-list-options.component';
import {CheckPath} from '../../../mx/options/CheckPath';
import {SuiteOptionsPaths} from '../suite-options/suite-options.component';
import {CreateCaseDialogComponent} from '../../../cases/components/create-case-dialog/create-case-dialog.component';
import {
  CreatePageObjectDialogComponent
} from '../../../cases/components/create-page-object-dialog/create-page-object-dialog.component';

@Component({
  selector: 'app-add-case-tab-dialog',
  templateUrl: './add-case-tab-dialog.component.html',
  styleUrls: ['./add-case-tab-dialog.component.css']
})
export class AddCaseTabDialogComponent implements OnInit {


  selectedItems = [];

  caseSelected: [] = [];

  suiteSelected: [] = [];

  params: SuiteAddItemsDialogParams;
  paramsChange = new EventEmitter();
  multiSelect = true;
  removeSuites = false;
  showPageObjects = false;
  showCreatePageObjects = false;
  showCreateCases = false;


  constructor(public dialogRef: MatDialogRef<AddCaseTabDialogComponent>,
              private cloudConfigService: CloudConfigService,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: {
                params: SuiteAddItemsDialogParams,
                multiSelect: boolean,
                removeSuites: boolean,
                showPageObjects: boolean,
                showCreatePageObjects: boolean,
                showCreateCases: boolean
              }) {

    if (data.multiSelect != undefined) {
      this.multiSelect = data.multiSelect;
    }

    if (data.removeSuites != undefined) {
      this.removeSuites = data.removeSuites;
    }

    if (data.showCreatePageObjects != undefined) {
      this.showCreatePageObjects = data.showCreatePageObjects;
    }

    if (data.showCreateCases != undefined) {
      this.showCreateCases = data.showCreateCases;
    }

    if (data.showPageObjects != undefined) {
      this.showPageObjects = data.showPageObjects;
    }


    if (data.params == undefined) {
      this.params = SuiteConstService.initAddItemDialogParams();
      // this.params.caseSearch.showCases.value = true;
      // this.params.caseSearch.showCases.defaultValue = true;

      if (this.showPageObjects == null || this.showPageObjects == false) {
        this.params.caseSearch.showPageObjects.value = false;
        this.params.caseSearch.showPageObjects.defaultValue = false;
        this.params.caseSearch.showCases.value = true;
        this.params.caseSearch.showCases.defaultValue = true;
      } else {
        this.params.caseSearch.showPageObjects.value = true;
        this.params.caseSearch.showPageObjects.defaultValue = true;
        this.params.caseSearch.showCases.value = false;
        this.params.caseSearch.showCases.defaultValue = false;
      }

    } else {
      this.params = data.params;
    }
  }


  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for running the test
  addShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('addButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.addShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.addShortcutListener, false);
  }

  caseSelectedItems(items) {
    this.caseSelected = items;

    this.newSelectedItems();
  }

  suiteSelectedItems(items) {
    this.suiteSelected = items;

    this.newSelectedItems();
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CreateCaseDialogComponent, {
      width: '700px',
      data: {
        openCreatedCase: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.dialogRef.close(
          {
            params: undefined,
            ids: [result.id]
          }
        );
      }
    });
  }

  openCreatePageObjectEventDialog() {
    const dialogRef = this.dialog.open(CreatePageObjectDialogComponent, {
      width: '700px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {

        this.dialogRef.close(
          {
            params: undefined,
            ids: [result.id]
          }
        );
      }
    });
  }


  newSelectedItems() {
    this.selectedItems = [...this.caseSelected, ...this.suiteSelected];
  }

  clickedScriptItem(clickedItem: ClickedTableItem<CaseAndSuiteParent>) {
    if (this.selectedItems.length == 0) {
      this.params.index.value = clickedItem.index;
      this.emmitNewAddItemParams();
      // setTimeout(() => {
      this.dialogRef.close(
        {
          params: this.params,
          ids: [clickedItem.item.id],
          scriptTab: true
        }
      );
      // }, 400);

    }
  }

  clickedItem(clickedItem: ClickedTableItem<CaseAndSuiteParent>) {
    if (this.selectedItems.length == 0) {
      this.params.index.value = clickedItem.index;
      this.emmitNewAddItemParams();
      // setTimeout(() => {
      this.dialogRef.close(
        {
          params: this.params,
          ids: [clickedItem.item.id],
          scriptTab: false
        }
      );
      // }, 400);

    }
  }

  returnSelectedIds() {
    const ids = [];
    if (this.selectedItems != undefined) {
      this.selectedItems.forEach((item) => {
        ids.push(item.id);
      });
    }
    setTimeout(() => {
      this.dialogRef.close(ids);
    }, 400);
  }

  casePageParamsChange(casePageParams: PageParams) {
    this.params.casePage = casePageParams;
    this.emmitNewAddItemParams();
  }

  pagesInfoChanges(pageInfo: PageInfo) {
    this.params.casePageInfo = pageInfo;
    this.emmitNewAddItemParams();
  }

  suitePagesInfoChanges(pageInfo: PageInfo) {
    this.params.suitePageInfo = pageInfo;
    this.emmitNewAddItemParams();
  }

  suitePageParamsChange(suitePageParams: PageParams) {
    this.params.suitePage = suitePageParams;
    this.emmitNewAddItemParams();
  }

  caseSearchParamsChange(caseSearchParams: CaseSearchParams) {
    this.params.caseSearch = caseSearchParams;
    this.emmitNewAddItemParams();
  }

  suiteSearchParamsChange(suiteSearchParams: SuiteSearchParams) {
    this.params.suiteSearch = suiteSearchParams;
    this.emmitNewAddItemParams();
  }

  emmitNewAddItemParams() {
    this.paramsChange.emit(this.params);
  }

  changeTabIndex(tabIndex: number) {
    this.params.tabIndex.value = tabIndex;
    this.emmitNewAddItemParams();
  }

  getCaseListOptionsCheckPathObject(): CaseListOptionsPaths {
    return {
      filter: new CheckPath('GET', this.cloudConfigService.getCases()),
      create: new CheckPath('POST', this.cloudConfigService.addCase()),
      createPageObject: new CheckPath('POST', this.cloudConfigService.addCase()),
      compile: new CheckPath('', ''),
      refresh: new CheckPath('GET', this.cloudConfigService.getCases()),
      export: new CheckPath('', ''),
      import: new CheckPath('', ''),
      delete: new CheckPath('', ''),
      restore: new CheckPath('', ''),
      trashRecords: new CheckPath('', ''),
      activeRecords: new CheckPath('', ''),
    };
  }

  getSuiteOptionsCheckPathObject(): SuiteOptionsPaths {
    return {
      filter: new CheckPath('GET', this.cloudConfigService.getSuites()),
      create: new CheckPath('POST', this.cloudConfigService.addSuite()),
      run: new CheckPath('', ''),
      refresh: new CheckPath('GET', this.cloudConfigService.getSuites()),
      duplicate: new CheckPath('', ''),
      export: new CheckPath('', ''),
      import: new CheckPath('', ''),
      trash: new CheckPath('', ''),
      active: new CheckPath('', ''),
      restore: new CheckPath('', ''),
      delete: new CheckPath('', '')
    };
  }


}
