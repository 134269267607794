import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiLoadResponseComponent} from './ai-load-response.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {IconModule} from "../../../mx/icon/icon.module";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatProgressBarModule} from "@angular/material/progress-bar";


@NgModule({
  declarations: [AiLoadResponseComponent],
  exports: [
    AiLoadResponseComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    IconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule
  ]
})
export class AiLoadResponseModule {
}
