<div>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <app-mx-diff-code-options
    (next)="navigateNext()"
    (prev)="navigatePrevious()">

  </app-mx-diff-code-options>

  <div #editorContainer class="editor-container"
       style="height: 770px !important; min-height: 250px !important;"></div>


</div>
