import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchComponent} from './search.component';

// Import Angular Material modules
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NavTreeFileIconModule} from '../nav-tree-file-icon/nav-tree-file-icon.module';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {ErrorModule} from "../../../mx/error/error.module";
import {IconModule} from "../../../mx/icon/icon.module";
import {EditDialogModule} from "../../../dialogs/edit-dialog/edit-dialog.module"; // Update the path as per your directory structure


@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    NavTreeFileIconModule, // Add FormsModule here
    MatProgressSpinnerModule, // Add Angular Material modules here
    MatCheckboxModule,
    MatTableModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    ErrorModule,
    IconModule,
    EditDialogModule
  ],
  exports: [SearchComponent]
})
export class SearchModule {
}
