import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterBehaviourService {
  private actionSource = new BehaviorSubject<string>('default-action');
  currentAction = this.actionSource.asObservable();

  constructor() {
  }

  changeAction(message: string) {
    this.actionSource.next(message);
  }
}
