<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>

  <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
    <p *ngIf="error" class="mb-0">{{ errorMessage }}</p>
    <button (click)="error=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <label>Name</label>
  <div class="mb-2">
    <input [(ngModel)]="projectParam.name" [disabled]="!checkPaths.update.enabled || !create || !allowEdit" type="text">
  </div>

  <label>Value</label>
  <div class="mb-2">
    <input [(ngModel)]="projectParam.value" [disabled]="!checkPaths.update.enabled || !allowEdit" id="valueInput"
           type="text">
  </div>

  <label>Description</label>
  <div>
    <input [(ngModel)]="projectParam.description" [disabled]="!checkPaths.update.enabled || !allowEdit" type="text">
  </div>

  <!--  <label>Auto increment</label>-->
  <div class="mt-3 ml-1">
    <mat-checkbox [(ngModel)]="projectParam.autoIncrement" [disabled]="!checkPaths.update.enabled || !allowEdit"
                  class="font-style">
      Auto increment
    </mat-checkbox>
  </div>

</div>

<div mat-dialog-actions>
  <button (click)="close()" id="cancelButton" mat-button>{{allowEdit ? 'Cancel' : 'Close'}}</button>

  <button (click)="create? add() : edit()" *ngIf="allowEdit" [disabled]="!checkPaths.update.enabled" color="primary"
          id="createButton"
          mat-button>
    {{createOkButton}}
  </button>
</div>
