import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AiChatService} from "../../shared/services/ai-chat.service";
import {UserProjectAIConfig} from "../../shared/models/userProjectAIConfig";
import {UserProjectAI} from "../../shared/models/userProjectAI";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-ai-options-dialog',
  templateUrl: './ai-options-dialog.component.html',
  styleUrls: ['./ai-options-dialog.component.css']
})
export class AiOptionsDialogComponent implements OnInit {

  public aiConfig: UserProjectAIConfig = new UserProjectAIConfig();
  public userProjectAI: UserProjectAI;

  constructor(
    public aiService: AiChatService,
    public dialogRef: MatDialogRef<AiOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
      id: string
    }
  ) {

    aiService.getUserProjectAI()
      .subscribe((userProjectAI: UserProjectAI) => {
        this.userProjectAI = userProjectAI;

        aiService.getUserProjectAIConfig(userProjectAI.vendor)
          .subscribe((aiConfig: UserProjectAIConfig) => {
            this.aiConfig = aiConfig;
          }, error => {
            console.error("error", error);
          });

      }, error => {
        console.error("error", error);
      });


  }

  ngOnInit(): void {
  }

  updateAIOption() {
    this
      .aiService
      .updateUserProjectAIConfig(this.aiConfig)
      .subscribe((aiConfig: UserProjectAIConfig) => {

          this
            .aiService
            .userProjectAIActiveVendor(this.aiConfig.vendor)
            .subscribe((userProjectAI: UserProjectAI) => {
              this.userProjectAI = userProjectAI;

              this.dialogRef.close();
            }, error => {
              this.dialogRef.close();
              console.error("error", error);
            });


        }, error => {
          this.dialogRef.close();
          console.error("4443 error", error);
        }
      );


  }

  closeDialog() {
    this.dialogRef.close();
  }

  changedVendor($event: MatSelectChange) {

    this.aiService.getUserProjectAIConfig($event.value)
      .subscribe((aiConfig: UserProjectAIConfig) => {
        this.aiConfig = aiConfig;

      }, error => {
        console.error("error", error);
      });

  }
}
