import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectCreateProjectComponent} from './select-create-project.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ErrorModule} from '../../../mx/error/error.module';
import {MatDialogModule} from '@angular/material/dialog';
import {ProjectOptionsModule} from '../../../projects/components/project-options/project-options.module';
import {ProjectsListModule} from '../../../projects/components/projects-list/projects-list.module';
import {MatButtonModule} from '@angular/material/button';
import {CreateDialogProjectModule} from '../../../projects/components/create-project/create-dialog-project.module';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    ErrorModule,
    MatDialogModule,
    ProjectOptionsModule,
    ProjectsListModule,
    MatButtonModule,
    CreateDialogProjectModule
  ],
  declarations: [
    SelectCreateProjectComponent
  ],
  exports: [
    SelectCreateProjectComponent
  ]
})

export class SelectCreateProjectModule {
}
