<h5 class="ml-4" mat-dialog-title>Create Folder</h5>

<div mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>
  <div class="pb-3" style="width: 80%; margin: 0 auto;">
    <label>Folder name</label>
    <input [(ngModel)]="folderName" [disabled]="saveSpinner" maxlength="32" type="text">
  </div>
  <!--  <div style="width: 80%; margin: 0 auto;" class="pb-3">-->
  <!--    <mat-checkbox type="checkbox" [checked]="overwrite" (change)="overwrite = !overwrite"-->
  <!--                  id="overwriteCheck">Overwrite data from zip file?-->
  <!--    </mat-checkbox>-->
  <!--  </div>-->
</div>
<div mat-dialog-actions>
  <button *ngIf="!saveSpinner" [mat-dialog-close] id="cancelButton" mat-button>Cancel</button>

  <button (click)="createNewFolder()" *ngIf="!saveSpinner" color="primary" id="createButton" mat-button>Create</button>
  <button *ngIf="saveSpinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>
