import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditDialogComponent} from './edit-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {OverlayscrollbarsModule} from 'overlayscrollbars-ngx';
import {MatInputModule} from '@angular/material/input';
import {IconModule} from '../../mx/icon/icon.module';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from "@angular/material/tabs";
import {MatTableModule} from "@angular/material/table";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    OverlayscrollbarsModule,
    MatInputModule,
    IconModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
  ],
  declarations: [
    EditDialogComponent
  ],
  exports: [
    EditDialogComponent
  ]
})

export class EditDialogModule {
}
