export class Project {

  constructor(
    public id?: string,
    public name?: string,
    public description?: string,
    public publicProject?: boolean,
    public hasLicense?: boolean,
    public maxRunDepth?: number,
    public maxSerialRuns?: number,
    public maxParallelRuns?: number,
    public maxPrivateParallelRuns?: number,
    public maxCommonParallelRuns?: number,
    public caseTimeToLive?: number,
    public userOwnerEmail?: string,
    public userOwnerDisplayName?: string
  ) {
  }

}
