import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavTreeFileIconComponent} from './nav-tree-file-icon.component';
import {IconModule} from '../../../mx/icon/icon.module';


@NgModule({
  declarations: [NavTreeFileIconComponent],
  exports: [
    NavTreeFileIconComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ]
})
export class NavTreeFileIconModule {
}
