export class CaseFileLocation {
  constructor(public id: string = null,
              public projectParamPart: string = null,
              public buildAutomationToolPart: string = null,
              public packageCasePart: string = null,
              public packageCasePartWithoutExtension = null,
              public fileNameWithExtension = null,
              public fileName = null,
              public extension = null,
              public fullPathWithoutExtension = null,
              public fullPath: string = null
  ) {
  }
}
