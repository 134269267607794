import {Directive, Input, OnInit} from '@angular/core';
import {CheckRolesService} from '../../../shared/services/check-roles.service';

@Directive()
export abstract class MxOptionAbstract<T> implements OnInit {

  checkPaths: any;
  checkRolesService: CheckRolesService;

  protected constructor(checkRolesService: CheckRolesService) {
    this.checkRolesService = checkRolesService;
  }

  @Input('checkPaths')
  public set setCheckPaths(checkPaths: T) {
    if (this.checkPaths == undefined) {
      this.checkPaths = checkPaths;
    }
  }

  ngOnInit(): void {
    this.refreshCheckRolePaths();
  }

  public refreshCheckRolePaths() {
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      response => {

        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(response, this.checkPaths);

      },
      error => {
        console.error(error);
      }
    );
  }


}
