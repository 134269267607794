<mat-card *ngIf="isLoading" [ngClass]="{
  'fill-screen': fullScreen,
  'spinner-loading': true
}"
          style="  box-shadow: 0 0px 0px 0px rgba(0,0,0,0), 0 0px 0px 0 rgba(0,0,0,0), 0 0px 0px 0 rgba(0,0,0,0);">
  <mat-progress-spinner
    style="z-index: 1010 !important;"
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>
