import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploadDialogComponent} from './upload-dialog.component';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ErrorModule} from '../../../mx/error/error.module';
import {IconModule} from '../../../mx/icon/icon.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DndModule} from '../../../mx/dnd/dnd.module';
import {NavTreeFileIconModule} from '../nav-tree-file-icon/nav-tree-file-icon.module';
import {OverlayscrollbarsModule} from 'overlayscrollbars-ngx';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    AngularFireStorageModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatProgressBarModule,
    ErrorModule,
    IconModule,
    MatCheckboxModule,
    DndModule,
    NavTreeFileIconModule,
    OverlayscrollbarsModule,
    MatTooltipModule
  ],
  declarations: [
    UploadDialogComponent
  ]
})

export class UploadDialogModule {
}
