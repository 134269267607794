import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityGroupService {

  constructor(
    private apiService: ApiService,
    private userProjectConfigService: UserProjectConfigService
  ) {
  }

  getAll(params?: any) {
    return this.apiService.get(this.userProjectConfigService.getSecurityGroups(), params);
  }

}
