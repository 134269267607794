import {SearchParam} from './searchParam';

export class PageParams {
  constructor(
    public page: SearchParam,
    public size: SearchParam,
    public sort: SearchParam
  ) {
  }
}
