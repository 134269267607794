import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageMxViewerComponent} from './image-mx-viewer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [ImageMxViewerComponent],
  exports: [
    ImageMxViewerComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class ImageMxViewerModule {
}
