import {MaxtafFile} from './maxtafFile';
import {CompileTabDetails} from './compileTabDetails';
import {Case} from '../../../cases/shared/models/case';

export class MaxtafFileDetails {

  constructor(public details: MaxtafFile,
              public isSaved: boolean,
              public compileDetails: CompileTabDetails,
              public projectId: string,
              public activeCode?: string,
              public canBeReloaded?: boolean,
              public allowEdit: boolean = true,
              public childTabs: Case[] = [],
              public runId: number = undefined,
              public selectLine: number = undefined
  ) {
  }

  isAllowCompile(): boolean {
    if (this.details == undefined) {
      return false;
    }

    if (
      this.details.typeOfFile == 'java'
      || (this.isCase()
        && this.details.testCase.caseType != undefined
        && (this.details.testCase.caseType.toLowerCase() == 'mxml'
          || this.details.testCase.caseType.toLowerCase() == 'java'
          || this.details.testCase.caseType.toLowerCase() == 'utam')
      )
    ) {
      return true;
    } else {
      return false;
    }

  }

  isCase(): boolean {
    if (this.details == undefined) {
      return false;
    }

    if (this.details.testCase != undefined && this.details.testCase.id != null) {
      return true;
    } else {
      return false;
    }
  }
}
