export class LspDefinitionResult {
  constructor() {
    this.uri = null;
    this.range = {
      start: {
        line: null,
        character: null
      },
      end: {
        line: null,
        character: null
      }
    }
  }
  uri: string;
  range: {
    start: {
      line: number,
      character: number
    },
    end: {
      line: number,
      character: number
    }
  }
}
