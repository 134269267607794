import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Project} from '../../../projects/shared/models/project';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {UserService} from '../../shared/services/user.service';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {FormControl} from '@angular/forms';
import {first} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {
  CreateDialogProjectComponent
} from '../../../projects/components/create-project/create-dialog-project.component';
import {PageParams} from '../../../mx/util/params/PageParams';
import {ProjectConstService} from '../../../projects/shared/services/project-const.service';
import {ProjectsListComponent} from '../../../projects/components/projects-list/projects-list.component';
import {AngularFireAuth} from '@angular/fire/auth';
import {ProjectOptionsPaths} from '../../../projects/components/project-options/project-options.component';
import {CheckPath} from '../../../mx/options/CheckPath';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';
import {CreatedProject} from '../../../projects/shared/models/created-project';
import {ClickedTableItem} from '../../../mx/util/list/ClickedTableItem';

@Component({
  selector: 'app-select-create-project',
  templateUrl: './select-create-project.component.html',
  styleUrls: ['./select-create-project.component.css']
})
export class SelectCreateProjectComponent implements OnInit {

  showErrorAlert = false;
  error;

  isLoading = true;
  myControl = new FormControl();
  filteredOptions: Observable<Project[]>;
  activeProjectId = '';
  onlyReturnSelectedProject = false;
  isFirebaseAnonymousUser = false;
  params;
  @ViewChild(ProjectsListComponent) projectsList: ProjectsListComponent;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<SelectCreateProjectComponent>,
    public tokensStoriage: MaxtafTokensStorageService,
    private userProjectConfigService: UserProjectConfigService,
    private userService: UserService,
    private projectService: ProjectService,
    private auth: AngularFireAuth,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { activeProjectId: '', onlyReturnSelectedProject: boolean }) {
    this.activeProjectId = data.activeProjectId == undefined ? '' : data.activeProjectId;
    this.onlyReturnSelectedProject = data.onlyReturnSelectedProject == undefined ? false : data.onlyReturnSelectedProject;
    this.auth.user.pipe(first()).subscribe(
      user => {
        this.isFirebaseAnonymousUser = user.isAnonymous;
        this.params.search.includePublic.value = this.isFirebaseAnonymousUser;
        this.params.search.includePublic.defaultValue = this.isFirebaseAnonymousUser;
        this.params.search = {...this.params.search};
        this.filter(this.params.search);
      }
    );
    this.params = this.createParamsCategory();
  }

  onCloseClick(project: Project): void {
    this.dialogRef.close(project);
  }

  ngOnInit(): void {

  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  openCreateDialog(type: string, project: Project, error) {

    const dialogRef = this.dialog.open(CreateDialogProjectComponent, {
      width: '500px',
      data: {
        type,
        project,
        error
      }
    });

    dialogRef.afterClosed().subscribe((result: CreatedProject) => {
      if (result) {
        this.clickedProject(new ClickedTableItem<Project>(result.userProject.project, undefined));
      }
    });
  }

  clickedProject(clickedItem: ClickedTableItem<Project>) {
    if (this.onlyReturnSelectedProject) {
      this.onCloseClick(clickedItem.item);
    } else {
      this.tokensStoriage.setProjectId('' + clickedItem.item.id);
      this.userService.getMyInfo().subscribe(res => {
        this.tokensStoriage.setUserProjectID('' + res.id);
        this.router.navigate(['home']);
        this.onCloseClick(clickedItem.item);
      });
    }
  }

  refreshProjects(): void {
    if (this.projectsList != undefined) {
      this.projectsList.refreshItems();
    }
  }

  filter(searchParams?): void {
    if (searchParams != undefined) {
      this.params.search = searchParams;
      this.params.page.page.value = this.params.page.page.defaultValue;
    }
    this.refreshProjects();
  }


  createParamsCategory() {
    return {
      search: this.createSearchParams(),
      page: this.createPageParams(),
    };
  }


  createPageParams(): PageParams {
    return ProjectConstService.getDefaultPageParams();
  }

  createSearchParams(): any {
    return ProjectConstService.getDefaultSearchParams();
  }

  pageParamsChange(pageParams: PageParams): void {
    this.params.page = pageParams;
  }

  getProjectOptionsCheckPathObject(): ProjectOptionsPaths {
    return {
      filter: new CheckPath('GET', this.userProjectConfigService.getProjects()),
      create: new CheckPath('POST', this.userProjectConfigService.createProject()),
      refresh: new CheckPath('GET', this.userProjectConfigService.getProjects())
    };
  }
}
