import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {ProjectsListComponent} from './projects-list.component';
import {SpinnerForTableModule} from '../../../../shared/util-components/spinner-for-table/spinner-for-table.module';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {ErrorModule} from '../../../mx/error/error.module';
import {IconModule} from '../../../mx/icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    SpinnerForTableModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatInputModule,
    ErrorModule,
    IconModule
  ],
  exports: [
    ProjectsListComponent
  ],
  declarations: [
    ProjectsListComponent
  ]
})

export class ProjectsListModule {
}
