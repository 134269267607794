import {ApiService} from '../../../../shared/services/api.service';
import {Injectable} from '@angular/core';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {Params} from '../../../mx/util/params/params';

@Injectable()
export class CompileService {

  constructor(
    private apiService: ApiService,
    private cloudConfigService: CloudConfigService
  ) {
  }

  compileFile(fileLocation: string) {
    return this.apiService.post(this.cloudConfigService.compileFile(fileLocation), {});
  }

  isCompiledFile(fileLocation: string) {
    return this.apiService.get(this.cloudConfigService.isCompiledFile(fileLocation), {});
  }

  compileCase(id: string) {
    return this.apiService.post(this.cloudConfigService.compileCase(id), {});
  }

  isCompiled(id: string) {
    return this.apiService.get(this.cloudConfigService.isCompiled(id));
  }

  isTranspiled(id: string) {
    return this.apiService.get(this.cloudConfigService.isTranspiled(id));
  }

  compileCasesInfo(id: string) {
    return this.apiService.get(this.cloudConfigService.compileCasesInfo(id));
  }

  deleteCasesInfo(id: string) {
    return this.apiService.delete(this.cloudConfigService.compileCasesInfo(id));
  }

  // deleteCasesInfos(searchParams?: any, pageParams?: any) {
  //   searchParams = new Params(searchParams);
  //   pageParams = new Params(pageParams);
  //   const params = {...searchParams, ...pageParams};
  //
  //   return this.apiService.delete(this.cloudConfigService.compileCasesInfos(), params);
  // }

  deleteCasesInfos(ids, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    let queryParams = '';

    for (const id of ids) {
      queryParams = queryParams + '&ids%5B%5D=' + id + '';
    }

    return this.apiService.delete(this.cloudConfigService.compileCasesInfos() + '?a=b' + queryParams, params);
  }

  compileCasesInfos(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.compileCasesInfos(), params);
  }

  compileInfoCasesInfos(id: string, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getCaseCompileInfos(id), params);
  }

  compileCases(ids: string[], searchParams, compileType) {
    let queryParams = '';

    for (const id of ids) {
      queryParams = queryParams + '&ids%5B%5D=' + id + '';
    }
    if (compileType == 2 && searchParams != undefined) {
      searchParams = new Params(searchParams);

      const params = {...searchParams};
      return this.apiService.post(this.cloudConfigService.compileCases(''), params);
    } else {
      return this.apiService.post(this.cloudConfigService.compileCases(queryParams), {});
    }
  }

  compileCasesAsync(ids: string[], searchParams, compileType) {
    let queryParams = '';

    for (const id of ids) {
      queryParams = queryParams + '&ids%5B%5D=' + id + '';
    }
    if (compileType == 2 && searchParams != undefined) {
      searchParams = new Params(searchParams);

      const params = {...searchParams};
      return this.apiService.post(this.cloudConfigService.compileCasesAsync(''), params);
    } else {
      return this.apiService.post(this.cloudConfigService.compileCasesAsync(queryParams), {});
    }
  }
}
