import {Case} from '../../../cases/shared/models/case';

export class MaxtafFile {
  name = '';
  content = '';

  path = '';
  pathWithName = '';
  typeOfFile = '';

  size = '';
  sizeOnDisk = '';

  created = '';
  modified = '';
  accessed = '';

  allowEdit = true;
  isFile = true;

  testCase: Case = undefined;
}
