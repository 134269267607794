import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Project} from '../../shared/models/project';
import {ProjectService} from '../../shared/services/project.service';
import {MatPaginator} from '@angular/material/paginator';
import {MxTable} from '../../../mx/util/list/MxTable';
import {PageParams} from '../../../mx/util/params/PageParams';
import {ProjectConstService, ProjectSearchParams} from '../../shared/services/project-const.service';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.css']
})
export class ProjectsListComponent extends MxTable<Project> implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input('idMaxSize') idMaxSize = 20;
  @Input('nameMaxSize') nameMaxSize = 30;

  @Input('activeProjectId') activeProjectId = '';
  @Input('hidePaginator') hidePaginator = false;

  @Input('displayedColumns') displayedColumns: string[] = ['active', 'name', 'id', 'owner', 'description'];
  idName = 'id';

  pageParams: PageParams;
  searchParams: ProjectSearchParams;

  constructor(
    private projectService: ProjectService,
  ) {
    super();
    this.pageParams = ProjectConstService.getDefaultPageParams();
  }


  ngOnInit(): void {
    super.ngOnInit();
  }

  getItems(): void {
    this.startLoadingSpinner();
    this.setItems([]);
    this.projectService.getAll(this.searchParams, this.pageParams).subscribe(
      (res) => {
        this.stopLoadingSpinner();
        if (res) {
          this.setItems(res.content);
          this.setPageInfo(res);
        } else {
          this.setItems([]);
        }
      },
      error => {
        this.showError(error);
        this.stopLoadingSpinner();
      }
    );
  }

  // setItems(newItems) {
  //   this.items = new MatTableDataSource<Project>(newItems);
  //   this.items.paginator = this.paginator;
  // }
  //
  // applyFilter(filterValue: string) {
  //   this.items.filter = filterValue.trim().toLowerCase();
  // }
  setDefaultValue(element) {

  }
}
