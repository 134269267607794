export class ChatSession {
  constructor(public id: string = '',
              public isActive: boolean = false,
              public userProjectId: string = '',
              public title: string = '',
              public description: string = '',
              public lastUsedDate: Date = null,
              public sessionPurpose: string = null,
              public sessionPurposeRelatedId: string = null) {
  }
}
