<h5 class="ml-4" mat-dialog-title>{{header}}</h5>

<div mat-dialog-content>

  <app-error-message *ngIf="showErrorAlert" [error]="error"></app-error-message>

  <label>File name</label>
  <div class="pb-3" style="width: 80%; margin: 0 auto;">
    <input [(ngModel)]="name" type="text">
  </div>

  <label *ngIf="!hidePath">File path</label>
  <div *ngIf="!hidePath" class="pb-3" style="width: 80%; margin: 0 auto;">
    <input [(ngModel)]="path" type="text">
  </div>
  <div class="pb-3" style="width: 80%; margin: 0 auto;">
    <mat-checkbox (change)="overwrite = !overwrite" [checked]="overwrite" [disabled]="spinner" id="overwriteCheck"
                  type="checkbox">Overwrite file if already exists.
    </mat-checkbox>
  </div>
</div>

<div mat-dialog-actions style="margin-right: 24px">
  <button [mat-dialog-close] id="cancelButton" mat-button>Cancel</button>
  <button (click)="create()" *ngIf="!spinner" color="primary" id="createButton" mat-button>Create</button>
  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>
