<h4 mat-dialog-title
    style="font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;">
  Compile logs
</h4>

<div mat-dialog-content>
  <app-compile-info-options
    (delete)="deleteCompileInfo();"
    (filter)="filter($event)"

    (refresh)="refreshCompileInfo();"
    [(searchParams)]="params.search"
    [checkPaths]="getCompileInfoListOptionsCheckPathObject()">

  </app-compile-info-options>

  <app-compile-info-list #list
                         (clickedItem)="clickedCompileInfo($event)"
                         (pageParamsChange)="pageParamsChange($event)"

                         (pagesInfoChanges)="setPageInfoChanges($event)"
                         (selected)="selected($event)"
                         [autoSetItems]="true"
                         [pageParams]="params.page"

                         [searchParams]="params.search"
                         class="mx-compile-list">

  </app-compile-info-list>
</div>
<div class="d-flex float-right px-4 py-2 mb-0 mt-0" mat-dialog-actions style="margin-bottom: 0px !important;">
  <button [mat-dialog-close]="false" id="cancelButton" mat-button style="float:right">Close</button>
</div>
