import {Injectable} from '@angular/core';

@Injectable()
export class ConfigService {

  private default_path_var = '{\\\\d+}';
  private host_url = '';
  private prefix = '/api';
  private header_project_id = 'X-Project-Id';
  private no_vnc_client_url = this.getNoVncClientHost() + '/noVncClient';
  private api_url = this.host_url + this.prefix + '/v0.8';
  private auth_url = this.api_url + '/auth';
  private change_password_url = this.auth_url + '/changePassword';
  private reset_credentials_url = this.auth_url + '/user/reset-credentials';
  private sign_up_url = this.host_url + this.prefix + '/sign-up';
  private check_paths_url = this.host_url + this.prefix + '/check/rolesForPaths';
  private tos_url = this.host_url + '/tos';
  private lsp_xml = this.getHostNameForLsp() + '/xmlLanguageServer';
  private lsp_java = this.getHostNameForLsp() + '/javaLanguageServer';
  private lsp_python = this.getHostNameForLsp() + '/pythonLanguageServer';
  private lsp_js = this.getHostNameForLsp() + '/jsLanguageServer';
  private lsp_json = this.getHostNameForLsp() + '/jsonLanguageServer';
  private terminal_server = this.getHostNameForTerminal();
  private convergence_server = 'https://convergence.maxtaf.com/convergence/default';

  public getDefaultPathVar() {
    return this.default_path_var;
  }

  public getHostURL() {
    return this.host_url;
  }

  public getPrefix() {
    return this.prefix;
  }

  public getHeaderProjectId() {
    return this.header_project_id;
  }

  public getNoVncClientUrl() {
    return this.no_vnc_client_url;
  }

  public getApiUrl() {
    return this.api_url;
  }

  public getAuthUrl() {
    return this.auth_url;
  }

  public getChangePasswordUrl() {
    return this.change_password_url;
  }

  public getResetCredentialsUrl() {
    return this.reset_credentials_url;
  }

  public getSignUpUrl() {
    return this.sign_up_url;
  }

  public getCheckPathsUrl() {
    return this.check_paths_url;
  }

  public getTosUrl() {
    return this.tos_url;
  }

  public getLspXml() {
    return this.lsp_xml;
  }

  public getLspJava() {
    return this.lsp_java;
  }

  public getLspPython() {
    return this.lsp_python;
  }

  public getLspJs() {
    return this.lsp_js;
  }

  public getLspJson() {
    return this.lsp_json;
  }

  public getTerminalServer() {
    return this.terminal_server;
  }

  public getConvergenceServer() {
    return this.convergence_server;
  }

  public getHost() {
    return window.location.protocol + '//' + window.location.hostname + (window.location.hostname == 'localhost' ? ':4200' : '');
  }

  public getHostNameForLsp() {
    return window.location.hostname == 'localhost' ? 'ws://localhost:4201' : 'wss://' + window.location.hostname + ':' + window.location.port;
  }

  public getHostNameForTerminal() {
    return window.location.hostname == 'localhost' ? 'http://localhost:4201' : 'https://' + window.location.hostname + ':' + window.location.port;
  }

  public getNoVncClientHost() {
    if (window.location.hostname == 'localhost')
      return 'localhost:4201';
    else
      return window.location.hostname;
  }

}
