import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {Params} from '../../../mx/util/params/params';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';

@Injectable({
  providedIn: 'root'
})
export class CaseCodeHistoryService {

  constructor(
    private apiService: ApiService,
    private cloudConfigService: CloudConfigService
  ) {
  }

  getAll(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getCaseCodeHistory(), params);
  }

  revertCode(caseId: string, caseCodeHistoryId: string) {
    return this.apiService.put(this.cloudConfigService.revertCaseCode(caseId, caseCodeHistoryId), '');
  }

}
