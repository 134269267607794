<div class="filter-by">
  <button (click)="runSearch(inputName.value)"
          [disabled]="disabled"
          class="prefix-input"
          mat-icon-button>
    <mx-icon [color]="disabled ? 'disabled': 'primary'" [ngClass]="{'icon-suffix': true, 'cursor-pointer': !disabled}"
             class="" mx="filter"></mx-icon>
  </button>

  <div class="input-name">
    <input
      #inputName
      (keyup)="isSearchCollapsed ? openCloseSearchPanel():null"
      (keyup.enter)="runSearch(inputName.value)"
      (keyup.space)="addSpace($event)"
      [(ngModel)]="filterValue"
      [cdkTrapFocusAutoCapture]="focus" [cdkTrapFocus]="focus"
      [disabled]="disabled"
      placeholder="{{placeholderText}}"
      type="text"
    >
  </div>

  <div *ngIf="!hideHelpButtons" style="position: relative">
    <button (click)="clearText();runSearch('')" [disabled]="disabled" [style.visibility]="inputName.value == '' ? 'hidden' : 'visible'" class="close-input"
            mat-icon-button>
      <mx-icon [color]="disabled ? 'disabled': 'primary'" [ngClass]="{'icon-suffix': true, 'cursor-pointer': !disabled}"
               mx="close"></mx-icon>
    </button>

    <button (click)="openCloseSearchPanel()" [disabled]="disabled" class="suffix-input" color="primary" mat-icon-button
            matSuffix>
      <mx-icon *ngIf="!isSearchCollapsed" [color]="disabled ? 'disabled': 'primary'"
               [ngClass]="{'icon-suffix': true, 'cursor-pointer': !disabled}"
               mx="open-arrow"></mx-icon>
      <mx-icon *ngIf="isSearchCollapsed" [color]="disabled ? 'disabled': 'primary'"
               [ngClass]="{'icon-suffix': true, 'cursor-pointer': !disabled}"
               mx="close-arrow"></mx-icon>
    </button>
  </div>
</div>

