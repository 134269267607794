<h1 mat-dialog-title xmlns="http://www.w3.org/1999/html">Edit chat</h1>


<label> Chat title</label>
<div class="mb-3 input-box">
  <input [(ngModel)]="session.title" type="text">
  <!--  <mx-icon mx="information" color="primary" class="ml-1 cursor-pointer"></mx-icon>-->
</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" id="closeButton" mat-button>Cancel</button>
  <button (click)="updateAiSessionTitle()" color="primary" mat-button>Update</button>
</div>

