import {Component, Inject, OnInit} from '@angular/core';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {FileService} from '../../../../shared/services/file.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {saveAs} from 'file-saver';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';

@Component({
  selector: 'app-export-project-params',
  templateUrl: './export-project-params.component.html',
  styleUrls: ['./export-project-params.component.css']
})
export class ExportProjectParamsComponent implements OnInit {


  className = 'ExportProjectParamsComponent';

  heading = 'Export Project Params';

  active = true;

  paramsFilter;

  selected: string[] = [];

  url: string;

  exportType = 'JSON';

  useFilter = false;

  error;
  exportError = false;
  exportSuccess = false;

  // Workaround for angular component issue #13870
  disableAnimation = true;

  constructor(
    public tokensStoriage: MaxtafTokensStorageService,
    private fileService: FileService,
    private cloudConfigService: CloudConfigService,
    public dialogRef: MatDialogRef<ExportProjectParamsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: string,
      heading: string,
      active: boolean,
      paramsFilter,
      selected: string[]
    }
  ) {
    dialogRef.disableClose = true;

    if (this.data.paramsFilter != undefined) {
      this.paramsFilter = this.data.paramsFilter;
    }

    if (this.data.selected != undefined) {
      this.selected = this.data.selected;
    }

  }

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.exportShortcutListener, false);
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for exporting parameters
  exportShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('exportButton').click();
    }
  };

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.exportShortcutListener, false);
  }

  export() {
    this.exportSuccess = false;
    this.exportError = false;

    this.setUrl();

    if (this.paramsFilter) {
      delete this.paramsFilter.page;
      delete this.paramsFilter.size;
      delete this.paramsFilter.sort;
    }

    // if (this.useFilter) {
    if (this.paramsFilter) {
      for (const key in this.paramsFilter) {
        if (this.paramsFilter[key] != undefined) {
          this.url += '&' + key + '=' + encodeURIComponent(this.paramsFilter[key]);
        }
      }
    }
    // }

    this.fileService.exportFile(this.url).subscribe(
      res => {
        this.exportSuccess = true;

        const contentDisposition = res.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();

        const blob = new Blob([res.body], {type: 'application/text'});
        saveAs(blob, fileName);
        this.closeDialog();
      },
      error => {
        console.error(error);
        this.showError(error);
      }
    );
  }

  setUrl() {
    this.url = this.cloudConfigService.exportProjectParams() + '?mx=true';

    let queryParams = '&';

    for (const id of this.selected) {
      queryParams = queryParams + 'ids%5B%5D=' + id + '&';
    }

    this.url += queryParams.substring(0, queryParams.length - 1);
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  showError(error) {
    this.error = error;
    this.exportError = true;
  }

}
