import {Component, OnInit} from '@angular/core';
import {MxFilter} from "../../../mx/filter/MxFilter";

@Component({
  selector: 'app-chat-sessions-filter',
  templateUrl: './chat-sessions-filter.component.html',
  styleUrls: ['./chat-sessions-filter.component.css']
})
export class ChatSessionsFilterComponent extends MxFilter implements OnInit {

  search: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  searchDefaultValues() {
    return {
      title: '',
      hideCaseRelated: false,
      exactSearch: 'Contains',
    };
  }

}

