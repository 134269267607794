import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExportProjectParamsComponent} from './export-project-params.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorModule} from '../../../mx/error/error.module';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [ExportProjectParamsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ErrorModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDialogModule,
  ],
  exports: [
    ExportProjectParamsComponent
  ]
})
export class ExportProjectParamsModule {

}
