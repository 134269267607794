import {Injectable} from '@angular/core';
import {PageParams} from '../../../mx/util/params/PageParams';
import {SearchParam} from '../../../mx/util/params/searchParam';

@Injectable({
  providedIn: 'root'
})
export class ProjectConstService {

  constructor() {

  }

  public static getDefaultSearchParams(isFirebaseAnonymousUser: boolean = false): ProjectSearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      userId: new SearchParam(null, null),
      projectId: new SearchParam(null, null),
      name: new SearchParam(null, null),
      description: new SearchParam(null, null),
      includePublic: new SearchParam(isFirebaseAnonymousUser, isFirebaseAnonymousUser),
      ownerUsername: new SearchParam(null, null)
    };
  }

  public static getDefaultPageParams(size = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('name,asc', 'name,asc')
    };
  }
}

export class ProjectSearchParams {
  public exactSearch: SearchParam;
  public userId: SearchParam;
  public projectId: SearchParam;
  public name: SearchParam;
  public description: SearchParam;
  public includePublic: SearchParam;
  public ownerUsername: SearchParam;
}
