import {Component, Input, OnInit} from '@angular/core';
import {GlobalEventsManagerService} from '../../../shared/services/global-events-manager.service';
import {interval, Subscription} from 'rxjs';
import {UserService} from '../../users/shared/services/user.service';
import {ProjectSensors} from '../../users/shared/models/projectSensors';
import {Router} from '@angular/router';
import {ProjectService} from '../../projects/shared/services/project.service';
import {MaxtafTokensStorageService} from '../../../shared/services/maxtaf-tokens-storage.service';

@Component({
  selector: 'mx-notice-board',
  templateUrl: './notice-board.component.html',
  styleUrls: ['./notice-board.component.css']
})
export class NoticeBoardComponent implements OnInit {

  lspServerIndicator = 'inactive';
  engineIndicator = 'REMOVED';
  bridgeIndicator = false;
  maxParallelRunsCount = 0;
  currentParallelRunsCount = 0;

  maxAiRequests = 0;
  currentAiRequests = 0;
  aiKey = '';

  @Input('refreshIntervalInMs') refreshInterval = 1000; // 1 sec
  refreshIntervalSub: Subscription;

  constructor(
    private globalEventsManagerService: GlobalEventsManagerService,
    private userService: UserService,
    public projectService: ProjectService,
    public tokensStorage: MaxtafTokensStorageService,
    public tokensService: MaxtafTokensStorageService,
    public router: Router) {
    this.startLiveRefresh();
  }

  ngOnInit(): void {
    this.globalEventsManagerService.lspServerIndicatorEmitter.subscribe((mode) => {
      this.lspServerIndicator = mode;
    });

    this.globalEventsManagerService.engineIndicatorEmitter.subscribe((mode) => {
      this.engineIndicator = mode;
    });

    this.globalEventsManagerService.bridgeIndicatorEmitter.subscribe((mode) => {
      this.bridgeIndicator = mode;
    });
  }

  ngOnDestroy() {
    this.stopLiveRefresh();
  }

  stopLiveRefresh() {
    if (this.refreshIntervalSub) {
      this.refreshIntervalSub.unsubscribe();
    }
  }

  startLiveRefresh() {
    this.refreshIntervalSub = interval(this.refreshInterval)
      .subscribe(res => {
        if (document.hidden) {
          return;
        }

        this.loadSensors();
      });
  }

  loadSensors() {
    this.userService.getSensors().subscribe(
      (sensors: ProjectSensors) => {

        this.bridgeIndicator = sensors.bridgeStatus;
        this.globalEventsManagerService.setBridge(this.bridgeIndicator);
        this.engineIndicator = sensors.engineStatus.toString();
        this.globalEventsManagerService.setEngine(sensors.engineStatus);

        this.maxParallelRunsCount = sensors.maxParallelRunsCount;
        this.currentParallelRunsCount = sensors.currentParallelRunsCount;

        this.maxAiRequests = sensors.maxAiRequests;
        this.globalEventsManagerService.setMaxAIRequests(sensors.maxAiRequests);
        this.currentAiRequests = sensors.currentAiRequests;
        this.globalEventsManagerService.setCurrentAIRequests(sensors.currentAiRequests);
        this.aiKey = sensors.aiKey;
        this.globalEventsManagerService.setAIKey(sensors.aiKey);
      },
      error => {
        console.error('error: ', error);
      }
    );
  }

  openActiveRuns() {
    this.router.navigate(['/runs']);
  }

  restartEngine() {
    this.projectService.restartEngine(this.tokensService.getProjectId()).subscribe(
      () => {
        this.projectService.getEngineStatus(this.tokensService.getProjectId()).subscribe(
          engineValue => {
          },
          error => {
            console.error('error: ', error);
          }
        );
      },
      error => {
        console.error('error: ', error);
      }
    );
  }

  restartLsp() {
    this.projectService.restartLspEngine(this.tokensService.getProjectId(), 'JAVA').subscribe(
      () => {
        this.projectService.getEngineStatus(this.tokensService.getProjectId()).subscribe(
          engineValue => {

          },
          error => {
            console.error('error: ', error);

          }
        );
      },
      error => {
        console.error('error: ', error);

      }
    );
  }
}
