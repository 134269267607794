import {Injectable} from '@angular/core';
import {PageParams} from '../../../mx/util/params/PageParams';
import {SearchParam} from '../../../mx/util/params/searchParam';
import {RunConstService, RunSearchParams} from '../../../runs/shared/services/run-const.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleConstService {

  constructor() {
  }

  public static getDefaultPageParams(size: number = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('name,desc', 'name,desc')
    };
  }

  public static getDefaultSearchParams(): ScheduleSearchParams {
    return {
      caseOrSuiteId: new SearchParam(null, null),
      showHidden: new SearchParam(false, false),
      name: new SearchParam(null, null),
      exactSearch: new SearchParam('Contains', 'Contains'),
    };
  }

  public static getDefaultSchedulePageParams(): ScheduleParams {
    return {
      schedule: this.initScheduleComponentParams(),

      runPage: RunConstService.getDefaultPageParams(),
      runSearch: RunConstService.getDefaultSearchParams(),

      scheduleIndex: new SearchParam(null, null),
      schedulePage: this.getDefaultPageParams(1),
      scheduleSearch: this.getDefaultSearchParams(),
    };
  }

  private static initScheduleComponentParams(): ScheduleComponentParams {
    return {
      tabIndex: new SearchParam(0, 0),
    };
  }
}

export class ScheduleSearchParams {
  public caseOrSuiteId: SearchParam;
  public showHidden: SearchParam;
  public name: SearchParam;
  public exactSearch: SearchParam;
}

export class ScheduleComponentParams {
  public tabIndex: SearchParam;
}

export class ScheduleParams {
  public schedule: ScheduleComponentParams;

  public runPage: PageParams;
  public runSearch: RunSearchParams;

  public scheduleIndex: SearchParam;
  public schedulePage: PageParams;
  public scheduleSearch: ScheduleSearchParams;
}

