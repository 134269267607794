import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectMainComponent} from './project-main.component';
import {MatCardModule} from '@angular/material/card';
import {LinkModule} from '../../../mx/link/link.module';
import {ErrorModule} from '../../../mx/error/error.module';
import {
  SecureConfirmationDialogModule
} from '../../../dialogs/secure-confirmation-dialog/secure-confirmation-dialog.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {IconModule} from '../../../mx/icon/icon.module';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ExportProjectDialogModule} from '../../dialogs/export-project-dialog/export-project-dialog.module';
import {GlobalImportDialogModule} from '../../dialogs/global-import-dialog/global-import-dialog.module';
import {ProjectMainTabOptionsModule} from '../project-main-tab-options/project-main-tab-options.module';
import {ExportLogDialogModule} from '../../dialogs/export-log-dialog/export-log-dialog.module';
import {EditDialogModule} from '../../../dialogs/edit-dialog/edit-dialog.module';

@NgModule({
  declarations: [ProjectMainComponent],
  exports: [
    ProjectMainComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    LinkModule,
    ErrorModule,
    SecureConfirmationDialogModule,
    MatCheckboxModule,
    IconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ExportProjectDialogModule,
    GlobalImportDialogModule,
    ProjectMainTabOptionsModule,
    ExportLogDialogModule,
    EditDialogModule
  ]
})
export class ProjectMainModule {
}
