<mat-expansion-panel
  #reportPanel
  *ngIf="!ai" class="logPanel border-top-thin-lightblue mb-3"
  hideToggle>
  <mat-expansion-panel-header style="height: 32px;">
    <mat-panel-title>
      <mx-icon *ngIf="!reportPanel.expanded" class="mr-3" color="primary" mx="collapse"></mx-icon>
      <mx-icon *ngIf="reportPanel.expanded" class="mr-3" color="primary" mx="expand"></mx-icon>

      <span style="color: #1e88e5;">...</span>
    </mat-panel-title>
    <mat-panel-description>

    </mat-panel-description>
  </mat-expansion-panel-header>

  <section class="main-container ai-title-wrapper">

    <div class="top-buttons">
      <button (click)="diffClick()"
              [matTooltip]="'Show Diff'"
              class="top-button"
              mat-button>
        <mx-icon mx="diff"></mx-icon>
        <span>Diff</span>
      </button>
      <button (click)="insert()"
              [matTooltip]="'Insert'"
              class="top-button"
              mat-button>
        <mx-icon mx="insert"></mx-icon>
        <span>Insert</span>
      </button>
      <button (click)="copyCode()"
              [matTooltip]="'Copy to clipboard'"
              class="top-button"
              mat-button>
        <mx-icon mx="copy"></mx-icon>
        <span>Copy</span>
      </button>


    </div>

    <div>
      <!--    <overlay-scrollbars-->
      <!--      #pageScroll-->
      <!--      [options]="{ scrollbars: { autoHide: 'l' } }"-->
      <!--    >-->
      <markdown
        [data]="message"
        class="markdown-style"
        lineNumbers
      >
      </markdown>
      <div style="height: 1rem; width: 100%"></div>
      <!--    </overlay-scrollbars>-->

    </div>
  </section>


</mat-expansion-panel>

<section *ngIf="ai" class="main-container ai-title-wrapper">

  <div class="top-buttons">
    <button (click)="diffClick()"
            [matTooltip]="'Show Diff'"
            class="top-button"
            mat-button>
      <mx-icon mx="diff"></mx-icon>
      <span>Diff</span>
    </button>
    <button (click)="insert()"
            [matTooltip]="'Insert'"
            class="top-button"
            mat-button>
      <mx-icon mx="insert"></mx-icon>
      <span>Insert</span>
    </button>
    <button (click)="copyCode()"
            [matTooltip]="'Copy to clipboard'"
            class="top-button"
            mat-button>
      <mx-icon mx="copy"></mx-icon>
      <span>Copy</span>
    </button>


  </div>

  <div>
    <!--    <overlay-scrollbars-->
    <!--      #pageScroll-->
    <!--      [options]="{ scrollbars: { autoHide: 'l' } }"-->
    <!--    >-->
    <markdown
      [data]="message"
      class="markdown-style"
      lineNumbers
    >
    </markdown>
    <div style="height: 1rem; width: 100%"></div>
    <!--    </overlay-scrollbars>-->

  </div>
</section>
