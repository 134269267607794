import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../../users/shared/services/user.service';
import {MatSidenav} from '@angular/material/sidenav';
import {GlobalEventsManagerService} from '../../../../shared/services/global-events-manager.service';
import {UserProject} from '../../../users/shared/models/userProject';
import {AuthMaxtafService} from '../../../../shared/services/auth-maxtaf.service';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {MatDialog} from '@angular/material/dialog';
import {UserDialogComponent} from '../../../users/dialogs/user-dialog/user-dialog.component';
import {MatExpansionPanel} from '@angular/material/expansion';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-ngx';
import {
  SelectCreateProjectComponent
} from '../../../users/dialogs/select-create-project/select-create-project.component';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';
import {
  LocalSaveWorkspaceTabsSessionStorageService
} from '../../../storage/shared/services/local-save-workspace-tabs-session-storage.service';
import {AiWrapperComponent} from '../../../ai/components/ai-wrapper/ai-wrapper.component';
import {RouterBehaviourService} from "../../../../shared/services/RouterBehaviourService";

export class HeaderOptionsPaths {
  systemReport = new CheckPath();
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  className = 'HeaderComponent';


  isAdmin = false;
  showProjectName = false;

  @Input('mobileSize') mobileSize = 992;
  @Input('mobileSize') tabletSize = 1200;
  smallMobileSize = 491;

  showTopMenu = true;
  showNavBar = false;//top priority top top top
  showNavBarButton = false;
  showNoticeBoard = false;
  showAdminSettings = false;
  showAccountSettings = false;
  forceHideTopBarSelectProject = false;
  forceShowTopBarUSerInfo = false;
  showSystemReport = true;

  public screenWidth = 0;
  maxtafVersion;

  //0 close
  //1 open icon
  //2 open
  sideNavMod = 2;

  @ViewChild('sidenav') public sidenav: MatSidenav;
  @ViewChild('pageScroll') public pageScroll: OverlayScrollbarsComponent;

  @Output('clickedToggleButton') clickedToggleButton = new EventEmitter<null>();

  /*
  * mobile
  *
  * open
  * close
  *
  *
  *
  * pc
  *
  * open
  * open icon
  *
  * */

  event$;
  url = '';// /terminal
  terminal = false;
  startDate;
  subscribe;

  @ViewChild('aiWrapperComponent') aiWrapperComponent: AiWrapperComponent;
  checkPaths: HeaderOptionsPaths = new HeaderOptionsPaths();
  expandedNavBar = true;
  expandedAIChat = false;
  /********************** Home ***********************/

  selectedOptions = 'home';
  homeHover = false;
  workspaceHover = false;
  filesHover = false;
  /******************* Parameters *********************/

  parametersHover = false;
  /******************* suites *********************/

  suitesHover = false;
  /******************* schedule *********************/

  scheduleHover = false;
  /******************* runs *********************/

  runsHover = false;
  /******************* Project Settings ********************************/

  projectHover = false;
  /************************ User Settings *****************************/

  profileHover = false;
  /***************** System Report *********************/

  systemReportHover = false;
  /************************ AI ***************************/

  aiHover = true;
  /**************** Documentation **********************/
  documentationHover = false;
  /****************** Tutorials ************************/

  tutorialsHover = false;
  /**************** Support ************************************/
  supportHover = false;
  /**************** Log out ************************************/
  logOutHover = false;
  sidenavHover = true;
  mouseX: number;
  mouseY: number;
  resizeAIMode = false;

  constructor(
    public userService: UserService,
    public authService: AuthMaxtafService,
    public tokensStorage: MaxtafTokensStorageService,
    public router: Router,
    public checkRolesService: CheckRolesService,
    public changeDetectorRef: ChangeDetectorRef,
    public userProjectConfigService: UserProjectConfigService,
    public globalEventsManagerService: GlobalEventsManagerService,
    public dialog: MatDialog,
    private tokenService: MaxtafTokensStorageService,
    private tabsSessionStorageService: LocalSaveWorkspaceTabsSessionStorageService,
    public projectService: ProjectService,
    public routerBehaviourService: RouterBehaviourService
  ) {
    // this.href = this.router.url;
    const pj = require('../../../../../../package.json');
    this.maxtafVersion = pj.version.replace('-SNAPSHOT', '');

    this.event$
      = this.router.events
      .subscribe(
        (event: any) => {
          if (event) {
            this.url = event.url;
            this.terminal = this.url == '/terminal';
          }
        });

    this.setScreenWidthChanges();
    this.setEngineRefresher();
    this.refreshCheckRolePaths();

    this.setShowHideNavbarAndAdminOptions();
  }

  ngOnInit(): void {


    this.startDate = new Date();
    /*this.subscribe = */
  }

  ngOnDestroy() {
    this.event$.unsubscribe();
  }

  getHeaderOptionsCheckPathObject(): HeaderOptionsPaths {
    return {
      systemReport: new CheckPath('GET', this.userProjectConfigService.getSystemParams()),
    };
  }

  public refreshCheckRolePaths() {
    if (this.tokensStorage.getUserId() == null || this.tokensStorage.getUserId() == '') {

    } else {
      this.checkPaths = this.getHeaderOptionsCheckPathObject();

      this.checkRolesService.checkPaths(this.checkPaths).subscribe(
        checkPathsArray => {
          this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray, this.checkPaths);

          // this.changeDetectorRef.detectChanges();
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  setEngineRefresher() {
    /*this.setEngineRefresherInterval =*/
    setInterval(() => {
      if (document.hidden) {
        return;
      }

      if (this.tokensStorage.getProjectId() !== undefined && this.tokensStorage.getProjectId() !== null && this.tokensStorage.getProjectId() !== '') {
        this.projectService.startEngineOrResetShutdownTimer(this.tokensStorage.getProjectId()).subscribe(
          () => {
          },
          error => {
            console.error(error);
          }
        );
      }
    }, 10000);
  }

  ngAfterViewInit() {
    this.globalEventsManagerService.setPageScroll(this.pageScroll.osInstance());
  }

  setScreenWidthChanges() {
    var switchSize = false;
    if ((this.screenWidth > this.mobileSize && window.innerWidth < this.mobileSize) ||
      (this.screenWidth < this.mobileSize && window.innerWidth > this.mobileSize)) {
      switchSize = true;
    }

    this.screenWidth = window.innerWidth;
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      if (switchSize) {
        this.globalEventsManagerService.showNavBar(true);
      }
      this.sideNavMod = 3;
      if (switchSize) {
        this.globalEventsManagerService.showNavBar(false);
      }
    } else {
      this.sideNavMod = 3;
      if (switchSize) {
        this.globalEventsManagerService.showNavBar(false);
      }
    }

    this.globalEventsManagerService.setSideNavMod(this.sideNavMod);
    this.globalEventsManagerService.setScreenWith(this.screenWidth);

    // this.changeDetectorRef.detectChanges();
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth > this.mobileSize) {
        this.sideNavMod = 2;
      } else {
        this.sideNavMod = 3;
      }

      // set screenWidth on screen size change


      // this.changeDetectorRef.detectChanges();
      this.globalEventsManagerService.setSideNavMod(this.sideNavMod);
      this.globalEventsManagerService.setScreenWith(this.screenWidth);
    };
  }

  setShowHideNavbarAndAdminOptions() {
    this.globalEventsManagerService.openAiPromptForCase.subscribe((caseId) => {
      try {
        if (this.expandedAIChat) {
          try {
            this.aiWrapperComponent.openAiPromptForCase(caseId);
          } catch (e) {
            console.error('error ', e);
          }
        } else {
          if (caseId == null) {
            try {
              this.aiWrapperComponent.openAiPromptForCase(caseId);
            } catch (e) {

            }
          } else {
            this.expandAIChat();
            if (this.aiWrapperComponent != undefined) {
              try {
                this.aiWrapperComponent.openAiPromptForCase(caseId);
              } catch (e) {
                console.error('error: ', e);
              }
            } else {
              setTimeout(() => {
                if (this.aiWrapperComponent != undefined) {
                  try {
                    this.aiWrapperComponent.openAiPromptForCase(caseId);
                  } catch (e) {
                    console.error('error: ', e);
                  }
                } else {
                  setTimeout(() => {
                    try {
                      this.aiWrapperComponent.openAiPromptForCase(caseId);
                    } catch (e) {
                      console.error('error: ', e);
                    }
                  }, 500);
                }
              }, 500);
            }
          }


        }

      } catch (e) {
        console.error('232 e: ', e);
      }

    });

    this.globalEventsManagerService.pastToAiPrompt.subscribe(({
                                                                code,
                                                                askAI = false,
                                                                timestamp,
                                                                caseId = undefined,
                                                                caseName = undefined
                                                              }) => {

      try {
        if (code == undefined) {//|| code == '' || code.trim() == ''
          return;
        }

        if (this.expandedAIChat) {
          try {
            this.aiWrapperComponent.addToPrompt(code, caseId, caseName);
          } catch (e) {
            console.error('error ', e);
          }
        } else {
          this.expandAIChat();
          if (this.aiWrapperComponent != undefined) {
            try {
              this.aiWrapperComponent.addToPrompt(code, caseId, caseName);
            } catch (e) {
              console.error('error: ', e);
            }
          } else {
            setTimeout(() => {
              if (this.aiWrapperComponent != undefined) {
                try {
                  this.aiWrapperComponent.addToPrompt(code, caseId, caseName);
                } catch (e) {
                  console.error('error: ', e);
                }
              } else {
                setTimeout(() => {
                  try {
                    this.aiWrapperComponent.addToPrompt(code, caseId, caseName);
                  } catch (e) {
                    console.error('error: ', e);
                  }
                }, 500);
              }
            }, 500);
          }
        }

      } catch (e) {
        console.error('error: ', e);
      }
    });

    this.globalEventsManagerService.askAIEmitter.subscribe(({
                                                              code,
                                                              askAI = true,
                                                              timestamp,
                                                              caseId = undefined,
                                                              caseName = undefined
                                                            }) => {

      if (code == undefined || code == '' || code.trim() == '') {
        return;
      }

      // if (!askAI) {
      //   try {
      //     this.aiWrapperComponent.addToPrompt(code);
      //   } catch (e) {
      //     console.error('error ', e);
      //   }
      // }

      if (this.expandedAIChat) {
        try {
          this.aiWrapperComponent.askAndSaveQuestion(code, caseId, caseName);
        } catch (e) {
          console.error('error ', e);
        }
      } else {
        this.expandAIChat();
        if (this.aiWrapperComponent != undefined) {
          try {
            this.aiWrapperComponent.askAndSaveQuestion(code, caseId, caseName);
          } catch (e) {
            console.error('error: ', e);
          }
        } else {
          setTimeout(() => {
            if (this.aiWrapperComponent != undefined) {
              try {
                this.aiWrapperComponent.askAndSaveQuestion(code, caseId, caseName);
              } catch (e) {
                console.error('error: ', e);
              }
            } else {
              setTimeout(() => {
                try {
                  this.aiWrapperComponent.askAndSaveQuestion(code, caseId, caseName);
                } catch (e) {
                  console.error('error: ', e);
                }
              }, 500);
            }
          }, 500);
        }
      }
    });

    this.globalEventsManagerService.showAdminSettingsEmitter.subscribe((mode) => {
      this.showAdminSettings = mode;
      this.setAdminOptions();
    });

    this.globalEventsManagerService.hideShowAIEmitter.subscribe((mode) => {
      // this.expandedAIChat = mode;
      if (mode) {
        this.expandAIChat();
      } else {
        this.closeAIChat();
        this.resizeAIMode = false;
      }
    });

    this.globalEventsManagerService.showUserInHeaderEmitter.subscribe((mode) => {
      this.showAccountSettings = mode;
      this.setAdminOptions();
    });

    this.globalEventsManagerService.showTopMenuEmitter.subscribe((mode) => {
      this.showTopMenu = mode;
      this.setAdminOptions();
    });

    this.globalEventsManagerService.showNavBarEmitter.subscribe((mode) => {
      if (this.screenWidth <= this.mobileSize && mode) {
        return;
      }

      if (this.showNavBar != mode) {
        this.showNavBar = mode;
        if (this.showNavBar) {
          this.sideNavMod = 2;
        } else {
          this.sideNavMod = 0;
        }
        this.globalEventsManagerService.setSideNavMod(this.sideNavMod);
      }
      this.setAdminOptions();
    });

    this.globalEventsManagerService.showNavBarButtonEmitter.subscribe((mode) => {
      this.showNavBarButton = mode;
    });

    this.globalEventsManagerService.showNoticeBoardEmitter.subscribe((mode) => {
      this.showNoticeBoard = mode;
    });

    this.globalEventsManagerService.showTopMenuChangeProjectButton.subscribe((mode) => {
      this.forceHideTopBarSelectProject = mode != null ? !mode : false;
    });

    this.globalEventsManagerService.showNavTopMenuUserInfoButton.subscribe((mode) => {
      this.forceShowTopBarUSerInfo = mode != null ? mode : false;
    });

  }

  setAdminOptions(): void {
    this.isAdmin = this.userService.isAdmin();
  }

  toggleButton() {
    this.refreshCheckRolePaths();

    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
    } else {
      this.sideNavMod = 3;
      this.sidenav.toggle();
      this.clickedToggleButton.emit();
    }

    this.globalEventsManagerService.setSideNavMod(this.sideNavMod);
  }

  logout() {
    this.hideAI();
    console.log('%c 11212 logout ');
    const currentUrl = this.router.url;
    this.router.navigate(['/logout'], {queryParams: {returnUrl: currentUrl}}).then();

  }

  userName() {
    const userProject: UserProject = this.userService.getCurrentUserProject();
    return (userProject == null || userProject.user == null) ? '' : userProject.user.email != null && userProject.user.email.length > 20 ? userProject.user.email.substr(0, 17) + '...' : userProject.user.email;
  }

  userNameFull() {
    const userProject: UserProject = this.userService.getCurrentUserProject();
    return (userProject == null || userProject.user == null) ? '' : userProject.user.email;
  }

  projectName() {
    const userProject: UserProject = this.userService.getCurrentUserProject();
    if (userProject == null || this.tokensStorage.getProjectId() === undefined) {
      this.showProjectName = false;
      return 'X';
    } else {
      this.showProjectName = true;
      if (userProject.project != undefined) {
        return userProject.project.name;
      } else {
        return 'Y';
      }
    }
  }

  clickedLogo() {
    this.tokensStorage.clearForSelectProject();
    this.router.navigate(['/selectProject']);
  }

  selectProjectPage() {
    this.router.navigate(['/selectProject']);
  }

  selectProject() {
    const dialogRef = this.dialog.open(SelectCreateProjectComponent, {
      width: '800px',
      panelClass: 'custom-dialog-container',
      data: {
        activeProjectId: this.tokensStorage.getProjectId()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.globalEventsManagerService.refreshAIChat(this.tokensStorage.getUserProjectID());
    });

  }

  openUserDialog() {
    const backupUser = {};
    Object.assign(backupUser, this.userService.getCurrentUserProject());
    // routerLink="/profile" [queryParams]="{userDetailsShow: true, userProjectDetailsShow: false}"
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '800px',
      data: {
        userProject: this.userService.getCurrentUserProject(),
        userDetailsShow: true,
        userProjectDetailsShow: false,
        allowEditName: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  expandAIChat() {
    this.aiWrapperComponent.openedAI()
    this.expandedAIChat = true;
  }

  closeAIChat() {
    this.expandedAIChat = false;
  }

  clickedHome(homeMenu) {
    homeMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'home';
    }
    this.router.navigate(['/home']);
  }

  onHoverHome() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'home') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.homeHover = true;
  }

  onHoverExitHome() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.homeHover = false;
  }

  /******************* Workspace *********************/



  clickedCases(casesMenu: MatExpansionPanel) {
    casesMenu.close();
    const currentUrl = this.router.url;
    if (this.router.url !== '/cases-workspace') {
      if (this.screenWidth > this.mobileSize) {
        this.sideNavMod = 2;
        this.selectedOptions = 'cases-workspace';
      }
      this.routerBehaviourService.changeAction("dontOpenList");
      this.router.navigate(['cases-workspace']);
    } else {
      this.routerBehaviourService.changeAction("openList");
      console.log("finally trying to route here");
    }
  }

  onHoverWorkspace() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }

    if (this.selectedOptions != 'cases-workspace') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }


    this.workspaceHover = true;
  }

  onHoverExitWorkspace() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.workspaceHover = false;
  }

  /******************* FileSystem *********************/


  clickedFiles(filesMenu) {
    filesMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'files';
    }
    this.router.navigate(['/files']);
  }

  onHoverFileSystem() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    //
    //   } else if (this.filesHover && this.selectedOptions == 'files') {
    //
    //   } else if (this.parametersHover && this.selectedOptions == 'parameters') {
    //
    if (this.selectedOptions != 'files') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }

    this.filesHover = true;
  }

  onHoverExitFileSystem() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.filesHover = false;
  }

  clickedParameters(parametersMenu: MatExpansionPanel) {
    parametersMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'parameters';
    }
    this.router.navigate(['/parameters']);
  }

  onHoverParameters() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'parameters') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.parametersHover = true;
  }

  onHoverExitParameters() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.parametersHover = false;
  }

  clickedSuites(suitesMenu: MatExpansionPanel) {
    suitesMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'suites';
    }
    this.router.navigate(['/suites']);
  }

  onHoverSuites() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'suites') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.suitesHover = true;
  }

  onHoverExitSuites() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.suitesHover = false;
  }

  clickedSchedule(scheduleMenu) {
    scheduleMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'schedule';
    }
    this.router.navigate(['/schedule']);
  }

  onHoverSchedule() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'schedule') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.scheduleHover = true;
  }

  onHoverExitSchedule() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.scheduleHover = false;
  }

  clickedRuns(runsMenu: MatExpansionPanel) {
    runsMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'runs';
    }
    this.router.navigate(['/runs']);
  }

  onHoverRuns() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'runs') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.runsHover = true;
  }

  onHoverExitRuns() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.runsHover = false;
  }

  clickedProject(projectMenu) {
    projectMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'project';
    }
    this.router.navigate(['/settings/project']);
  }

  onHoverProjectSetting() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'project') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.projectHover = true;
  }

  onHoverExitProjectSetting() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.projectHover = false;
  }

  clickedProfile(profileMenu) {
    profileMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'profile';
    }
    this.router.navigate(['/profile']);
  }

  onHoverUserSettings() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'profile') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.profileHover = true;
  }

  onHoverExitUserSettings() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.profileHover = false;
  }

  clickedSystemReport(systemReportMenu: MatExpansionPanel) {
    systemReportMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'systemReport';
    }
    this.router.navigate(['/systemReport']);
  }

  onHoverSystemReport() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'systemReport') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.systemReportHover = true;
  }

  onHoverExitSystemReport() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.systemReportHover = false;
  }

  clickedAI(aiMenu: MatExpansionPanel) {
    aiMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'ai';
    }
    // this.expandedAIChat = !this.expandedAIChat;
    //this.expandedAIChat = true;
    if (this.expandedAIChat) {
      this.closeAIChat();
    } else {
      this.expandAIChat();
    }

    this.resizeAIMode = false;
  }

  onHoverAI() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'ai') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.aiHover = true;
  }

  onHoverExitAI() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.aiHover = false;
  }

  clickedDocumentation(scheduleMenu: MatExpansionPanel) {
    scheduleMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'documentation';
    }
    window.open('https://www.maxtaf.com/guides/mtc-guide/?version=Cloud', '_blank');
  }

  onHoverDocumentation() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'documentation') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.documentationHover = true;
  }

  onHoverExitDocumentation() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.documentationHover = false;
  }

  clickedTutorials(scheduleMenu: MatExpansionPanel) {
    scheduleMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'tutorials';
    }
    window.open('https://www.maxtaf.com/tutorials', '_blank');
  }

  onHoverTutorials() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'tutorials') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.tutorialsHover = true;
  }

  onHoverExitTutorials() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.tutorialsHover = false;
  }

  clickedSupport(supportMenu: MatExpansionPanel) {
    supportMenu.close();
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'support';
    }
    window.open('mailto:support@codedevelopment.com', '_blank');
  }

  onHoverSupport() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'support') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.supportHover = true;
  }

  /***************************************************/

  onHoverExitSupport() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.supportHover = false;
  }

  clickedLogOutMenu(supportMenu: MatExpansionPanel) {
    if (this.screenWidth > this.mobileSize) {
      this.sideNavMod = 2;
      this.selectedOptions = 'logOut';
    }
    supportMenu.close();
    console.log('%c 11212 logout ');
    this.logout();
  }

  onHoverLogOut() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    if (this.selectedOptions != 'logOut') {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }
    this.logOutHover = true;
  }

  onHoverExitLogOut() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }
    this.logOutHover = false;
  }

  onHover() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }

    if (this.mouseX < 60 && this.mouseY > 50) {

    } else {
      this.sidenavHover = true;
      this.sideNavMod = 1;
    }

    //   }
    // }, 100);
  }

  onHoverExit() {
    if (this.screenWidth <= this.mobileSize) {
      return;
    }

    this.sideNavMod = 2;
    this.sidenavHover = false;

  }

  onHoverContentExit() {

  }

  onHoverContent() {
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
  }

  hideAI() {
    this.closeAIChat();
    this.resizeAIMode = false;
  }


  resizeAI() {
    this.resizeAIMode = !this.resizeAIMode;
  }

  // returnSizeAIChat()  {
  //   if (this.expandedAIChat)  {
  //     if(this.resizeAIMode) {
  //       return 800;
  //     } else {
  //       return 275;
  //     }
  //   } else {
  //     return 0;
  //   }
  //   // expandedAIChat ? resizeAIMode? 800 :275: 0
  // }
}
