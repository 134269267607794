<h1 mat-dialog-title>Change case file location/name</h1>

<div class="change-file-location-container" mat-dialog-content>
  <label *ngIf="testCase.caseType != 'JAVA'">Source directory</label>
  <span *ngIf="testCase.caseType != 'JAVA'" [matTooltip]="'Change this in project params'"
        class="mb-2">{{fileLocation.projectParamPart}}</span>

  <!--  <mat-form-field  *ngIf="testCase.caseType == 'JAVA'" class="ml-1">-->
  <!--    <mat-select placeholder="maven source directory"-->
  <!--                [(value)]="fileLocation.buildAutomationToolPart">-->
  <!--      <mat-option value="Contains" >{{buildAutomationToolPath.testSourceDirectory}}</mat-option>-->
  <!--      <mat-option value="Exact">{{buildAutomationToolPath.sourceDirectory}}</mat-option>-->
  <!--    </mat-select>-->
  <!--  </mat-form-field>-->
  <label *ngIf="testCase.caseType == 'JAVA'">Source directory</label>
  <mat-radio-group *ngIf="testCase.caseType == 'JAVA'" [(ngModel)]="fileLocation.buildAutomationToolPart"
                   aria-label="Select an option">
    <mat-radio-button
      value="{{buildAutomationToolPath.testSourceDirectory}}">{{fileLocation.projectParamPart}}{{buildAutomationToolPath.testSourceDirectory}}</mat-radio-button>
    <br>
    <mat-radio-button
      value="{{buildAutomationToolPath.sourceDirectory}}">{{fileLocation.projectParamPart}}{{buildAutomationToolPath.sourceDirectory}}</mat-radio-button>
  </mat-radio-group>

  <mat-form-field class="mt-1">
    <input [(ngModel)]="fileLocation.packageCasePartWithoutExtension" matInput placeholder="File location"
           type="text">
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" id="closeButton" mat-button>Cancel</button>
  <button (click)="updateCaseFileLocation()" *ngIf="!spinner && autoUpdate" color="primary" mat-button>Update</button>
  <button (click)="updateCaseFileLocation()" *ngIf="!spinner && !autoUpdate" color="primary" mat-button>OK</button>
  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>
