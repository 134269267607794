<div mat-dialog-content>
  <!--<pre>{{message}}</pre>-->

  <app-editor-workspace
    #mainEditor
    (fileChanges)="fileChanges($event)"
    [addActionOptions]="true"
    [allowEdit]="true"
    [content]="message"
    [height]="'500'"
    [ngStyle]="{
                    'height': 500,
                     'width': 600
                    }"

    [typeOfFile]="'text'"

    [width]="'600'"

    class="editor"
    style="background: white"

  ></app-editor-workspace>
</div>

<div mat-dialog-actions>
  <button (click)="do()" color="primary" id="createButton" mat-button>
    Save & Submit
  </button>
  <button [mat-dialog-close]="undefined" mat-button>
    Cancel
  </button>

  <!--  <button id="createButton" mat-button *ngIf="!saveSpinner" (click)="do()" color="primary">-->
  <!--    {{duplicate ? 'Duplicate' : 'Create'}}-->
  <!--  </button>-->
  <!--  <button mat-button *ngIf="saveSpinner" color="primary">-->
  <!--    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">-->
  <!--      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"-->
  <!--                   [diameter]="20"-->
  <!--                   class="whiteSpinner"></mat-spinner>-->
  <!--    </mat-icon>-->
  <!--  </button>-->
</div>
