import {Component, Inject, OnInit} from '@angular/core';
import {Project} from '../../../projects/shared/models/project';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {FileService} from '../../../../shared/services/file.service';
import {TemplateService} from '../../../../shared/services/template.service';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {TransferExportService} from '../../../../shared/services/transfer-export.service';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {saveAs} from 'file-saver';
import {RemoteFilesService} from '../../shared/services/remote-files.service';

@Component({
  selector: 'app-download-file-dialog',
  templateUrl: './download-file-dialog.component.html',
  styleUrls: ['./download-file-dialog.component.css']
})
export class DownloadFileDialogComponent implements OnInit {

  className = 'ExportCaseDialogComponent';

  heading = 'Export Cases';

  active = true;

  paramsFilter;

  selected: string[] = [];

  url: string;

  // JSON XML TEMPLATE ...
  exportType = 'JSON';

  onlyArchivedOrActive = true;
  useFilter = false;

  templateName = '';
  templateDescription = '';

  error;
  exportError = false;
  exportSuccess = false;

  hideAdvancedSettings = false;
  overwriteCase = false;

  projectsList: Project[] = [];
  projects = new FormControl();

  // Workaround for angular component issue #13870
  disableAnimation = true;

  spinner = false;

  pathWithName;
  isFolder;

  constructor(
    private remoteFileService: RemoteFilesService,
    public dialog: MatDialog,
    public tokensStoriage: MaxtafTokensStorageService,
    private fileService: FileService,
    private templateService: TemplateService,
    private cloudConfigService: CloudConfigService,
    private projectService: ProjectService,
    private transferService: TransferExportService,
    public checkRolesService: CheckRolesService,
    public dialogRef: MatDialogRef<DownloadFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      pathWithName: string,
      isFolder: boolean,
    }
  ) {
    dialogRef.disableClose = true;

    if (this.data.pathWithName != undefined) {
      this.pathWithName = this.data.pathWithName;
    }

    if (this.data.isFolder != undefined) {
      this.isFolder = this.data.isFolder;
    }
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = function (e) {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('closeButton').click();
    }
  };

  // ENTER shortcut for exporting the test
  exportShortcutListener = function (e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('exportButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.exportShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.exportShortcutListener, false);
  }


  export() {
    this.exportCases();
  }

  exportCases() {
    this.exportSuccess = false;
    this.exportError = false;
    this.spinner = true;

    const filePaths: Array<string> = new Array<string>();

    filePaths.push(this.pathWithName + (this.isFolder ? '/' : ''));

    this.remoteFileService.downloadFilesOrFolders(filePaths).subscribe(
      res => {
        this.exportSuccess = true;

        const contentDisposition = res.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();

        const blob = new Blob([res.body], {type: 'application/text'});
        saveAs(blob, fileName);
        this.spinner = false;
        this.closeDialog();
      },
      error => {
        this.spinner = false;
        console.error(error);
        this.showError(error);
      }
    );

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  showError(error) {
    this.error = error;
    this.exportError = true;
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }


}
