import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MxOptionAbstract} from "../../../mx/options/MxOptionAbstract";
import {ImportCaseInfoConstParams} from "../import-case-info-dialog/import-case-info-const.service";
import {MatDialog} from "@angular/material/dialog";
import {CheckRolesService} from "../../../../shared/services/check-roles.service";

@Component({
  selector: 'app-mx-diff-code-options',
  templateUrl: './mx-diff-code-options.component.html',
  styleUrls: ['./mx-diff-code-options.component.css']
})
export class MxDiffCodeOptionsComponent extends MxOptionAbstract<MxDiffCodeOptionsComponent> implements OnInit {

  @Input('searchParams') searchParams: ImportCaseInfoConstParams;
  @Output('searchParamsChange') searchParamsChange = new EventEmitter<MxDiffCodeOptionsComponent>();
  @Input('lockFilterParams') lockFilterParams = [];
  @Input('forDialog') forDialog = true;


  @Input('allowShadow') allowShadow = true;
  @Input('allowEdit') edit = true;
  @Input('showButtons') showButtons = true;
  @Input('showButtonText') showButtonText = true;
  @Input('showTooltip') showTooltip = true;
  @Input('smallHeight') smallHeight = true;
  @Input('defaultColors') defaultColors = true;
  @Input('iconMarginBottom') iconMarginBottom = true;

  @Output('next') nextEvent = new EventEmitter<null>();
  @Output('prev') prevEvent = new EventEmitter<null>();


  constructor(public dialog: MatDialog,
              public checkRolesService: CheckRolesService) {
    super(checkRolesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // document.addEventListener('keydown', this.deleteShortcutListener, false);
  }

  ngOnDestroy() {
    // document.removeEventListener('keydown', this.deleteShortcutListener, false);
  }

  filter() {
    //this.filterEvent.emit(this.searchParams);
  }

  next() {
    this.nextEvent.emit();
  }

  prev() {
    this.prevEvent.emit();
  }

}

