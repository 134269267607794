import {Component, OnInit} from '@angular/core';
import {MxFilter} from '../../../mx/filter/MxFilter';
import {AngularFireAuth} from '@angular/fire/auth';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.css']
})
export class ProjectFilterComponent extends MxFilter implements OnInit {
  search: any;
  includePublic = false;

  constructor(private auth: AngularFireAuth) {
    super();
    this.auth.user.pipe(first()).subscribe(
      user => {
        this.includePublic = user.isAnonymous;
      }
    );
  }

  ngOnInit(): void {
  }

  searchDefaultValues() {
    return {
      exactSearch: 'Contains',
      userId: '',
      projectId: '',
      name: '',
      ownerUsername: '',
      description: '',
      includePublic: this.includePublic
    };
  }
}
