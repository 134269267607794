<div (keyup.enter)="filterRecords()"
     class="container-fluid">

  <div class="row">

    <div class="col-md">
      <mat-form-field class="w-100">
        <mat-select [(value)]="search.exactSearch"
                    placeholder="Filter done as exact match, or not">
          <mat-option value="Contains">Contains</mat-option>
          <mat-option value="Exact">Exact</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="col-md" style="display: flex; justify-content: center;">
      <button (click)="filterRecords()" class="mr-1 mb-1 heightButton" color="primary" mat-button mat-raised-button>
        <mx-icon marginBottom="true" mx="filter"></mx-icon>
        <span class="px-1">Filter</span>
      </button>
      <button (click)="clearFilter()" class="mr-1 mb-1 heightButton" color="primary" mat-button mat-raised-button>
        <mx-icon marginBottom="true" mx="clear"></mx-icon>
        <span class="px-1">Clear</span>
      </button>
      <button (click)="clickedClose()" class="mb-1 heightButton" color="primary" mat-button mat-raised-button>
        <mx-icon marginBottom="true" mx="close"></mx-icon>
        <span class="px-1">Close</span>
      </button>
    </div>

  </div>

  <div class="row">

    <div class="col-md">
      <mat-form-field class="w-100">
        <input [(ngModel)]="search.name" [disabled]="lockSearchParams.includes('name')" matInput
               placeholder="Name..."
               type="text">
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <input [(ngModel)]="search.description" [disabled]="lockSearchParams.includes('description')" matInput
               placeholder="Description..."
               type="text">
      </mat-form-field>
    </div>

  </div>

  <div class="row">

    <div class="col-md">
      <mat-form-field class="w-100">
        <input [(ngModel)]="search.ownerUsername" [disabled]="lockSearchParams.includes('ownerUsername')" matInput
               placeholder="Owner username..."
               type="text">
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <input [(ngModel)]="search.projectId" [disabled]="lockSearchParams.includes('projectId')" matInput
               placeholder="Id..."
               type="text">
      </mat-form-field>
    </div>

  </div>

  <div class="row">

    <div class="col-md center-checkbox">
      <mat-checkbox [(ngModel)]="search.includePublic" [disabled]="lockSearchParams.includes('includePublic')">Show
        public projects
      </mat-checkbox>
    </div>

  </div>

</div>
