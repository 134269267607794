import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-filter-by',
  templateUrl: './filter-by.component.html',
  styleUrls: ['./filter-by.component.css']
})
export class FilterByComponent implements OnInit {
  className = 'FilterByComponent';

  @Input('disabled') disabled = false;
  @Input('hideHelpButtons') hideHelpButtons = false;
  @Input('isSearchCollapsed') isSearchCollapsed = false;
  @Output('isSearchCollapsedChange') isSearchCollapsedChange = new EventEmitter<any>();

  @Input('placeholderText') placeholderText = 'Name..';
  @Input('filterBy') filterBy = 'name';

  filterValue: string = '';

  searchParams;

  @Input('ignoreParams') ignoreParams: string[] = [];
  @Input('focus') focus = false;
  /*
   * Return searchParams
   * */
  @Output('searchParamsChange') searchParamsChange = new EventEmitter<any>();
  /*
  * Return searchParams
  * */
  @Output('filter') runFilter = new EventEmitter<any>();
  @ViewChild('inputName', {static: false}) inputElm: ElementRef;

  constructor(private el: ElementRef) {
  }

  /*
  * Set searchParams
  * */
  @Input('searchParams') set setSearchParams(searchParams) {
    this.searchParams = searchParams;

    this.setSearchFromPublicSearch();
  }

  ngOnInit(): void {
    const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
    input?.focus();
    try {
      input?.select();
    } catch (e) {

    }
  }

  openCloseSearchPanel() {
    this.isSearchCollapsed = !this.isSearchCollapsed;
    this.isSearchCollapsedChange.emit(this.isSearchCollapsed);
  }

  runSearch(text: string) {
    this.setPublicSearchFromSearch();
    this.searchParamsChange.emit(this.searchParams);
    this.runFilter.emit();
  }

  clearText() {
    this.filterValue = '';
    this.cleanParams();
  }

  addSpace(e) {
    let temp = this.filterValue.substring(0, e.target.selectionStart) + ' ' + this.filterValue.substring(e.target.selectionStart, this.filterValue.length);
    this.filterValue = temp;
  }

  cleanParams() {
    Object.keys(this.searchParams).forEach(key => {
      this.searchParams[key].value = this.searchParams[key].defaultValue;
    });
  }

  setSearchFromPublicSearch() {
    this.filterValue = '';

    Object.keys(this.searchParams).forEach(key => {
      const value = this.searchParams[key].value;
      const defaultValue = this.searchParams[key].defaultValue;

      if (value != undefined && !this.ignoreParams.includes(key) && (defaultValue == undefined || value.toString().toLowerCase() != defaultValue.toString().toLowerCase())) {
        this.filterValue += (this.filterValue == '' ? '' : ' ') + key + ':[' + value + ']';
      }
    });
  }

  setPublicSearchFromSearch() {
    let temp: string = this.filterValue;
    const startTarget = '[';
    const endTarget = ']';
    const keyValueSeparator = ':';
    let brackets = new Map();
    let newTemp = '';
    let defaultParams = '';
    brackets = getBrackets();
    removeBracketsContent();
    this.cleanParams();
    const words: string[] = temp.split(' ');

    const paramsKeys: string[] = Object.keys(this.searchParams);

    for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {

      newTemp += (this.filterValue.charAt(newTemp.length) == ' ' ? ' ' : '') + words[wordIndex];

      let paramIndex = 0;
      for (paramIndex = 0; paramIndex < paramsKeys.length; paramIndex++) {

        if (words[wordIndex].startsWith(paramsKeys[paramIndex] + keyValueSeparator)) {
          const key: string = paramsKeys[paramIndex];

          const paramValue = getParamValue(words[wordIndex], paramsKeys[paramIndex], this.filterValue);

          if (this.searchParams[key].value == undefined || this.searchParams[key].value == '') {
            this.searchParams[key].value = paramValue;
          } else {
            this.searchParams[key].value = this.searchParams[key] + ' ' + paramValue;
          }

          break;
        }

      }

      if (paramIndex == paramsKeys.length) {
        defaultParams += (defaultParams == '' ? '' : ' ') + words[wordIndex];

        let valueInBrackets;
        do {
          valueInBrackets = getParamBracketValue(this.filterValue);
          if (valueInBrackets != undefined) {
            defaultParams += (defaultParams == startTarget ? '' : startTarget) + valueInBrackets + endTarget;
          }
        } while (valueInBrackets != undefined);
      }

    }


    if ((this.searchParams[this.filterBy].value == undefined || this.searchParams[this.filterBy].value == '') && defaultParams != undefined && defaultParams.trim() != '') {
      this.searchParams[this.filterBy].value = defaultParams.trim();
    }

    /*
     * get param value and update new temp
     */
    function getParamValue(word: string, param: string, searchValue: string) {
      let paramValue = word.substr((param + keyValueSeparator).length);

      let valueInBrackets;
      do {
        valueInBrackets = getParamBracketValue(searchValue);
        if (valueInBrackets != undefined) {
          paramValue += (paramValue == '' ? '' : '') + valueInBrackets;
        }
      } while (valueInBrackets != undefined);
      return paramValue;
    }

    /*
    * get value In Brackets and update new temp
    */
    function getParamBracketValue(searchValue: string) {
      let valueInBrackets;
      const endBracket = brackets.get(newTemp.length);

      if (endBracket != undefined) {
        valueInBrackets = searchValue.substr(newTemp.length + 1, endBracket - (newTemp.length + 1));

        brackets.delete(newTemp.length);
        newTemp += startTarget + valueInBrackets + endTarget;
      }

      return valueInBrackets;
    }

    /*
     * get brackets
     */
    function getBrackets() {
      let tempBrackets = temp;

      const brackets = new Map();
      while (tempBrackets.includes(startTarget) && tempBrackets.includes(endTarget)) {
        const start = tempBrackets.lastIndexOf(startTarget);
        const end = tempBrackets.indexOf(endTarget, start);

        if (end < 0) {
          let rest = tempBrackets.substr(start);

          rest = rest.replace(startTarget, 'a');
          rest = rest.replace(endTarget, 'a');

          tempBrackets = tempBrackets.substr(0, start) + rest;

          brackets.set(start, tempBrackets.length);

        } else {
          let rest = tempBrackets.substr(start, end - start + 1);

          rest = rest.replace(startTarget, 'a');
          rest = rest.replace(endTarget, 'a');

          tempBrackets = tempBrackets.substr(0, start) + rest + tempBrackets.substr(end + 1, tempBrackets.length - end);
          brackets.set(start, end);
        }
      }

      return brackets;
    }

    /*
     * remove brackets and update temp
     */
    function removeBracketsContent() {
      while (temp.includes(startTarget) && temp.includes(endTarget)) {
        const start = temp.lastIndexOf(startTarget);
        const end = temp.indexOf(endTarget, start);

        if (end < 0) {
          temp = temp.substr(0, start);
        } else {
          temp = temp.substr(0, start) + ' ' + temp.substr(end + 1, temp.length - end);
        }
      }
    }
  }

}
