<h1 mat-dialog-title>Import {{type}}</h1>


<div mat-dialog-content>
  <div *ngIf="!importSpinner">
    <div class="row justify-content-md-center" style=" max-width: 100%;">
      <div *ngIf="showSuccess" class="row">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <h5 class="alert-heading"> {{(
            (importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != "") ||
            (importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != "") ||
            (importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != "") ||
            (importInfoDetails.suitesError != null && importInfoDetails.suitesError != "") ||
            (importInfoDetails.casesError != null && importInfoDetails.casesError != "")
          ) ? 'Partial ' : ''}} Success</h5>
          <hr>
          <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
          <button (click)="showSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>

    <app-error-message *ngIf="showUploadError" [error]="error" [showInDialog]="true"></app-error-message>

    <mat-progress-bar *ngIf="currentFileUpload" [value]="progress.percentage" class="mt-1"
                      mode="determinate"></mat-progress-bar>

    <br *ngIf="currentFileUpload"/>

    <div class="container" style=" max-width: 100%;">
      <div class="row justify-content-md-center">
        <div class="col">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input (change)="selectFile($event)" class="custom-file-input" id="inputGroupFile01" type="file">
              <label class="custom-file-label" for="inputGroupFile01">{{fileName}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br *ngIf="!hideSourceRadioGroup">

    <mat-radio-group *ngIf="!hideSourceRadioGroup" [disabled]="spinner">
      <mat-radio-button (change)="importType = 'json'" [checked]="importType === 'json'" class="mr-3" value="JSON">JSON
      </mat-radio-button>

      <br>

      <mat-radio-button (change)="importType = 'xml'" [checked]="importType === 'xml'" value="XML">XML
      </mat-radio-button>

      <br>

      <mat-radio-button (change)="importType = 'junit'" [checked]="importType === 'junit'" value="JUNIT">Selenium IDE /
        JUnit
      </mat-radio-button>

      <br>

      <mat-radio-button (change)="importType = 'python'" [checked]="importType === 'python'" value="PYTHON">Selenium IDE
        /
        PyTest
      </mat-radio-button>

      <br>

      <mat-radio-button (change)="importType = 'javascript'" [checked]="importType === 'javascript'" value="JAVASCRIPT">
        Selenium IDE /
        Mocha
      </mat-radio-button>

      <br>

      <mat-radio-button (change)="importType = 'csharp'" [checked]="importType === 'csharp'" value="JAVASCRIPT">
        Selenium IDE /
        NUnit
      </mat-radio-button>
    </mat-radio-group>

    <br *ngIf="!hideSourceRadioGroup">
    <br>

    <mat-checkbox (change)="overwrite1 = !overwrite1" [checked]="overwrite1" [disabled]="spinner" id="overwriteCheck"
                  type="checkbox">{{overwriteButton1Message}}
    </mat-checkbox>

    <br *ngIf="this.type.toLowerCase() != 'project params'"/>
    <mat-checkbox (change)="compile = !compile" *ngIf="this.type.toLowerCase() != 'project params'" [checked]="compile"
                  [disabled]="spinner" id="compileCheck"
                  type="checkbox">{{compileButton1Message}}
    </mat-checkbox>
    <br *ngIf="!hideOverwriteButton2"/>
    <mat-checkbox (change)="overwrite2 = !overwrite2" *ngIf="!hideOverwriteButton2" [checked]="overwrite2" [disabled]="spinner"
                  id="overwrite2Check"
                  type="checkbox">{{overwriteButton2Message}}
    </mat-checkbox>
    <br *ngIf="!hideOverwriteButton3"/>
    <mat-checkbox (change)="overwrite3 = !overwrite3" *ngIf="!hideOverwriteButton3" [checked]="overwrite3" [disabled]="spinner"
                  id="overwrite3Check"
                  type="checkbox">{{overwriteButton3Message}}
    </mat-checkbox>
  </div>

  <div *ngIf="importSpinner && this.importInfoDetails == undefined">
    <mat-spinner [diameter]="50" color="primary" style="align-self:flex-end; margin: 0 auto;"></mat-spinner>
  </div>
  <div *ngIf="importSpinner && this.importInfoDetails != undefined">

    <div class="row justify-content-md-center" style=" max-width: 100%;">
      <div *ngIf="showSuccess" class="row">
        <div *ngIf="importStatusField">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            <h5 class="alert-heading">Success</h5>
            <hr>
            <p *ngIf="successMessage && !partialSuccess" class="mb-0">{{ successMessage }} </p>
            <button (click)="showSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div *ngIf="partialSuccess">
          <div class="alert alert-warning alert-dismissible fade show" role="alert">
            <h5 class="alert-heading">Partial success</h5>
            <hr>
            <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
            <button (click)="showSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div *ngIf="!importStatusField && !partialSuccess">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <h5 class="alert-heading">Error</h5>
            <hr>
            <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
            <button (click)="showSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="this.type.toLowerCase() == 'project params'">
      Project Params: {{importInfoDetails.currentProjectParamsNumber}} / {{importInfoDetails.maxProjectParamsNumber}}
    </div>
    <div *ngIf="this.type.toLowerCase() != 'project params'">
      Cases: {{importInfoDetails.currentCasesNumber}} / {{importInfoDetails.maxCasesNumber}}
    </div>
    <div *ngIf="this.type === 'schedule' || this.type === 'suite'">
      Suites: {{importInfoDetails.currentSuitesNumber}} / {{importInfoDetails.maxSuitesNumber}}
    </div>
    <div
      *ngIf="(this.type === 'schedule' || this.type === 'suite') && importInfoDetails.currentSuitesNumber == importInfoDetails.maxSuitesNumber && importInfoDetails.connectingCasesWithSuitesStart && !importInfoDetails.connectingCasesWithSuitesEnd">
      {{counterDot == 0 ? 'Connecting cases with suites.' : counterDot == 1 ? 'Connecting cases with suites..' : 'Connecting cases with suites...'}}
    </div>
    <div *ngIf="this.type === 'schedule'">
      Schedules: {{importInfoDetails.currentScheduleNumber}} / {{importInfoDetails.maxScheduleNumber}}
    </div>


    <br *ngIf="importInfoDetails.projectParamsSkipped != null && importInfoDetails.projectParamsSkipped != ''">

    <mat-expansion-panel *ngIf="importInfoDetails.projectParamsSkipped != null && importInfoDetails.projectParamsSkipped != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped project params
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.projectParamsSkipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.projectParamsErrors != null && importInfoDetails.projectParamsErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Project params errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.projectParamsErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Error log
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.projectParamsError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.casesSkipped != null && importInfoDetails.casesSkipped != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped cases
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.casesSkipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.casesErrors != null && importInfoDetails.casesErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cases errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.casesErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.casesError != null && importInfoDetails.casesError != ''"
                         [expanded]="importInfoDetails.casesError != null && importInfoDetails.casesError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Error log
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.casesError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="importInfoDetails.suitesSkipped != null && importInfoDetails.suitesSkipped != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped suites
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.suitesSkipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.suitesErrors != null && importInfoDetails.suitesErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.suitesErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.suitesError != null && importInfoDetails.suitesError != ''"
                         [expanded]="importInfoDetails.suitesError != null && importInfoDetails.suitesError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Error log
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.suitesError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.caseSuitesErrors != null && importInfoDetails.caseSuitesErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suite items errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.caseSuitesErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.caseSuitesError != null && importInfoDetails.caseSuitesError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites items error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.caseSuitesError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.scheduleSkipped != null && importInfoDetails.scheduleSkipped != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped schedules
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.scheduleSkipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.scheduleErrors != null && importInfoDetails.scheduleErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Schedules errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.scheduleErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != ''"
                         [expanded]="importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Error log
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.scheduleError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <br>
  </div>
</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" *ngIf="!spinner && finished" color="primary" id="okButton" mat-button
          mat-raised-button>Ok
  </button>
  <button (click)="closeDialog()" *ngIf="!spinner && !finished" id="closeButton" mat-button>Close</button>
  <button (click)="upload()" *ngIf="!spinner && !finished" [disabled]="!selectedFiles" [ngClass]="{'green_button': selectedFiles}"
          id="uploadButton" mat-raised-button>Upload
  </button>

  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>
