import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {UserProject} from '../../shared/models/userProject';
import {UserService} from '../../shared/services/user.service';
import {RemoteFilesService} from '../../../storage/shared/services/remote-files.service';
import {ProjectPageMainTabParams} from '../../shared/services/user-const.service';
import {MatDialog} from '@angular/material/dialog';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {Router} from '@angular/router';
import {
  SecureConfirmationDialogComponent
} from '../../../dialogs/secure-confirmation-dialog/secure-confirmation-dialog.component';
import {Project} from '../../../projects/shared/models/project';
import {EditDialogComponent} from '../../../dialogs/edit-dialog/edit-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {EngineStatus} from '../../shared/models/EngineStatus';
import {GlobalEventsManagerService} from '../../../../shared/services/global-events-manager.service';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';
import {ExportProjectDialogComponent} from '../../dialogs/export-project-dialog/export-project-dialog.component';
import {GlobalImportDialogComponent} from '../../dialogs/global-import-dialog/global-import-dialog.component';
import {ProjectMainTabOptionsPaths} from '../project-main-tab-options/project-main-tab-options.component';
import {StorageConfigService} from '../../../../shared/services/storage-config.service';
import {ExportLogDialogComponent} from '../../dialogs/export-log-dialog/export-log-dialog.component';

class ProjectMainTabCheckPaths {
  updateProjectName = new CheckPath();
  publicProject = new CheckPath();
  restartEngine = new CheckPath();
  deleteProject = new CheckPath();
  updateDescription = new CheckPath();
}

@Component({
  selector: 'app-project-main',
  templateUrl: './project-main.component.html',
  styleUrls: ['./project-main.component.css']
})
export class ProjectMainComponent implements OnInit {

  error = undefined;
  showErrorAlert = false;

  checkPaths: ProjectMainTabCheckPaths;

  params: ProjectPageMainTabParams;
  @Output('paramsChange') paramsChangeEmitter = new EventEmitter<ProjectPageMainTabParams>();
  public storagePassword: string;
  public storagePasswordFetched = false;
  userProject: UserProject = new UserProject();
  publicProjectCheckbox = false;
  @Output('projectChange') projectChange = new EventEmitter<Project>();
  engineStatusSpinner = false;
  restartEngineSpinner = false;
  engineStatus: EngineStatus = EngineStatus.REMOVED;

  constructor(
    private userService: UserService,
    public tokensStorage: MaxtafTokensStorageService,
    public tokensService: MaxtafTokensStorageService,
    public remoteFilesService: RemoteFilesService,
    private changeDetector: ChangeDetectorRef,
    public projectService: ProjectService,
    private userProjectConfigService: UserProjectConfigService,
    public dialog: MatDialog,
    private router: Router,
    private storageConfigService: StorageConfigService,
    public checkRolesService: CheckRolesService,
    private globalEventsManagerService: GlobalEventsManagerService,
    private snackBar: MatSnackBar
  ) {
    this.refreshEngineStatus();//???
    this.userProject = this.userService.getCurrentUserProject();

    this.checkPaths = this.getProjectMainTabCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );
  }

  @Input('params') set setParams(params: ProjectPageMainTabParams) {
    this.params = params;
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.globalEventsManagerService.engineIndicatorEmitter.subscribe((mode) => {
      this.engineStatus = mode;
    });
  }

  projectName() {
    const userProject: UserProject = this.userService.getCurrentUserProject();
    if (userProject == null || this.tokensStorage.getProjectId() == undefined) {
      return '';
    } else {
      return userProject.project.name;
    }
  }

  getStorageAddress(): string {
    if (window.location.hostname === 'localhost' || window.location.hostname === 'test17.maxtaf.com' || window.location.hostname === 'test18.maxtaf.com') {
      return 'dev.storage.maxtaf.com';
    } else if (window.location.hostname === 'beta.maxtaf.com') {
      return 'beta.storage.maxtaf.com';
    } else {
      throw new Error('Wrong hostname: ' + window.location.hostname + '! It can be beta.maxtaf.com, test17.maxtaf.com or test18.maxtaf.com only');
    }
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(SecureConfirmationDialogComponent, {
      width: '500px',
      data: {
        projectId: this.tokensStorage.getProjectId(),
        title: 'Are you sure you want to delete the project: ' + this.projectName() + ' (' + this.tokensStorage.getProjectId() + ')?',
        body: 'All of your data bound to that project will be deleted and this will be irreversible!',
        submitButtonText: 'Delete'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteProject();
      }
    });
  }

  deleteProject() {
    this.projectService.deleteProject(this.tokensStorage.getProjectId()).subscribe(
      data => {
        this.router.navigate(['/selectProject']);
      }, error => {
        this.showError(error);
      }
    );
  }

  checkedPublicProjectCheckbox(event) {
    event.preventDefault();
    if (!this.userProject.user.enabledPrivateProjects) {
      return;
    }

    if (!this.userProject.project.publicProject) {
      this.openMakeProjectPublicDialog();
    } else {
      this.openMakeProjectPrivateDialog();
    }
  }

  openMakeProjectPublicDialog() {
    const dialogRef = this.dialog.open(SecureConfirmationDialogComponent, {
      width: '500px',
      data: {
        projectId: this.tokensStorage.getProjectId(),
        title: 'Are you sure you want to make the project: ' + this.projectName() + ' (' + this.tokensStorage.getProjectId() + ')' + ' public?',
        body: 'Everybody will be able to see the project!',
        submitButtonText: 'Make public'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.makeProjectPublicOrPrivate();
      }
    });
  }

  openMakeProjectPrivateDialog() {
    const dialogRef = this.dialog.open(SecureConfirmationDialogComponent, {
      width: '500px',
      data: {
        projectId: this.tokensStorage.getProjectId(),
        title: 'Are you sure you want to make the project: ' + this.projectName() + ' (' + this.tokensStorage.getProjectId() + ')' + ' private?',
        body: 'All your public links will expire and nobody except project members will be able to see the project!',
        submitButtonText: 'Make private'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.makeProjectPublicOrPrivate();
      }
    });
  }

  makeProjectPublicOrPrivate() {
    const project = this.createProjectObject();
    project.publicProject = !project.publicProject;
    this.projectService.updateProject(this.tokensStorage.getProjectId(), project).subscribe(
      result => {
        this.userProject.project.publicProject = result.publicProject;
      }, error => {
        this.showError(error);
      }
    );
  }

  createProjectObject(): Project {
    const project = new Project();
    project.name = this.userProject.project.name;
    project.description = this.userProject.project.description;
    project.publicProject = this.userProject.project.publicProject;
    return project;
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  hideError() {
    this.error = undefined;
    this.showErrorAlert = false;
  }

  replaceStringWithNewLines(str: string): string {
    if (str == undefined) {
      return undefined;
    }
    str = str.replace(/_/g, '<wbr>_');
    str = str.replace(/-/g, '<wbr>-');
    str = str.replace(/ /g, '<wbr> ');
    return str;
  }

  openEditDialog(type, name, nameField) {

    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '700px',
      data: {type, name, value: this.userProject.project[nameField]}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return;
      }

      this.hideError();

      const changedProject = {...this.userProject.project};

      changedProject[nameField] = result;

      const body = {
        id: undefined
      };
      Object.assign(body, changedProject);
      body.id = 0;

      this.updateProject(this.userProject.project.id, body);
    });
  }

  updateProject(projectId: string, body) {
    this.projectService.updateProject(projectId, body)
      .subscribe(
        res => {
          this.userProject.project = res;
          this.emitCaseChange();
          this.openSnackBar('Project is updated', 'OK', 5);
        },
        error => {
          console.error(error);
          this.showError(error);
        }
      );
  }

  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }

  emitCaseChange() {
    this.projectChange.emit(this.userProject.project);
  }

  refreshEngineStatus() {
    this.engineStatusSpinner = true;

    this.projectService.getEngineStatus(this.tokensService.getProjectId()).subscribe(
      (engineStatus: EngineStatus) => {
        this.engineStatus = engineStatus;
        this.engineStatusSpinner = false;
        this.globalEventsManagerService.setEngine(this.engineStatus);
      },
      error => {
        console.error('error: ', error);
        this.error.emit(error);
        this.engineStatusSpinner = false;
      }
    );
  }

  restartEngine() {
    if (this.restartEngineSpinner) {
      return;
    }
    this.restartEngineSpinner = true;

    this.projectService.restartEngine(this.tokensService.getProjectId()).subscribe(
      () => {
        this.projectService.getEngineStatus(this.tokensService.getProjectId()).subscribe(
          engineValue => {
            this.openSnackBar('Engine restarted successfully', 'OK', 2);
            this.restartEngineSpinner = false;
          },
          error => {
            console.error('error: ', error);
            this.error.emit(error);
            this.restartEngineSpinner = false;
          }
        );
      },
      error => {
        console.error('error: ', error);
        this.error.emit(error);
        this.restartEngineSpinner = false;
      }
    );
  }

  exportProject() {
    const dialogRef = this.dialog.open(ExportProjectDialogComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  importProject() {
    const dialogRef = this.dialog.open(GlobalImportDialogComponent, {
      width: '600px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getProjectMainTabOptionsCheckPathObject(): ProjectMainTabOptionsPaths {
    return {
      export: new CheckPath('GET', this.storageConfigService.globalExport()),
      import: new CheckPath('POST', this.storageConfigService.globalImport()),
      restartEngine: new CheckPath('POST', this.userProjectConfigService.startEngineOrResetShutdownTimer())
    };
  }

  unauthorizedRequests() {
    const dialogRef = this.dialog.open(ExportLogDialogComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private getProjectMainTabCheckPaths(): ProjectMainTabCheckPaths {
    return {
      updateProjectName: new CheckPath('PUT', this.userProjectConfigService.updateProject()),
      publicProject: new CheckPath('PUT', this.userProjectConfigService.updateProject()),
      restartEngine: new CheckPath('POST', this.userProjectConfigService.startEngineOrResetShutdownTimer()),
      deleteProject: new CheckPath('DELETE', this.userProjectConfigService.deleteProject()),
      updateDescription: new CheckPath('PUT', this.userProjectConfigService.updateProject())
    };
  }
}
