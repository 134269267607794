import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {MaxtafTokensStorageService} from './maxtaf-tokens-storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageConfigService {

  constructor(
    private configService: ConfigService,
    private tokenService: MaxtafTokensStorageService
  ) {
  }

  private API_URL = this.configService.getHostURL() + this.configService.getPrefix() + '/storage';

  private FILES_PATH = 'files';
  private SAVE_RUN_SCREENSHOT_PATH = 'saveRunScreenshot';
  private SAVE_RUN_VIDEO_PATH = 'saveRunVideo';
  private FILE_COPY_PATH = 'copy';
  private FOLDERS_PATH = 'folders';
  private FILE_NAME_PATH = 'names';
  private FILE_NODE_PATH = 'nodes';
  private GIT_CLONE_PATH = 'clone';
  private UPLOAD_PATH = 'upload';
  private DOWNLOAD_PATH = 'download';
  private PROJECT_PATH = 'project';
  private PROJECT_PASSWORD_PATH = 'password';
  private PROJECT_PASSWORD_AS_JSON_PATH = 'passwordAsJSON';
  private UPDATE_ALL_PROJECT_STORAGES_PATH = 'updateAllProjectStorages';
  private COPY_MXML_FILES_IF_LICENSED_PATH = 'copyMxmlFilesIfLicensed';

  private FOLDERS_URL = this.API_URL + '/' + this.FOLDERS_PATH;
  private FILES_URL = this.API_URL + '/' + this.FILES_PATH;
  private FILES_IMAGE_URL = this.FILES_URL + '/' + 'image';
  private FILES_VIDEO_URL = this.FILES_URL + '/' + 'video';
  private FILES_HTML_URL = this.FILES_URL + '/' + 'html';
  private SAVE_RUN_VIDEO_URL = this.API_URL + '/' + this.SAVE_RUN_VIDEO_PATH;
  private SAVE_RUN_SCREENSHOT_URL = this.API_URL + '/' + this.SAVE_RUN_SCREENSHOT_PATH;
  private DOWNLOAD_FILES_URL = this.API_URL + '/' + this.FILES_PATH + '/' + this.DOWNLOAD_PATH;
  private UPLOAD_URL = this.API_URL + '/' + this.FILES_PATH + '/' + this.UPLOAD_PATH;
  private FILE_COPY_URL = this.API_URL + '/' + this.FILES_PATH + '/' + this.FILE_COPY_PATH;
  private FILE_TRANSFER_URL = this.API_URL + '/' + this.FILES_PATH + '/transfer';
  private FILE_NAME_URL = this.API_URL + '/' + this.FILES_PATH + '/' + this.FILE_NAME_PATH;
  private FILES_ZIP_URL = this.API_URL + '/' + this.FILES_PATH + '/' + 'zip';
  private FILES_UNZIP_URL = this.API_URL + '/' + this.FILES_PATH + '/' + 'unzip';
  private FILES_RENAME_URL = this.API_URL + '/' + this.FILES_PATH + '/' + 'rename';
  private GIT_CLONE_URL = this.API_URL + '/' + this.FILES_PATH + '/' + this.GIT_CLONE_PATH;
  private FILE_NODES_URL = this.API_URL + '/' + this.FILES_PATH + '/' + this.FILE_NODE_PATH;
  private PROJECT_URL = this.API_URL + '/' + this.PROJECT_PATH;
  private PROJECT_PASSWORD_URL = this.PROJECT_URL + '/' + this.PROJECT_PASSWORD_PATH;
  private PROJECT_PASSWORD_AS_JSON_URL = this.PROJECT_URL + '/' + this.PROJECT_PASSWORD_AS_JSON_PATH;
  private UPDATE_ALL_PROJECT_STORAGES_URL = this.PROJECT_URL + '/' + this.UPDATE_ALL_PROJECT_STORAGES_PATH;
  private COPY_MXML_FILES_IF_LICENSED_URL = this.PROJECT_URL + '/' + this.COPY_MXML_FILES_IF_LICENSED_PATH;
  private GLOBAL_EXPORT_URL = this.API_URL + '/' + 'project' + '/' + 'global' + '/' + 'export';
  private GLOBAL_IMPORT_URL = this.API_URL + '/' + 'project' + '/' + 'global' + '/' + 'import';

  public getFileNames() {
    return this.FILE_NAME_URL;
  }

  public globalExport() {
    return this.GLOBAL_EXPORT_URL;
  }

  public globalImport() {
    return this.GLOBAL_IMPORT_URL;
  }

  public globalImportInfo(id) {
    return this.API_URL + '/import/info/project/' + id;
  }

  public deleteFile(pathWithName?: string) {
    return this.FILES_URL + '?pathWithName=' + pathWithName;
  }

  public createFile(pathWithName?: string, overwrite: boolean = false) {
    return this.FILES_URL + '?pathWithName=' + pathWithName + '&overwrite=' + overwrite;
  }

  public unzipFile(pathWithZipName?: string, unzipLocation?: string, overwrite: boolean = false) {
    return this.FILES_UNZIP_URL + '?pathWithName=' + pathWithZipName + '&overwrite=' + overwrite + (unzipLocation == undefined ? '' : '&unzipLocation=' + unzipLocation);
  }

  public zipFile(zipPath?: string, pathWithName?: string) {
    return this.FILES_ZIP_URL + '?pathsWithNames=' + pathWithName + '&zipPath=' + zipPath;
  }

  public updateFile(pathWithName?: string) {
    return this.FILES_URL + '?pathWithName=' + pathWithName;
  }

  public getFile(pathWithName?: string, linkWithCase: boolean = false, projectId?) {
    return this.FILES_URL + '?pathWithName=' + pathWithName + '&linkWithCase=' + linkWithCase + ((projectId == undefined) ? '' : '&' + this.tokenService.PROJECT_TOKEN + '=' + projectId);
  }

  copyOrMoveFile(
    copyFrom?: string,
    copyFromProjectId?: string,
    copyTo?: string,
    move?: boolean,
    overwrite?: boolean
  ) {
    return this.FILE_COPY_URL + '?copyFrom=' + copyFrom + '&copyFromProjectId=' + copyFromProjectId + '&copyTo=' + copyTo + '&move=' + move + '&overwrite=' + overwrite;
  }

  transferFile(
    copyFrom?: string,
    copyFromProjectId?: string,
    copyTo?: string,
    move?: boolean,
    overwrite?: boolean
  ) {
    return this.FILE_TRANSFER_URL + '?copyFrom=' + copyFrom + '&copyFromProjectId=' + copyFromProjectId + '&copyTo=' + copyTo + '&move=' + move + '&overwrite=' + overwrite;
  }

  renameFile(
    path?: string,
    oldName?: string,
    newName?: string,
    overwrite?: boolean
  ) {
    return this.FILES_RENAME_URL + '?path=' + path + '&oldName=' + oldName + '&newName=' + newName + '&overwrite=' + overwrite;
  }

  public createFolder(path?: string, folderName?: string, overwrite: boolean = false) {
    return this.FOLDERS_URL + '?path=' + path + '&folderName=' + folderName + '&overwrite=' + overwrite;
  }

  public cloneGitProject() {
    return this.GIT_CLONE_URL;
  }

  public uploadFile(queryParams?) {
    return this.UPLOAD_URL + '?' + queryParams;
  }

  public downloadFile(requestParameters?) {
    return this.DOWNLOAD_FILES_URL + (requestParameters != undefined ? requestParameters : '');
  }

  public getMaxtafFileNodes() {
    return this.FILE_NODES_URL;
  }

  public getStoragePasswordAsJSON() {
    return this.PROJECT_PASSWORD_AS_JSON_URL;
  }

  public getImageFile(pathWithName: string) {
    return this.FILES_IMAGE_URL + '?pathWithName=' + pathWithName;
  }

  public getVideoFile(pathWithName: string) {
    return this.FILES_VIDEO_URL + '?pathWithName=' + pathWithName + '&X-Firebase-Auth=' + this.tokenService.getFireToken() + '&X-Firebase-Refresh-Token=' + this.tokenService.getFireRefreshToken() + '&X-Project-Id=' + this.tokenService.getProjectId() + '&X-User-TimeZone-Id=' + Intl.DateTimeFormat().resolvedOptions().timeZone + '&v=' + (new Date()).getTime();
  }

  public getHtmlFile(pathWithName: string) {
    return this.FILES_HTML_URL + '?pathWithName=' + pathWithName + '&X-Firebase-Auth=' + this.tokenService.getFireToken() + '&X-Firebase-Refresh-Token=' + this.tokenService.getFireRefreshToken() + '&X-Project-Id=' + this.tokenService.getProjectId() + '&X-User-TimeZone-Id=' + Intl.DateTimeFormat().resolvedOptions().timeZone + '&v=' + (new Date()).getTime();
  }

}
