<h1 mat-dialog-title>Api key</h1>

<div mat-dialog-content>
  <div #message class="fs-12 alert alert-success alert-dismissible fade show" role="alert">
    <p>
      Success! Your API key has been created. Please make sure to copy and save it in a secure place immediately. We
      will not display this key again due to security purposes. If lost or forgotten, you will need to create a new one.
    </p>
    <p>
      Remember, your API key communicates a secure access between your application and our service. You need to include
      this key in the 'Authorization: Bearer {{apiKey}}' header when making HTTP requests to our API. Please assure to
      keep the key confidential and avoid sharing it with unauthorized individuals.
    </p>
    <p>
      Keep in mind not to expose your API key in publicly accessible areas such as GitHub, client-side code, etc.
    </p>
  </div>

  <div *ngIf="apiKey != undefined && apiKey != ''" class="fs-12 alert alert-secondary show">
    <div style="width:1px"></div>
    <div style="    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;">
      {{apiKey}}
      <div style="max-width: 80px">
        <button (click)="copyLogToClipboard(); $event.stopPropagation()" *ngIf="apiKey != undefined && apiKey != ''" [autofocus]="false" class="pl-0 pr-0"
                color="primary"
                mat-icon-button>
          <mx-icon mx="copy" size="small"></mx-icon>
        </button>
        <button (click)="downloadApiKey(); $event.stopPropagation()" *ngIf="apiKey != undefined && apiKey != ''" [autofocus]="false" class="pl-0 pr-0"
                color="primary"
                mat-icon-button>
          <mx-icon mx="download" size="small"></mx-icon>
        </button>

      </div>

    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" [mat-dialog-close]="false"
          mat-button>Close
  </button>
</div>

