import {Component, Inject, OnInit} from '@angular/core';
import {MaxtafFile} from "../../shared/models/maxtafFile";
import {MaxtafFileNode} from "../nav-workspace/shared/models/MaxtafFileNode";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LspDefinitionResult} from "../../shared/models/lspDefinitionResult";

@Component({
  selector: 'app-monaco-editor-lsp-reference-list',
  templateUrl: './monaco-editor-lsp-reference-list.component.html',
  styleUrls: ['./monaco-editor-lsp-reference-list.component.css']
})
export class MonacoEditorLspReferenceListComponent implements OnInit {


  file: MaxtafFile = new MaxtafFile();

  isCopy = true;
  copyNode: MaxtafFileNode;
  pasteNode: MaxtafFileNode;

  pastePath = '';
  pasteFileName = '';

  showErrorAlert = false;
  error;

  overwrite = false;
  spinner = false;

  list: LspDefinitionResult[];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MonacoEditorLspReferenceListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      list: LspDefinitionResult[],
    }
  ) {

    if (data.list != undefined) {
      this.list = data.list;
    }

    console.log('3313 this.list: ', this.list);
    console.log('3313 data.list: ', data.list);
  }

  ngOnInit(): void {

  }


  startLoadingSpinner() {
    this.spinner = true;
  }

  stopLoadingSpinner() {
    this.spinner = false;
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  closeDialog(item: LspDefinitionResult) {
    this.dialogRef.close(item);
  }
}
