import {Component, OnInit} from '@angular/core';
import {MxFilter} from '../../../mx/filter/MxFilter';

@Component({
  selector: 'app-compile-info-case-info-filter',
  templateUrl: './compile-info-case-info-filter.component.html',
  styleUrls: ['./compile-info-case-info-filter.component.css']
})
export class CompileInfoCaseInfoFilterComponent extends MxFilter implements OnInit {

  search: any;

  constructor() {
    super();
  }

  ngOnInit(): void {

  }

  resetForm() {

  }


  searchDefaultValues() {
    return {
      caseName: '',
      errorMessage: '',
      exactSearch: 'Contains',
    };
  }


  clearCompileFilter() {
    this.resetForm();
    this.clearFilter();
  }


}
