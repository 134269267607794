import {Injectable} from '@angular/core';
import {PageParams} from '../../../mx/util/params/PageParams';
import {SearchParam} from '../../../mx/util/params/searchParam';

@Injectable({
  providedIn: 'root'
})
export class CompileInfoConstService {

  constructor() {

  }

  public static initCompileInfoDialogParams(): CompileInfoConstDialogParams {
    return {
      page: CompileInfoConstService.getDefaultPageParams(),
      search: CompileInfoConstService.getDefaultSearchParams(),
    };
  }

  public static getDefaultSearchParams(): CompileInfoConstParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      startDate: new SearchParam(null, null),
      endDate: new SearchParam(null, null),
      completed: new SearchParam(null, null),
      errors: new SearchParam(null, null),
    };
  }

  public static getDefaultPageParams(size = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('createdDate,desc', 'createdDate,desc')
    };
  }

}

export class CompileInfoConstDialogParams {
  page: PageParams;
  search: CompileInfoConstParams;
}

export class CompileInfoConstParams {
  exactSearch: SearchParam;
  startDate: SearchParam;
  endDate: SearchParam;
  completed: SearchParam;
  errors: SearchParam;
}
