import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CompileService} from '../../shared/services/compile.service';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CompileInfoOptionsPaths} from '../compile-info-options/compile-info-options.component';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {
  CompileInfoConstDialogParams,
  CompileInfoConstParams,
  CompileInfoConstService
} from './compile-info-const.service';
import {CompileInfo} from '../../shared/models/compileInfo';
import {ClickedTableItem} from '../../../mx/util/list/ClickedTableItem';
import {PageParams} from '../../../mx/util/params/PageParams';
import {PageInfo} from '../../../../shared/models/pageInfo';
import {CompileInfoListComponent} from '../compile-info-list/compile-info-list.component';
import {CompileInfoDeleteDialogComponent} from '../compile-info-delete-dialog/compile-info-delete-dialog.component';
import {CompileInfoDetailsDialogComponent} from '../compile-info-details-dilaog/compile-info-details-dialog.component';

@Component({
  selector: 'app-compile-info-dialog',
  templateUrl: './compile-info-dialog.component.html',
  styleUrls: ['./compile-info-dialog.component.css']
})
export class CompileInfoDialogComponent implements OnInit {
  pagesInfo: PageInfo = new PageInfo();
  compileInfos: CompileInfo[] = [];
  @ViewChild(CompileInfoListComponent) compileInfoList: CompileInfoListComponent;
  params: CompileInfoConstDialogParams;

  constructor(
    public dialogRef: MatDialogRef<CompileInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialog: MatDialog,
    private cloudConfigService: CloudConfigService,
    private compileService: CompileService
  ) {
    this.params = CompileInfoConstService.initCompileInfoDialogParams();
  }

  ngOnInit(): void {
  }

  getCompileInfoListOptionsCheckPathObject(): CompileInfoOptionsPaths {
    return {
      refresh: new CheckPath('GET', this.cloudConfigService.compileCasesInfos()),
      delete: new CheckPath('DELETE', this.cloudConfigService.compileCasesInfo())
    };
  }

  filter(compileInfoConstParams: CompileInfoConstParams) {
    this.compileInfoList.getItems();
  }

  refreshCompileInfo() {
    this.compileInfoList.getItems();
  }

  deleteCompileInfo() {
    const dialogRef = this.dialog.open(CompileInfoDeleteDialogComponent, {
      width: '400px',
      data: {
        params: this.params,
        selected: this.compileInfos,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // if (result) {
      this.refreshCompileInfo();
      // }
    });

  }

  selected(compileInfos: CompileInfo[]) {
    this.compileInfos = compileInfos.slice();
  }

  clickedCompileInfo(compileInfo: ClickedTableItem<CompileInfo>) {

    const dialogRef = this.dialog.open(CompileInfoDetailsDialogComponent, {
      width: '600px',
      panelClass: 'custom-dialog-container',
      data: {
        compileInfo: compileInfo.item
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });

  }

  setPageInfoChanges(pagesInfo: PageInfo) {
    this.pagesInfo = pagesInfo;
  }

  pageParamsChange(pageParams: PageParams) {
    this.params.page = pageParams;
  }

  private showError(error: any) {
    console.error(error);
  }
}
