import {Component, OnInit} from '@angular/core';
import {MxFilter} from '../../../mx/filter/MxFilter';

@Component({
  selector: 'app-project-params-filter',
  templateUrl: './project-params-filter.component.html',
  styleUrls: ['./project-params-filter.component.css']
})
export class ProjectParamsFilterComponent extends MxFilter implements OnInit {

  search: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  searchDefaultValues() {
    return {
      name: '',
      value: '',
      exactSearch: 'Contains',
    };
  }

}
