import {filter, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {serialize} from '../utilities/serialize';

export enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH'
}

@Injectable()
export class ApiService {

  headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient
  ) {
  }

  get(path: string, args?: any, textAsJson?: boolean): Observable<any> {
    let options;
    if (textAsJson && textAsJson === true) {
      options = {
        headers: this.headers,
        withCredentials: true,
        responseType: 'text' as 'json',
      };
    } else {
      options = {
        headers: this.headers,
        withCredentials: true
      };
    }

    if (args) {
      options.params = serialize(args);
    }

    return this.http.get(path, options);
  }

  post(path: string, body: any, customHeaders?: HttpHeaders): Observable<any> {
    return this.request(path, body, RequestMethod.Post, customHeaders);
  }

  put(path: string, body: any): Observable<any> {
    return this.request(path, body, RequestMethod.Put);
  }

  patch(path: string, body: any): Observable<any> {
    return this.request(path, body, RequestMethod.Patch);
  }

  delete(path: string, body?: any): Observable<any> {
    return this.request(path, body, RequestMethod.Delete);
  }

  private request(path: string, body: any, method = RequestMethod.Post, customHeaders?: HttpHeaders): Observable<any> {
    const req = new HttpRequest(method, path, body, {
      headers: customHeaders || this.headers,
      reportProgress: true,
      withCredentials: true
    });

    return this.http.request(req).pipe(
      filter(response => response instanceof HttpResponse),
      map((response: HttpResponse<any>) => response.body)
    );
  }

}
