import {Component, Inject, Input, OnInit} from '@angular/core';
import {CheckPath} from "../../../mx/options/CheckPath";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CloudConfigService} from "../../../../shared/services/cloud-config.service";
import {CheckRolesService} from "../../../../shared/services/check-roles.service";
import {ProjectAIParam} from "../../../ai/shared/models/projectAIParam";
import {AiChatService} from "../../../ai/shared/services/ai-chat.service";

class ProjectAIParamCheckPaths {
  update = new CheckPath();
}

@Component({
  selector: 'app-add-project-aiparam-dialog',
  templateUrl: './add-project-aiparam-dialog.component.html',
  styleUrls: ['./add-project-aiparam-dialog.component.css']
})
export class AddProjectAIParamDialogComponent implements OnInit {

  checkPaths: ProjectAIParamCheckPaths;


  @Input('projectParam') projectParam: ProjectAIParam;
  create = true;
  title = '';
  createOkButton: string;
  backupProjectParams: ProjectAIParam;

  errorMessage = '';
  error = false;
  run = false;
  allowEdit = true;

  constructor(
    public dialogRef: MatDialogRef<AddProjectAIParamDialogComponent>,
    private aiChatService: AiChatService,
    private cloudConfigService: CloudConfigService,
    public checkRolesService: CheckRolesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      projectParam: ProjectAIParam,
      edit: boolean,
      title: string,
    }
  ) {
    if (data.projectParam == undefined) {
      this.create = true;
      this.projectParam = new ProjectAIParam();
      this.title = 'Add parameter';
      this.createOkButton = 'Add';
    } else {
      this.create = false;
      this.projectParam = data.projectParam;
      this.backupProjectParams = new ProjectAIParam();
      this.backupProjectParams.name = data.projectParam.name;
      this.backupProjectParams.value = data.projectParam.value;
      this.title = 'Edit parameter';
      this.createOkButton = 'Save';
    }

    if (data.edit != undefined) {
      this.allowEdit = data.edit;
    }


    if (data.title != undefined) {
      this.title = data.title;
    }

    this.checkPaths = this.getProjectParamCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.addShortcutListener, false);
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for adding a parameter or entering a value after name
  addShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      if (this.projectParam.name && !(this.projectParam.value)) {
        document.getElementById('valueInput').focus();
      } else if (this.projectParam.name && this.projectParam.value) {
        document.getElementById('createButton').click();
      }
    }
  };

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.addShortcutListener, false);
  }

  // add() {
  //   this.addProjectParams();
  // }

  edit() {
    // if(this.configuration) {
    //   this.editConfigurationParams();
    // } else {
    //   this.editProjectParams();
    // }

    this.error = false;
    this.aiChatService.updateProjectAiConfig(this.projectParam)
      .subscribe(
        res => {
          this.error = false;
          this.closeDialog(false);
        },
        error => {
          console.error(error);
          this.errorMessage = error.error.errorMessage;
          this.error = true;
          // this.closeDialog(false);
        }
      );
  }

  close() {
    if (!this.create) {
      this.projectParam.name = this.backupProjectParams.name;
      this.projectParam.value = this.backupProjectParams.value;
    }
    this.closeDialog(false);
  }


  closeDialog(res): void {
    this.dialogRef.close(res);
  }

  private getProjectParamCheckPaths(): ProjectAIParamCheckPaths {
    return {
      update: new CheckPath('PUT', this.cloudConfigService.setProjectParams())
    };
  }

}
