import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {IconModule} from '../../../mx/icon/icon.module';
import {CommonModule} from '@angular/common';
import {FilterCaseComponent} from './filter-case.component';

@NgModule({
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    IconModule,
    CommonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    IconModule,
    CommonModule,
  ],
  declarations: [
    FilterCaseComponent
  ],
  exports: [
    FilterCaseComponent
  ]
})

export class FilterCaseModule {
}
