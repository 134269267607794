import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RemoteFilesService} from '../../shared/services/remote-files.service';
import {MaxtafFile} from '../../shared/models/maxtafFile';

@Component({
  selector: 'app-create-new-file',
  templateUrl: './create-new-file.component.html',
  styleUrls: ['./create-new-file.component.css']
})
export class CreateNewFileComponent implements OnInit, OnDestroy {

  file: MaxtafFile = new MaxtafFile();

  name = '';
  path = '/';
  header = 'Add file';

  hidePath = false;

  showErrorAlert = false;
  error;

  overwrite = false;
  spinner = false;

  constructor(
    public dialog: MatDialog,
    public storageService: RemoteFilesService,
    public dialogRef: MatDialogRef<CreateNewFileComponent>,
    private remoteFileService: RemoteFilesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      name: string,
      path: string,

      header: string,

      hidePath: boolean
    }
  ) {
    if (data.name != undefined) {
      this.name = data.name;
    }

    if (data.path != undefined) {
      this.path = data.path;
    }

    if (data.header != undefined) {
      this.header = data.header;
    }

    if (data.hidePath != undefined) {
      this.hidePath = data.hidePath;
    }

  }


  create() {
    this.createFileRequest();
  }

  createFileRequest() {
    const filePath = this.path + this.name;
    this.startLoadingSpinner();
    this.remoteFileService.createFile(filePath, this.overwrite)
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          this.dialogRef.close(filePath);
        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );
  }

  startLoadingSpinner() {
    this.spinner = true;
  }

  stopLoadingSpinner() {
    this.spinner = false;
  }


  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for creating the test
  createShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('createButton').click();
    }
  };


  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }


  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.createShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.createShortcutListener, false);
  }
}
