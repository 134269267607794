import {PageInfo} from '../../../../../../shared/models/pageInfo';

export class MaxtafFileNode {

  public childrenPageInfo: PageInfo;
  public selectLine: number;

  constructor(public name: string,
              public path: string,
              public pathWithName: string,
              public typeOfFile: string,
              public level = 1,
              public isFolder = false,
              public isLoading = false,
              public projectId: string,
              pageableResult?
  ) {
    this.setPageInfo(pageableResult);
  }

  setPageInfo(pageableResult) {
    if (pageableResult != undefined) {
      this.childrenPageInfo = new PageInfo();
    } else {
      this.childrenPageInfo = undefined;
      return;
    }
    this.childrenPageInfo.isFirstPage = pageableResult.first;
    this.childrenPageInfo.isLastPage = pageableResult.last;
    this.childrenPageInfo.totalElements = pageableResult.totalElements;
    this.childrenPageInfo.totalPages = pageableResult.totalPages;
    this.childrenPageInfo.pageIndex = pageableResult.number + 1;
    this.childrenPageInfo.maxElementsOnPage = pageableResult.size;
    this.childrenPageInfo.sizeOfElementsOnPage = pageableResult.numberOfElements;
  }
}
