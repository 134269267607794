import {NgModule} from '@angular/core';
import {ProjectParamsListComponent} from './project-params-list.component';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {SpinnerForTableModule} from '../../../../shared/util-components/spinner-for-table/spinner-for-table.module';
import {ProjectParamsFilterModule} from '../project-params-filter/project-params-filter.module';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import {ExportProjectParamsModule} from '../../dialogs/export-project-params/export-project-params.module';
import {ProjectParamsOptionsModule} from '../project-params-options/project-params-options.module';
import {IconModule} from '../../../mx/icon/icon.module';
import {MatSortModule} from '@angular/material/sort';
import {DeleteDialogModule} from '../../../dialogs/delete-dialog/delete-dialog.module';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  imports: [
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    ProjectParamsFilterModule,
    SpinnerForTableModule,
    MatMenuModule,
    CommonModule,
    ExportProjectParamsModule,
    ProjectParamsOptionsModule,
    IconModule,
    MatSortModule,
    DeleteDialogModule,
    MatTooltipModule
  ],
  declarations: [
    ProjectParamsListComponent
  ],
  exports: [
    ProjectParamsListComponent
  ]
})

export class ProjectParamsListModule {
}
