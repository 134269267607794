import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from '../../layout/users/shared/services/user.service';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  canActivate(): boolean {
    return true;
  }

}
