import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MaxtafFileDetails} from '../../shared/models/maxtafFileDetails';
import {FormControl} from '@angular/forms';
import {RemoteFilesService} from '../../shared/services/remote-files.service';
import {CompileService} from '../../../cases/shared/services/compile.service';
import {MatDialog} from '@angular/material/dialog';
import {YesNoDialogComponent} from '../../../dialogs/yes-no-dialog/yes-no-dialog.component';
import {CompileTabDetails} from '../../shared/models/compileTabDetails';
import {Router} from '@angular/router';
import {MaxtafFileNode} from '../nav-workspace/shared/models/MaxtafFileNode';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {
  LocalSaveWorkspaceTabsSessionStorageService
} from '../../shared/services/local-save-workspace-tabs-session-storage.service';
import {FileTabWorkspaceComponent} from '../file-tab-workspace/file-tab-workspace.component';
import {MatTabGroup} from '@angular/material/tabs';
import {ChatSessionHelper} from "../../../../shared/models/chatSessionHelper";
import {GlobalEventsManagerService} from "../../../../shared/services/global-events-manager.service";
import {LspDefinitionResult} from "../../shared/models/lspDefinitionResult";
import {UserService} from "../../../users/shared/services/user.service";

@Component({
  selector: 'app-tabs-workspace',
  templateUrl: './tabs-workspace.component.html',
  styleUrls: ['./tabs-workspace.component.css']
})
export class TabsWorkspaceComponent implements OnInit, OnDestroy, AfterViewInit {

  showAlertError = false;
  error;

  tabs: MaxtafFileDetails[] = [];
  selectedTab = new FormControl(0);

  tabSwitched: EventEmitter<string> = new EventEmitter();

  @Input('transfer') transfer = false;
  @ViewChild('tabGroup', {static: false}) childTabGroup!: MatTabGroup;
  @ViewChild('fileTabWorkspace') fileTabWorkspaceComponent: FileTabWorkspaceComponent;
  projectId;
  minusIndex = 0;
  blockRefresh = false;

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private compileService: CompileService,
    private router: Router,
    private tokenService: MaxtafTokensStorageService,
    private globalEventsManager: GlobalEventsManagerService,
    private remoteFileService: RemoteFilesService,
    private tabsSessionStorageService: LocalSaveWorkspaceTabsSessionStorageService,
  ) {
    this.projectId = tokenService.getProjectId();

    this.tabs = tabsSessionStorageService.getTabs();

    this.tabs.forEach((tab, index) => {
      this.refreshTab(tab, index);
    });

    this.setSelectedTabIndex(this.tabsSessionStorageService.getSelectedTab());
    try {
      if (
        this.tabs != null &&
        this.tabs.length > 0 &&
        this.selectedTab.value != null &&
        this.tabs[this.selectedTab.value] != null &&
        this.tabs[this.selectedTab.value].details != null &&
        this.tabs[this.selectedTab.value].details.testCase != null &&
        this.tabs[this.selectedTab.value].details.testCase.id != null
      ) {
        this.globalEventsManager.setRelatedSession(new ChatSessionHelper(this.tabs[this.selectedTab.value].details.testCase.id, this.tabs[this.selectedTab.value].details.testCase.name));
      }
    } catch (e) {
      console.error(e);
    }

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  @Input('newTab') set newTab(node: MaxtafFileNode) {
    this.addNewTab(node);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.tabsSessionStorageService.saveTabs(this.tabs, this.projectId);
    this.tabsSessionStorageService.saveSelectedTab(this.selectedTab.value, this.projectId);
  }

  public addNewTab(node: MaxtafFileNode, pathWithName?: string) {
    if (node == undefined && pathWithName == undefined) {
      return;
    }

    let projectId;
    let selectLine;
    if (node != undefined) {
      pathWithName = node.pathWithName;
      projectId = node.projectId;
      selectLine = node.selectLine;
      console.log('1212 node selectLine: ', selectLine)
    } else {
      projectId = this.tokenService.getProjectId();
    }

    let selectedIndex;
    this.tabs.forEach((tab, index) => {
      if (tab.details.pathWithName == pathWithName && tab.projectId == projectId) {
        selectedIndex = index;
      }
    });
    console.log('1212 selectedIndex: ', selectedIndex);
    if (selectedIndex == undefined) {
      this.getFile(pathWithName, projectId, selectLine);
    } else {
      this.tabs[selectedIndex].selectLine = selectLine;
      this.tabsSessionStorageService.saveTabs(this.tabs);
      this.selectedTab.setValue(selectedIndex);
    }
  }

  public closeTab(pathWithName: string, projectId?: string, index?: number, isSaved?: boolean) {
    if (index == undefined && isSaved == undefined) {

      if (projectId == undefined) {
        projectId = this.tokenService.getProjectId();
      }

      this.tabs.forEach(function (tab, index, object) {
        if (tab.details != undefined && tab.details.pathWithName == pathWithName && tab.projectId == projectId) {
          object.splice(index, 1);
        }
      });
      this.tabsSessionStorageService.saveTabs(this.tabs);
      return;
    } else {
      if (isSaved) {
        this.removeTabFromArray(index);
      } else {
        const dialogRef = this.dialog.open(YesNoDialogComponent, {
          width: '400px',
          data: {
            body: '',
            label1: 'Continue anyway',
            label2: 'Cancel',
            title: 'You have unsaved changes! If you leave, your changes may be lost.',
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.removeTabFromArray(index);
          }
        });
      }
    }
  }

  removeTabFromArray(index: number) {
    this.tabs.splice(index, 1);
    this.tabsSessionStorageService.saveTabs(this.tabs);

    if (this.tabs.length == 0) {
      try {
        this.globalEventsManager.setRelatedSession(new ChatSessionHelper(null, null));
      } catch (e) {
        console.error(e);
      }
    }
  }

  onSelectedIndexChange(index: any) {
    this.selectedTab.setValue(index);
    // this.showReloadOrKeepDialog(this.tabs[this.selectedTab.value]);
    if (this.fileTabWorkspaceComponent != undefined && this.fileTabWorkspaceComponent.caseCodeOptionsComponent != undefined) {
      //
      //   setTimeout(() => {
      this.fileTabWorkspaceComponent.caseCodeOptionsComponent.dragEnd();
      //   }, 3000);
    } else {
    }
  }

  public refreshTabWorkspaceEditors(matTabChangeEvent) {
    this.selectedTab.setValue(matTabChangeEvent.index);
    this.tabSwitched.emit();

    try {
      if (
        this.tabs != null &&
        this.tabs.length > 0 &&
        this.selectedTab.value != null &&
        this.tabs[this.selectedTab.value] != null &&
        this.tabs[this.selectedTab.value].details != null &&
        this.tabs[this.selectedTab.value].details.testCase != null &&
        this.tabs[this.selectedTab.value].details.testCase.id != null
      ) {
        this.globalEventsManager.setRelatedSession(new ChatSessionHelper(this.tabs[this.selectedTab.value].details.testCase.id, this.tabs[this.selectedTab.value].details.testCase.name));
      }
    } catch (e) {
      console.error(e);
    }
    // if (this.fileTabWorkspaceComponent != undefined && this.fileTabWorkspaceComponent.caseCodeOptionsComponent != undefined) {
    //   try {
    //     this.fileTabWorkspaceComponent.caseCodeOptionsComponent.dragEnd();
    //   } catch (e){
    //     console.error(e);
    //   }
    // }
    if (this.childTabGroup != undefined && !this.blockRefresh) {
      const index = (matTabChangeEvent.tab.__ngContext__.length - 1);
      // const index = 23;
      matTabChangeEvent.tab.__ngContext__[index].refreshFileTabEditors(true);
    }

    this.blockRefresh = false;
  }

  addTabInArray(tab: MaxtafFileDetails) {
    this.blockRefresh = true;
    this.tabs.push(tab);
    this.tabsSessionStorageService.saveTabs(this.tabs);
  }

  public hasChanges(): boolean {
    let hasChanges = false;
    this.tabs.forEach(tab => {
      if (!tab.isSaved) {
        hasChanges = true;
      }
    });
    return hasChanges;
  }

  refreshTab(tab: MaxtafFileDetails, index?: number) {
    this.remoteFileService.getFile(tab.details.pathWithName, true, tab.projectId).subscribe(
      fileDetails => {
        // tab.details = fileDetails;
        if (tab.isSaved) {
          tab.activeCode = fileDetails.content;
          tab.isSaved = true;
          this.isCompiledFile(tab);
        } else {
          if (tab.activeCode == fileDetails.content) {
            tab.isSaved = true;
            this.isCompiledFile(tab);
          } else if (tab.details.content != fileDetails.content) {
            tab.isSaved = false;
            tab.canBeReloaded = true;
          } else {
            tab.isSaved = false;
            tab.canBeReloaded = false;
          }
        }

        tab.details = fileDetails;

        if (index == this.selectedTab.value) {
          this.showReloadOrKeepDialog(tab);
        }

        this.isCompiledFile(tab);
      }, error => {

        // this.tabs.splice(index, 1);
        this.removeTabFromArray(index - this.minusIndex);
        this.minusIndex++;

        if (this.selectedTab.value >= index) {
          this.setSelectedTabIndex(this.selectedTab.value - this.minusIndex);
        }

      }
    );
  }

  isCompiledFile(tab: MaxtafFileDetails) {

    if (!tab.isAllowCompile() || tab.projectId != this.tokenService.getProjectId()) {
      tab.compileDetails.isCompiled = true;
      return;
    }

    this.compileService.isCompiledFile(tab.details.pathWithName).subscribe(
      isCompiled => {
        if (isCompiled) {
          tab.compileDetails.isCompiled = true;
          tab.compileDetails.compileResult = undefined;
        } else {
          tab.compileDetails.isCompiled = false;
        }
      },
      () => {
        tab.compileDetails.isCompiled = false;
      }
    );
  }

  showError(error) {
    this.error = error;
    this.showAlertError = true;
  }

  private showReloadOrKeepDialog(tab: MaxtafFileDetails) {
    if (tab.canBeReloaded) {
      const dialogRef = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          body: '',
          label1: 'Reload',
          label2: 'Keep',
          title: 'File \'' + tab.details.name + '\' has changed in the meantime. Do you want to reload the file from workspace or keep your edited copy?',
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          tab.activeCode = tab.details.content;
        }
        tab.canBeReloaded = false;
      });
    }
  }

  private getFile(pathWithName: string, projectId: string, selectLine: number) {
    this.remoteFileService.getFile(pathWithName, true, projectId).subscribe(
      details => {

        const newTab = new MaxtafFileDetails(
          details,
          true,
          new CompileTabDetails(
            true,
            false,
            undefined),
          projectId,
          details.content
        );
        newTab.selectLine = selectLine;
        newTab.allowEdit = this.tokenService.getProjectId() == projectId;
        this.addTabInArray(newTab);
        this.selectedTab.setValue(this.tabs.length - 1);

        this.isCompiledFile(newTab);
      }, error => {
        console.error('getFile: pathWithName: ' + pathWithName + ' projectId: ' + projectId);
        this.showError(error);
      }
    );
  }

  private setSelectedTabIndex(selectedTab: number) {

    this.selectedTab.setValue(selectedTab);

    if (this.selectedTab.value >= 0 && this.tabs != undefined && this.tabs.length != 0 && this.selectedTab.value < this.tabs.length) {
      this.onSelectedIndexChange(this.selectedTab.value);

    }
  }

  openNewFile(lspDefinitionResult: LspDefinitionResult) {
    let path = lspDefinitionResult.uri;
    let selectLine = lspDefinitionResult.range.start.line
    console.log('12121212 4 tabs workspace lspDefinitionResult: ', lspDefinitionResult);
    const maxtafFileNode = new MaxtafFileNode(
      path.substr(path.lastIndexOf('/') + 1),
      path.substr(0, path.lastIndexOf('/')),
      path,
      path.substr(path.lastIndexOf('.') + 1),
      1,
      false,
      false,
      this.userService.getCurrentUserProject().project.id
    );
    maxtafFileNode.selectLine = selectLine;
    this.addNewTab(maxtafFileNode, path);

  }
}
