<section class="main-container">

  <div class="row">


    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <mat-select [(value)]="search.exactSearch"
                    placeholder="Filter done as exact match, or not">
          <mat-option value="Contains">Contains</mat-option>
          <mat-option value="Exact">Exact</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md" style="padding-bottom: 5px;">
      <button (click)="filterRecords()" class="mr-1 mb-1" color="primary" mat-raised-button>
        <mx-icon marginBottom="true" mx="filter"></mx-icon>
        <span class="pl-1">Filter</span>
      </button>
      <button (click)="clearFilter()" class="mr-1 mb-1" color="primary" mat-raised-button>
        <mx-icon marginBottom="true" mx="clear"></mx-icon>
        <span class="pl-1">Clear</span>
      </button>
      <button (click)="clickedClose()" class="mb-1" color="primary"
              mat-raised-button>
        <mx-icon marginBottom="true" mx="close"></mx-icon>
        <span class="pl-1">Close</span>
      </button>
    </div>
  </div>

  <div class="row">

    <div class="col-md center-checkbox">
      <div class="col-md" style="padding-left: 0px;">
        <mat-checkbox
          (change)="changePageObject($event)"
          *ngIf="pageObject"
          [(ngModel)]="search.showPageObjects"
          [disabled]="lockSearchParams.includes('showPageObjects')">
          Show page objects
        </mat-checkbox>

        <mat-checkbox
          (change)="changeCases($event)"
          *ngIf="!pageObject"
          [(ngModel)]="search.showCases"
          [disabled]="lockSearchParams.includes('showCases')">
          Show cases
        </mat-checkbox>
      </div>

      <div class="col-md">
        <mat-checkbox
          (change)="changeCases($event)"
          *ngIf="pageObject"
          [(ngModel)]="search.showCases"
          [disabled]="lockSearchParams.includes('showCases')">
          Show cases
        </mat-checkbox>

        <mat-checkbox
          (change)="changePageObject($event)"
          *ngIf="!pageObject"
          [(ngModel)]="search.showPageObjects"
          [disabled]="lockSearchParams.includes('showPageObjects')">
          Show page objects
        </mat-checkbox>
      </div>
    </div>


    <!--    <div class="col-md center-checkbox">-->
    <!--      <div class="col-md" style="padding-left: 0px;">-->
    <!--        <mat-checkbox-->
    <!--          [disabled]="lockSearchParams.includes('showActiveRecords')"-->
    <!--          [(ngModel)]="search.showActiveRecords"-->
    <!--        >-->
    <!--          Active records-->
    <!--        </mat-checkbox>-->
    <!--      </div>-->

    <!--      <div class="col-md">-->
    <!--        <mat-checkbox-->
    <!--          [disabled]="lockSearchParams.includes('showTrashRecords')"-->
    <!--          [(ngModel)]="search.showTrashRecords">-->
    <!--          Trash records-->
    <!--        </mat-checkbox>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="col-md">
      <mat-form-field class="w-100">
        <mat-select [(value)]="search.caseType"
                    [disabled]="lockSearchParams.includes('caseType')" placeholder="Select type..">
          <mat-option>None</mat-option>
          <mat-option value="JAVA">Java</mat-option>
          <mat-option value="PYTHON">Python</mat-option>
          <mat-option value="JAVASCRIPT">JavaScript</mat-option>
          <mat-option value="CSHARP">C#</mat-option>
          <mat-option value="ROBOT_FRAMEWORK">Robot Framework</mat-option>
          <mat-option *ngIf="isLicensed" value="MXML">Mxml</mat-option>
          <mat-option *ngIf="isLicensed" value="UTAM">Utam</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


  </div>

  <div class="row">
    <div class="col-md">
      <mat-form-field class="w-100">
        <textarea [(ngModel)]="search.description" [disabled]="lockSearchParams.includes('description')"
                  matInput placeholder="Description..."></textarea>
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <textarea [(ngModel)]="search.params" [disabled]="lockSearchParams.includes('params')" matInput
                  placeholder="Params..."></textarea>
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <textarea [(ngModel)]="search.code" [disabled]="lockSearchParams.includes('code')" matInput
                  placeholder="Code..."></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <mat-form-field class="w-100">
        <input (keyup.enter)="filterRecords()" [(ngModel)]="search.fileLocation" [disabled]="lockSearchParams.includes('fileLocation')"
               matInput
               placeholder="File location..."
               type="text"
        >
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <input (keyup.enter)="filterRecords()" [(ngModel)]="search.name" [disabled]="lockSearchParams.includes('name')" matInput

               placeholder="Name..."
               type="text">
      </mat-form-field>
    </div>
  </div>
</section>
