import {NgModule} from '@angular/core';
import {UserComponent} from './user.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {CommonModule} from '@angular/common';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {IconModule} from '../../../mx/icon/icon.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ErrorModule} from '../../../mx/error/error.module';
import {EditPasswordDialogModule} from '../../dialogs/edit-password-dialog/edit-password-dialog.module';
import {EditDialogModule} from '../../../dialogs/edit-dialog/edit-dialog.module';
import {CreateApiKeyModule} from "../../dialogs/create-api-key/create-api-key.module";
import {YesNoDialogModule} from "../../../dialogs/yes-no-dialog/yes-no-dialog.module";
import {ApiKeyInfoModule} from "../api-key-info/api-key-info.module";
import {MatTabsModule} from "@angular/material/tabs";
import {ProjectMainModule} from "../project-main/project-main.module";
import {ProjectParamsListModule} from "../project-params-list/project-params-list.module";
import {ProjectParamsOptionsModule} from "../project-params-options/project-params-options.module";
import {UserListOptionsModule} from "../user-list-options/user-list-options.module";
import {UserRequestsListModule} from "../user-requests-list/user-requests-list.module";
import {UsersListModule} from "../users-list/users-list.module";
import {
  UserPreferenceParamsOptionsModule
} from "../user-preference-params-options/user-preference-params-options.module";
import {UserPreferenceParamsListModule} from "../user-preference-params-list/user-preference-params-list.module";
import {
  AddUserPreferenceParamDialogComponentModule
} from "../add-user-preference-param-dialog-component/add-user-preference-param-dialog-component.module";
import {UserParamsOptionsModule} from "../user-params-options/user-params-options.module";
import {UserParamsListModule} from "../user-params-list/user-params-list.module";
import {ListCaseModule} from "../../../cases/components/list-case/list-case.module";
import {AddEditUserParamDialogModule} from "../add-edit-user-param-dialog/add-edit-user-param-dialog.module";
import {UserInfoModule} from "../user-info/user-info.module";

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    IconModule,
    ReactiveFormsModule,
    ErrorModule,
    EditPasswordDialogModule,
    EditDialogModule,
    CreateApiKeyModule,
    YesNoDialogModule,
    ApiKeyInfoModule,
    MatTabsModule,
    ProjectMainModule,
    ProjectParamsListModule,
    ProjectParamsOptionsModule,
    UserListOptionsModule,
    UserRequestsListModule,
    UsersListModule,
    UserPreferenceParamsOptionsModule,
    UserPreferenceParamsListModule,
    AddUserPreferenceParamDialogComponentModule,
    UserParamsOptionsModule,
    UserParamsListModule,
    ListCaseModule,
    AddEditUserParamDialogModule,
    UserInfoModule
  ],
  declarations: [
    UserComponent
  ],
  exports: [
    UserComponent
  ]
})

export class UserModule {
}
