import {Component, Inject, OnInit} from '@angular/core';
import {Project} from '../../../projects/shared/models/project';
import {FormControl} from '@angular/forms';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {FileService} from '../../../../shared/services/file.service';
import {TemplateService} from '../../../../shared/services/template.service';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {saveAs} from 'file-saver';
import {TransferExportService} from '../../../../shared/services/transfer-export.service';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {PageParams} from '../../../mx/util/params/PageParams';
import {SearchParam} from '../../../mx/util/params/searchParam';

class ExportCaseCheckPaths {
  json = new CheckPath();
  xml = new CheckPath();
  transfer = new CheckPath();
  template = new CheckPath();
}


@Component({
  selector: 'app-export-case-dialog',
  templateUrl: './export-case-dialog.component.html',
  styleUrls: ['./export-case-dialog.component.css']
})
export class ExportCaseDialogComponent implements OnInit {

  checkPaths: ExportCaseCheckPaths;

  className = 'ExportCaseDialogComponent';

  heading = 'Export Cases';

  active = true;

  paramsFilter;

  selected: string[] = [];

  url: string;

  // JSON XML TEMPLATE ...
  exportType = 'JSON';

  onlyArchivedOrActive = true;
  useFilter = false;

  templateName = '';
  templateDescription = '';

  error;
  exportError = false;
  exportSuccess = false;

  hideAdvancedSettings = false;
  overwriteCase = false;

  projectsList: Project[] = [];
  projects = new FormControl();

  // Workaround for angular component issue #13870
  disableAnimation = true;

  spinner = false;

  constructor(
    public dialog: MatDialog,
    public tokensStoriage: MaxtafTokensStorageService,
    private fileService: FileService,
    private templateService: TemplateService,
    private cloudConfigService: CloudConfigService,
    private projectService: ProjectService,
    private transferService: TransferExportService,
    public checkRolesService: CheckRolesService,
    public dialogRef: MatDialogRef<ExportCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      heading: string,
      active: boolean,
      paramsFilter,
      selected: string[]
    }
  ) {
    dialogRef.disableClose = true;

    this.checkPaths = this.getExportCaseCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );

    if (this.data.active != undefined) {
      this.active = this.data.active;
    }

    if (this.data.paramsFilter != undefined) {
      this.paramsFilter = this.data.paramsFilter;
      this.useFilter = true;
    }

    if (this.data.selected != undefined) {
      this.selected = this.data.selected;
    }

    this.getProjectsOfUser();
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = function (e) {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('closeButton').click();
    }
  };

  // ENTER shortcut for exporting the test
  exportShortcutListener = function (e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('exportButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.exportShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.exportShortcutListener, false);
  }


  export() {
    if (this.exportType === 'TRANSFER') {
      this.transferCases();
    } else if (this.exportType === 'TEMPLATE') {
      this.exportTemplate();
    } else {
      this.exportCases();
    }
  }

  exportCases() {
    this.exportSuccess = false;
    this.exportError = false;

    this.setUrl();

    this.fileService.exportFile(this.url).subscribe(
      res => {
        this.exportSuccess = true;

        const contentDisposition = res.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();

        const blob = new Blob([res.body], {type: 'application/text'});
        saveAs(blob, fileName);
        this.closeDialog();
      },
      error => {
        console.error(error);
        this.showError(error);
      }
    );

  }

  transferCases() {
    this.exportSuccess = false;
    this.exportError = false;

    this.setUrl();

    this.spinner = true;

    this.transferService.transferItems(this.url).subscribe(
      res => {
        this.exportSuccess = true;
        this.spinner = false;
        this.closeDialog();
      },
      error => {
        this.spinner = false;
        console.error(error);
        this.showError(error);
      }
    );
  }

  exportTemplate() {

    if (this.templateName.trim() === '') {
      this.showError({error: {errorCode: 'Template name missing', errorMessage: 'You must set template name!'}});
      return;
    }

    this.spinner = true;

    this.templateService.exportTemplate(this.selected, this.templateName.trim(), this.templateDescription.trim()).subscribe(
      res => {
        this.exportSuccess = true;
        this.spinner = false;
        this.closeDialog();
      },
      error => {
        this.spinner = false;
        console.error(error);
        this.showError(error);
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  showError(error) {
    this.error = error;
    this.exportError = true;
  }

  getProjectsOfUser() {
    const pageParams: PageParams = new PageParams(
      new SearchParam(0, 0),
      new SearchParam(100, 100),
      new SearchParam('name,asc', 'name,asc')
    );

    const afterFilter = {
      allowedPath: new SearchParam(this.cloudConfigService.addCase(), this.cloudConfigService.addCase()),
      allowedPathType: new SearchParam('POST', 'POST'),
    };

    this.projectService.getAll(afterFilter, pageParams).subscribe(
      (res: Project[]) => {
        this.projectsList = res['content'];
        this.projectsList = this.projectsList.filter(item => item.id != this.tokensStoriage.getProjectId());
      },
      error => {
        this.projectsList = [];
        console.error(error);
        this.showError(error);
      }
    );
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  setUrl() {
    this.url = this.cloudConfigService.exportCases(this.exportType.toLowerCase());

    if (this.exportType === 'TRANSFER') {
      let queryParams = '?';
      for (const project of this.projects.value) {
        queryParams = queryParams + 'projectIds%5B%5D=' + project.id + '&';
      }
      this.url += queryParams.substring(0, queryParams.length - 1);
      this.url += '&overwriteCases=' + this.overwriteCase + '&';
    } else {
      this.url += '?';
    }

    this.url += 'useFilter=' + this.useFilter + (this.onlyArchivedOrActive ? '&' + 'archivedCases' + '=' + !this.active : '');

    if (this.paramsFilter) {
      delete this.paramsFilter.page;
      delete this.paramsFilter.size;
      delete this.paramsFilter.sort;
    }

    if (this.useFilter) {
      if (this.paramsFilter) {
        for (const key in this.paramsFilter) {
          if (this.paramsFilter[key] != undefined) {
            this.url += '&' + key + '=' + encodeURIComponent(this.paramsFilter[key]);
          }
        }
      }
    }

    let queryParams = '';

    for (const id of this.selected) {
      queryParams = queryParams + '&ids%5B%5D=' + id + '';
    }
    this.url += queryParams;
  }

  private getExportCaseCheckPaths(): ExportCaseCheckPaths {
    return {
      json: new CheckPath('GET', this.cloudConfigService.exportCases('json')),
      xml: new CheckPath('GET', this.cloudConfigService.exportCases('xml')),
      template: new CheckPath('GET', this.cloudConfigService.exportTemplate()),
      transfer: new CheckPath('POST', this.cloudConfigService.exportCases('transfer')),
    };
  }
}
