import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AuthMaxtafService} from './shared/services/auth-maxtaf.service';
import {GlobalEventsManagerService} from './shared/services/global-events-manager.service';
import 'moment-timezone';
import {MaxtafTokensStorageService} from './shared/services/maxtaf-tokens-storage.service';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {MatDialog} from '@angular/material/dialog';
import {IdleDialogComponent} from './layout/dialogs/idle-dialog/idle-dialog.component';
import {ConsoleToggleServiceService} from "./shared/services/console-toggle-service.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  className = 'AppComponent';
  grayScreen = false;
  darkGrayScreen = false;
  start = true;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  idleTimeInSeconds = 3600;
  timeoutTimeInSeconds = 30;
  pingInSeconds = 1200;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private authService: AuthMaxtafService,
    private tokensStorageService: MaxtafTokensStorageService,
    private globalEventsManager: GlobalEventsManagerService,
    public dialog: MatDialog,
    public globalEventsManagerService: GlobalEventsManagerService,
    private idle: Idle,
    private keepalive: Keepalive,
    private consoleToggleService: ConsoleToggleServiceService
  ) {

    // this.consoleToggleService.disableConsoleInProduction();

    this.globalEventsManager.grayScreenEmitter.subscribe((isGray) => {
      this.grayScreen = isGray;
    });

    this.globalEventsManager.darkGrayScreenEmitter.subscribe((darkGrayScreen) => {
      this.darkGrayScreen = darkGrayScreen;
    });

    this.matIconRegistry.addSvgIcon(
      'run_compile',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/run-build.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'facebook-colored',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/facebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/mdi/facebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'github',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/github.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'google-colored',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/google.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/mdi/google.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'twitter-colored',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/twitter.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/social/mdi/twitter.svg')
    );


    let getNgIdleLastActive = tokensStorageService.getNgIdleLastActive();

    if (getNgIdleLastActive != null) {
      let nowTimeInMilliseconds = Date.now();

      let difference = (nowTimeInMilliseconds - Number(getNgIdleLastActive)) / 1000;

      if (difference > this.idleTimeInSeconds) {
        this.logout();
      }
    }

    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(this.idleTimeInSeconds);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(this.timeoutTimeInSeconds);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'UYU No longer idle.';
      this.authService.refreshToken().then();
      this.timedOut = false;
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'UYU Timed out!';
      this.timedOut = true;
      this.logout();
      this.idle.stop();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'UYU You\'ve gone idle!';

      if (document.hidden) {
        this.logout();
      } else {
        if (this.authService.isLoginUser()) {
          if (this.router.url !== '/login' && this.router.url !== '/register' && this.router.url !== '/tos') {
            const dialogRef = this.dialog.open(IdleDialogComponent, {
              width: '500px',
              data: {
                idleTime: this.timeoutTimeInSeconds
              }
            });

            dialogRef.afterClosed().subscribe(result => {

            });
          }
        }
      }
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'UYU You will time out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(this.pingInSeconds);

    keepalive.onPing.subscribe(() => {
      this.idleState = 'UYU ping ' + this.lastPing;
      if (!this.timedOut) {
        this.authService.refreshToken().then();
      }
      this.lastPing = new Date();
    });

    if (authService.isLoginUser()) {
      this.reset();
    }

  }

  reset() {
    this.idle.watch();
    this.idleState = 'UYU Started.';
    this.timedOut = false;
  }

  logout() {
    console.log('%c 11212 logout ');
    if (this.router.url !== '/login' && this.router.url !== '/register' && this.router.url !== '/tos') {
      this.dialog.closeAll();
      this.globalEventsManager.hideShowAIChat(false);
      this.globalEventsManagerService.showNavBar(false);
      this.globalEventsManagerService.hideAllBars();
      this.globalEventsManagerService.hideShowAIChat(false);
      console.log('%c 11212 logout ');
      this.authService.logout();
      const currentUrl = this.router.url;
      this.router.navigate(['/logout'], {queryParams: {returnUrl: currentUrl}}).then();

      this.reset();

    }
  }

  ngOnInit(): void {
    document.addEventListener(
      'visibilitychange'
      , () => {
        if (document.hidden) {

        } else {
          if (this.router.url === '/login' || this.router.url === '/register') {
            if (localStorage.getItem('X-Firebase-Auth') != null) {
              this.router.navigate(['/selectProject']);
            }
          } else {
            if (this.router.url !== '/tos' && this.router.url !== '/login' && this.router.url !== '/register') {
              if (localStorage.getItem('X-Firebase-Auth') == null) {
                console.log('%c 11212 logout ');
                const currentUrl = this.router.url;
                this.router.navigate(['/logout'], {queryParams: {returnUrl: currentUrl}}).then();

              }
            }
          }
        }
      }
    );
  }

  ngOnDestroy() {

  }

  @HostListener('window:mousemove')
  @HostListener('keydown')
  refreshUserState(): void {
    this.idle.watch();
  }


}

