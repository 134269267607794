import {Directive, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PageInfo} from '../../../../shared/models/pageInfo';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {PageParams} from '../params/PageParams';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {MxBase} from '../base/MxBase';
import {interval, Subscription} from 'rxjs';
import {ClickedTableItem} from './ClickedTableItem';
import {YesNoDialogComponent} from '../../../dialogs/yes-no-dialog/yes-no-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';


/**
 * da bi radili @Input i @Output ne treba koristiti template vars
 *
 * */
@Directive()
export abstract class MxTable<T> extends MxBase implements OnInit, OnDestroy {

  abstract idName: string;

  @Input('displayedColumns') abstract displayedColumns: string[];

  @Input('loading') isLoading = false;

  @Input('autoSetItems') autoSetItems = false;

  @Input('pageSizeOptions') pageSizeOptions: number[] = [5, 10, 20, 40, 60, 100, 300, 1000];
  @Input('pagesInfo') pagesInfo: PageInfo = new PageInfo();

  items: MatTableDataSource<T> = new MatTableDataSource<T>([]);
  abstract searchParams: any;
  abstract pageParams: PageParams;
  @Output('pageParamsChange') pageParamsChangeEmit = new EventEmitter<any>();
  @Output('pagesInfoChanges') pagesInfoEmitter = new EventEmitter<any>();
  @Output('selected') selected = new EventEmitter<any>();
  @Output('clickedItem') clicked = new EventEmitter<ClickedTableItem<T>>();
  @Output('refresh') refresh = new EventEmitter<any>();
  selection = new SelectionModel<T>(true, []);
  @Input('refreshIntervalInMs') refreshInterval = 1000; // 1 sec
  refreshIntervalSub: Subscription;
  allowReloadTable = false;
  dialog: MatDialog;
  protected blockNewRequest = false;

  protected constructor() {
    super();

  }

  @Input('items') set _items(items: T[]) {
    this.setItems(items);
  }

  @Input('searchParams') set setSearchParamsFromImport(searchParams) {

    this.searchParams = searchParams;

    if (this.allowReloadTable) {
      this.loadPage();
    }
  }

  @Input('pageParams') set setPageParamsFromImport(pageParams) {
    this.pageParams = pageParams;

    if (this.allowReloadTable) {
      this.loadPage();
    }
  }

  ngOnInit(): void {
    this.loadPage();
    this.allowReloadTable = true;
  }

  ngOnDestroy() {
    this.stopLiveRefresh();
  }

  /************** set items ************/

  abstract getItems();

  refreshItems() {
    this.getItems();
  }

  startLiveRefresh() {
    this.refreshIntervalSub = interval(this.refreshInterval)
      .subscribe(res => {
        if (document.hidden) {
          return;
        }

        this.loadPage();

        this.blockNewRequest = true;

      });
  }

  stopLiveRefresh() {
    if (this.refreshIntervalSub) {
      this.refreshIntervalSub.unsubscribe();
    }
  }

  clickedItem(item: T, index: number) {
    this.clicked.emit(new ClickedTableItem<T>(item, index));
  }

  setItems(newItems) {
    this.items = new MatTableDataSource<T>(newItems);
    // this.cases.sort = this.sortTable;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.data.length;
    return numSelected === numRows;
  }

  isAllSelectedMethod(totalElements) {
    const numSelected = this.selection.selected.length;
    const numRows = totalElements;
    return numSelected === numRows;
  }

  masterToggle(totalPages: any, sizeOfElementsOnPage: any) {
    // below checks if all the elements on a page is selected
    if (this.checkIfAllItemsOnPageIsSelected()) {
      // all elements on page is selected -> deselect side
      if (this.selection.selected.length > sizeOfElementsOnPage) {
        // there are other selected items on other page(s)
        let dialogRef: MatDialogRef<YesNoDialogComponent>;
        dialogRef = this.dialog.open(YesNoDialogComponent, {
          width: '500px',
          data: {
            title: 'Would you like to save the selection of items on other page(s)',
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result == true) {

            for (let i = 0; i <= sizeOfElementsOnPage - 1; i++) {

              for (let j = 0; j <= this.selection.selected.length - 1; j++) {
                if (JSON.stringify(this.selection.selected[j]) === JSON.stringify(this.items.data[i])) {
                  this.selection.deselect(this.selection.selected[j]);
                  this.emitSelected();
                  break;
                }
              }

            }

          } else {
            // clear all
            this.selection.clear();
            this.emitSelected();

          }
        });

      } else {
        // there are no selection on other pages
        this.selection.clear();
        this.emitSelected();
      }

    } else {
      // some elements on page is selected -> select side
      // only selects items that are deselected
      for (let i = 0; i <= sizeOfElementsOnPage - 1; i++) {
        const isSelected = this.isRecordSelected(this.items.data[i]);
        if (!isSelected) {
          this.selection.select(this.items.data[i]);
          this.emitSelected();
        }
      }
    }

  }

  isRecordSelected(record: any) {
    const item = JSON.stringify(record);
    let result = false;
    for (let i = 0; i <= this.selection.selected.length; i++) {
      const selectedItem = JSON.stringify(this.selection.selected[i]);
      if (item === selectedItem) {
        result = true;
        break;
      }
    }
    return result;
  }

  checkIfAllItemsOnPageIsSelected() {
    const selected = this.selection.selected;
    const data = this.items.data;
    let b = false;
    for (let i = 0; i < data.length; i++) {
      b = false;
      for (let j = 0; j < selected.length; j++) {
        if (JSON.stringify(selected[j]) === JSON.stringify(data[i])) {
          b = true;
          break;
        }
      }
      if (b === false) {
        break;
      }
    }


    if (b === true) {
      return true;
    } else {
      return false;
    }
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }


  sortData(sort: Sort) {
    if (sort.active == undefined || sort.active.trim() == '' || sort.direction == undefined || sort.direction.trim() == '') {
      this.pageParams.sort.value = this.pageParams.sort.defaultValue;
    } else {
      this.pageParams.sort.value = `${sort.active},${sort.direction}`;
    }
    this.pageParamsChangeEmit.emit(this.pageParams);

    this.loadPage();
  }

  setPageSizeOrIndex(page: PageEvent) {
    this.pageParams.size.value = page.pageSize;
    this.pageParams.page.value = page.pageIndex;

    this.pageParamsChangeEmit.emit(this.pageParams);

    this.loadPage();
  }

  isLastElementOnLastPage(): boolean {
    return this.pagesInfo.isLastPage && this.pagesInfo.sizeOfElementsOnPage === 1 && this.pagesInfo.pageIndex > 0;
  }

  showPreviousPage(): void {
    const page: number = this.pageParams.page.value;
    this.pageParams.page.value = (page == 0) ? 0 : page - 1;
    this.pageParamsChangeEmit.emit(this.pageParams);

    this.loadPage();
  }

  setPageInfo(res) {
    this.pagesInfo.isFirstPage = res.first;
    this.pagesInfo.isLastPage = res.last;
    this.pagesInfo.totalElements = res.totalElements;
    this.pagesInfo.totalPages = res.totalPages;
    this.pagesInfo.pageIndex = res.number + 1;
    this.pagesInfo.maxElementsOnPage = res.size;
    // this.pagesInfo.pageSort = res['sort'];
    this.pagesInfo.sizeOfElementsOnPage = res.numberOfElements;

    this.pagesInfoEmitter.emit(this.pagesInfo);
  }

  setFakePageInfo(res) {
    this.pagesInfo.isFirstPage = true;
    this.pagesInfo.isLastPage = true;
    this.pagesInfo.totalElements = res.totalElements;
    this.pagesInfo.totalPages = 1;
    this.pagesInfo.pageIndex = 1;
    this.pagesInfo.maxElementsOnPage = 4;
    // this.pagesInfo.pageSort = res['sort'];
    this.pagesInfo.sizeOfElementsOnPage = 4;

    this.pagesInfoEmitter.emit(this.pagesInfo);
  }

  loadPage() {
    if (this.autoSetItems) {
      this.getItems();
    } else {
      this.refresh.emit();
    }
  }

  showFirstPage(): void {
    this.pageParams.page.value = 0;
    this.pageParamsChangeEmit.emit(this.pageParams);
    this.loadPage();
  }

  /*********** select **************/

  isSelectedRow(row) {
    const size = this.selection.selected.length;
    for (let i = 0; i < size; i++) {
      if (this.selection.selected[i][this.idName] == row[this.idName]) {
        return true;
      }
    }
    return false;
  }

  selectedChange(row) {
    this.selectOrDeselect(row);
    this.emitSelected();
  }

  selectOrDeselect(row) {
    const size = this.selection.selected.length;
    for (let i = 0; i < size; i++) {
      if (this.selection.selected[i][this.idName] == row[this.idName]) {
        this.selection.deselect(this.selection.selected[i]);
        return true;
      }
    }
    this.selection.select(row);
    return false;
  }

  public unselectAll() {
    this.selection.clear();
  }

  startLoadingSpinner() {
    this.isLoading = true;
  }

  stopLoadingSpinner() {
    this.isLoading = false;
  }

  private emitSelected() {
    this.selected.emit(this.selection.selected);
  }
}
