import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.css']
})
export class YesNoDialogComponent implements OnInit {

  title = '';
  body = '';
  disableSecondButton = false;
  disableThirdButton = true;
  label1 = '';
  label2 = '';
  label3 = '';

  constructor(
    public dialogRef: MatDialogRef<YesNoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      body: string,
      disableSecondButton: boolean,
      label1: string,
      label2: string,
      label3: string,
      disableThirdButton: boolean,
    }
  ) {
    if (data.title && data.title != null) {
      this.title = data.title;
    }
    if (data.body && data.body != null) {
      this.body = data.body;
    }
    if (data.label1 && data.label1 != null) {
      this.label1 = data.label1;
    }
    if (data.label2 && data.label2 != null) {
      this.label2 = data.label2;
    }

    if (data.label3 && data.label3 != null) {
      this.label3 = data.label3;
    }
    if (data.disableSecondButton && data.disableSecondButton != null) {
      this.disableSecondButton = data.disableSecondButton;
    }

    if (data.disableThirdButton != null) {
      this.disableThirdButton = data.disableThirdButton;
    }

  }

  // ESC shortcut for closing the window
  noShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('noButton').click();
    }
  };

  // ENTER shortcut for running the test
  yesShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('yesButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.noShortcutListener, false);
    document.addEventListener('keydown', this.yesShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.noShortcutListener, false);
    document.removeEventListener('keydown', this.yesShortcutListener, false);
  }
}
