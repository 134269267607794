import {Component, Input, OnInit} from '@angular/core';
import {StorageConfigService} from '../../../../shared/services/storage-config.service';

@Component({
  selector: 'app-mx-video-viewer',
  templateUrl: './mx-video-viewer.component.html',
  styleUrls: ['./mx-video-viewer.component.css']
})
export class MxVideoViewerComponent implements OnInit {

  @Input('pathWithName') pathWithName: string;
  videoUrl: string;

  constructor(
    private storageConfigService: StorageConfigService
  ) {
  }

  ngOnInit(): void {
    this.videoUrl = this.storageConfigService.getVideoFile(this.pathWithName);
  }

}
