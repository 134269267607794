import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TransferExportService {

  constructor(
    private apiService: ApiService,
    private config: ConfigService
  ) {
  }

  transferItems(url: string) {
    return this.apiService.post(url, '{}');
  }
}
