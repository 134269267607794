<h1 mat-dialog-title xmlns="http://www.w3.org/1999/html">AI Settings</h1>

<div mat-dialog-content>

  <div *ngIf="aiConfig != null" class="mb-3 input-box">
    <mat-form-field class="form-field-full-width w-100">
      <mat-select (selectionChange)="changedVendor($event)"
                  [(value)]="aiConfig.vendor"
                  placeholder="Vendor">
        <mat-option value="OpenAI">Open AI</mat-option>
        <mat-option [disabled]="true" value="Claude">Claude</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <label *ngIf="aiConfig != null">Key</label>
  <div *ngIf="aiConfig != null" class="mb-3 input-box">
    <input [(ngModel)]="aiConfig.key" type="text">
    <mx-icon [matTooltip]="'This value overrides the value from the configuration parameters that the project administrator could have set for the entire project.'" class="ml-1 cursor-pointer" color="primary"
             mx="information"></mx-icon>
  </div>

  <label *ngIf="aiConfig != null "> Model</label>
  <div *ngIf="aiConfig != null" class="mb-3 input-box">
    <input [(ngModel)]="aiConfig.model" type="text">
    <mx-icon [matTooltip]="'This value overrides the value from the configuration parameters that the project administrator could have set for the entire project.'" class="ml-1 cursor-pointer" color="primary"
             mx="information"></mx-icon>
  </div>

  <label *ngIf="aiConfig != null " style="text-align: center;"> Choose a conversation style</label>
  <div *ngIf="aiConfig != null" class="conversation-style">
    <div class="mat-button-toggle-group-wrapper">
      <mat-button-toggle-group [(ngModel)]="aiConfig.temperature" aria-label="AI Temperature" name="aiTemperature">
        <mat-button-toggle value="0.9">
          <diV class="conversation-option">
            <div class="more-word">More</div>
            <div class="important-word">Creative</div>
          </diV>
        </mat-button-toggle>
        <mat-button-toggle value="0.5">
          <div class="conversation-option">
            <div class="more-word">More</div>
            <div class="important-word">Balanced</div>
          </div>
        </mat-button-toggle>
        <mat-button-toggle value="0.1">
          <div class="conversation-option">
            <div class="more-word">More</div>
            <div class="important-word">Precise</div>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

  </div>


</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" id="closeButton" mat-button>Cancel</button>
  <button (click)="updateAIOption()" color="primary" mat-button>Update</button>
</div>

