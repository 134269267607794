<h1 *ngIf="title != ''" class="text-center" mat-dialog-title>{{title}}</h1>
<div *ngIf="body != ''" mat-dialog-content>
  <p>{{body}}</p>
</div>
<div mat-dialog-actions>

  <button *ngIf="!disableThirdButton" [mat-dialog-close]="undefined" id="cancelButton" mat-button>
    {{(label3 != '' ? label3 : 'CANCEL')}}
  </button>

  <button *ngIf="!disableSecondButton" [mat-dialog-close]="false" color="warn" id="noButton" mat-button>
    {{(label2 != '' ? label2 : 'NO')}}
  </button>

  <button [mat-dialog-close]="true" color="primary" id="yesButton" mat-button>
    {{(label1 != '' ? label1 : 'YES')}}
  </button>

</div>
