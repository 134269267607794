<h1 mat-dialog-title>{{heading}}</h1>

<div mat-dialog-content>
  <div *ngIf="exportSuccess" class="fs-12 alert alert-success alert-dismissible fade show" role="alert">
    Export finished with success.
    <button (click)="exportSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <app-error-message *ngIf="exportError" [error]="error" [showInDialog]="true" class="fs-12"></app-error-message>

</div>


<div mat-dialog-actions>
  <button (click)="closeDialog()" id="closeButton" mat-button>{{exportSuccess || spinner ? 'Close' : 'Cancel'}}</button>
  <button (click)="export()" *ngIf="!spinner" color="primary" id="exportButton" mat-button>Export</button>
  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>

