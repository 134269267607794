export class SearchCriteria {
  directoryPath: string;
  filePattern: string;
  searchString?: string;
  caseSensitive: boolean;

  constructor(directoryPath: string = '', filePattern: string = '', searchString: string = '', caseSensitive: boolean = false) {
    this.directoryPath = directoryPath;
    this.filePattern = filePattern;
    this.searchString = searchString;
    this.caseSensitive = caseSensitive;
  }
}

