import {Injectable} from '@angular/core';
import {SearchParam} from "../../../mx/util/params/searchParam";
import {CaseSearchParams} from "./case-const.service";
import {PageParams} from "../../../mx/util/params/PageParams";
import {SuiteSearchParams} from "../../../suites/shared/services/suite-const.service";

@Injectable({
  providedIn: 'root'
})
export class CaseSuiteConstCommonService {

  constructor() {
  }

  public static getDefaultCaseSearchParams(archived: boolean = false): CaseSearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      name: new SearchParam(null, null),
      caseType: new SearchParam(null, null),
      description: new SearchParam(null, null),
      params: new SearchParam(null, null),
      code: new SearchParam(null, null),
      fileLocation: new SearchParam(null, null),
      anyFileLocation: new SearchParam(null, null),
      archived: new SearchParam(archived, archived),
      showPageObjects: new SearchParam(true, true),
      showCases: new SearchParam(true, true),
      showTrashRecords: new SearchParam(archived, archived),
      showActiveRecords: new SearchParam(!archived, !archived),
    };
  }

  public static getDefaultCasePageParams(size = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('updatedDate,desc', 'updatedDate,desc')
    };
  }

  public static getDefaultSuiteSearchParams(archived: boolean = false): SuiteSearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      name: new SearchParam(null, null),
      description: new SearchParam(null, null),
      params: new SearchParam(null, null),
      archived: new SearchParam(archived, archived)
    };
  }

  public static getDefaultSuitePageParams(size = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('updatedDate,desc', 'updatedDate,desc')
    };
  }

}
