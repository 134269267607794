import {Component, Inject, OnInit} from '@angular/core';
import {MaxtafFile} from '../../shared/models/maxtafFile';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RemoteFilesService} from '../../shared/services/remote-files.service';
import {MaxtafFileNode} from '../nav-workspace/shared/models/MaxtafFileNode';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';

@Component({
  selector: 'app-paste-dialog',
  templateUrl: './paste-dialog.component.html',
  styleUrls: ['./paste-dialog.component.css']
})
export class PasteDialogComponent implements OnInit {

  file: MaxtafFile = new MaxtafFile();

  isCopy = true;
  copyNode: MaxtafFileNode;
  pasteNode: MaxtafFileNode;

  pastePath = '';
  pasteFileName = '';

  showErrorAlert = false;
  error;

  overwrite = false;
  spinner = false;

  constructor(
    public dialog: MatDialog,
    public storageService: RemoteFilesService,
    public dialogRef: MatDialogRef<PasteDialogComponent>,
    private tokensService: MaxtafTokensStorageService,
    private remoteFileService: RemoteFilesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      copyNode: MaxtafFileNode,
      pasteNode: MaxtafFileNode,
      isCopy: boolean
    }
  ) {
    if (data.copyNode != undefined) {
      this.copyNode = data.copyNode;
    }

    if (data.pasteNode != undefined) {
      this.pasteNode = data.pasteNode;
      this.pastePath = this.pasteNode.pathWithName + (this.pasteNode.path.endsWith('/') ? '' : '/');
      this.pasteFileName = data.copyNode.name;
    }
    if (data.isCopy != undefined) {
      this.isCopy = data.isCopy;
    }
  }

  ngOnInit(): void {

  }

  paste() {
    this.pasteFileRequest();
  }

  pasteFileRequest() {
    this.startLoadingSpinner();
    this.remoteFileService.copyFile(
      this.copyNode.pathWithName,
      this.copyNode.projectId,
      this.pastePath + (this.pastePath.endsWith('/') ? '' : '/') + this.pasteFileName,
      this.tokensService.getProjectId(),
      !this.isCopy,
      this.overwrite
    )
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          this.dialogRef.close(true);
        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );
  }

  startLoadingSpinner() {
    this.spinner = true;
  }

  stopLoadingSpinner() {
    this.spinner = false;
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

}
