import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RemoteFilesService} from '../../shared/services/remote-files.service';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.css']
})
export class RenameDialogComponent implements OnInit {

  pathWithName = '';
  path = '';
  name = '';
  isFile = true;

  newName = '';

  showErrorAlert = false;
  error;

  overwrite = false;
  spinner = false;

  constructor(
    public dialog: MatDialog,
    public storageService: RemoteFilesService,
    public dialogRef: MatDialogRef<RenameDialogComponent>,
    private remoteFileService: RemoteFilesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      pathWithName: string,
      path: string,
      name: string,
      isFile: boolean
    }
  ) {
    if (data.pathWithName != undefined) {
      this.pathWithName = data.pathWithName;
    }

    if (data.path != undefined) {
      this.path = data.path;
    }

    if (data.name != undefined) {
      this.name = data.name;
      this.newName = this.name;
    }

    if (data.isFile != undefined) {
      this.isFile = data.isFile;
    }
  }

  ngOnInit(): void {

  }

  rename() {
    this.renameFileRequest();
  }

  renameFileRequest() {
    this.startLoadingSpinner();
    this.remoteFileService.renameFile(
      this.path,
      this.name,
      this.newName,
      this.overwrite
    )
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          this.dialogRef.close(true);

        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );
  }

  startLoadingSpinner() {
    this.spinner = true;
  }

  stopLoadingSpinner() {
    this.spinner = false;
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }


}
