import {NgModule} from '@angular/core';
import {FilterByComponent} from './filter-by.component';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IconModule} from '../icon/icon.module';
import {A11yModule} from '@angular/cdk/a11y';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    IconModule,
    A11yModule
  ],
  declarations: [
    FilterByComponent
  ],
  exports: [
    FilterByComponent
  ]
})

export class FilterByModule {
}
