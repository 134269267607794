import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatTabGroup} from '@angular/material/tabs';
import {BehaviorSubject} from 'rxjs';
import {CdkDragDrop, CdkDragEnter, CdkDragExit, moveItemInArray} from '@angular/cdk/drag-drop';
import {SuiteAddItemsDialogParams} from '../../../suites/shared/services/suite-const.service';
import {CaseService} from '../../shared/services/case.service';
import {MatDialog} from '@angular/material/dialog';
import {CaseConstService, CaseParams, CaseSearchParams, CaseTabsParams} from '../../shared/services/case-const.service';
import {YesNoDialogComponent} from '../../../dialogs/yes-no-dialog/yes-no-dialog.component';
import {CaseCodeHistoryService} from '../../shared/services/case-code-history.service';
import {RemoteFilesService} from '../../../storage/shared/services/remote-files.service';
import {UserService} from '../../../users/shared/services/user.service';
import {
  LocalSaveWorkspaceTabsSessionStorageService
} from '../../../storage/shared/services/local-save-workspace-tabs-session-storage.service';
import {CaseComponent} from '../case/case.component';
import {CaseTab} from '../../shared/models/CaseTab';
import {PageInfo} from '../../../../shared/models/pageInfo';
import {PageParams} from '../../../mx/util/params/PageParams';
import {SearchParam} from '../../../mx/util/params/searchParam';
import {AddCaseTabDialogComponent} from '../../../suites/components/add-case-tab-dialog/add-case-tab-dialog.component';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CompileResult} from '../../shared/models/compile-result';
import {ClickedTableItem} from '../../../mx/util/list/ClickedTableItem';
import {CaseAndSuiteParent} from '../../../suites/shared/models/case-and-suite-parent';
import {CaseListOptionsPaths} from '../case-list-options/case-list-options.component';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {CreatePageObjectDialogComponent} from '../create-page-object-dialog/create-page-object-dialog.component';
import {DeleteCaseDialogComponent} from '../delete-case-dialog/delete-case-dialog.component';
import {
  SameNameAlreadyExistsComponent
} from '../../../dialogs/same-name-already-exists/same-name-already-exists.component';
import {CompileDialogComponent} from '../compile-dialog/compile-dialog.component';
import {CompileInfoDialogComponent} from '../compile-info-dialog/compile-info-dialog.component';
import {ExportCaseDialogComponent} from '../export-case-dialog/export-case-dialog.component';
import {Params} from '../../../mx/util/params/params';
import {ImportComponent} from '../../../dialogs/import/import.component';
import {ImportCaseInfoDialogComponent} from '../import-case-info-dialog/import-case-info-dialog.component';
import {ListCaseComponent} from '../list-case/list-case.component';
import {CreateCaseOrPageObjectComponent} from '../create-case-or-page-object/create-case-or-page-object.component';
import {GlobalEventsManagerService} from "../../../../shared/services/global-events-manager.service";
import {Case} from "../../shared/models/case";
import {ChatSessionHelper} from "../../../../shared/models/chatSessionHelper";

@Component({
  selector: 'app-case-tabs',
  templateUrl: './case-tabs.component.html',
  styleUrls: ['./case-tabs.component.css']
})
export class CaseTabsComponent implements OnInit, OnDestroy {
  @Output('isTrash') makeChildListActive = new EventEmitter();
  @ViewChild('tabGroup', {static: false}) childTabGroup!: MatTabGroup;

  CHILD_ID_NAME = 'menu-name';
  childMenuIds$ = new BehaviorSubject<string[]>([]);
  // menus = [1 ,2, 3];


  // tabs: CaseTab[] = [];

  startCaseTab: CaseTab = undefined;
  maxTabName: number = 20;

  tabSwitched: EventEmitter<string> = new EventEmitter();
  @ViewChild('caseComponent') caseComponent: CaseComponent;
  blockRefresh = false;
  @Input('pageObjects') isPageObjectsPage = false;
  @Output('changedParams') changedParamsEmitter = new EventEmitter<CaseTabsParams>();
  params: CaseTabsParams;
  newTab = new CaseTab();

  constructor(
    public dialog: MatDialog,
    private caseService: CaseService,
    private caseCodeHistoryService: CaseCodeHistoryService,
    private globalEventsManagerService: GlobalEventsManagerService,
    public remoteFilesService: RemoteFilesService,
    private cloudConfigService: CloudConfigService,
    private userService: UserService,
    public router: Router,
    public snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private tabsSessionStorageService: LocalSaveWorkspaceTabsSessionStorageService,
  ) {
    // this.projectId = tokenService.getProjectId();
    // this.tabs = tabsSessionStorageService.getCaseTabs();

    // this.tabs.forEach((tab, index) => {
    //   this.refreshTab(tab, index);
    // });
    //
    // this.setSelectedTabIndex(this.tabsSessionStorageService.getSelectedTab());
    // window.onbeforeunload = () => this.ngOnDestroy();

  }

  @Input('firstCase') set firstCaseTab(startCaseTab: CaseTab) {
    this.startCaseTab = startCaseTab;

    // this.startCaseTab.activeCode = startCaseTab.testCase.code;
    // startCaseTab.activeCode = startCaseTab.testCase.code;

    if (this.params != undefined && this.params.tabs != undefined && this.startCaseTab != undefined && this.startCaseTab.testCase != undefined && this.startCaseTab.testCase.id != undefined) {
      // if (this.params.tabs.length == 0) {
      this.addFirstCase(startCaseTab);
      this.changedParams();
      // }
      this.startCaseTab = undefined;
    }
  }

  @Input('params') set setParams(params: CaseTabsParams) {
    this.params = params;

    if (this.startCaseTab != undefined && this.startCaseTab.testCase != undefined && this.startCaseTab.testCase.id != undefined) {
      // if (this.params.tabs.length == 0) {
      this.addFirstCase(this.startCaseTab);
      this.changedParams();
      // }
      this.startCaseTab = undefined;
    }

    if (this.params.selectedTab.value != null) {
      setTimeout(() => {
        this.childTabGroup.selectedIndex = this.params.selectedTab.value;


        // }
        this.recalculateUniqIdsForDragDrop();
      }, 400);
    }
  }

  isNameLongerThenMaxAllowedSize(name: string) {
    if (name == undefined) {
      return false;
    }
    if (name.length > this.maxTabName) {
      return true;
    } else {
      return false;
    }
  }

  cutName(name: string) {
    if (name == undefined) {
      return name;
    }
    return name.substring(0, this.maxTabName) + '..';
  }

  cutNameIfNameIsLongerThenMaxAllowedSize(name) {
    if (this.isNameLongerThenMaxAllowedSize(name)) {
      return this.cutName(name);
    } else {
      return name;
    }
  }

  public refreshCaseTabEditors(matTabChangeEvent, caseList?: ListCaseComponent) {
    let previousIndex = this.params.selectedTab.value;
    this.params.selectedTab.value = matTabChangeEvent.index;

    this.tabSwitched.emit();
    this.changedParams();
    // if (matTabChangeEvent.index == 0 || matTabChangeEvent.index == this.params.tabs.length + 1) {
    //
    // }
    // if (this.childTabGroup != undefined && !this.blockRefresh) {
    //
    //   let index = (matTabChangeEvent.tab.__ngContext__.length - 1);
    //   if (matTabChangeEvent.index != 0 && matTabChangeEvent.index != this.params.tabs.length + 1) {
    //   } else if (caseList) {
    //   }
    // } else {
    // }

    this.blockRefresh = false;

    // this.cdr.detectChanges();
  }

  addFirstCase(startCaseTab) {

    const index = this.findIndexById(startCaseTab.testCase.id);

    if (index == undefined) {
      this.addTabInArray(startCaseTab);
    } else {
      this.childTabGroup.selectedIndex = index;
      this.params.selectedTab.value = this.childTabGroup.selectedIndex;
      // if (result.scriptTab != undefined && result.scriptTab == true) {
      //   this.params.tabs[this.params.selectedTab.value].caseParams.case.caseTabIndex.value = 1;
      // }
      this.recalculateUniqIdsForDragDrop();
      this.changedParams();
    }
  }

  addTabInArray(tab: CaseTab, addToEnd = true) {
    this.blockRefresh = true;
    // this.params.tabs.push(tab);
    addToEnd ? this.params.tabs.push(tab) : this.params.tabs.unshift(tab);
  }

  updateCasePageObject() {
    let value = 'all';
    if (this.params.addTabParams.caseSearch.showPageObjects.value && this.params.addTabParams.caseSearch.showCases.value) {
      value = 'all';
    } else if (this.params.addTabParams.caseSearch.showPageObjects.value) {
      value = 'pageObjects';
    } else if (this.params.addTabParams.caseSearch.showCases.value) {
      value = 'cases';
    }

    // this.userService.setUserPreferencesParam('mx.user.preferences.cases-workspace.search.show-page-objects-or-cases', value).subscribe(
    //   (res: UserPreferencesParam) => {
    //
    //   },
    //   (error) => {
    //     console.error('error', error);
    //   }
    // );
  }

  ngOnDestroy() {
    // this.tabsSessionStorageService.saveCaseTabs(this.tabs, this.paramsId);
    // this.tabsSessionStorageService.saveSelectedTab(this.selectedTab.value, this.projectId);
    this.changedParams();
  }

  ngOnInit(): void {
    this.recalculateUniqIdsForDragDrop();
    // if (this.isPageObjectsPage == null || this.isPageObjectsPage == false) {
    //   this.params.addTabParams.caseSearch.showPageObjects.value = false;
    //   this.params.addTabParams.caseSearch.showPageObjects.defaultValue = false;
    //   this.params.addTabParams.caseSearch.showCases.value = true;
    //   this.params.addTabParams.caseSearch.showCases.defaultValue = true;
    // } else {
    //   this.params.addTabParams.caseSearch.showPageObjects.value = true;
    //   this.params.addTabParams.caseSearch.showPageObjects.defaultValue = true;
    //   this.params.addTabParams.caseSearch.showCases.value = false;
    //   this.params.addTabParams.caseSearch.showCases.defaultValue = false;
    // }

  }

  trackByIndex(index: number): number {
    return index;
  }

  onDropTab(event: CdkDragDrop<string[]>): void {
    const previousIndex = parseInt(event.previousContainer.id.replace(this.CHILD_ID_NAME, ''), 10);
    const newIndex = parseInt(event.container.id.replace(this.CHILD_ID_NAME, ''), 10);
    moveItemInArray(this.params.tabs, previousIndex, newIndex);
    this.showDragWrapper(event);
    if (previousIndex == this.childTabGroup.selectedIndex) {
      this.childTabGroup.selectedIndex = newIndex;
    }
    this.changedParams();
  }

  onDragEntered(event: CdkDragEnter): void {
    this.hideDragWrapper(event);
  }

  onDragExited(event: CdkDragExit): void {
    this.showDragWrapper(event);
  }

  // onTabClosed
  onRemoveMenu(event: MouseEvent, index: number): void {
    event.stopPropagation();

    if (this.params.tabs[index].isSaved) {
      this.removeTabMenu(index);
    } else {

      const dialogRef = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          title: '',
          body: 'There are unsaved changes in ' + ((this.params.tabs[index].testCase.pageObject == null || this.params.tabs[index].testCase.pageObject == true) ? 'page object ' + '\'' + this.params.tabs[index].testCase.name + '\'' : 'case ' + '\'' + this.params.tabs[index].testCase.name + '\'') + '. Do you want to save them?',
          disableSecondButton: false,
          disableThirdButton: false
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          return;
        } else if (result) {
          this.params.tabs[index].testCase.code = this.params.tabs[index].activeCode;

          this.updateCase(this.params.tabs[index].testCase.id, this.params.tabs[index].testCase);
          this.removeTabMenu(index);
        } else {
          this.removeTabMenu(index);
        }
      });
    }
  }

  updateCase(caseId: string, body) {
    this.caseService.updateCase(caseId, body)
      .subscribe(
        res => {
          this.openSnackBar((body.pageObject ? 'Case ' : 'Page object ') + '\'' + body.name + '\' is updated', 'OK', 5);
        },
        error => {
          console.error(error);
        }
      );
  }

  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }

  removeTabMenu(index) {
    if (index == undefined) {
      return;
    }

    this.params.tabs.splice(index, 1);

    if (this.childTabGroup.selectedIndex > index) {
      this.params.selectedTab.value = this.params.selectedTab.value - 1;
      this.childTabGroup.selectedIndex = this.params.selectedTab.value;
    }

    if (this.childTabGroup.selectedIndex === this.params.tabs.length) {
      this.childTabGroup.selectedIndex = this.childTabGroup.selectedIndex;
    }
    this.recalculateUniqIdsForDragDrop();
    this.changedParams();

    this.cdr.detectChanges();
  }

  changedParams() {
    this.changedParamsEmitter.emit(this.params);
  }

  changedCaseParams(caseParams: CaseParams, tab: CaseTab) {
    tab.caseParams = caseParams;

    this.changedParams();
  }

  onAddMenu(params?, addToEnd = true): void {
    this.newTab.activeCode = this.newTab.testCase.code;

    if (params != undefined) {
      this.newTab.caseParams.caseIndex.value = params.index.value;
      this.newTab.caseParams.caseIndex.defaultValue = params.index.value;
      this.newTab.caseParams.casesSearch = params.caseSearch;
      this.newTab.caseParams.casesPagesInfo = this.transformCasePageInfo(params.casePageInfo, params.index.value);
      this.newTab.caseParams.casesPage = this.transformCasePage(this.newTab.caseParams.casesPagesInfo, this.newTab.caseParams.casesPage);
    } else {
      if (this.newTab.caseParams == undefined || this.newTab.caseParams.caseIndex == undefined) {
        this.newTab.caseParams = CaseConstService.getDefaultCasePageParams();
      }

      this.newTab.caseParams.caseIndex.value = 1;
      this.newTab.caseParams.caseIndex.defaultValue = 1;
      this.newTab.caseParams.casesSearch = new CaseSearchParams();
      this.newTab.caseParams.casesPagesInfo = new PageInfo(true, true, 1, 1, 1, 1, 1);
      this.newTab.caseParams.casesPage = CaseConstService.getDefaultPageParams(1);
    }


    this.addTabInArray(this.newTab, addToEnd);

    if (addToEnd) {
      if (this.params.tabs.length > 1) {
        const newIndex = this.params.tabs.length - 1;
        this.childTabGroup.selectedIndex = newIndex;
        this.params.selectedTab.value = newIndex;
      }
    } else {
      this.childTabGroup.selectedIndex = 1;
      this.params.selectedTab.value = this.childTabGroup.selectedIndex;
    }


    setTimeout(() => {
      this.recalculateUniqIdsForDragDrop();
      this.changedParams();

    }, 1000);
  }

  public addItemInTabs(id, scriptTab, params?, addToEnd = true) {
    const index = this.findIndexById(id);

    if (index == undefined) {
      this.getCase(id, params, scriptTab, addToEnd);
    } else {
      // setTimeout(() => {
      this.childTabGroup.selectedIndex = index;
      this.params.selectedTab.value = this.childTabGroup.selectedIndex;
      if (scriptTab != undefined && scriptTab == true) {
        this.params.tabs[this.params.selectedTab.value].caseParams.case.caseTabIndex.value = 1;
      }

      this.recalculateUniqIdsForDragDrop();
      this.changedParams();
      // }, 400);
    }
  }

  addItemInTab(
    id: string,
    caseParams: CaseParams,
    isTransferCase: boolean,
    compileResult: CompileResult,
    compileError,
    selectLine: number = undefined
  ) {
    const index = this.findIndexById(id);

    if (index == undefined) {
      this.getCaseFromSingleCase(id, caseParams, isTransferCase, compileResult, compileError, selectLine);
    } else {

      this.params.tabs[index - 1].caseParams.case.caseTabIndex.value = 1;
      this.params.tabs[index - 1].compileError = compileError;

      this.childTabGroup.selectedIndex = index;
      this.params.selectedTab.value = this.childTabGroup.selectedIndex;

      this.recalculateUniqIdsForDragDrop();
      this.changedParams();
      // }, 400);
    }

    if (typeof id === 'undefined') {

      // this.childTabGroup.selectedIndex = 1;
      // this.params.selectedTab.value = this.childTabGroup.selectedIndex;
    }
  }

  findIndexById(id: string): number | null | undefined {
    for (let i = 0; i < this.params.tabs.length; i++) {
      if (this.params.tabs[i].testCase.id == id) {
        return i + 1;
      }
    }
    return null;
  }

  transformCasePageInfo(_pageInfo: PageInfo, index: number): PageInfo {
    const pageInfo: PageInfo = _pageInfo;

    pageInfo.pageIndex = (_pageInfo.pageIndex - 1) * _pageInfo.maxElementsOnPage + (index + 1);
    pageInfo.maxElementsOnPage = 1;
    pageInfo.sizeOfElementsOnPage = 1;
    pageInfo.totalElements = _pageInfo.totalElements;
    pageInfo.totalPages = _pageInfo.totalElements;


    if (pageInfo.pageIndex == 0) {
      pageInfo.isFirstPage = true;
    } else {
      pageInfo.isFirstPage = false;
    }

    if (pageInfo.pageIndex == pageInfo.totalPages) {
      pageInfo.isLastPage = true;
    } else {
      pageInfo.isLastPage = false;
    }

    return pageInfo;
  }

  setActiveCodeChanges(tab: CaseTab, activeCode: string) {
    tab.activeCode = activeCode;
    this.changedParams();
  }

  setIsSavedChanges(tab: CaseTab, isSaved: any) {
    tab.isSaved = isSaved;
    this.changedParams();
  }

  /************** add button *****************/
  /************** add button *****************/


  onAddChildControl(event: MouseEvent): void {
    event.stopPropagation();

    this.newTab.caseParams = CaseConstService.getDefaultCasePageParams();

    const dialogRef = this.dialog.open(AddCaseTabDialogComponent, {
      width: '900px',
      panelClass: 'custom-dialog-container',
      data: {
        multiSelect: false,
        removeSuites: true,
        showPageObjects: this.isPageObjectsPage,
        showCreatePageObjects: this.isPageObjectsPage,
        showCreateCases: !this.isPageObjectsPage,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.addItemInTabs(result.ids[0], result.scriptTab, result.params);

      } else {
        this.newTab = new CaseTab();
      }
    });

    // this.tabs.push(newTab);
    // this.recalculateUniqIdsForDragDrop();
  }

  /*************** plus **********************/
  /*************** plus **********************/

  pagesInfoChanges(pageInfo: PageInfo) {
    this.params.addTabParams.casePageInfo = pageInfo;
    this.changedParams();
  }

  casePageParamsChange(casePageParams: PageParams) {
    this.params.addTabParams.casePage = casePageParams;
    this.changedParams();
  }

  caseSearchParamsChange(caseSearchParams: CaseSearchParams) {
    this.params.addTabParams.caseSearch = caseSearchParams;
    this.changedParams();
    this.updateCasePageObject();
  }

  selected(selected) {
    this.params.addTabParams.selectedItems = selected.slice();
    this.changedParams();
  }

  //TODO fix No script was found for this case. Do you want to restore the last script that was contained within this case
  // returnCode() {
  //   if (this.newTab.testCase.code == undefined || this.newTab.testCase.code == '') {
  //     //TODO get file
  //     this.remoteFilesService.getFile(this.newTab.testCase.fileLocation.fullPath, false, this.userService.getCurrentUserProject().project.id).subscribe(
  //       res => {
  //
  //       },
  //       getError => {
  //         const dialogRef = this.dialog.open(YesNoDialogComponent, {
  //           width: '400px',
  //           data: {
  //             title: 'No script was found for this case. Do you want to restore the last script that was contained within this case?',
  //           }
  //         });
  //
  //         dialogRef.afterClosed().subscribe(result => {
  //
  //           if (result) {
  //
  //             let searchParamsForCodeHistory = CaseConstService.getDefaultSearchParamsForCodeHistory(this.newTab.testCase.id);
  //             let pageParamsForCodeHistory = CaseConstService.getDefaultPageParamsForCodeHistory();
  //
  //             this.caseCodeHistoryService.getAll(searchParamsForCodeHistory, pageParamsForCodeHistory)
  //               .subscribe(
  //                 res => {
  //                   // this.stopLoadingSpinner();
  //                   // if (res) {
  //                   //   this.setItems(res.content);
  //                   //   this.setPageInfo(res);
  //                   // } else {
  //                   //   this.setItems([]);
  //                   // }
  //
  //                   this.caseCodeHistoryService.revertCode(this.newTab.testCase.id, res.content[0].id).subscribe(
  //                     res => {
  //                       // this.stopRevertSpinner();
  //                       // this.dialogRef.close(true);
  //
  //                       // this.hideError();
  //                       this.getCase();
  //                     },
  //                     error => {
  //                       // this.showError(error);
  //                       // this.stopRevertSpinner();
  //                     }
  //                   );
  //
  //                 },
  //                 caseCodeHistoryError => {
  //                   // this.showError(error);
  //                   // this.stopLoadingSpinner();
  //                 }
  //               );
  //
  //             // revertCode() {
  //             //   this.startRevertSpinner();
  //
  //             // }
  //           }
  //         });
  //       }
  //     );
  //   }
  // }

  clickedScriptItem(clickedItem: ClickedTableItem<CaseAndSuiteParent>, addToEnd = true) {
    this.newTab = new CaseTab();
    this.newTab.caseParams = CaseConstService.getDefaultCasePageParams();

    if (clickedItem.item) {
      this.params.addTabParams.index.value = clickedItem.index;
      this.addItemInTabs(clickedItem.item.id, true, this.params.addTabParams, addToEnd);
    } else {
      this.newTab = new CaseTab();
    }
  }

  clickedItem(clickedItem: ClickedTableItem<CaseAndSuiteParent>, addToEnd = true) {
    this.newTab = new CaseTab();
    this.newTab.caseParams = CaseConstService.getDefaultCasePageParams();

    if (clickedItem.item) {
      this.params.addTabParams.index.value = clickedItem.index;

      this.addItemInTabs(clickedItem.item.id, false, this.params.addTabParams, addToEnd);
    } else {
      this.newTab = new CaseTab();
    }
  }

  /************** add button *****************/

  openCreateDialog(addToEnd = true, createCase = true) {
    if (createCase) {
      this.newTab = new CaseTab();
      this.newTab.caseParams = CaseConstService.getDefaultCasePageParams();

      const dialogRef = this.dialog.open(CreateCaseOrPageObjectComponent, {
        width: '700px',
        data: {
          openCreatedCase: false,
          askAI: true
        }
      });

      dialogRef.afterClosed().subscribe((result: Case) => {
        if (result != null) {

          // let prompt =
          //     "You are a coding assistant named CodeGPT. Your role is to help users create scripts by engaging in a dialogue to understand their coding goals and requirements. Ask the user a series of questions to gather information about the desired script, including its purpose, functionality, input/output, error handling, and any specific constraints. Based on the user's responses, generate the appropriate code in the specified programming language (Java, Python, or JavaScript) and provide explanations or suggestions as needed.\n" +
          //     "If you need to understand anything about the machine where the code will be executed, you can send a command line that you want executed by prefixing it with 'SYSTEM CALL:'. For example, 'SYSTEM CALL: find / -name \"pom.xml\" | cat' will search for a 'pom.xml' file and return its content, allowing you to determine if any new dependencies are required. You can also use 'curl' to access the internet and gather additional information." +
          //     "\n" +
          //     "\nYour starting code template is as follows:" +
          //   "\n```" + result.caseType.toLowerCase() + "\n" +
          //   result.code +
          //   "\n```\n" +
          //   "\nBegin the conversation by introducing yourself and asking the user about the purpose and main functionality of the script they want to create.";
          //
          // this.globalEventsManagerService.askAI(prompt, result.id, result.name);

          // this.params.addTabParams.index.value = clickedItem.index;
          this.addItemInTabs(result.id, true, undefined, addToEnd);
        } else {
          this.newTab = new CaseTab();
        }

      });
    } else {
      this.openCreatePageObjectEventDialog(addToEnd);
    }
  }


  /*************** plus **********************/

  /*************** plus **********************/
  /*************** plus **********************/

  openCreatePageObjectEventDialog(addToEnd = true) {
    // this.openCreateDialog(addToEnd, false);
    this.newTab = new CaseTab();
    this.newTab.caseParams = CaseConstService.getDefaultCasePageParams();

    const dialogRef = this.dialog.open(CreatePageObjectDialogComponent, {
      width: '700px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        // this.params.addTabParams.index.value = clickedItem.index;
        this.addItemInTabs(result.id, false, undefined, addToEnd);

      } else {
        this.newTab = new CaseTab();
      }
    });
  }

  getCaseListOptionsCheckPathObject(): CaseListOptionsPaths {
    return {
      filter: new CheckPath('GET', this.cloudConfigService.getCases()),
      create: new CheckPath('POST', this.cloudConfigService.addCase()),
      compile: new CheckPath('POST', this.cloudConfigService.compileCases()),
      createPageObject: new CheckPath('POST', this.cloudConfigService.addCase()),
      refresh: new CheckPath('GET', this.cloudConfigService.getCases()),
      export: new CheckPath('GET', this.cloudConfigService.exportCases()),
      import: new CheckPath('POST', this.cloudConfigService.importCases()),
      delete: new CheckPath('DELETE', this.cloudConfigService.deleteCase()),
      restore: new CheckPath('PUT', this.cloudConfigService.unTrashCase()),
      trashRecords: new CheckPath('GET', this.cloudConfigService.getCases()),
      activeRecords: new CheckPath('GET', this.cloudConfigService.getCases()),
    };
  }

  /***************** options ****************************/
  /***************** options ****************************/

  trashRecords(caseList: ListCaseComponent) {
    this.params.addTabParams.caseSearch.showTrashRecords.value = true;
    this.params.addTabParams.caseSearch.showActiveRecords.value = false;
    this.changedParams();
    caseList.refreshItems();
  }

  activeRecords(caseList: ListCaseComponent) {
    this.params.addTabParams.caseSearch.showTrashRecords.value = false;
    this.params.addTabParams.caseSearch.showActiveRecords.value = true;
    this.changedParams();
    caseList.refreshItems();
  }

  deleteDialog(caseList: ListCaseComponent) {
    const dialogRef = this.dialog.open(DeleteCaseDialogComponent, {
      width: '400px',
      data: {
        title: 'Are you sure you want to delete cases?',
        selected: this.params.addTabParams.selectedItems,
        archived: false,
        searchParams: this.params.addTabParams.caseSearch,
        runCount: this.params.addTabParams.casePageInfo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result == 'select' || result == 'single') {
          this.deleteSelectedCasesAndUnselectAll(caseList);
        }


        this.unselectAll(caseList);
        caseList.refreshItems();
        caseList.unselectAll();
      }
    });
  }

  deleteSelectedCasesAndUnselectAll(caseList: ListCaseComponent) {
    this.deleteSelectedCases(caseList);
    this.unselectAll(caseList);
  }

  deleteSelectedCases(caseList: ListCaseComponent) {
    for (const testCase of this.params.addTabParams.selectedItems) {
      if (testCase.archived) {
        this.deleteCase(testCase.id, caseList);
      } else {
        this.moveCaseInTrash(testCase.id, caseList);
      }
    }
  }

  deleteCase(deleteId: string, caseList: ListCaseComponent) {
    this.caseService.deleteCaseArchived(deleteId)
      .subscribe(
        res => {
          if (caseList.isLastElementOnLastPage()) {
            this.showPreviousPage(caseList);
          } else {
            caseList.refreshItems();
          }

          const index = this.findIndexById(deleteId);

          if (index != undefined) {
            this.removeTabMenu(index - 1);
          }
        },
        error => {
          this.showError(error);
        }
      );
  }

  moveCaseInTrash(deleteId: string, caseList: ListCaseComponent) {
    this.caseService.deleteCase(deleteId)
      .subscribe(
        res => {
          if (caseList.isLastElementOnLastPage()) {
            this.showPreviousPage(caseList);
          } else {
            caseList.refreshItems();
          }


          const index = this.findIndexById(deleteId);


          if (index != undefined) {
            this.removeTabMenu(index - 1);
          }
        },
        error => {
          this.showError(error);
        }
      );
  }

  /***************** options ****************************/

  /***************** options ****************************/
  /***************** options ****************************/

  compileDialog(caseList: ListCaseComponent) {
    const dialogRef = this.dialog.open(CompileDialogComponent, {
      width: '600px',
      panelClass: 'custom-dialog-container',
      data: {
        ids: this.params.addTabParams.selectedItems.map(e => e.id),
        searchParams: this.params.addTabParams.caseSearch,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.unselectAll(caseList);
    });
  }

  compileInfoDialog() {
    const dialogRef = this.dialog.open(CompileInfoDialogComponent, {
      width: '700px',
      panelClass: 'custom-dialog-container-without-height',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openExportDialog(caseList: ListCaseComponent): void {
    const dialogRef = this.dialog.open(ExportCaseDialogComponent, {
      width: '500px',
      data: {
        active: !this.params.addTabParams.caseSearch.showTrashRecords.value,
        selected: this.params.addTabParams.selectedItems == undefined || this.params.addTabParams.selectedItems == null ? [] : this.params.addTabParams.selectedItems.map(e => e.id),
        paramsFilter: new Params(this.params.addTabParams.caseSearch)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.unselectAll(caseList);
    });
  }

  openImportDialog(caseList: ListCaseComponent): void {
    const dialogRef = this.dialog.open(ImportComponent, {
      width: '500px',
      data: {type: 'case'}
    });

    dialogRef.afterClosed().subscribe(result => {
      caseList.refreshItems();
    });
  }

  openImportInfoDialog(): void {
    const dialogRef = this.dialog.open(ImportCaseInfoDialogComponent, {
      width: '700px',
      panelClass: 'custom-dialog-container',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  restoreDialog(caseList: ListCaseComponent) {

    const dialogRef = this.dialog.open(DeleteCaseDialogComponent, {
      width: '400px',
      data: {
        title: 'Are you sure you want to restore the  cases?',
        selected: this.params.addTabParams.selectedItems,
        archived: true,
        restore: true,
        searchParams: this.params.addTabParams.caseSearch,
        runCount: this.params.addTabParams.casePageInfo.totalElements
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result == 'select' || result == 'single') {
          this.restoreSelectedCasesAndUnselectAll(caseList);
        }


        this.unselectAll(caseList);
        caseList.refreshItems();
        caseList.unselectAll();
      }
    });
  }

  restoreSelectedCasesAndUnselectAll(caseList: ListCaseComponent) {
    this.restoreSelectedCases(caseList);
    this.unselectAll(caseList);
  }

  unselectAll(caseList: ListCaseComponent) {
    // this.selectedCases = [];
    this.params.addTabParams.selectedItems = [];
    caseList.unselectAll();
    this.changedParams();
  }

  restoreSelectedCases(caseList: ListCaseComponent) {

    for (const testCase of this.params.addTabParams.selectedItems) {
      this.restoreCase(testCase, caseList);
    }
  }

  restoreCase(testCase, caseList: ListCaseComponent) {
    this.caseService.restoreCase(testCase.id)
      .subscribe(
        res => {
          // this.pageParams.page.value = 0;
          // this.refreshCases();
          // this.getCases();

          if (caseList.isLastElementOnLastPage()) {
            this.showPreviousPage(caseList);
          } else {
            caseList.refreshItems();
          }
        },
        error => {
          if (error.status == 409) {
            this.sameNameAlreadyExistsComponentDialog(testCase, caseList);
          } else {
            this.showError(error);
          }
          console.error(error);
        }
      );
  }

  showPreviousPage(caseList: ListCaseComponent): void {
    caseList.showPreviousPage();
  }

  sameNameAlreadyExistsComponentDialog(testCase, caseList: ListCaseComponent): void {
    const dialogRef = this.dialog.open(SameNameAlreadyExistsComponent, {
      width: '400px',
      data: {case: testCase}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        this.params.addTabParams.casePage.page.value = this.params.addTabParams.casePage.page.defaultValue;

        this.changedParams();
        caseList.refreshItems();
      }
      if (result) {
        this.restoreCase(testCase, caseList);
      }
    });
  }

  showError(error) {
    // this.error = error;
    // this.showErrorAlert = true;
  }

  checkForCleaningAIRelatedSession(matTabChangeEvent) {
    if (matTabChangeEvent.index == 0) {
      try {
        // if(this.params.selectedTab.value == 0) {
        this.globalEventsManagerService.setRelatedSession(new ChatSessionHelper(null, null));
      } catch (e) {
        console.error(e);
      }
      // }
    }
  }

  private showDragWrapper(event: CdkDragExit | CdkDragDrop<string[]>): void {
    const element = this.getDragWrappedElement(event);
    if (element) {
      element.classList.remove('d-none');
    }
  }

  private hideDragWrapper(event: CdkDragEnter): void {
    const element = this.getDragWrappedElement(event);
    if (element) {
      element.classList.add('d-none');
    }
  }

  private getDragWrappedElement(event: CdkDragEnter | CdkDragExit): HTMLElement | null {
    return event.container.element.nativeElement.querySelector(`.drag-wrapper`);
  }

  private recalculateUniqIdsForDragDrop(): void {
    // const uniqIds: string[] = [];
    // this.params.tabs.reduce((accumulator: string[], _, index) => {
    //   accumulator.push(`${this.CHILD_ID_NAME}${index}`);
    //   return accumulator;
    // }, uniqIds);
    // this.childMenuIds$.next(uniqIds);
  }

  private transformCasePage(casesPagesInfo: PageInfo, casePage: PageParams): PageParams {
    const result = new PageParams(
      new SearchParam(casesPagesInfo.pageIndex, 0),
      new SearchParam(1, 1),
      casePage.sort
    );
    return result;
  }

  private getCaseFromSingleCase(
    id: string,
    caseParams: CaseParams,
    isTransferCase: boolean,
    compileResult: CompileResult,
    compileError,
    selectLine: number
  ) {
    if (id == undefined || id == 'undefined') {
      return;
    }
    this.caseService.getCase(id)
      .subscribe(
        res => {
          this.newTab.testCase = res;

          // this.returnCode();

          // this.globalEventsManager.setSideNavMod(2);
          console.log('this.newTab.testCase id: ' + id + ':', this.newTab.testCase);
          if (this.newTab.testCase.name === null) {
            this.newTab.testCase.name = '';
          }

          this.newTab.activeCode = this.newTab.testCase.code;
          this.newTab.caseParams = caseParams;
          this.newTab.isTransferCase = isTransferCase;
          this.newTab.compileResult = compileResult;
          this.newTab.compileError = compileError;
          this.newTab.selectLine = selectLine;

          this.addTabInArray(this.newTab);

          setTimeout(() => {
            this.childTabGroup.selectedIndex = this.params.tabs.length;
            this.params.selectedTab.value = this.childTabGroup.selectedIndex;

            this.recalculateUniqIdsForDragDrop();
            this.changedParams();
          }, 400);

          // this.onAddMenuFromSingleCase(params);
          // this.recalculateUniqIdsForDragDrop();
          this.newTab = new CaseTab();
        },
        error => {
          console.error(error);
          this.newTab = new CaseTab();
          // if (error && error.status === 404) {
          //   this.redirectIfNotFound(error);
          // } else {
          //   if (error.error.errorMessage.includes('File doesn\'t exist on a given path') && error.error.errorCode == 404) {
          //     this.returnCode();
          //   }
          //
          //   this.showError(error);
          // }
        }
      );
  }

  private getCase(id, params?: SuiteAddItemsDialogParams, scriptTab?, addToEnd = true) {
    this.caseService.getCase(id)
      .subscribe(
        res => {
          this.newTab.testCase = res;

          // this.returnCode();

          // this.globalEventsManager.setSideNavMod(2);
          if (this.newTab.testCase.name === null) {
            this.newTab.testCase.name = '';
          }

          if (scriptTab != undefined && scriptTab == true) {
            this.newTab.caseParams.case.caseTabIndex.value = 1;

            //this.params.case.caseTabIndex.value = 1;
          }

          // this.setCodeObject();


          this.onAddMenu(params, addToEnd);
          // this.recalculateUniqIdsForDragDrop();
          this.newTab = new CaseTab();
        },
        error => {
          console.error(error);
          this.newTab = new CaseTab();
          // if (error && error.status === 404) {
          //   this.redirectIfNotFound(error);
          // } else {
          //   if (error.error.errorMessage.includes('File doesn\'t exist on a given path') && error.error.errorCode == 404) {
          //     this.returnCode();
          //   }
          //
          //   this.showError(error);
          // }
        }
      );
  }
}
