import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css']
})
export class LinkComponent implements OnInit {

  @Input('text') text = '';
  @Input('icon') icon = '';
  @Output('clickLink') clicked = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  clickLink() {
    this.clicked.emit(true);
  }
}
