import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChatSessionComponent} from './chat-session.component';
import {OverlayscrollbarsModule} from "overlayscrollbars-ngx";
import {AiMessageModule} from "../ai-message/ai-message.module";
import {AiLoadResponseModule} from "../ai-load-response/ai-load-response.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {IconModule} from "../../../mx/icon/icon.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";


@NgModule({
  declarations: [ChatSessionComponent],
  exports: [
    ChatSessionComponent
  ],
  imports: [
    CommonModule,
    OverlayscrollbarsModule,
    AiMessageModule,
    AiLoadResponseModule,
    MatProgressSpinnerModule,
    IconModule,
    MatTooltipModule,
    MatButtonModule
  ]
})
export class ChatSessionModule {
}
