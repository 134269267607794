<h1 mat-dialog-title>{{heading}}</h1>

<div mat-dialog-content>
  <div *ngIf="exportSuccess" class="fs-12 alert alert-success alert-dismissible fade show" role="alert">
    Export finished with success.
    <button (click)="exportSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <app-error-message *ngIf="exportError" [error]="error" [showInDialog]="true" class="fs-12"></app-error-message>

</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" id="cancelButton" mat-button>{{exportSuccess ? 'Close' : 'Cancel'}}</button>
  <button (click)="export()" color="primary" id="exportButton" mat-button>Export</button>
</div>

