<div *ngIf="!spinner" [@.disabled]="disableAnimation">

  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <mat-expansion-panel [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span [ngStyle]="{'color': hideTitle ? 'white' : 'black'}">{{title}}</span>
      </mat-panel-title>
      <mat-panel-description style="color: black;">
        {{description}}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-expansion-panel style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Performance
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field class="w-100">
        <input (ngModelChange)="repeatRunChangeValue($event)" OnlyNumber="true" [(ngModel)]="repeatRun" [disabled]="this.baseRun" matInput maxlength="6"
               placeholder="Repeat run" type="number">
      </mat-form-field>

      <mat-form-field class="w-100">
        <mat-label>Run type</mat-label>
        <mat-select (valueChange)="parallelSerialChangeValue($event)" [disabled]="this.baseRun"
                    [value]="parallelSerial">
          <mat-option [value]="ParallelSerialEnum.PARALLEL">Parallel</mat-option>
          <mat-option [value]="ParallelSerialEnum.SERIAL">Serial</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Params
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field *ngIf="!hideParams" class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="params" [disabled]="this.baseRun" [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput placeholder="Run params"></textarea>
      </mat-form-field>

      <mat-form-field class="w-100">
        <mat-label>Run params type</mat-label>
        <mat-select (valueChange)="runParamsTypeChangeValue($event)" [disabled]="this.baseRun" [value]="runParamsType">
          <mat-option [value]="RunParamsTypeEnum.PRIORITY">Use as priority params</mat-option>
          <mat-option [value]="RunParamsTypeEnum.ONLY_THOSE">Use only those params</mat-option>
          <mat-option [value]="RunParamsTypeEnum.REPLACE_PARAMS">Use as {{caseOrSuiteType.toLowerCase()}} params
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="hideDataDriven != true" style="border-top-right-radius: 0; border-top-left-radius: 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Data driven
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!--        <mat-progress-bar *ngIf="currentFileUpload" mode="determinate" [value]="progress.percentage"-->
      <!--                          class="mt-1"></mat-progress-bar>-->

      <div class="container" style=" max-width: 100%;">
        <div class="row justify-content-md-center">
          <div class="col">
            <div class="input-group mb-3">
              <div class="custom-file">
                <input (change)="selectFile($event)" [disabled]="this.baseRun" class="custom-file-input" id="inputGroupFile01"
                       type="file">
                <label class="custom-file-label" for="inputGroupFile01">{{fileName}}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-md-center" style=" max-width: 100%;">
          <div *ngIf="showUploadError" class="row" style=" max-width: 100%;">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <h4 class="alert-heading">Error occurred during upload!</h4>
              <hr>
              <pre *ngIf="errorMessage" class="mb-0" style=" max-width: 100%;"> {{ errorMessage }} </pre>
              <button (click)="showUploadError=false" aria-label="Close" class="close" data-dismiss="alert"
                      type="button">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <mat-form-field class="w-100">
        <mat-label>Run type</mat-label>
        <mat-select (valueChange)="parallelSerialDataDrivenChangeValue($event)" [disabled]="this.baseRun"
                    [value]="parallelSerialDataDriven">
          <mat-option [value]="ParallelSerialEnum.PARALLEL">Parallel</mat-option>
          <mat-option [value]="ParallelSerialEnum.SERIAL">Serial</mat-option>
        </mat-select>
      </mat-form-field>

    </mat-expansion-panel>


    <mat-expansion-panel #apiRequestExpansionPanel style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Api Requests
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="position: relative;">
        <pre>curl '{{apiRequestUrl}}{{caseOrSuiteName}}' \
-H "X-Project-Id: {{tokensService.getProjectId()}}" \
-H "X-Maxtaf-Api-Key: <span [matTooltip]="'You can set the API key in user settings.'"
                            style="color: red;">your_api_key</span>" \
-H "X-User-TimeZone-Id: {{timeZone}}" \
-H "Accept: application/json" \
-F 'runDetails={{curlyBrace}}
          "repeatRun": "{{repeatRun}}",
    "syncAsync": "SYNC",
    "parallelSerial": "{{parallelSerial}}",
    "dataDrivenParallelSerial": "{{parallelSerialDataDriven}}",
    "runParams": "{{params}}",
    "runParamsType": "{{runParamsType}}"
};type=application/json' <span *ngIf="selectedFiles != null">\</span>
<span *ngIf="selectedFiles != null">-F "dataDrivenFile=@<span matTooltip="Example: c:/maxtaf/dd_file.csv"
                                                              style="color: red;">your_datadriven_file_path</span>;type=text/plain"</span>
        </pre>
        <button (click)="copyToClipboard(); $event.stopPropagation()" color="primary" mat-button
                style="position: absolute; right: 0px; bottom: 20px;">
          <mx-icon mx="copy"></mx-icon>
        </button>
      </div>
    </mat-expansion-panel>

  </mat-expansion-panel>

</div>
