import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AskQuestionDialogComponent} from './ask-question-dialog.component';
import {FormsModule} from "@angular/forms";
import {IconModule} from "../../../mx/icon/icon.module";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {OverlayscrollbarsModule} from "overlayscrollbars-ngx";


@NgModule({
  declarations: [AskQuestionDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    OverlayscrollbarsModule
  ],
  exports: [
    AskQuestionDialogComponent
  ]
})
export class AskQuestionDialogModule {
}
