<mx-options
  #mxOptions
  [allowShadow]="allowShadow"
  [defaultColors]="true"
  [iconMarginBottom]="iconMarginBottom"
  [showButtonText]="showButtonText"
  [showButtons]="showButtons"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
>

  <mx-options-header>

    <!--      restart-->
    <button (click)="restartEngine()"
            *ngIf="showButtons"
            [disabled]="!checkPaths.restartEngine.enabled"
            [matTooltip]="!showTooltip? undefined : 'Restart engine'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="restart"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Restart Engine</span>
    </button>

    <!--      export-->
    <button (click)="export()"
            *ngIf="showButtons"
            [disabled]="!checkPaths.export.enabled"
            [matTooltip]="!showTooltip? undefined : 'Export'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="export"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Export Project</span>
    </button>

    <!--      import-->
    <button (click)="import()"
            *ngIf="showButtons"
            [disabled]="!checkPaths.import.enabled"
            [matTooltip]="!showTooltip? undefined : 'Import'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="import"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Import Project</span>
    </button>

    <!--      export-->
    <button (click)="unauthorizedRequests()"
            *ngIf="showButtons && userService.isAdmin()"
            [matTooltip]="!showTooltip? undefined : 'Unauthorized requests'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="export"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Unauthorized requests</span>
    </button>

  </mx-options-header>

  <mx-options-body>

    <!--    <div class="filter">-->
    <!--      &lt;!&ndash;filter&ndash;&gt;-->
    <!--      <app-filter-suite-->
    <!--        *ngIf="!single"-->
    <!--        [lockSearchParams]="lockFilterParams"-->
    <!--        [searchParams]="searchParams"-->
    <!--        (searchParamsChange)="newParams($event)"-->
    <!--        (filter)="filter()"-->
    <!--        (close)="mxOptions.closeBody();">-->
    <!--      </app-filter-suite>-->
    <!--    </div>-->
  </mx-options-body>
</mx-options>

