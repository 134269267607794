<section class="main-container">
  <div class="tab-container">
    <mat-tab-group #tabGroup
                   (selectedTabChange)="refreshCaseTabEditors($event); caseList.refreshItems(); checkForCleaningAIRelatedSession($event)"
                   [(selectedIndex)]="params.selectedTab.value"
                   animationDuration="0"
                   class="casesection-tabs"
                   id="tabs"
    >

      <mat-tab
      >
        <ng-template mat-tab-label
                     style="min-width: 28px !important; max-width: 28px !important;  padding: 0px !important; position: relative;">
          <div class="list-tab">
            <button
              class="plus-tab no-ripple"
              mat-icon-button>
              <mx-icon
                [style.color]="'black'"
                class="plus-tab-icon"
                marginBottom="true"
                mx="list-tab"
              ></mx-icon>
            </button>

            <div class="list-tab-text-container">
              <span class="list-tab-text fs-14">List</span>
            </div>
          </div>
        </ng-template>

        <app-case-list-options
          (active)="activeRecords(caseList)"
          (compile)="compileDialog(caseList)"
          (compileInfo)="compileInfoDialog()"


          (create)="openCreateDialog(false)"

          (createPageObjectEvent)="openCreatePageObjectEventDialog(false)"
          (delete)="deleteDialog(caseList)"


          (export)="openExportDialog(caseList)"
          (filter)="caseList.refreshItems()"

          (import)="openImportDialog(caseList)"
          (importInfo)="openImportInfoDialog()"
          (refresh)="caseList.refreshItems()"

          (restore)="restoreDialog(caseList)"
          (searchParamsChange)="caseSearchParamsChange($event)"
          (trash)="trashRecords(caseList)"
          [checkPaths]="getCaseListOptionsCheckPathObject()"


          [forDialog]="false"
          [hasSelected]="this.params?.addTabParams?.selectedItems?.length > 0"
          [ignoreParams]="['showPageObjects', 'showCases', 'showTrashRecords','showActiveRecords']"
          [isTrash]="this.params.addTabParams.caseSearch.showTrashRecords.value"
          [searchParams]="params.addTabParams.caseSearch"
          [showCase]="!this.isPageObjectsPage"
          [showDelete]="true"

          [showPageObject]="this.isPageObjectsPage"
          [showRestore]="this.params.addTabParams.caseSearch.showTrashRecords.value"
        >

        </app-case-list-options>

        <app-list-case #caseList
                       (clickedItem)="clickedItem($event, false)"
                       (clickedScriptItem)="clickedScriptItem($event, false)"

                       (pageParamsChange)="casePageParamsChange($event)"
                       (pagesInfoChanges)="pagesInfoChanges($event)"
                       (selected)="selected($event)"
                       [autoSetItems]="true"


                       [displayedColumns]="['select', 'openScript', 'name', 'category', 'caseType', 'description']"
                       [multiSelect]="true"
                       [pageParams]="params.addTabParams.casePage"
                       [searchParams]="params.addTabParams.caseSearch"
        ></app-list-case>

      </mat-tab>


      <mat-tab *ngFor="let tab of this.params.tabs; let index = index; trackBy: trackByIndex">
        <ng-template mat-tab-label>
          <div
            class="w-100 child-menu-drop-list">
            <div
              class="d-flex align-items-center"
            >

              <div class="d-flex w-100" style="min-width: 160px">

                <button *ngIf="!tab.isSaved" class="tab-button" mat-icon-button matTooltip="There are unsaved changes">
                </button>


                <div *ngIf="tab.isSaved" style="width:40px; height: 30px"></div>

                <div [matTooltip]="tab.testCase.name" class="d-flex align-items-center">
                <span
                  class="tab-name"> {{cutNameIfNameIsLongerThenMaxAllowedSize(tab.testCase.name)}}{{!tab.isSaved ? '*' : ''}}</span>
                </div>

                <button
                  (click)="onRemoveMenu($event, index);"
                  class="tab-button"
                  color="accent"
                  mat-icon-button
                  matTooltip="Close case">
                  <mx-icon class="tab-close-icon" marginBottom="true" mx="close"></mx-icon>
                </button>

              </div>
            </div>
          </div>
        </ng-template>


        <div
          *ngIf="params.selectedTab.value != index + 1"
          style="padding-top:3rem; padding-bottom: 3rem; display: flex; justify-content: center; align-items: center; height: calc(100vh - 100px);"
        >
          <mat-spinner></mat-spinner>
        </div>

        <app-case
          #caseComponent
          (activeCodeChange)="setActiveCodeChanges(tab, $event)"
          (caseChange)="tab.testCase = $event;changedParams();"
          (caseInitCalled)="caseComponent.refreshCaseEditors(true)"
          (deleted)="removeTabMenu(index)"
          (duplicate)="addItemInTabs($event, undefined, undefined)"
          (isSavedChanges)="setIsSavedChanges(tab, $event)"
          (paramsChange)="changedCaseParams($event, tab)"
          *ngIf="params.selectedTab.value === index + 1"
          [activeCode]="tab.activeCode"

          [case]="tab.testCase"
          [compileErrorInfo]="tab.compileError"
          [compileResult]="tab.compileResult"
          [edit]="!tab.testCase.archived"
          [firstCaseId]="tab.testCase.id"
          [params]="tab.caseParams"

          [tabsMode]="true"
          [transfer]="tab.isTransferCase"
          [trash]="tab.testCase.archived"
        >

        </app-case>

      </mat-tab>

    </mat-tab-group>
  </div>
</section>
