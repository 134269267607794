export class PageInfo {


  constructor(
    public isFirstPage?: boolean,
    public isLastPage?: boolean,
    public pageIndex?: number, // pageNumber
    public sizeOfElementsOnPage?: number,
    public maxElementsOnPage?: number, // size
    public totalElements?: number,
    public totalPages?: number) {
  }
}
