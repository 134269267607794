import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChatSessionSearchParams} from "../../shared/services/ai-const.service";
import {ChatSession} from "../../shared/models/chatSession";
import {AiChatService} from "../../shared/services/ai-chat.service";

@Component({
  selector: 'app-ai-delete-chat-sessions',
  templateUrl: './ai-delete-chat-sessions.component.html',
  styleUrls: ['./ai-delete-chat-sessions.component.css']
})
export class AiDeleteChatSessionsComponent implements OnInit {


  title = '';
  chatSessionSearchParams: ChatSessionSearchParams;
  selectedChatSession: ChatSession;
  useFilter = true;

  constructor(
    private aiChatService: AiChatService,
    public dialogRef: MatDialogRef<AiDeleteChatSessionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      chatSessionSearchParams: ChatSessionSearchParams,
      selectedChatSession: ChatSession
    }
  ) {
    this.chatSessionSearchParams = data.chatSessionSearchParams;
    this.selectedChatSession = data.selectedChatSession;

    if (this.selectedChatSession != null) {
      this.title = 'Are you sure you want to delete the chat titled \'' + this.selectedChatSession.title + '\'?'
    } else {
      this.title = 'Are you sure you want to delete chats?'
    }

  }

  // ESC shortcut for closing the window
  noShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('noButton').click();
    }
  };

  // ENTER shortcut for running the test
  yesShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('yesButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.noShortcutListener, false);
    document.addEventListener('keydown', this.yesShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.noShortcutListener, false);
    document.removeEventListener('keydown', this.yesShortcutListener, false);
  }

  deleteSessions() {
    if (this.selectedChatSession != null) {
      this.deleteAIChat();
    } else {
      this.deleteAIChats();
    }

  }

  deleteAIChat() {
    // this.showLoadResponseDiv = true;
    this.aiChatService.deleteChatSession(this.selectedChatSession.id).subscribe(res => {

      this.dialogRef.close(true);
    }, error => {
      // this.showLoadResponseDiv = false;
      console.error('error: ', error);
    });
  }

  deleteAIChats() {
    let query = "?a=1&" + (this.chatSessionSearchParams != null ? (
      ((this.chatSessionSearchParams.hideCaseRelated.value == null) ? "" : '&hideCaseRelated=' + this.chatSessionSearchParams.hideCaseRelated.value) +
      ((this.chatSessionSearchParams.title.value == null) ? "" : '&title=' + this.chatSessionSearchParams.title.value) +
      ((this.chatSessionSearchParams.exactSearch.value == null) ? "" : '&exactSearch=' + this.chatSessionSearchParams.exactSearch.value)
    ) : "")

    if (!this.useFilter) {
      query = "";
    }

    this.aiChatService.deleteChatSessions(query).subscribe(res => {

      this.dialogRef.close(true);
    }, error => {
      // this.showLoadResponseDiv = false;
      console.error('error: ', error);
    });
  }

}
