import {Injectable} from '@angular/core';
import {ApiService} from "../../../../shared/services/api.service";
import {UserProjectConfigService} from "../../../../shared/services/user-project-config.service";

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {

  constructor(
    private apiService: ApiService,
    private userProjectConfigService: UserProjectConfigService
  ) {
  }

  createApiKey() {
    return this.apiService.post(this.userProjectConfigService.createApiKey(), '');
  }

  deleteApiKey() {
    return this.apiService.delete(this.userProjectConfigService.createApiKey());
  }
}
