<section class="main-container" style="height: calc(100vh - 54px) !important;     display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
font-size: 14px;">
  <div style="width: 100%">
    <div class="nav-tree-category-title" style="height: 30px; background: #d9d7d7;">
      <div style="padding-left: 1rem">
        <span class="mr-1">AI Chat</span>
        <!--        warningMessage-->

        <span (click)="openAddOrEditProjectParamDialog()" *ngIf="this.projectParamValue == null || this.projectParamValue == ''"
              [matTooltip]="'You have a daily limit of '+maximumNumberOfRequests+' requests with the MaxTAF Cloud AI key.\n'+
'You have already used '+currentNumberOfRequests+' requests.\n'+
'If you would like to use your own openAI key, specify it in the configuration parameters as the value for \'mx.ai.openai.key\'.'" class="project-params-link">
          ({{currentNumberOfRequests}}/{{maximumNumberOfRequests}})
        </span>
      </div>

      <div class="ai-options">


        <button (click)="scrollToBottom()" *ngIf=" this.selectedChatSession != null"
                [matTooltip]="'Scroll down'"
                class="show-hide-nav-bar-button"
                mat-icon-button>
          <mx-icon [mx]="'arrow-collapse-down'"></mx-icon>
        </button>
        <button (click)="setSearchChatSessions()" *ngIf=" this.selectedChatSession == null"
                [matTooltip]="'Search'"
                class="show-hide-nav-bar-button"
                mat-icon-button>
          <mx-icon [mx]="'filter'"></mx-icon>
        </button>
        <button (click)="openChatSessions()" *ngIf="this.selectedChatSession != null"
                [matTooltip]="'Chat Sessions'"
                class="show-hide-nav-bar-button"
                mat-icon-button>
          <mx-icon [mx]="'list'"></mx-icon>
        </button>
        <button (click)="resizeAI()" [disabled]="this.userService.isCurrentUserGuest() || this.userService.isCurrentUserAnonymous() "
                [matTooltip]="'Resize ai'"
                class="show-hide-nav-bar-button"
                mat-icon-button>
          <mx-icon [mx]="!resizeAIMode?'arrow-collapse-right' : 'arrow-collapse-left'"></mx-icon>
        </button>
        <!--        <button mat-icon-button class="show-hide-nav-bar-button"-->
        <!--                [matTooltip]="'Delete'"-->
        <!--                *ngIf=" this.selectedChatSession != null"-->
        <!--                (click)="menuDeleteAi()">-->
        <!--          <mx-icon [mx]="'delete'"></mx-icon>-->
        <!--        </button>-->
        <button (click)="openOptionsContextMenu($event); $event.stopPropagation();" [disabled]="this.userService.isCurrentUserGuest() || this.userService.isCurrentUserAnonymous() "
                [matTooltip]="'options'"
                class="show-hide-nav-bar-button"
                mat-icon-button>
          <mx-icon mx="options"></mx-icon>
        </button>
        <button (click)="hideShowVisibility()" [matTooltip]="'Hide conversation'"
                class="show-hide-nav-bar-button"
                mat-icon-button>
          <mx-icon mx="minus"></mx-icon>
        </button>
      </div>
      <div
        *ngIf="showChatSessionSearch"
        style="position: absolute; width: 100%; top: 30px; z-index: 100001; background: #d9d7d7;">

        <app-chat-sessions-filter

          (close)="hideSearchChatSessions();"
          (filter)="filterSearchChatSessions($event)"
          (searchParamsChange)="newSearchChatSessionsParams($event)"
          [lockSearchParams]="[]"
          [searchParams]="this.chatSessionSearchParams">

        </app-chat-sessions-filter>

      </div>
    </div>

    <ng-template #contextMenu>
      <section (clickOutside)="closeContextMenu()" class="node-context-menu">

        <button (click)="menuRefreshAi()" class="context-menu-button" mat-menu-item>
          <mx-icon marginBottom="true" mx="refresh"></mx-icon>
          Refresh
        </button>
        <button (click)="menuDeleteAi()" class="context-menu-button" mat-menu-item>
          <mx-icon marginBottom="true" mx="delete"></mx-icon>
          Delete
        </button>
        <!--        <button class="context-menu-button" mat-menu-item (click)="menuExportAi()">-->
        <!--          <mx-icon marginBottom="true" mx="export"></mx-icon>-->
        <!--          Export-->
        <!--        </button>-->
        <button (click)="menuAiSetting()" class="context-menu-button" mat-menu-item>
          <mx-icon marginBottom="true" mx="settings"></mx-icon>
          Settings
        </button>
      </section>
    </ng-template>


  </div>

  <div *ngIf="this.userService.isCurrentUserAnonymous() || this.userService.isCurrentUserGuest()"
       class="ai-guest-anonymous">
      <span>
        As a {{(this.userService.isCurrentUserAnonymous() ? 'anonymous' : 'guest')}} you do not have access to AI on this project
      </span>
  </div>
  <!--  selected: {{this.selectedChatSession != null ? this.selectedChatSession.title : 'X'}}-->
  <!--  <br>-->
  <!--  probable: {{this.probableSelectedChatSession != null ? this.probableSelectedChatSession.title : 'X'}}-->

  <!--  *ngIf="selectedChatSession != null && ((probableSelectedChatSession != undefined && this.selectedChatSession.id != this.probableSelectedChatSession.id) || probableCloseAIChat)"-->
  <div
    *ngIf="selectedChatSession != null && probableSelectedChatSession != undefined && this.selectedChatSession.id != this.probableSelectedChatSession.id"
    style="
  height: 150px;
    background: #d9d7d7;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
">
    <button
      (click)="closeIdInfoDiv()"
      color="primary"
      mat-icon-button
      style="
      position: absolute;
      top: 20px;
      right: 0px;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 20px;
  ">&times;
    </button>
    <div style="
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
padding-top: 10px;
">
      <!--      *ngIf="!probableCloseAIChat"-->
      <span>Do you want to open the AI chat for the '{{probableSelectedChatSessionTestName}}' case?</span>
      <!--      <span *ngIf="probableCloseAIChat">Do you want to return to the AI chats list?</span>-->
    </div>
    <div style="padding-bottom: 10px">
      <!--      *ngIf="!probableCloseAIChat"-->
      <button (click)="switchToNewAiChat()"
              color="primary"
              mat-raised-button
              style="min-width: 140px">Switch
      </button>
      <!--      <button-->
      <!--        *ngIf="probableCloseAIChat"-->
      <!--        mat-raised-button-->
      <!--        style="min-width: 140px"-->
      <!--        (click)="returnToAiSessionList()"-->
      <!--        color="primary">Return-->
      <!--      </button>-->
    </div>

  </div>

  <as-split *ngIf="!this.userService.isCurrentUserAnonymous() && !this.userService.isCurrentUserGuest()" [gutterSize]="5" direction="vertical"
            unit="pixel">

    <as-split-area #chatArea style="overflow: hidden;caret-color: transparent;">


      <app-ai-sessions-list

        #aiSessionsListComponent
        (clickedSession)="clickedSession($event)"
        *ngIf="selectedChatSession == null && !creatingChatSession"
        [chatSessionSearch]="chatSessionSearchParams"

        [maxHeight]="getChatHeight()"
      >
      </app-ai-sessions-list>

      <app-chat-session
        #aiChatSession
        (completedQuestion)="isRunActive = false"
        (startQuestion)="isRunActive = true"
        *ngIf="selectedChatSession != null || creatingChatSession"

        [chatSession]="selectedChatSession"
        [maxHeight]="getChatHeight()"
      >

      </app-chat-session>

      <!--        </overlay-scrollbars>-->
      <!--      </div>-->
    </as-split-area>


    <as-split-area
      #inputArea
      [maxSize]="this.inputHeight"
      [minSize]="this.inputHeight"

      [size]="this.inputHeight"
      style="overflow: hidden; background: #d9d7d7;"
    >
      <div #aiInputWrapper class="ai-input-wrapper">
        <div
          *ngIf="errorMessage != null && errorMessage != ''"
          style="
  border-top: #007bff 1px solid;
    border-bottom: #007bff 1px solid;
  background: #d9d7d7;"
        >

        <span (click)="closeMessage()" class="close-dialog"
              style="float: right; cursor: pointer; padding-right: 5px;padding-top: 5px;">
          <mat-icon>close</mat-icon>
        </span>

          <div class="message-box">
            {{errorMessage}}
          </div>

        </div>


        <div
          [ngStyle]="{ 'height': inputHeight + 'px' }"
        >
          <app-ai-input
            #aiInput
            (askQuestion)="askAndSaveQuestion($event)"
            (inputActiveChanges)="inputActiveChanges($event)"
            (linkCase)="addCaseCodeToQuestion($event)"

            (rows)="setInputHeight($event)"
            (stopRequest)="stopAIRequest()"
            [disableInput]="disableInput"
            [inputActive]="this.selectedChatSession != null"
            [inputHeight]="inputHeight-10"
            [isRunActive]="isRunActive && !disableInput"
            [showLinkCase]="this.selectedChatSession != null && this.selectedChatSession.sessionPurposeRelatedId != null">

          </app-ai-input>
        </div>
      </div>
    </as-split-area>
  </as-split>
</section>
