<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>

  <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
    <p *ngIf="error" class="mb-0">{{ errorMessage }}</p>
    <button (click)="error=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <label>Name</label>
  <div class="mb-2">
    <input [(ngModel)]="projectParam.name" [disabled]="true" type="text">
  </div>

  <label> {{projectParam.masked && projectParam.hasValue ? 'New ' : ''}}Value</label>
  <div class="mb-2">
    <input [(ngModel)]="projectParam.value" [disabled]="!checkPaths.update.enabled || !allowEdit" id="valueInput"
           type="text">
  </div>

  <!--  <label>Description</label>-->
  <!--  <div>-->
  <!--    <input type="text" [disabled]="!checkPaths.update.enabled || !allowEdit" [(ngModel)]="projectParam.description">-->
  <!--  </div>-->


</div>

<div mat-dialog-actions>
  <button (click)="close()" id="cancelButton" mat-button>{{allowEdit ? 'Cancel' : 'Close'}}</button>

  <button (click)="edit()" *ngIf="allowEdit" [disabled]="!checkPaths.update.enabled" color="primary"
          id="createButton"
          mat-button>
    {{projectParam.masked && projectParam.hasValue ? 'Save' : 'Save'}}
  </button>
</div>
