import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditSessionDialogComponent} from './edit-session-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {IconModule} from "../../../mx/icon/icon.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [EditSessionDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    IconModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    EditSessionDialogComponent
  ]
})
export class EditSessionDialogModule {
}
