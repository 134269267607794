import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RunAdvanceSettingsComponent} from './run-advance-settings.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ErrorModule} from '../../../mx/error/error.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IconModule} from '../../../mx/icon/icon.module';


@NgModule({
  declarations: [RunAdvanceSettingsComponent],
  exports: [RunAdvanceSettingsComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatTabsModule,
    MatExpansionModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    RouterModule,
    MatInputModule,
    MatDialogModule,
    ErrorModule,
    MatTooltipModule,
    IconModule
  ]
})
export class RunAdvanceSettingsModule {
}
