import {NgModule} from '@angular/core';
import {ProjectParamsFilterComponent} from './project-params-filter.component';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {IconModule} from '../../../mx/icon/icon.module';

@NgModule({
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    IconModule
  ],
  declarations: [
    ProjectParamsFilterComponent
  ],
  exports: [
    ProjectParamsFilterComponent
  ]
})

export class ProjectParamsFilterModule {
}
