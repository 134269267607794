import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GlobalExportService} from '../../shared/services/global-export.service';
import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {ImportInfoDetails} from '../../../dialogs/import/ImportInfoDetails';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-global-import-dialog',
  templateUrl: './global-import-dialog.component.html',
  styleUrls: ['./global-import-dialog.component.css']
})
export class GlobalImportDialogComponent implements OnInit {

  @Input()
  type = '';

  fileName = 'Choose file';

  inputFileReference = null;

  selectedFiles: FileList;

  currentFileUpload: File;
  progress: { percentage: number } = {percentage: 0};

  showSuccess = false;
  showUploadError = false;
  error;

  successMessage: string;

  spinner = false;
  finished = false;

  projectParamsState = false;
  importType = 'zip';

  hideSourceRadioGroup = false;

  hideOverwriteButton2 = true;
  overwriteButton1Message = 'Overwrite data with import file?';
  overwriteButton2Message = '';


  importInfoDetails: ImportInfoDetails = undefined;

  showError = false;
  workspaceDot = 0;
  counterDot = 0;
  importSpinner: boolean = false;

  overwriteProjectParams = false;
  overwriteCases = false;
  compile = false;
  overwriteSuites = false;
  overwriteSchedules = false;
  overwriteWorkspace = false;

  refreshIntervalSub: Subscription;
  @Input('refreshIntervalInMs') refreshInterval = 1000;
  importInfoId = undefined;

  constructor(public globalExportService: GlobalExportService,
              public dialogRef: MatDialogRef<GlobalImportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {}) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.stopLiveRefresh();
  }

  showErrorAlert(error) {
    this.error = error;
    this.showUploadError = true;
  }

  hideErrorAlert() {
    this.error = null;
    this.showUploadError = false;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectFile(event) {
    this.hideErrorAlert();
    const file: File = event.target.files.item(0);

    this.inputFileReference = event.target;

    if (file.name.toLowerCase().match('.*\.zip')) {
      this.selectedFiles = event.target.files;
      this.fileName = this.selectedFiles.item(0).name;

      if (file.name.toLowerCase().match('.*\.java')) {
        this.importType = 'junit';
      }

      if (file.name.toLowerCase().match('.*\.xml')) {
        this.importType = 'xml';
      }

      if (file.name.toLowerCase().match('.*\.py')) {
        this.importType = 'python';
      }

      if (file.name.toLowerCase().match('.*\.js')) {
        this.importType = 'javascript';
      }

      if (file.name.toLowerCase().match('.*\.cs')) {
        this.importType = 'csharp';
      }

      if (file.name.toLowerCase().match('.*\.zip')) {
        this.importType = 'zip';
      }

      if (file.name.toLowerCase().match('.*\.json')) {
        this.importType = 'json';
      }

    } else {
      this.showErrorAlert({
        name: 'Invalid format!',
        message: 'Supported extensions are zip - please select one of these for import.'
      });
      this.selectedFiles = null;
      this.fileName = 'Choose file';
    }
  }

  startLiveRefresh() {
    this.refreshIntervalSub = interval(this.refreshInterval)
      .subscribe(res => {
        if (this.counterDot + 1 > 2) {
          this.counterDot = 0;
          this.workspaceDot = 0;
        } else {
          this.counterDot += 1;
          this.workspaceDot += 1;
        }

        this.globalExportService.getImportInfo(this.importInfoId).subscribe(
          res => {
            this.importInfoResponse(res);
          },
          error => {
            this.spinner = false;
            console.error(error);
          }
        );

      });
  }

  stopLiveRefresh() {
    this.showSuccess = true;
    if (this.refreshIntervalSub) {
      this.refreshIntervalSub.unsubscribe();
    }
  }

  upload() {
    this.hideErrorAlert();
    this.showSuccess = false;
    this.progress.percentage = 0;

    this.currentFileUpload = this.selectedFiles.item(0);

    this.spinner = true;
    this.importSpinner = true;
    this.globalExportService.globalImport(
      this.currentFileUpload,
      this.overwriteProjectParams,
      this.overwriteCases,
      this.compile,
      this.overwriteSuites,
      this.overwriteSchedules,
      this.overwriteWorkspace
    )
      .subscribe(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
          } else {
            if (event instanceof HttpResponse) {
              // this.showSuccess = true;
              // let successJson = JSON.parse(event.body);
              const successJson = event.body;
              this.importInfoId = successJson.id;
              this.successMessage = 'File ' + this.currentFileUpload.name + ' was imported with success.';

              if (this.progress.percentage >= 100) {
                this.spinner = false;
                this.finished = true;

                this.startLiveRefresh();
              }
            } else {
            }

            this.inputFileReference.value = '';
          }
        },
        error => {
          console.error(' $$$$$$$$ error', error);
          this.showErrorAlert(error);

          this.fileName = 'Choose file';
          this.progress = {percentage: 0};
          this.selectedFiles = null;
          this.currentFileUpload = null;
          this.selectedFiles = null;
          this.spinner = false;
          this.inputFileReference.value = '';
        }
      );

    this.selectedFiles = undefined;
  }

  paramsChangeValue() {

  }

  private importInfoResponse(res: ImportInfoDetails) {

    this.importInfoDetails = res;

    if (this.importInfoDetails.projectParamsCompleted) {
      this.stopLiveRefresh();
    }
  }
}
