import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorMessageComponent} from './error-message/error-message.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {IconModule} from '../icon/icon.module';
import {OverlayscrollbarsModule} from 'overlayscrollbars-ngx';


@NgModule({
  declarations: [ErrorMessageComponent],
  exports: [
    ErrorMessageComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    IconModule,
    OverlayscrollbarsModule
  ]
})
export class ErrorModule {
}
