<app-error-message *ngIf="showSaveError" [error]="error"></app-error-message>

<h1 class="text-center" mat-dialog-title>Already has a {{type}} named "{{(type == 'CASE') ? case.name :
  suite.name}}"</h1>

<div mat-dialog-actions>
  <button (click)="openEditNameDialog()" class="w-100 ml-0" mat-button> Change name</button>
  <button (click)="replace()" class="w-100 ml-0" mat-button> Replace {{type == 'CASE' ? 'cases' : 'suites'}}</button>
  <button [mat-dialog-close]="false" class="w-100 ml-0" mat-button> Skip</button>
</div>
