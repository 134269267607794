<div mat-dialog-title style="display: flex; justify-content: space-between;">
  <h4 style="align-self:flex-start;">Change password</h4>

  <span [style.color]="color" style="font-size: 16px; align-self:flex-end;">{{message}}</span>
  <mat-spinner *ngIf="spinner" [diameter]="50" color="accent" style="align-self:flex-end;"></mat-spinner>
</div>

<div mat-dialog-content>

  <form #loginForm="ngForm" [formGroup]="form">

    <mat-form-field class="full-width-input">
      <input [type]="hideCurrentPasswordButton  ? 'password' : 'text'" formControlName="oldPassword" matInput
             name="hideCurrentPasswordButton" ngModel
             placeholder="Current password">
      <mat-icon (click)="hideCurrentPasswordButton = !hideCurrentPasswordButton" matSuffix>{{hideCurrentPasswordButton ?
        'visibility' : 'visibility_off'}}
      </mat-icon>
      <mat-error>Current password is <strong>required</strong></mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-input">
      <input [type]="hidePasswordButton  ? 'password' : 'text'" formControlName="password" matInput
             placeholder="New password">
      <mat-icon (click)="hidePasswordButton = !hidePasswordButton" matSuffix>{{hidePasswordButton ? 'visibility' :
        'visibility_off'}}
      </mat-icon>
      <mat-error *ngIf="form.get('password').invalid">
        Password is <strong>required</strong> and min length is 5
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-input">
      <input [type]="hideConfirmPasswordButton ? 'password' : 'text'" formControlName="confirmPassword" matInput
             placeholder="Retype new password">
      <mat-icon (click)="hideConfirmPasswordButton = !hideConfirmPasswordButton" matSuffix>
        {{hideConfirmPasswordButton ? 'visibility' : 'visibility_off'}}
      </mat-icon>
      <mat-error *ngIf="form.get('confirmPassword')?.invalid">Passwords should be the same</mat-error>
    </mat-form-field>

  </form>

</div>

<div mat-dialog-actions style="display: flex; justify-content: flex-end;">
  <button (click)="tryChangePassword()" *ngIf="!showButtonOk" color="primary" mat-raised-button
          style="float:right;font-weight:bold;">Change password
  </button>
  <button *ngIf="showButtonOk" [mat-dialog-close]="true" color="primary" mat-raised-button
          style="float:right;font-weight:bold;">OK
  </button>
  <button [mat-dialog-close]="false" mat-button>Cancel</button>
</div>
