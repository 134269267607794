import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {ConfigService} from './config.service';
import {first, map} from 'rxjs/operators';
import {UserService} from '../../layout/users/shared/services/user.service';
import {MaxtafTokensStorageService} from './maxtaf-tokens-storage.service';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {UserProject} from '../../layout/users/shared/models/userProject';
import {User} from '../../layout/users/shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthMaxtafService {

  constructor(
    public apiService: ApiService,
    public config: ConfigService,
    public userService: UserService,
    public tokensService: MaxtafTokensStorageService,
    private auth: AngularFireAuth,
    private router: Router
  ) {
  }

  signup(fireToken: string): Observable<void> {
    this.tokensService.setFireToken(fireToken);
    return this.apiService.post(this.config.getSignUpUrl(), null).pipe(map(user => {
      if (this.userService.getCurrentUserProject() != null) {
        this.userService.setCurrentUserProject(new UserProject());
      }
      this.userService.getCurrentUserProject().user = new User(user.id, user.email, user.displayName);
      this.tokensService.setUserId(user.id);
    }));
  }

  // logout(): void {
  //   this.tokensService.clearSession();
  //   this.userService.setCurrentUserProject(new UserProject());
  //   this.auth.signOut().then(() => {
  //     this.router.navigate(['/login']).then();
  //   });
  // }


  logout(redirectQueryParams = undefined): void {

    console.log('%c 11212 1 logout(currentUrl = undefined) redirectQueryParams: ', 'background: red', redirectQueryParams);

    // Clear session and current user details
    this.tokensService.clearSession();
    this.userService.setCurrentUserProject(new UserProject());

    // Sign out and navigate to the login page, passing the current URL
    this.auth.signOut().then(() => {
      this.router.navigate(['/login'], {queryParams: redirectQueryParams}).then();
    });
  }

  isLoginUser() {
    let isLoginUser = this.tokensService.getFireToken() != null && this.tokensService.getFireToken() != '';
    return isLoginUser;
  }

  changePassword(passwordChanger): Observable<any> {
    return this.apiService.post(this.config.getChangePasswordUrl(), passwordChanger);
  }

  refreshToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.auth.authState.pipe(first()).subscribe(
        async user => {
          if (user) {
            (await this.auth.currentUser).getIdToken(true).then(
              token => {
                this.tokensService.setFireToken(token);
                resolve('Token refreshed successfully');
              }
            ).catch(
              error => {
                reject('Token cannot be refreshed because of error: ' + error);
              }
            );
          }
        },
        error => {
          console.error(error);
        }
      );
    });
  }

}
