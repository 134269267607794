import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {UserService} from '../../../users/shared/services/user.service';
import {AiConfigService} from "./ai-config.service";
import {AIChatConfig} from "../models/aIChatConfig";
import {Observable} from "rxjs";
import {Params} from "../../../mx/util/params/params";
import {UserProjectAIConfig} from "../models/userProjectAIConfig";

@Injectable({
  providedIn: 'root'
})
export class AiChatService {

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private aiConfigService: AiConfigService
  ) {
  }

  getChat() {
    return this.apiService.get(this.aiConfigService.aiController_GET_getAIChat(), '');
  }

  saveQuestion(sessionId, message: any) {
    return this.apiService.post(this.aiConfigService.chatRecordController_POST_addChatRecord(sessionId), message);
  }

  updateChat(id, message, chatSessionId) {
    return this.apiService.put(this.aiConfigService.aiController_PUT_updateQuestion(id) + '?chatSessionId=' + chatSessionId, message);
  }

  askQuestionWithoutContent() {
    return this.apiService.get(this.aiConfigService.aiController_GET_askQuestion(), "{}");
  }

  delete() {
    return this.apiService.delete(this.aiConfigService.aiController_DELETE_deleteAiChat());
  }

  export() {
    return this.apiService.get(this.aiConfigService.aiController_GET_exportAiChat());
  }

  getAiConfig(): Observable<AIChatConfig> {
    return this.apiService.get(this.aiConfigService.aiController_GET_config());
  }

  updateAiConfig(aiConfig: AIChatConfig): Observable<AIChatConfig> {
    return this.apiService.put(this.aiConfigService.aiController_GET_config(), aiConfig);
  }

  //new

  getChatRecord(chatRecordId) {
    return this.apiService.get(this.aiConfigService.chatRecordController_GET_getChatRecord(chatRecordId));
  }

  //Flux<ChatRecordDTO>
  getChatRecords(chatSessionId) {
    return this.apiService.get(this.aiConfigService.chatRecordController_GET_getChatRecords(chatSessionId));
  }

  addChatRecord(chatSessionId, newPrompt) {
    return this.apiService.post(this.aiConfigService.chatRecordController_POST_addChatRecord(chatSessionId), newPrompt);
  }

  // Flux<ChatRecordDTO>
  updateChatRecordAndRemoveAllAfter(chatRecordId, newPrompt) {
    return this.apiService.put(this.aiConfigService.chatRecordController_PUT_updateChatRecordAndRemoveAllAfter(chatRecordId), newPrompt);
  }

//Flux<ChatRecordDTO>
  deleteChatRecordAndRemoveAllAfter(chatRecordId, chatSessionId) {
    return this.apiService.delete(this.aiConfigService.chatRecordController_DELETE_deleteChatRecordAndRemoveAllAfter(chatRecordId) + "?chatSessionId=" + chatSessionId);
  }

  deleteChatRecord(chatRecordId) {
    return this.apiService.delete(this.aiConfigService.chatRecordController_DELETE_deleteChatRecord(chatRecordId));
  }

  deleteChatRecords(chatSessionId) {
    return this.apiService.delete(this.aiConfigService.chatRecordController_DELETE_deleteChatRecords(chatSessionId));
  }

  getChatSession(chatSessionId) {
    return this.apiService.get(this.aiConfigService.chatSessionController_GET_getChatSession(chatSessionId));
  }

  getChatSessions() {
    return this.apiService.get(this.aiConfigService.chatSessionController_GET_getChatSessions());
  }

  updateChatSession(chatSession) {
    return this.apiService.put(this.aiConfigService.chatSessionController_PUT_updateChatSession(), chatSession);
  }

  createChatSession(chatSession) {
    return this.apiService.post(this.aiConfigService.chatSessionController_POST_createChatSession(), chatSession);
  }

  deleteChatSession(chatSessionId) {
    return this.apiService.delete(this.aiConfigService.chatSessionController_DELETE_deleteChatSession(chatSessionId));
  }

  clearChatSession(chatSessionId) {
    return this.apiService.put(this.aiConfigService.chatSessionController_PUT_clearChatSession(chatSessionId), "{}");
  }

  askQuestionAsync() {
    return this.apiService.get(this.aiConfigService.aIController_GET_askQuestionAsync());
  }

  askQuestionWithSession(chatSessionId, newPrompt) {
    return this.apiService.get(this.aiConfigService.aIController_GET_askQuestionWithSession(chatSessionId) + (newPrompt != undefined && newPrompt != '' ? "?question=" + newPrompt : ''));
  }

  getProjectAiConfig(name) {
    return this.apiService.get(this.aiConfigService.projectAIParamController_GET_getProjectAiConfig() + "?name=" + name);
  }

  //Flux<ProjectAIConfigDTO>
  getProjectAiConfigs(searchParams, pageParams) {//AIProjectConfigFilter aiProjectConfigFilter
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};


    return this.apiService.get(this.aiConfigService.projectAIParamController_GET_getProjectAiConfigs(), params);
  }

  updateProjectAiConfig(projectAIConfig) {
    return this.apiService.put(this.aiConfigService.projectAIParamController_PUT_updateProjectAiConfig(), projectAIConfig);
  }

  getUserProjectAIConfigs() {//Flux<UserProjectAIConfigDTO>
    return this.apiService.get(this.aiConfigService.userProjectAIConfigController_GET_getUserProjectAIConfigs());
  }

  getUserProjectAIConfig(aiVendor) {
    return this.apiService.get(this.aiConfigService.userProjectAIConfigController_GET_getUserProjectAIConfig() + "?aiVendor=" + aiVendor);
  }

  updateUserProjectAIConfig(userProjectAIConfig: UserProjectAIConfig) {
    return this.apiService.put(this.aiConfigService.userProjectAIConfigController_PUT_updateUserProjectAIConfig(), userProjectAIConfig);
  }

  getUserProjectAI() {
    return this.apiService.get(this.aiConfigService.userProjectAIController_GET_getUserProjectAI());
  }

  getCleanUserProjectAI() {
    return this.apiService.get(this.aiConfigService.userProjectAIController_GET_getCleanUserProjectAI());
  }

  userProjectAIActiveVendor(newDefaultVendor) {
    return this.apiService.put(this.aiConfigService.userProjectAIController_PUT_userProjectAIActiveVendor() + "?vendor=" + newDefaultVendor, "{}");
  }

  userProjectAIActiveSession(newDefaultChatSessionId) {
    return this.apiService.put(this.aiConfigService.userProjectAIController_PUT_userProjectAIActiveSession() + "?chatSessionId=" + newDefaultChatSessionId, '{}');
  }

  getChatSessionByPurpose(purpose, caseId: string, title: string) {
    return this.apiService.get(this.aiConfigService.userProjectAIController_GET_getChatSessionByPurpose(caseId) + "?title=" + title + "&purpose=" + purpose, '{}');
  }

  cancelAiRequest(sessionId: string, runId: string) {
    return this.apiService.get(this.aiConfigService.userProjectAIController_GET_cancelAiRequest(sessionId, runId), '{}');
  }


  deleteChatSessions(query) {


    // pageParams = new Params(pageParams);
    // const params = {...searchParams, ...pageParams};


    return this.apiService.delete(this.aiConfigService.chatSessionController_DELETE_deleteChatSessions() + query);
  }
}
