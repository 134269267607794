<mat-expansion-panel
  #expansionPanel
  (keydown.Enter)="$event.stopImmediatePropagation();setBody();"
  (keydown.Space)="$event.stopImmediatePropagation();setBody();"
  [expanded]="false"
  [hideToggle]="true"
  [ngClass]="{'card-shadow': allowShadow}">

  <mat-expansion-panel-header (click)="setBody()"
                              (keydown.Enter)="$event.stopImmediatePropagation();setBody();"
                              (keydown.Space)="$event.stopImmediatePropagation();setBody();"
                              [ngClass]="{
                                          'normal-height': !smallHeight,
                                          'small-height': smallHeight,
                                          'small-options-button-toolbar': smallHeight,
                                          'mx-options-header-second-colors': !defaultColors,
                                          'mx-options-header-default-colors': defaultColors,
                                          'header-bottom-border': headerBottomBorder
                                          }"
                              style="padding:0 !important">

    <mat-toolbar [ngClass]="{
                  'normal-height': !smallHeight,
                  'small-height': smallHeight,
                  'options-button': smallHeight,
                  'mx-options-header-second-colors': !defaultColors,
                  'mx-options-header-default-colors': defaultColors
                 }"
                 class="toolbar-options">


      <button (click)="scrollLeft()"
              *ngIf="hasOverflowX()"
              [ngClass]="{
                          'mx-options-header-second-colors': !defaultColors,
                          'mx-options-header-default-colors': defaultColors,
                          'options-button': smallHeight,
                          'right-gray-shadow': defaultColors,
                          'right-blue-shadow': !defaultColors
                         }"
              class="arrow-nav-button mr-2"
              mat-icon-button>
        <mx-icon marginBottom="true" mx="left-arrow"></mx-icon>
      </button>

      <overlay-scrollbars
        #optionsScroll
        [ngClass]="{
                  'normal-height': !smallHeight,
                  'small-height': smallHeight,
                  'mx-options-header-second-colors': !defaultColors,
                  'mx-options-header-default-colors': defaultColors
                 }"
        [options]="{ scrollbars: { visibility: 'h' } }"
        style="width: 100%;"

      >

        <div
          [ngClass]="{
                  'normal-height': !smallHeight,
                  'small-height': smallHeight,
                  'options-button': smallHeight,
                  'mx-options-header-second-colors': !defaultColors,
                  'mx-options-header-default-colors': defaultColors
                 }"
          [style.padding-left]="hasOverflowX() ? '0' : '15px'"
          class="mx-options-header">
          <ng-content select="mx-options-header"></ng-content>
        </div>

      </overlay-scrollbars>

      <button (click)="scrollRight()"
              *ngIf="hasOverflowX()"
              [ngClass]="{
                          'mx-options-header-second-colors': !defaultColors,
                          'mx-options-header-default-colors': defaultColors,
                          'options-button': smallHeight,
                          'left-gray-shadow': defaultColors,
                          'left-blue-shadow': !defaultColors
                         }"
              class="arrow-nav-button ml-2"
              mat-icon-button>
        <mx-icon marginBottom="true" mx="right-arrow"></mx-icon>
      </button>

    </mat-toolbar>

  </mat-expansion-panel-header>
  <div [ngClass]="{'body-bottom-border': bodyBottomBorder}" [ngStyle]="{'background': bodyColor}">
    <ng-content select="mx-options-body"></ng-content>
  </div>
</mat-expansion-panel>
