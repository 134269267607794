<mx-options
  #mxOptions
  [allowShadow]="allowShadow"
  [defaultColors]="true"
  [iconMarginBottom]="iconMarginBottom"
  [showButtonText]="showButtonText"
  [showButtons]="showButtons"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
>

  <mx-options-header>


    <app-filter-by
      (filter)="filter()"
      (isSearchCollapsedChange)="mxOptions.toggleBody();"
      (searchParamsChange)="newParams($event)"
      *ngIf="showButtons"
      [disabled]="!checkPaths.filter.enabled"
      [isSearchCollapsed]="mxOptions.isSearchCollapsed"
      [searchParams]="searchParams"
      class="filter-by mr-1 ml-2"
    ></app-filter-by>


    <!--      create-->
    <button (click)="create()"
            *ngIf="showButtons && !configuration"
            [disabled]="!checkPaths.create.enabled"
            [matTooltip]="!showTooltip? undefined : 'Create'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" color="green" mx="create"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Create</span>
    </button>


    <!--      refresh-->
    <button (click)="refresh()"
            *ngIf="showButtons"
            [disabled]="!checkPaths.refresh.enabled"
            [matTooltip]="!showTooltip? undefined : 'Refresh'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="refresh"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Refresh</span>
    </button>

    <!--      delete-->
    <button (click)="delete()"
            *ngIf="showButtons && (hasSelected)"
            [disabled]="!checkPaths.delete.enabled"
            [matTooltip]="!showTooltip? undefined : 'Delete'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="delete"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Delete</span>
    </button>

    <!--      reset-->
    <button (click)="reset()"
            *ngIf="showButtons && configuration"
            [disabled]="!checkPaths.reset.enabled"
            [matTooltip]="!showTooltip? undefined : 'Reset to default'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="reset"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Reset</span>
    </button>

    <!--    <button mat-button-->
    <!--            *ngIf="showButtons"-->
    <!--            [disabled]="false"-->
    <!--            [matMenuTriggerFor]="username"-->
    <!--            [matTooltip]="!showTooltip? undefined : 'Options'">-->
    <!--      <mx-icon [marginBottom]="iconMarginBottom" mx="options"></mx-icon>-->
    <!--      <span class="px-1" *ngIf="showButtonText">More options</span>-->
    <!--    </button>-->

    <!--    <mat-menu #username="matMenu">-->
    <!--      <button-->
    <!--        *ngIf="showButtons && checkPaths.export.enabled"-->
    <!--        (click)="export()"-->
    <!--        [disabled]="!checkPaths.export.enabled"-->
    <!--        color="primary" mat-menu-item>-->
    <!--        <mx-icon mx="export" color="primary"></mx-icon>-->
    <!--        <span class="menu-text-primary ml-2">Export</span>-->
    <!--      </button>-->

    <!--      <button-->
    <!--        *ngIf="!showButtons && !checkPaths.export.enabled"-->
    <!--        (click)="$event.stopPropagation()"-->
    <!--        [disabled]="!checkPaths.export.enabled"-->
    <!--        mat-menu-item>-->
    <!--        <mx-icon mx="export"></mx-icon>-->
    <!--        <span class="ml-2">Export</span>-->
    <!--      </button>-->

    <!--      <button-->
    <!--        *ngIf="showButtons && checkPaths.import.enabled"-->
    <!--        (click)="import()"-->
    <!--        [disabled]="!checkPaths.import.enabled"-->
    <!--        color="primary" mat-menu-item>-->
    <!--        <mx-icon mx="export" color="primary"></mx-icon>-->
    <!--        <span class="menu-text-primary ml-2">Import</span>-->
    <!--      </button>-->

    <!--      <button-->
    <!--        *ngIf="!showButtons && !checkPaths.import.enabled"-->
    <!--        (click)="$event.stopPropagation()"-->
    <!--        [disabled]="!checkPaths.import.enabled"-->
    <!--        mat-menu-item>-->
    <!--        <mx-icon mx="export"></mx-icon>-->
    <!--        <span class="ml-2">Import</span>-->
    <!--      </button>-->

    <!--      <button-->
    <!--        *ngIf="showButtons && checkPaths.import.enabled"-->
    <!--        (click)="importInfo()"-->
    <!--        [disabled]="!checkPaths.import.enabled"-->
    <!--        color="primary" mat-menu-item>-->
    <!--        <mx-icon mx="import-info" color="primary"></mx-icon>-->
    <!--        <span class="menu-text-primary ml-2">Import info</span>-->
    <!--      </button>-->

    <!--      <button-->
    <!--        *ngIf="!showButtons && !checkPaths.import.enabled"-->
    <!--        (click)="importInfo()"-->
    <!--        [disabled]="!checkPaths.import.enabled"-->
    <!--        mat-menu-item>-->
    <!--        <mx-icon mx="import-info"></mx-icon>-->
    <!--        <span class="ml-2">Import info</span>-->
    <!--      </button>-->
    <!--    </mat-menu>-->

  </mx-options-header>

  <mx-options-body>
    <div class="filter">
      <app-user-params-filter
        (close)="mxOptions.closeBody();"
        (filter)="filter()"
        (searchParamsChange)="newParams($event)"
        [lockSearchParams]="lockFilterParams"
        [searchParams]="searchParams"
      ></app-user-params-filter>
    </div>
  </mx-options-body>
</mx-options>
