<!--compile error-->
<div *ngIf="compileError.compileError || compileError.transpileError"
     [ngClass]="smallHeight ? 'small-expansion-panel' : 'normal-expansion-panel'"
     class="compile-error-container data-wrapper card-shadow data-wrapper-padding-none data-wrapper-border-radius-none">
  <mat-accordion [multi]="true" class="example-headers-align">

    <mat-expansion-panel #mxmlCompileErrorPanel
                         *ngIf="compileError.transpileError"
                         [expanded]="true"
                         class="mb-0 compile-error"
                         hideToggle
    >
      <mat-expansion-panel-header
        style="background: #ff5252; border-radius: 0 0 0 0 !important;  padding-left: 15px; padding-right: 15px; cursor: pointer">
        <mat-panel-title style="color:white;">

          <mx-icon *ngIf="!disableClose && !mxmlCompileErrorPanel.expanded" class="white_arrows mr-3"
                   mx="collapse"></mx-icon>
          <mx-icon *ngIf="!disableClose && mxmlCompileErrorPanel.expanded" class="white_arrows mr-3"
                   mx="expand"></mx-icon>

          <button (click)="doActionAI(); $event.stopPropagation()"
                  class="mr-2 ask-ai"
                  color="primary"
                  mat-raised-button>
            <mx-icon mx="ai"></mx-icon>
            <span>Ask Ai</span>
          </button>

          Mxml compile error:
        </mat-panel-title>
        <mat-panel-description *ngIf="!disableRemove" class="mr-0">
          <span></span>
          <mx-icon (click)="closeTranspileError()" class="close_compile_error"
                   marginBottom="true"
                   mx="close">
          </mx-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <overlay-scrollbars
        [options]="{ scrollbars: { autoHide: 'n' } }"
        style=" padding: 10px 28px; max-height: 236px !important;"
      >
        <pre (click)="goToTranspiledErrorLine($event)" [innerHTML]="transpileErrorMessage"
             style="color: black;"></pre>
      </overlay-scrollbars>
    </mat-expansion-panel>

    <mat-expansion-panel #javaCompileErrorPanel
                         *ngIf="compileError.compileError"
                         [expanded]="true" class="mt-0 compile-error"
                         hideToggle

    >
      <mat-expansion-panel-header
        style="background: #ff5252; padding-left: 15px; padding-right: 15px; cursor: pointer">
        <mat-panel-title style="color:white;">
          <mx-icon *ngIf="!disableClose && !javaCompileErrorPanel.expanded" class="white_arrows mr-3"
                   mx="collapse"></mx-icon>
          <mx-icon *ngIf="!disableClose && javaCompileErrorPanel.expanded" class="white_arrows mr-3"
                   mx="expand"></mx-icon>

          <button (click)="doActionAI(); $event.stopPropagation()"
                  class="mr-2 ask-ai"
                  color="primary"
                  mat-raised-button>
            <mx-icon mx="ai"></mx-icon>
            <span>Ask Ai</span>
          </button>
          Java compile error:
        </mat-panel-title>

        <mat-panel-description *ngIf="!disableRemove" class="mr-0">
          <span></span>
          <mx-icon (click)="closeCompileError()" class="close_compile_error" marginBottom="true"
                   mx="close">
          </mx-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <overlay-scrollbars
        [options]="{ scrollbars: { autoHide: 'n' } }"
        style=" padding: 10px 28px; max-height: 236px !important; width: 100%;"
      >
          <pre (click)="goToCompileErrorLine($event)" [innerHTML]="compileErrorMessage"
               style="color: black;"></pre>
      </overlay-scrollbars>
    </mat-expansion-panel>
    <!--    </overlay-scrollbars>-->
  </mat-accordion>
</div>
