<div [ngClass]="{'image-box': isInWorkspace}" [ngStyle]="{'height': isInWorkspace ? 'unset' : 'unset'}"
     style="margin-bottom: 5px">

  <img (click)="openImage()"
       *ngIf="image"
       [attr.src]="image"
       alt="Image from workspace"
       class="image-item">

  <mat-spinner
    *ngIf="showSpinner"
    class="image-item"></mat-spinner>

</div>
