<app-navbar></app-navbar>

<div
  *ngIf="grayScreen && !darkGrayScreen"
  style="height: 100vh;
    width: 100vw;
    background: rgb(0 0 0 / 86%);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;"></div>

<div
  *ngIf="grayScreen && darkGrayScreen"
  style="height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,.92);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;"></div>
