import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserParamsListComponent} from './user-params-list.component';
import {IconModule} from "../../../mx/icon/icon.module";
import {MatButtonModule} from "@angular/material/button";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SpinnerForTableModule} from "../../../../shared/util-components/spinner-for-table/spinner-for-table.module";


@NgModule({
  declarations: [UserParamsListComponent],
  exports: [
    UserParamsListComponent,
    UserParamsListComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    SpinnerForTableModule
  ]
})
export class UserParamsListModule {
}
