<h1 class="text-center" mat-dialog-title>Are you sure you want to delete the compilation completion information?</h1>
<div mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [fullWidth]="true"></app-error-message>

  <div *ngIf="!hideAdvancedSettings" [@.disabled]="disableAnimation" style="margin-left: 2rem">
    <br>

    <mat-radio-group [(ngModel)]="deleteType" color="primary">
      <mat-radio-button [disabled]="this.selected.length == 0" [value]="1">Only selected</mat-radio-button>
      <br>
      <mat-radio-button [value]="2">Use Filter</mat-radio-button>
    </mat-radio-group>
  </div>

  <br>

</div>
<div mat-dialog-actions>
  <button *ngIf="!spinner || !disableSecondButton" [mat-dialog-close]="false" color="warn" id="noButton" mat-button>
    NO
  </button>

  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>

  <button (click)="deleteDeleteDialog()" *ngIf="!spinner" color="primary" id="yesButton" mat-button>
    YES
  </button>
</div>
