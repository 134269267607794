import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {CloudConfigService} from './cloud-config.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(
    private apiService: ApiService,
    private cloudConfigService: CloudConfigService,
  ) {
  }

  getTemplatesList() {
    return this.apiService.get(this.cloudConfigService.getTemplatesList());
  }

  exportTemplate(id: string[], templateName: string, templateDescription: string) {
    return this.apiService.get(this.cloudConfigService.exportTemplate(id, templateName, templateDescription));
  }

}
