import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserProject} from "../../shared/models/userProject";
import {UserParam} from "../../shared/models/userParams";
import {PageInfo} from "../../../../shared/models/pageInfo";
import {FormControl} from "@angular/forms";
import {UserPreferenceParamsListComponent} from "../user-preference-params-list/user-preference-params-list.component";
import {SecurityGroup} from "../../shared/models/securityGroup";
import {UserPageParams} from "../../shared/services/user-const.service";
import {CheckPath} from "../../../mx/options/CheckPath";
import {MatDialog} from "@angular/material/dialog";
import {SecurityGroupService} from "../../shared/services/security-group.service";
import {MaxtafTokensStorageService} from "../../../../shared/services/maxtaf-tokens-storage.service";
import {UserProjectConfigService} from "../../../../shared/services/user-project-config.service";
import {UserService} from "../../shared/services/user.service";
import {ProjectService} from "../../../projects/shared/services/project.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CheckRolesService} from "../../../../shared/services/check-roles.service";
import {GlobalEventsManagerService} from "../../../../shared/services/global-events-manager.service";
import {ApiKeyService} from "../../shared/services/api-key.service";
import {YesNoDialogComponent} from "../../../dialogs/yes-no-dialog/yes-no-dialog.component";
import {CreateApiKeyComponent} from "../../dialogs/create-api-key/create-api-key.component";
import {ApiKeyInfoComponent} from "../api-key-info/api-key-info.component";
import {EditPasswordDialogComponent} from "../../dialogs/edit-password-dialog/edit-password-dialog.component";
import {EditDialogComponent} from "../../../dialogs/edit-dialog/edit-dialog.component";
import {User} from "../../shared/models/user";

class UserInfoCheckPaths {
  updateParams = new CheckPath();
  updateRoles = new CheckPath();
}

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  className = 'UserComponent';

  @Input('shadow') shadow = true;
  bridgeActive = false;
  bridgeActiveSpinner = true;

  userProject: UserProject = new UserProject();

  userParamsList: UserParam[] = [];
  userParamsListPage: UserParam[] = [];
  userParamsListPagesInfo: PageInfo = new PageInfo();
  isLoadingResultsUserParamsList = false;

  selectedSecurityGroups = new FormControl();
  oldSecurityGroups = new FormControl();

  ownerDisabled = false;

  showErrorAlert = false;

  @ViewChild(UserPreferenceParamsListComponent) userPreferenceParamsListComponent: UserPreferenceParamsListComponent;
  @Input('securityGroups') securityGroups: SecurityGroup[];
  @Input('allowEditSecurityGroups') allowEditSecurityGroups = true;
  @Input('allowEditName') allowEditName = false;
  @Output('getUser') getUser = new EventEmitter<any>();
  @Output('error') error = new EventEmitter<any>();
  @Input('showApiKey') showApiKey = true;
  @Input('editPassword') editPassword = false;
  @Input('params') params: UserPageParams;
  userDetailsShow = true;
  userProjectDetailsShow = true;
  checkPaths: UserInfoCheckPaths;

  constructor(
    public dialog: MatDialog,
    public securityGroupService: SecurityGroupService,
    public tokensService: MaxtafTokensStorageService,
    private userProjectConfigService: UserProjectConfigService,
    private userService: UserService,
    private projectService: ProjectService,
    public snackBar: MatSnackBar,
    public checkRolesService: CheckRolesService,
    private globalEventsManagerService: GlobalEventsManagerService,
    private apiKeyService: ApiKeyService,
  ) {
    this.checkPaths = this.getUserCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );
  }

  @Input('userProject') set _user(userProject: UserProject) {
    this.userProject = userProject;
    if (this.userProject.id != undefined) {
      this.refreshBridgeStatus();
    }

    this.securityGroupService.getAll('').subscribe(
      res => {
        this.securityGroups = res;

        // this.selectedSecurityGroups.setValue(this.user.securityGroups);
        // this.selectedSecurityGroups.setValue(this.securityGroups);

        const newSelectedSecurityGroup = [];

        this.userProject.securityGroups.forEach(selectedSecurityGroup => {
          for (const securityGroup of this.securityGroups) {
            if (selectedSecurityGroup.name == securityGroup.name) {
              newSelectedSecurityGroup.push(securityGroup);
              break;
            }
          }
        });

        this.selectedSecurityGroups.setValue(newSelectedSecurityGroup);

        if (this.userProject.user.id == this.userService.getUserId()) {
          this.allowEditSecurityGroups = false;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  @Input('userDetailsShow') set _userDetailsShow(res) {
    this.userDetailsShow = res == true || res == 'true';
  }

  @Input('userProjectDetailsShow') set _userProjectDetailsShow(res) {
    this.userProjectDetailsShow = res == true || res == 'true';
  }

  ngOnInit(): void {
    this.securityGroupService.getAll('').subscribe(
      res => {
        this.securityGroups = res;

        // this.selectedSecurityGroups.setValue(this.user.securityGroups);
        // this.selectedSecurityGroups.setValue(this.securityGroups);

        const newSelectedSecurityGroup = [];

        this.userProject.securityGroups.forEach(selectedSecurityGroup => {
          for (const securityGroup of this.securityGroups) {
            if (selectedSecurityGroup.name == securityGroup.name) {
              newSelectedSecurityGroup.push(securityGroup);
              break;
            }
          }
        });

        this.selectedSecurityGroups.setValue(newSelectedSecurityGroup);

        if (this.userProject.user.id == this.userService.getUserId()) {
          this.allowEditSecurityGroups = false;
        }
      },
      error => {
        console.error(error);
      }
    );

    this.globalEventsManagerService.bridgeIndicatorEmitter.subscribe((mode) => {
      this.bridgeActive = mode;
    });

  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  refreshBridgeStatus() {
    this.bridgeActiveSpinner = true;
    this.userService.getBridgeAddress().subscribe(
      bridgeValue => {
        this.bridgeActive = bridgeValue != '';
        if (!this.userProjectDetailsShow) {
          this.openSnackBar('Bridge status refreshed', 'OK', 2);
        }
        this.bridgeActiveSpinner = false;
        this.globalEventsManagerService.setBridge(this.bridgeActive);
      },
      error => {
        console.error('error: ', error);
        this.error.emit(error);
      }
    );
  }

  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }

  deleteApiKeyDialogComponent() {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        title: 'Are you sure you want to delete the api key?',
        body: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiKeyService.deleteApiKey().subscribe(
          res => {
            this.userProject.existApiKey = false;
          }, error => {
            this.userProject.existApiKey = true;
          });
      }
    });


  }

  openUserEditFieldDialog(type: string, name: string, nameField: string) {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '700px',
      data: {type: type, name: name, value: this.userProject.user[nameField]}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.userProject.user[nameField] = result;
        this.getUser.emit(this.userProject);
        this.updateUser(this.userProject.user);
      }
    });
  }

  updateUser(user: User) {
    this.userService.putLikePatch(user.id, user).subscribe(
      res => {
      }, error => {
        console.error(error);
        this.showError(error);
      }
    );
  }

  changeSecurityGroup(selected: SecurityGroup[]) {
    this.userProject.securityGroups = selected;

    this.getUser.emit(this.userProject);

    this.updateSecurityGroups(this.userProject, selected);
  }

  updateSecurityGroups(userProject: UserProject, securityGroups: SecurityGroup[]) {
    this.userService.putUserProjectSecurityGroups(userProject.id, securityGroups).subscribe(
      res => {
        // this.oldSecurityGroups = this.selectedSecurityGroups;
      }, error => {
        // this.selectedSecurityGroups = this.oldSecurityGroups;
        console.error(error);
        this.showError(error);
      }
    );
  }

  showSecurityGroups(): string {
    let output = '';

    if (this.userProject.securityGroups != undefined) {
      this.userProject.securityGroups.forEach(securityGroup => {
        output += securityGroup.publicName + ', ';
      });
    }

    return output.substring(0, output.length - 2);
  }

  editPasswordDialogComponent() {
    const dialogRef = this.dialog.open(EditPasswordDialogComponent, {
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getUser.emit(this.userProject);
      }
    });
  }

  apiKeyInfo() {
    const dialogRef = this.dialog.open(ApiKeyInfoComponent, {
      width: '700px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  createApiKeyDialogComponent() {
    const dialogRef = this.dialog.open(CreateApiKeyComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.userProject.existApiKey = true;
    });
  }

  private getUserCheckPaths(): UserInfoCheckPaths {
    return {
      updateParams: new CheckPath('PUT', this.userProjectConfigService.updateUserProject()),
      updateRoles: new CheckPath('PUT', this.userProjectConfigService.createOrUpdateOrDeleteUserProjectSecurityGroups()),
    };
  }


}
