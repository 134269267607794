import {Injectable} from '@angular/core';
import {SearchParam} from "../../../mx/util/params/searchParam";


@Injectable({
  providedIn: 'root'
})
export class AiConstService {

  constructor() {
  }

  public static getDefaultChatSessionSearchParams(): ChatSessionSearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      title: new SearchParam(null, null),
      hideCaseRelated: new SearchParam(null, null),
    };
  }

}

export class ChatSessionSearchParams {
  exactSearch: SearchParam;
  title: SearchParam;
  hideCaseRelated: SearchParam;
}
