import {Component, OnInit} from '@angular/core';
import {MxFilter} from "../../../mx/filter/MxFilter";

@Component({
  selector: 'app-user-params-filter',
  templateUrl: './user-params-filter.component.html',
  styleUrls: ['./user-params-filter.component.css']
})
export class UserParamsFilterComponent extends MxFilter implements OnInit {


  search: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  searchDefaultValues() {
    return {
      name: '',
      value: '',
      exactSearch: 'Contains',
    };
  }

}
