import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CheckPath} from "../../../mx/options/CheckPath";
import {MxOptionAbstract} from "../../../mx/options/MxOptionAbstract";
import {MatDialog} from "@angular/material/dialog";
import {CheckRolesService} from "../../../../shared/services/check-roles.service";

export class UserParamsOptionsPaths {
  filter = new CheckPath();
  create = new CheckPath();
  refresh = new CheckPath();
  export = new CheckPath();
  import = new CheckPath();
  delete = new CheckPath();
  reset = new CheckPath();
}

@Component({
  selector: 'app-user-params-options',
  templateUrl: './user-params-options.component.html',
  styleUrls: ['./user-params-options.component.css']
})
export class UserParamsOptionsComponent extends MxOptionAbstract<UserParamsOptionsPaths> implements OnInit {

  @Input('searchParams') searchParams;
  @Output('searchParamsChange') searchParamsChange = new EventEmitter<any>();

  @Input('singe') single = true;
  @Input('hasSelected') hasSelected = false;

  @Input('allowShadow') allowShadow = true;
  @Input('allowEdit') edit = true;
  @Input('showButtons') showButtons = true;
  @Input('configuration') configuration = false;
  @Input('showButtonText') showButtonText = true;
  @Input('showTooltip') showTooltip = false;
  @Input('smallHeight') smallHeight = false;
  @Input('iconMarginBottom') iconMarginBottom = true;
  @Input('lockFilterParams') lockFilterParams = [];

  @Output('filter') filterEvent = new EventEmitter<null>();
  @Output('refresh') refreshEvent = new EventEmitter<null>();
  @Output('create') createEvent = new EventEmitter<null>();
  @Output('export') exportEvent = new EventEmitter<null>();
  @Output('import') importEvent = new EventEmitter<null>();
  @Output('importInfo') importInfoEvent = new EventEmitter<null>();
  @Output('delete') deleteEvent = new EventEmitter<null>();
  @Output('reset') resetEvent = new EventEmitter<null>();

  constructor(public dialog: MatDialog, public checkRolesService: CheckRolesService) {
    super(checkRolesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  filter() {
    this.filterEvent.emit();
  }

  refresh() {
    this.refreshEvent.emit();
  }

  create() {
    this.createEvent.emit();
  }

  export() {
    this.exportEvent.emit();
  }

  import() {
    this.importEvent.emit();
  }

  delete() {
    this.deleteEvent.emit();
  }

  newParams(searchParams) {
    this.searchParams = {...searchParams};
    this.searchParamsChange.emit(this.searchParams);
  }

  importInfo() {
    this.importInfoEvent.emit();
  }

  reset() {
    this.resetEvent.emit();
  }


}
