import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MxOptionAbstract} from '../../../mx/options/MxOptionAbstract';
import {MatDialog} from '@angular/material/dialog';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CompileInfoConstParams} from '../compile-info-dialog/compile-info-const.service';

export class CompileInfoOptionsPaths {
  refresh = new CheckPath();
  delete = new CheckPath();
}

@Component({
  selector: 'app-compile-info-options',
  templateUrl: './compile-info-options.component.html',
  styleUrls: ['./compile-info-options.component.css']
})
export class CompileInfoOptionsComponent extends MxOptionAbstract<CompileInfoOptionsPaths> implements OnInit {

  @Input('searchParams') searchParams: CompileInfoConstParams;
  @Output('searchParamsChange') searchParamsChange = new EventEmitter<CompileInfoConstParams>();
  @Input('lockFilterParams') lockFilterParams = [];
  @Input('forDialog') forDialog = false;


  @Input('allowShadow') allowShadow = true;
  @Input('allowEdit') edit = true;
  @Input('showButtons') showButtons = true;
  @Input('showButtonText') showButtonText = true;
  @Input('showTooltip') showTooltip = false;
  @Input('smallHeight') smallHeight = false;
  @Input('defaultColors') defaultColors = true;
  @Input('iconMarginBottom') iconMarginBottom = true;

  @Output('filter') filterEvent = new EventEmitter<CompileInfoConstParams>();
  @Output('refresh') refreshEvent = new EventEmitter<null>();
  @Output('delete') deleteEvent = new EventEmitter<null>();


  constructor(public dialog: MatDialog,
              public checkRolesService: CheckRolesService) {
    super(checkRolesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // document.addEventListener('keydown', this.deleteShortcutListener, false);
  }

  ngOnDestroy() {
    // document.removeEventListener('keydown', this.deleteShortcutListener, false);
  }

  filter() {
    //this.filterEvent.emit(this.searchParams);
  }

  refresh() {
    this.refreshEvent.emit();
  }

  delete() {
    this.deleteEvent.emit();
  }

  newParams(searchParams: CompileInfoConstParams) {
    this.searchParams = {...searchParams};
    this.searchParamsChange.emit(this.searchParams);
  }


}
