import {Injectable} from '@angular/core';
import {PageParams} from '../../../mx/util/params/PageParams';
import {SearchParam} from '../../../mx/util/params/searchParam';

@Injectable({
  providedIn: 'root'
})
export class RunConstService {

  constructor() {
  }

  static getDefaultPageParams(size: number = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      // sort: new SearchParam('updatedDate,desc', 'updatedDate,desc')
      sort: new SearchParam('id,desc', 'id,desc')
    };
  }

  static getDefaultPageParamsFailedAttemptsRun(): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(10, 10),
      sort: new SearchParam('id,desc', 'id,desc')
    };
  }

  static getDefaultSearchParams(activeRuns = true, completedRuns = true): RunSearchParams {
    return {
      id: new SearchParam(null, null),
      minRunId: new SearchParam(null, null),
      maxRunId: new SearchParam(null, null),

      parentId: new SearchParam(null, null),
      scheduleJobId: new SearchParam(null, null),
      failedAttemptsKey: new SearchParam(null, null),

      type: new SearchParam('', ''),
      failureType: new SearchParam('', ''),
      caseOrSuiteId: new SearchParam(null, null),
      name: new SearchParam(null, null),

      userEmail: new SearchParam(null, null),
      userId: new SearchParam(null, null),

      minRunDate: new SearchParam(null, null),
      maxRunDate: new SearchParam(null, null),

      minCompletedDate: new SearchParam(null, null),
      maxCompletedDate: new SearchParam(null, null),

      activeRuns: new SearchParam(completedRuns, completedRuns),
      completedRuns: new SearchParam(activeRuns, activeRuns),

      showPassed: new SearchParam(completedRuns, completedRuns),
      showFailed: new SearchParam(completedRuns, completedRuns),
      showStopped: new SearchParam(completedRuns, completedRuns),
      showInterrupted: new SearchParam(completedRuns, completedRuns),
      showFailedToStart: new SearchParam(completedRuns, completedRuns),

      showStarted: new SearchParam(activeRuns, activeRuns),
      showQueued: new SearchParam(activeRuns, activeRuns),
      showWaiting: new SearchParam(activeRuns, activeRuns),

      log: new SearchParam(null, null),
      console: new SearchParam(null, null),
      params: new SearchParam(null, null),

      minFailureCount: new SearchParam(null, null),
      failureCount: new SearchParam(null, null),
      maxFailureCount: new SearchParam(null, null),

      failureMessage: new SearchParam(null, null),
      failureStackTrace: new SearchParam(null, null),

      searchEverywhere: new SearchParam(null, null),
      preventDeletion: new SearchParam(null, null),

      exactSearch: new SearchParam('Contains', 'Contains'),
    };
  }


  public static getDefaultRunDetailsPageParams(): RunDetailsParams {
    return {
      runDetailsId: new SearchParam(null, null),

      tabIndex: new SearchParam(0, 0),
      runChildrenPage: RunConstService.getDefaultPageParams(),
      runChildrenSearch: RunConstService.getDefaultSearchParams(),

      failedAttemptsRunsPage: RunConstService.getDefaultPageParamsFailedAttemptsRun(),
      failedAttemptsRunsSearch: RunConstService.getDefaultSearchParams(),

      runsSearch: RunConstService.getDefaultSearchParams(),
      runsPage: RunConstService.getDefaultPageParams(),
      runIndex: new SearchParam(null, null),
    };
  }

  public static getRunsPageParams(): RunsPageParams {
    return {
      tabIndex: new SearchParam(0, 0),

      activeTab: this.getDefaultActiveRunTab(),
      completedTab: this.getDefaultCompletedRunTab()
    };
  }

  public static getDefaultActiveRunTab(): CompletedOrActiveRunsListParams {
    return {
      page: this.getDefaultPageParams(),
      search: this.getDefaultSearchParams(true, true),
    };
  }

  public static getDefaultCompletedRunTab(): CompletedOrActiveRunsListParams {
    return {
      page: this.getDefaultPageParams(),
      search: this.getDefaultSearchParams(false, true),
    };
  }

}

export class RunSearchParams {
  public id: SearchParam = new SearchParam(null, null);
  public minRunId: SearchParam = new SearchParam(null, null);
  public maxRunId: SearchParam = new SearchParam(null, null);

  public parentId: SearchParam = new SearchParam(null, null);
  public scheduleJobId: SearchParam = new SearchParam(null, null);
  public failedAttemptsKey: SearchParam = new SearchParam(null, null);

  public failureType: SearchParam = new SearchParam(null, null);
  public type: SearchParam = new SearchParam(null, null);
  public caseOrSuiteId: SearchParam = new SearchParam(null, null);
  public name: SearchParam = new SearchParam(null, null);

  public userEmail: SearchParam = new SearchParam(null, null);
  public userId: SearchParam = new SearchParam(null, null);

  public minRunDate: SearchParam = new SearchParam(null, null);
  public maxRunDate: SearchParam = new SearchParam(null, null);

  public minCompletedDate: SearchParam = new SearchParam(null, null);
  public maxCompletedDate: SearchParam = new SearchParam(null, null);

  public activeRuns: SearchParam = new SearchParam(null, null);
  public completedRuns: SearchParam = new SearchParam(null, null);

  public showPassed: SearchParam = new SearchParam(null, null);
  public showFailed: SearchParam = new SearchParam(null, null);
  public showStopped: SearchParam = new SearchParam(null, null);
  public showInterrupted: SearchParam = new SearchParam(null, null);
  public showFailedToStart: SearchParam = new SearchParam(null, null);

  public showStarted: SearchParam = new SearchParam(null, null);
  public showQueued: SearchParam = new SearchParam(null, null);
  public showWaiting: SearchParam = new SearchParam(null, null);

  public log: SearchParam = new SearchParam(null, null);
  public console: SearchParam = new SearchParam(null, null);
  public params: SearchParam = new SearchParam(null, null);

  public minFailureCount: SearchParam = new SearchParam(null, null);
  public failureCount: SearchParam = new SearchParam(null, null);
  public maxFailureCount: SearchParam = new SearchParam(null, null);

  public failureMessage: SearchParam = new SearchParam(null, null);
  public failureStackTrace: SearchParam = new SearchParam(null, null);

  public searchEverywhere: SearchParam = new SearchParam(null, null);

  public exactSearch: SearchParam = new SearchParam(null, null);
  public preventDeletion: SearchParam = new SearchParam(null, null);
}

export class RunDetailsParams {
  public runDetailsId: SearchParam;
  public tabIndex: SearchParam;
  public runChildrenPage: PageParams;
  public runChildrenSearch: RunSearchParams;

  public failedAttemptsRunsPage: PageParams;
  public failedAttemptsRunsSearch: RunSearchParams;

  public runsSearch: RunSearchParams;
  public runsPage: PageParams;
  public runIndex: SearchParam;
}

export class CompletedOrActiveRunsPageParams {
  public tabIndex: SearchParam;
  public caseTab: CompletedOrActiveRunsListParams;
  public suiteTab: CompletedOrActiveRunsListParams;
}

export class RunsPageParams {
  public tabIndex: SearchParam;
  public activeTab: CompletedOrActiveRunsListParams;
  public completedTab: CompletedOrActiveRunsListParams;
}

export class CompletedOrActiveRunsListParams {
  public page: PageParams;
  public search: RunSearchParams;
}
