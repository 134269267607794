import {Component, OnInit, ViewChild} from '@angular/core';
import {MxFilter} from '../../../mx/filter/MxFilter';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {MatInput} from '@angular/material/input';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-compile-info-filter',
  templateUrl: './compile-info-filter.component.html',
  styleUrls: ['./compile-info-filter.component.css']
})
export class CompileInfoFilterComponent extends MxFilter implements OnInit {
  search: any;

  @ViewChild('fromInput', {
    read: MatInput
  }) fromInput: MatInput;

  @ViewChild('toInput', {
    read: MatInput
  }) toInput: MatInput;


  constructor() {
    super();
  }

  ngOnInit(): void {

  }

  resetForm() {
    this.fromInput.value = '';
    this.toInput.value = '';
  }


  searchDefaultValues() {
    return {
      startDate: '',
      endDate: '',
      completed: '',
      errors: '',
      exactSearch: 'Contains',
    };
  }

  setMinRunDate(value: unknown) {
    this.search.startDate = this.dateValue(value);
  }

  setMaxRunDate(value: unknown) {
    this.search.endDate = this.dateValue(value);
  }

  dateValue(value) {
    return moment(value, 'yyyy-MM-dd HH:mm:ss').toISOString().replace('T', ' ').substr(0, moment(value, 'yyyy-MM-dd HH:mm:ss').toISOString().lastIndexOf('.'));
  }

  clearCompileFilter() {
    this.resetForm();
    this.clearFilter();
  }


}
