import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserParamsOptionsComponent} from './user-params-options.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {IconModule} from "../../../mx/icon/icon.module";
import {ProjectParamsFilterModule} from "../project-params-filter/project-params-filter.module";
import {OverlayscrollbarsModule} from "overlayscrollbars-ngx";
import {MxOptionsModule} from "../../../mx/options/mx-options/mx-options.module";
import {FilterByModule} from "../../../mx/filter-by/filter-by.module";
import {MatMenuModule} from "@angular/material/menu";
import {UserParamsFilterModule} from "../user-params-filter/user-params-filter.module";


@NgModule({
  declarations: [UserParamsOptionsComponent],
  exports: [UserParamsOptionsComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    IconModule,
    ProjectParamsFilterModule,
    OverlayscrollbarsModule,
    MxOptionsModule,
    FilterByModule,
    MatMenuModule,
    UserParamsFilterModule
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class UserParamsOptionsModule {
}
