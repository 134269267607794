import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Idle} from '@ng-idle/core';
import {Router} from '@angular/router';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-idle-dialog',
  templateUrl: './idle-dialog.component.html',
  styleUrls: ['./idle-dialog.component.css']
})
export class IdleDialogComponent implements OnInit {

  seconds;
  refreshIntervalSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<IdleDialogComponent>,
    public dialog: MatDialog,
    private idle: Idle,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: {
      idleTime: number
    }
  ) {
    this.seconds = this.data.idleTime;
    dialogRef.disableClose = true;
    this.refreshIntervalSub = interval(1000)
      .subscribe(res => {
        this.seconds = this.data.idleTime - res;
        if (this.seconds < 1) {
          console.log('%c 11212 logout ');
          this.logout();
        }
      });

  }

  stopLiveRefresh() {
    if (this.refreshIntervalSub) {
      this.refreshIntervalSub.unsubscribe();
    }
  }

  // ESC shortcut for closing the window
  noShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('noButton').click();
    }
  };

  // ENTER shortcut for running the test
  yesShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('yesButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.noShortcutListener, false);
    document.addEventListener('keydown', this.yesShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.noShortcutListener, false);
    document.removeEventListener('keydown', this.yesShortcutListener, false);
    this.stopLiveRefresh();
  }

  hideChildModal(): void {
    this.dialogRef.close();
  }

  stay() {
    this.dialogRef.close();
    this.reset();
  }

  logout() {
    console.log('%c 11212 logout ');
    if (this.router.url !== '/login' && this.router.url !== '/register' && this.router.url !== '/tos') {
      this.idle.stop();
      console.log('%c 11212 logout ');
      const currentUrl = this.router.url;
      this.router.navigate(['/logout'], {queryParams: {returnUrl: currentUrl}}).then();

    }
    this.dialog.closeAll();
  }

  reset() {
    this.idle.watch();
    this.dialog.closeAll();
  }
}
