export class User {

  constructor(
    public id?: string,
    public email?: string,
    public displayName?: string,
    public enabledPrivateProjects = false
  ) {
  }

}
