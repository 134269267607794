export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAJc_w5tXB1uePPN8X5-U97PCpSV_s6B2M',
    authDomain: 'maxtaf-cloud.firebaseapp.com',
    databaseURL: 'https://maxtaf-cloud.firebaseio.com',
    projectId: 'maxtaf-cloud',
    storageBucket: 'maxtaf-cloud.appspot.com',
    messagingSenderId: '262496151846'
  }
};
