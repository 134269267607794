import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiKeyInfoComponent} from './api-key-info.component';
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [ApiKeyInfoComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule
  ]
})
export class ApiKeyInfoModule {
}
