<section>
  <div class="responsive-table-wrapper">
    <table (matSortChange)="sortData($event)" [dataSource]="items"
           [matSortActive]="pageParams.sort.value.split(',')[0]"
           [matSortDirection]="pageParams.sort.value.split(',')[1]"
           mat-table
           matSort>

      <!-- Type Column -->
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
        <td *matCellDef="let element" mat-cell>
          {{element.name}}
        </td>
      </ng-container>

      <!-- Run Date Column -->
      <ng-container matColumnDef="value">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Value</th>
        <td #value *matCellDef="let element" [matTooltipDisabled]="!isOverflow(value)" class="tooltipInProjectParam" mat-cell
            matTooltip="{{element.value}}">
          {{element.value}}
        </td>
      </ng-container>


      <!-- Run Date Column -->
      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Description</th>
        <td *matCellDef="let element" mat-cell>
          {{element.description}}
        </td>
      </ng-container>

      <!-- Run Date Column -->
      <ng-container matColumnDef="remove">
        <th *matHeaderCellDef mat-header-cell style="width: 150px;"></th>
        <td *matCellDef="let element" mat-cell style="width: 150px;">
          <button
            (click)="$event.stopPropagation();removeProjectParam(element)"
            mat-icon-button
          >
            <mx-icon class="gray_remove" marginBottom="true" mx="delete"></mx-icon>
          </button>
        </td>
      </ng-container>

      <!-- Stop Column -->
      <ng-container matColumnDef="options" style="width: 100px; max-width: 100px;">
        <th *matHeaderCellDef mat-header-cell style="width: 100px; max-width: 100px;"> Options</th>
        <td *matCellDef="let element" mat-cell style="width: 100px; max-width: 100px;">
          <!-- Suite is stopped ** Cancel execution of the suite? -->

          <button (click)="setDefaultValue(element);$event.stopPropagation();"
                  [color]="'primary'"
                  [matTooltip]="'Set Default Value'"
                  class="hoverBlue stop-button-min-width"
                  mat-button>

            <mx-icon mx="reset"></mx-icon>
            <!--            Set Default-->
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr (click)="clickedItem(row, i)" *matRowDef="let row; columns: displayedColumns;let i = index;"
          mat-row style="cursor: pointer;"></tr>

    </table>
  </div>

  <spinner-for-table [loading]="isLoading"></spinner-for-table>

  <mat-paginator
    (page)="setPageSizeOrIndex($event)"
    [length]="pagesInfo.totalElements"
    [pageIndex]="pageParams.page.value"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageParams.size.value"
    showFirstLastButtons></mat-paginator>

</section>
