export class AIInsertCode {


  constructor(
    public code: string = '',
    public askAI = true,
    public timestamp = new Date(),
    public caseId: string = undefined,
    public caseName: string = undefined,
  ) {
  }
}
