import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CaseSearchParams} from '../../shared/services/case-const.service';
import {CheckPath} from '../../../mx/options/CheckPath';
import {MxOptionAbstract} from '../../../mx/options/MxOptionAbstract';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';

export class CaseListOptionsPaths {
  filter = new CheckPath();
  create = new CheckPath();
  createPageObject = new CheckPath();
  compile = new CheckPath();
  refresh = new CheckPath();
  export = new CheckPath();
  import = new CheckPath();
  delete = new CheckPath();
  restore = new CheckPath();
  trashRecords = new CheckPath();
  activeRecords = new CheckPath();
}

@Component({
  selector: 'app-case-list-options',
  templateUrl: './case-list-options.component.html',
  styleUrls: ['./case-list-options.component.css']
})
export class CaseListOptionsComponent extends MxOptionAbstract<CaseListOptionsPaths> implements OnInit, OnDestroy {

  @Input('searchParams') searchParams: CaseSearchParams;
  @Output('searchParamsChange') searchParamsChange = new EventEmitter<CaseSearchParams>();
  @Input('lockFilterParams') lockFilterParams = [];
  @Input('ignoreParams') ignoreParams = ['showPageObjects', 'showCases'];
  @Input('forDialog') forDialog = false;
  @Input('isTrash') isTrash = false;
  @Input('hasSelected') hasSelected = true;
  @Input('showCheckboxes') showCheckboxes = false;
  @Input('showDelete') showDelete = true;
  @Input('showRestore') showRestore = true;
  @Input('showPageObject') showPageObject = false;
  @Input('showCase') showCase = true;

  @Input('allowShadow') allowShadow = true;
  @Input('allowEdit') edit = true;
  @Input('showButtons') showButtons = true;
  @Input('showButtonText') showButtonText = true;
  @Input('showTooltip') showTooltip = false;
  @Input('smallHeight') smallHeight = false;
  @Input('defaultColors') defaultColors = true;
  @Input('iconMarginBottom') iconMarginBottom = true;

  @Output('filter') filterEvent = new EventEmitter<CaseSearchParams>();
  @Output('create') createEvent = new EventEmitter<null>();
  @Output('createPageObjectEvent') createPageObjectEvent = new EventEmitter<null>();
  @Output('compile') compileEvent = new EventEmitter<null>();
  @Output('refresh') refreshEvent = new EventEmitter<null>();
  @Output('export') exportEvent = new EventEmitter<null>();
  @Output('import') importEvent = new EventEmitter<null>();
  @Output('importInfo') importInfoEvent = new EventEmitter<null>();
  @Output('delete') deleteEvent = new EventEmitter<null>();
  @Output('restore') restoreEvent = new EventEmitter<null>();
  @Output('trash') trashRecordsEvent = new EventEmitter<null>();
  @Output('active') activeRecordsEvent = new EventEmitter<null>();
  @Output('compileInfo') compileInfoEvent = new EventEmitter<null>();

  constructor(public dialog: MatDialog, public checkRolesService: CheckRolesService) {
    super(checkRolesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
  }

  filter() {
    this.filterEvent.emit(this.searchParams);
  }

  create() {
    this.createEvent.emit();
  }

  createPageObject() {
    this.createPageObjectEvent.emit();
  }

  compile() {
    this.compileEvent.emit();
  }

  compileInfo() {
    this.compileInfoEvent.emit();
  }

  refresh() {
    this.refreshEvent.emit();
  }

  export() {
    this.exportEvent.emit();
  }

  import() {
    this.importEvent.emit();
  }

  importInfo() {
    this.importInfoEvent.emit();
  }

  delete() {
    this.deleteEvent.emit();
  }

  restore() {
    this.restoreEvent.emit();
  }

  trashRecords() {
    this.trashRecordsEvent.emit();
  }

  activeRecords() {
    this.activeRecordsEvent.emit();
  }

  newParams(searchParams: CaseSearchParams) {
    this.searchParams = {...searchParams};
    this.searchParamsChange.emit(this.searchParams);
  }


  clickedPageObject() {
    if (this.searchParams != undefined) {
      this.searchParams.showPageObjects.value = !this.searchParams.showPageObjects.value;
      this.searchParams.showPageObjects.defaultValue = !this.searchParams.showPageObjects.defaultValue;
      this.newParams(this.searchParams);
    }
  }

  clickedShowCases() {
    if (this.searchParams != undefined) {
      this.searchParams.showCases.value = !this.searchParams.showCases.value;

      this.newParams(this.searchParams);
    }
  }


}
