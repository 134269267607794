<h1 mat-dialog-title>Import Project</h1>
<div mat-dialog-content>
  <div *ngIf="!importSpinner">
    <div class="row justify-content-md-center" style=" max-width: 100%;">
      <div *ngIf="showSuccess" class="row">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <h5 class="alert-heading"> {{(
            (importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != "") ||
            (importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != "") ||
            (importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != "") ||
            (importInfoDetails.suitesError != null && importInfoDetails.suitesError != "") ||
            (importInfoDetails.casesError != null && importInfoDetails.casesError != "")
          ) ? 'Partial ' : ''}} Success!</h5>
          <hr>
          <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
          <button (click)="showSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>

    <app-error-message *ngIf="showUploadError" [error]="error" [showInDialog]="true"></app-error-message>

    <mat-progress-bar *ngIf="currentFileUpload" [value]="progress.percentage" class="mt-1"
                      mode="determinate"></mat-progress-bar>

    <div class="container" style=" max-width: 100%;">
      <div class="row justify-content-md-center">
        <div class="col">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input (change)="selectFile($event)" class="custom-file-input" id="inputGroupFile01" type="file">
              <label class="custom-file-label" for="inputGroupFile01">{{fileName}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br>

    <mat-checkbox (change)="overwriteProjectParams = !overwriteProjectParams" [checked]="overwriteProjectParams" [disabled]="spinner"
                  id="overwriteProjectParams"
                  type="checkbox">Overwrite project params data with import file?
    </mat-checkbox>
    <br/>
    <mat-checkbox (change)="overwriteCases = !overwriteCases" [checked]="overwriteCases" [disabled]="spinner"
                  id="overwriteCases"
                  type="checkbox">Overwrite cases data with import file?
    </mat-checkbox>
    <br/>
    <mat-checkbox (change)="compile = !compile" [checked]="compile" [disabled]="spinner" id="compileCheck"
                  type="checkbox">Compile cases?
    </mat-checkbox>
    <br/>
    <mat-checkbox (change)="overwriteSuites = !overwriteSuites" [checked]="overwriteSuites" [disabled]="spinner"
                  id="overwriteSuites"
                  type="checkbox">Overwrite suites data with import file?
    </mat-checkbox>
    <br/>
    <mat-checkbox (change)="overwriteSchedules = !overwriteSchedules" [checked]="overwriteSchedules" [disabled]="spinner"
                  id="overwriteSchedules"
                  type="checkbox">Overwrite schedules data with import file?
    </mat-checkbox>
    <br/>
    <mat-checkbox (change)="overwriteWorkspace = !overwriteWorkspace" [checked]="overwriteWorkspace" [disabled]="spinner"
                  id="overwriteWorkspace"
                  type="checkbox">Overwrite workspace files data with import file?
    </mat-checkbox>
    <br/>
    <br/>
  </div>

  <div *ngIf="importSpinner && this.importInfoDetails == undefined">
    <mat-spinner [diameter]="50" color="primary" style="align-self:flex-end; margin: 0 auto;"></mat-spinner>
  </div>

  <div *ngIf="importSpinner && this.importInfoDetails != undefined">

    <!--    {{(importInfoDetails != null && (-->
    <!--    (importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != "") ||-->
    <!--    (importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != "") ||-->
    <!--    (importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != "") ||-->
    <!--    (importInfoDetails.suitesError != null && importInfoDetails.suitesError != "") ||-->
    <!--    (importInfoDetails.casesError != null && importInfoDetails.casesError != "")-->
    <!--  ))?'Partial ' : ''}}-->
    <div class="row justify-content-md-center" style=" max-width: 100%;">
      <div *ngIf="showSuccess" class="row">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <h5 class="alert-heading"> {{(
            (importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != "") ||
            (importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != "") ||
            (importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != "") ||
            (importInfoDetails.suitesError != null && importInfoDetails.suitesError != "") ||
            (importInfoDetails.casesError != null && importInfoDetails.casesError != "")
          ) ? 'Partial ' : ''}} Success!</h5>
          <hr>
          <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
          <button (click)="showSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>

    <div>
      Project Params: {{importInfoDetails.currentProjectParamsNumber}}/{{importInfoDetails.maxProjectParamsNumber}}
    </div>
    <div>
      Cases:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{importInfoDetails.currentCasesNumber}}
      /{{importInfoDetails.maxCasesNumber}}
    </div>
    <div>
      Suites:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{importInfoDetails.currentSuitesNumber}}
      /{{importInfoDetails.maxSuitesNumber}}
    </div>
    <div
      *ngIf=" importInfoDetails.currentSuitesNumber == importInfoDetails.maxSuitesNumber && importInfoDetails.connectingCasesWithSuitesStart && !importInfoDetails.connectingCasesWithSuitesEnd">
      {{counterDot == 0 ? 'Connecting cases with suites.' : counterDot == 1 ? 'Connecting cases with suites..' : 'Connecting cases with suites...'}}
    </div>
    <div *ngIf="this.type === 'schedule'">
      Schedules:&nbsp;{{importInfoDetails.currentScheduleNumber}}/{{importInfoDetails.maxScheduleNumber}}
    </div>
    <div *ngIf="importInfoDetails.workspaceStart && !importInfoDetails.workspaceCompleted">
      {{workspaceDot == 0 ? 'Import workspace.' : workspaceDot == 1 ? 'Import workspace..' : 'Import workspace...'}}
    </div>
    <div *ngIf="importInfoDetails.workspaceCompleted">
      {{'workspace was imported'}}
    </div>

    <br>

    <mat-expansion-panel *ngIf="importInfoDetails.projectParamsSkipped != null && importInfoDetails.projectParamsSkipped != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped project params
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.projectParamsSkipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.projectParamsErrors != null && importInfoDetails.projectParamsErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Project params errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.projectParamsErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Project param error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.projectParamsError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.casesSkipped != null && importInfoDetails.casesSkipped != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped cases
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.casesSkipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.casesErrors != null && importInfoDetails.casesErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cases errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.casesErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.casesError != null && importInfoDetails.casesError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cases error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.casesError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="importInfoDetails.suitesSkipped != null && importInfoDetails.suitesSkipped != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped suites
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.suitesSkipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.suitesErrors != null && importInfoDetails.suitesErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.suitesErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.suitesError != null && importInfoDetails.suitesError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.suitesError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <!--    <mat-expansion-panel style="border-radius: 0;" *ngIf="importInfoDetails.suitesSkipped != null && importInfoDetails.suitesSkipped != ''">-->
    <!--      <mat-expansion-panel-header>-->
    <!--        <mat-panel-title>-->
    <!--          Skipped suite items-->
    <!--        </mat-panel-title>-->
    <!--      </mat-expansion-panel-header>-->

    <!--      <mat-form-field class="w-100" >-->
    <!--          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"-->
    <!--                    matAutosizeMinRows="2"-->
    <!--                    [contentEditable]="false"-->
    <!--                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.suitesSkipped" (ngModelChange)="paramsChangeValue()"></textarea>-->
    <!--      </mat-form-field>-->
    <!--    </mat-expansion-panel>-->

    <mat-expansion-panel *ngIf="importInfoDetails.caseSuitesErrors != null && importInfoDetails.caseSuitesErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suite items errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.caseSuitesErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.caseSuitesError != null && importInfoDetails.caseSuitesError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites items error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.caseSuitesError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.scheduleSkipped != null && importInfoDetails.scheduleSkipped != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped schedules
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.scheduleSkipped" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Skipped"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.scheduleErrors != null && importInfoDetails.scheduleErrors != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Schedules errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.scheduleErrors" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Errors"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Schedules error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.scheduleError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>
    <!--  -->
    <!--  <mat-expansion-panel style="border-radius: 0;" *ngIf="importInfoDetails.scheduleErrors != null && importInfoDetails.scheduleErrors != ''">-->
    <!--    <mat-expansion-panel-header>-->
    <!--      <mat-panel-title>-->
    <!--        Workspace errors-->
    <!--      </mat-panel-title>-->
    <!--    </mat-expansion-panel-header>-->

    <!--    <mat-form-field class="w-100" >-->
    <!--          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"-->
    <!--                    matAutosizeMinRows="2"-->
    <!--                    [contentEditable]="false"-->
    <!--                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.workspaceError" (ngModelChange)="paramsChangeValue()"></textarea>-->
    <!--    </mat-form-field>-->
    <!--  </mat-expansion-panel>-->

    <mat-expansion-panel *ngIf="importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != ''"
                         style="border-radius: 0;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Workspace error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea (ngModelChange)="paramsChangeValue()" [(ngModel)]="importInfoDetails.workspaceError" [contentEditable]="false"
                    [matTextareaAutosize]="true"
                    matAutosizeMaxRows="5"
                    matAutosizeMinRows="2" matInput
                    placeholder="Error"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <br>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="closeDialog()" *ngIf="!spinner && finished" id="okButton" mat-button mat-raised-button>Ok</button>
  <button (click)="closeDialog()" *ngIf="!spinner && !finished" id="closeButton" mat-button>Close</button>
  <button (click)="upload()" *ngIf="!spinner && !finished" [disabled]="!selectedFiles" [ngClass]="{'green_button': selectedFiles}"
          id="uploadButton" mat-raised-button>Upload
  </button>

  <!--  <button mat-button *ngIf="spinner" color="primary">-->
  <!--    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">-->
  <!--      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"-->
  <!--                   [diameter]="20"-->
  <!--                   class="whiteSpinner"></mat-spinner>-->
  <!--    </mat-icon>-->
  <!--  </button>-->
</div>
