import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CompileError} from '../../../cases/shared/models/compileError';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {CompileResult} from '../../../cases/shared/models/compile-result';
import {MatExpansionPanel} from '@angular/material/expansion';
import {Case} from '../../../cases/shared/models/case';
import {GlobalEventsManagerService} from "../../../../shared/services/global-events-manager.service";

@Component({
  selector: 'app-show-compile-error',
  templateUrl: './show-compile-error.component.html',
  styleUrls: ['./show-compile-error.component.css']
})
export class ShowCompileErrorComponent implements OnInit {

  compileResultObj = undefined;
  testCase: Case;
  @Input('smallHeight') smallHeight = false;
  @Input('disableRemove') disableRemove = false;
  @Input('expendAlways') expendAlways = false;
  @Input('disableClose') disableClose = false;
  @Output('compiledErrorLine') compiledErrorLine = new EventEmitter<any>();
  @Output('closeTranspileError') closeTranspileErrorEvent = new EventEmitter<null>();
  @Output('closeCompileError') closeCompileErrorEvent = new EventEmitter<null>();
  @Output('transpiledErrorLine') transpiledErrorLine = new EventEmitter<any>();
  compileError: CompileError = new CompileError();
  compileErrorMessage: SafeHtml;
  transpileErrorMessage: SafeHtml;
  @ViewChild('mxmlCompileErrorPanel') mxmlCompileErrorPanel: MatExpansionPanel;
  @ViewChild('javaCompileErrorPanel') javaCompileErrorPanel: MatExpansionPanel;

  constructor(private domSanitizer: DomSanitizer,
              private globalEventsManager: GlobalEventsManagerService,) {
  }

  @Input('compileResult') set compileResult(compileResult: CompileResult) {
    this.compileResultObj = compileResult;
    this.convertCompileResultToCompileError(compileResult);
  }

  @Input('testCase') set setTestCase(testCase: Case) {
    this.testCase = testCase;

    if (this.compileResultObj != undefined) {
      this.convertCompileResultToCompileError(this.compileResultObj);
    }

  }

  ngOnInit(): void {
  }

  doActionAI() {

    let question =
      "I'm having an issue with compiling code. Here is the code causing the error: ";
    question = question + ' \n\n```' + this.testCase.caseType + '\n' + this.testCase.code + '\n```';

    question = question + "\n\n When I try to compile this code, I receive the following error: \n\n```\n" +
      this.compileError.compileError
      + "\n```\n\nCould you please assist me in resolving it and successfully compiling the code?\n"


    this.globalEventsManager.askAI(question, this.testCase.id, this.testCase.name);
  }

  goToCompileErrorLine(e) {
    if (e.target.dataset.line !== undefined) {
      const line = parseInt(e.target.dataset.line);
      const path = e.target.dataset.path;

      this.collapseMxmlAndJavaCompileErrorPanels();
      this.compiledErrorLine.emit({line, path});
    }
  }

  goToTranspiledErrorLine(e) {
    if (e.target.dataset.line !== undefined) {
      const line = parseInt(e.target.dataset.line);
      const path = e.target.dataset.path;

      this.collapseMxmlAndJavaCompileErrorPanels();
      this.transpiledErrorLine.emit({line, path});
    }
  }

  collapseMxmlAndJavaCompileErrorPanels() {
    let hasBothCompileErrors = false;

    if (this.compileError.compileError && this.compileError.transpileError) {
      hasBothCompileErrors = true;
    }

    if (hasBothCompileErrors) {
      this.mxmlCompileErrorPanel.expanded = false;
      this.javaCompileErrorPanel.expanded = false;
    }
  }

  closeCompileError() {
    this.compileError.compileError = undefined;
    this.closeCompileErrorEvent.emit();
  }

  closeTranspileError() {
    this.compileError.transpileError = undefined;
    this.closeTranspileErrorEvent.emit();
  }

  private convertCompileResultToCompileError(compileResult: CompileResult) {
    this.compileError.compileError = compileResult.compileError;
    this.compileError.transpileError = compileResult.transpileError;

    const patternTofindCompileError = /ERROR occurred on line [a-zA-Z0-9. /-_]* with message:/g;
    const patternTofindTranspileError = /(Error on line [0-9]* of document file:[a-zA-Z0-9. /-]*:|Error on line [0-9]*|ERROR occurred on line [a-zA-Z0-9. /-_]* with message:)/g;
    // const patternTofindTranspileError = /(Error on line [0-9]*|ERROR occurred on line [0-9]*)/g;

    let contentCompileError;
    let contentTranspileError;

    // contentCompileError = compileResult.compileError;
    if (compileResult.compileError && compileResult.compileError !== '') {
      contentCompileError = compileResult.compileError.replace(patternTofindCompileError, (match) => {

        match = match.replace('ERROR occurred on line ', '');
        let linkContent = match.replace(' with message:', '');
        let lineNumber;
        let path;

        if (linkContent.includes(' in file ')) {
          const index = linkContent.indexOf(' in file ');
          lineNumber = linkContent.substr(0, index).trim();
          path = linkContent.substr(index + 9).replace('/mnt/project', '').trim();
          if (path != undefined && path.trim() == this.testCase?.fileLocation?.fullPath) {
            linkContent = lineNumber;
          } else {
            linkContent = linkContent.replace('/mnt/project', '').trim();

          }

        } else {
          lineNumber = linkContent.trim();
        }
        return 'ERROR occurred on <a href=\'javascript:void(0);\' data-line=' + lineNumber + ' data-path=' + path + '>line ' + linkContent + '</a> with message:';
      });
    }

    if (compileResult.transpileError && compileResult.transpileError !== '') {
      // contentTranspileError = compileResult.transpileError;
      contentTranspileError = compileResult.transpileError.replace(patternTofindTranspileError, (match) => {


        let lineNumber;
        let path;
        let linkContent;

        lineNumber = match.replace('Error on line ', '');
        lineNumber = lineNumber.replace('ERROR occurred on line ', '');
        linkContent = lineNumber;

        if (match.startsWith('Error on line ')) {
          if (match.includes(' of document file://')) {
            match = match.replace('Error on line ', '');
            linkContent = match.replace(' of document file://', ' of document file: ');
            linkContent = linkContent.substr(0, linkContent.length - 1);

            const index = match.indexOf(' of document file://');

            lineNumber = match.substr(0, index).trim();
            path = linkContent.substr(index + 19).replace('/mnt/project', '').trim();
            linkContent = linkContent.replace('/mnt/project', '').trim();
            return 'Error on <a href=\'javascript:void(0);\' data-line=' + lineNumber + ' data-path=' + path + '>line ' + linkContent + '</a>:';
          } else {
            lineNumber = match.replace('Error on line ', '');
            linkContent = lineNumber;
            return 'Error on <a href=\'javascript:void(0);\' data-line=' + lineNumber + ' data-path=' + path + '>line ' + linkContent + '</a>';
          }
        }
        if (match.startsWith('ERROR occurred on line ')) {
          match = match.replace('ERROR occurred on line ', '');
          linkContent = match.replace(' with message:', '');

          if (linkContent.includes(' in file ')) {
            let index = linkContent.indexOf(' in file ');
            lineNumber = linkContent.substr(0, index).trim();
            path = linkContent.substr(index + 9).replace('/mnt/project', '').trim();
            linkContent = linkContent.replace('/mnt/project', '').trim();
          } else {
            lineNumber = linkContent.trim();
          }

          return 'ERROR occurred on <a href=\'javascript:void(0);\' data-line=' + lineNumber + ' data-path=' + path + '>line ' + linkContent + '</a> with message:';
        }


        return 'Error on <a href=\'javascript:void(0);\' data-line=' + lineNumber + ' data-path=' + path + '>line ' + linkContent + '</a>';
      });
    }

    this.compileErrorMessage = this.domSanitizer.bypassSecurityTrustHtml(contentCompileError);
    this.transpileErrorMessage = this.domSanitizer.bypassSecurityTrustHtml(contentTranspileError);
  }
}
