import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {GlobalEventsManagerService} from "../../../../shared/services/global-events-manager.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {AiDiffComponent} from "../ai-diff/ai-diff.component";
import {CaseService} from "../../../cases/shared/services/case.service";

@Component({
  selector: 'app-ai-code',
  templateUrl: './ai-code.component.html',
  styleUrls: ['./ai-code.component.css']
})
export class AiCodeComponent implements OnInit {
  message = undefined;
  cleanCode = '';
  numberOfMessageLines = 0;
  editorWidth = 0;
  @Input('ai') ai = false;
  @Input('language') language = undefined;
  @ViewChild('editorBox', {read: ElementRef}) editorBox: ElementRef;
  protected readonly undefined = undefined;

  constructor(private globalEventsManagerService: GlobalEventsManagerService,
              private caseService: CaseService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {

  }

  @Input('message') set setMessage(newMessage: string) {
    this.message = newMessage;
    this.cleanCode = this.message.replace(/^```[\s\S]*?\n|```$/g, "");
    this.numberOfMessageLines = this.message.split('\n').length;
  }

  ngOnInit(): void {

  }

  diffClick() {
    const dialogRef = this.dialog.open(AiDiffComponent, {
      width: '1200px',
      panelClass: 'custom-dialog-container',
      data: {
        code1: this.cleanCode,
        language1: this.language,
        code2: this.caseService.mainCode,
        language2: this.caseService.mainCodeLanguage,
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  insert() {
    this.globalEventsManagerService.setAiInsertCode(this.cleanCode);
    // this.globalEventsManagerService.insertCode.subscribe((code) => {
    //   this.lspServerIndicator = mode;
    // });
  }

  copyCode() {
    this.copyToClipboard();
  }


  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.cleanCode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.openSnackBar('Successfully copied to clipboard', 'OK', 5);
  }

  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }

}
