import {Injectable} from '@angular/core';
import {CollectionViewer, DataSource, SelectionChange} from '@angular/cdk/collections';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {MaxtafFileNode} from '../models/MaxtafFileNode';
import {FlatTreeControl} from '@angular/cdk/tree';
import {NavMaxtafFileService} from './nav-maxtaf-file.service';
import {map} from 'rxjs/operators';
import * as OverlayScrollbars from 'overlayscrollbars';

@Injectable({
  providedIn: 'root'
})
export class DynamicFileSystemService implements DataSource<MaxtafFileNode> {

  public error;
  dataChange = new BehaviorSubject<MaxtafFileNode[]>([]);
  private navTreeScrolling: OverlayScrollbars;

  constructor(private treeControl: FlatTreeControl<MaxtafFileNode>,
              private navMaxtafFileService: NavMaxtafFileService
  ) {

  }

  get data(): MaxtafFileNode[] {
    return this.dataChange.value;
  }

  set data(value: MaxtafFileNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  connect(collectionViewer: CollectionViewer): Observable<MaxtafFileNode[] | ReadonlyArray<MaxtafFileNode>> {

    this.treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<MaxtafFileNode>).added ||
        (change as SelectionChange<MaxtafFileNode>).removed) {
        this.handleTreeControl(change as SelectionChange<MaxtafFileNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {

  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<MaxtafFileNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: MaxtafFileNode, expand: boolean) {
    // poslati ovde node a onda ako ga nema otvoriti sve roditelje!!!

    const index = this.data.indexOf(node);

    if (expand) {
      this.expandFolder(node, index);
    } else {
      node.setPageInfo(undefined);
      let count = 0;
      // TODO koja je poenta ovog fora
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {
      }
      this.data.splice(index + 1, count);

      this.dataChange.next(this.data);

      this.scrollTo(node.level - 1);
    }


  }

  public allowScrolling(navTreeScrolling: OverlayScrollbars) {
    this.navTreeScrolling = navTreeScrolling;
  }

  public addFolderNodes(pathWithName: string, projectId): number {
    if (pathWithName == '/' || pathWithName == '') {
      this.treeControl.expand(this.data[0]);
      return 0;
    }

    const index: number = this.data.findIndex(e => {
      return e.pathWithName == pathWithName;
    });

    if (index != undefined && index >= 0) {
      this.treeControl.expand(this.data[index]);
      return index;
    }

    const pathArray: string[] = pathWithName.split('/');

    const name: string = pathArray[pathArray.length - 1];
    const path = pathWithName.substr(0, pathWithName.length - (name.length + 1));

    const node: MaxtafFileNode = new MaxtafFileNode(
      name,
      path,
      pathWithName,
      '',
      pathArray.length - 1,
      true,
      false,
      projectId,
      undefined,
    );

    const indexParent = this.addFolderNodes(path, projectId);

    this.data.splice(indexParent + 1, 0, node);

    this.dataChange.next(this.data);

    this.treeControl.expand(node);

    return indexParent + 1;
  }

  private scrollTo(level: number) {
    if (this.navTreeScrolling != undefined) {
      this.navTreeScrolling.scroll({x: (level) * 20 - 10}, 300);
    }
  }

  private expandFolder(node: MaxtafFileNode, index: number) {
    const params = {directory: node.pathWithName, page: 0, size: 10000, sort: 'name'};
    node.isLoading = true;
    this.navMaxtafFileService.getMaxtafFileNodes(params, node.projectId).subscribe(
      res => {

        const children = res.content;


        if (!children || index < 0) { // If no children, or cannot find the node, no op
          node.isLoading = false;
          return;
        }
        node.setPageInfo(res);

        const nodes = children.map((maxtafFileNode: MaxtafFileNode) =>
          new MaxtafFileNode(
            maxtafFileNode.name,
            maxtafFileNode.path,
            maxtafFileNode.pathWithName,
            maxtafFileNode.typeOfFile,
            node.level + 1,
            maxtafFileNode.isFolder,
            false,
            node.projectId));

        this.data.splice(index + 1, 0, ...nodes);
        this.scrollTo(node.level);
        if (nodes.length == 1) {
          this.treeControl.expand(nodes[0]);
        }

        this.dataChange.next(this.data);

        node.isLoading = false;
      },
      error => {
        console.error('dynamic file system toggleNode error: ', error);
        this.error = error;
        node.isLoading = false;
      }
    );
  }
}
