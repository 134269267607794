import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true}
  ]
})
export class EqualValidator implements Validator {

  @Input('otherValueControl') otherValueControl: AbstractControl;
  @Input('reverse') reverse = false;


  constructor() {
  }

  validate(control: AbstractControl): { [key: string]: any } {
    // self value (e.g. retype password)
    const selfValue = control.value;

    // control value (e.g. password)
    const sentValue = this.otherValueControl.value;

    // value not equal
    if (sentValue && selfValue != sentValue && !this.reverse) {
      return {validateEqual: false};
    }

    // value equal and reverse
    if (sentValue && selfValue == sentValue && this.reverse) {
      delete this.otherValueControl.errors.validateEqual;
      if (this.otherValueControl.errors.length == undefined || this.otherValueControl.errors.length == null || this.otherValueControl.errors.length == 0) {
        this.otherValueControl.setErrors(null);
      }
    }

    // value not equal and reverse
    if (sentValue && selfValue != sentValue && this.reverse) {
      this.otherValueControl.setErrors({validateEqual: false});
    }


    return null;
  }

}

export class PasswordValidation {

  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password != confirmPassword) {
      AC.get('confirmPassword').setErrors({MatchPassword: true});
    } else {
      return null;
    }
  }
}
