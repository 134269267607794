<mx-options
  #mxOptions
  [allowShadow]="allowShadow"
  [defaultColors]="defaultColors"
  [iconMarginBottom]="iconMarginBottom"
  [showButtonText]="showButtonText"
  [showButtons]="showButtons"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
>

  <mx-options-header>

    <!--      filter-->
    <app-filter-by
      (filter)="filter()"
      (isSearchCollapsedChange)="mxOptions.toggleBody();"
      (searchParamsChange)="newParams($event)"
      *ngIf="showButtons"
      [disabled]="!checkPaths.filter.enabled"
      [filterBy]="'name'"
      [focus]="focusFilter"
      [isSearchCollapsed]="mxOptions.isSearchCollapsed"
      [placeholderText]="'Project name..'"
      [searchParams]="searchParams"
      class="filter-by mr-1 ml-2"
    ></app-filter-by>


    <!--      create-->
    <button (click)="create()"
            *ngIf="showButtons"
            [disabled]="!checkPaths.create.enabled"
            [matTooltip]="!showTooltip? undefined : 'Create new project'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" color="green" mx="create"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Create</span>
    </button>

    <!--      refresh-->
    <button (click)="refresh()"
            *ngIf="showButtons"
            [disabled]="!checkPaths.refresh.enabled"
            [matTooltip]="!showTooltip? undefined : 'Refresh'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="refresh"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Refresh</span>
    </button>

  </mx-options-header>

  <mx-options-body>

    <div class="filter">
      <app-project-filter
        (close)="mxOptions.closeBody();"
        (filter)="filter()"
        (searchParamsChange)="newParams($event)"
        [lockSearchParams]="lockFilterParams"
        [searchParams]="searchParams"
      ></app-project-filter>
    </div>

  </mx-options-body>
</mx-options>
