<mat-toolbar class="px-4 mb-0">
  <span>Select a project</span>
  <span class="example-spacer"></span>
</mat-toolbar>

<div class="mt-0 pt-0" mat-dialog-content>

  <div class="px-4">
    <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>
  </div>

  <app-project-options
    (create)="openCreateDialog('Project', null, null)"
    (filter)="filter($event)"
    (refresh)="refreshProjects()"
    [(searchParams)]="params.search"
    [checkPaths]="getProjectOptionsCheckPathObject()"
  >

  </app-project-options>
  <app-projects-list
    (clickedItem)="clickedProject($event)"

    (pageParamsChange)="pageParamsChange($event)"
    [autoSetItems]="true"
    [pageParams]="params.page"

    [searchParams]="params.search"
  >

  </app-projects-list>
</div>

<div mat-dialog-actions style="margin-right: 24px">
  <button [mat-dialog-close] mat-button>Cancel</button>
</div>
