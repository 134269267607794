import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilePropertiesDialogComponent} from './file-properties-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ErrorModule} from '../../../mx/error/error.module';
import {IconModule} from '../../../mx/icon/icon.module';
import {EditDialogModule} from '../../../dialogs/edit-dialog/edit-dialog.module';

@NgModule({
  declarations: [FilePropertiesDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    ErrorModule,
    IconModule,
    EditDialogModule
  ],
  exports: [
    FilePropertiesDialogComponent
  ]
})
export class FilePropertiesDialogModule {
}
