import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProjectParams} from '../../shared/models/projectParams';
import {ProjectParamsService} from '../../shared/services/project-params.service';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';

class ProjectParamCheckPaths {
  update = new CheckPath();
}

@Component({
  selector: 'app-add-project-param-dialog',
  templateUrl: './add-project-param-dialog.component.html',
  styleUrls: ['./add-project-param-dialog.component.css']
})
export class AddProjectParamDialogComponent implements OnInit {

  checkPaths: ProjectParamCheckPaths;

  @Input('projectParam') projectParam: ProjectParams;
  create = true;
  title = '';
  createOkButton: string;
  backupProjectParams: ProjectParams;

  errorMessage = '';
  error = false;
  run = false;
  allowEdit = true;
  configuration = false;

  constructor(
    public dialogRef: MatDialogRef<AddProjectParamDialogComponent>,
    private projectParamService: ProjectParamsService,
    private cloudConfigService: CloudConfigService,
    public checkRolesService: CheckRolesService,
    @Inject(MAT_DIALOG_DATA) public data: {
      projectParam: ProjectParams,
      edit: boolean,
      title: string,
      configuration: boolean
    }
  ) {
    if (data.projectParam == undefined) {
      this.create = true;
      this.projectParam = new ProjectParams();
      this.title = 'Add parameter';
      this.createOkButton = 'Add';
    } else {
      this.create = false;
      this.projectParam = data.projectParam;
      this.backupProjectParams = new ProjectParams();
      this.backupProjectParams.name = data.projectParam.name;
      this.backupProjectParams.value = data.projectParam.value;
      this.title = 'Edit parameter';
      this.createOkButton = 'Save';
    }

    if (data.edit != undefined) {
      this.allowEdit = data.edit;
    }

    if (data.configuration != undefined) {
      this.configuration = data.configuration;
    }

    if (data.title != undefined) {
      this.title = data.title;
    }

    this.checkPaths = this.getProjectParamCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.addShortcutListener, false);
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for adding a parameter or entering a value after name
  addShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      if (this.projectParam.name && !(this.projectParam.value)) {
        document.getElementById('valueInput').focus();
      } else if (this.projectParam.name && this.projectParam.value) {
        document.getElementById('createButton').click();
      }
    }
  };

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.addShortcutListener, false);
  }

  add() {
    this.addProjectParams();
  }

  edit() {
    if (this.configuration) {
      this.editConfigurationParams();
    } else {
      this.editProjectParams();
    }
  }

  close() {
    if (!this.create) {
      this.projectParam.name = this.backupProjectParams.name;
      this.projectParam.value = this.backupProjectParams.value;
    }
    this.closeDialog(false);
  }

  closeDialog(res): void {
    this.dialogRef.close(res);
  }

  private addProjectParams() {
    this.error = false;
    this.projectParamService.createProjectParams(this.projectParam)
      .subscribe(
        res => {
          this.error = false;
          this.closeDialog(true);
        },
        error => {
          console.error(error);
          this.errorMessage = error.error.errorMessage;
          this.error = true;
          // this.closeDialog(false);
        }
      );
  }

  private editProjectParams() {
    this.error = false;
    this.projectParamService.setProjectParams(this.projectParam)
      .subscribe(
        res => {
          this.error = false;
          this.closeDialog(false);
        },
        error => {
          console.error(error);
          this.errorMessage = error.error.errorMessage;
          this.error = true;
          // this.closeDialog(false);
        }
      );
  }

  private editConfigurationParams() {
    this.error = false;
    this.projectParamService.setConfigurationParams(this.projectParam)
      .subscribe(
        res => {
          this.error = false;
          this.closeDialog(false);
        },
        error => {
          console.error(error);
          this.errorMessage = error.error.errorMessage;
          this.error = true;
          // this.closeDialog(false);
        }
      );
  }

  private getProjectParamCheckPaths(): ProjectParamCheckPaths {
    return {
      update: new CheckPath('PUT', this.cloudConfigService.setProjectParams())
    };
  }
}
