<section class="main-container">

  <app-error-message *ngIf="showAlertError" [cleanDesign]="true" [error]="error" [fullWidth]="true"></app-error-message>


  <div class="case-tabs-wrapper change-tab">
    <mat-tab-group #tabGroup (selectedTabChange)="refreshTabWorkspaceEditors($event)"
                   [selectedIndex]="selectedTab.value">
      <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab.details.name">
        <ng-template mat-tab-label>
          <div [matTooltip]="'/' + tab.projectId + tab.details.pathWithName"
               class="small-tab"
               matTooltipClass="wrap-tooltip-text">
            <div style="width: 10px"></div>

            <div>
              <button *ngIf="!tab.isSaved" class="tab-button" mat-icon-button>
                <mx-icon color="primary" marginBottom="true" mx="file-edit-circle"></mx-icon>
              </button>
              <span class="tab-name">{{tab.details.name}}</span>
            </div>

            <button
              (click)="closeTab(tab.details.pathWithName, tab.projectId, index, tab.isSaved);$event.stopPropagation()"
              class="tab-button"
              color="accent"
              mat-icon-button>
              <mx-icon class="tab-close-icon" marginBottom="true" mx="close"></mx-icon>
            </button>
          </div>
        </ng-template>


        <app-file-tab-workspace
          #fileTabWorkspace
          (close)="closeTab(tab.details.pathWithName, tab.projectId, index, true)"
          [tab]="tab"
          [transfer]="transfer"
          (openNewFile)="openNewFile($event)"
          style="height: 100%"
        ></app-file-tab-workspace>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
