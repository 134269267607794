import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserDialogComponent} from './user-dialog.component';
import {IconModule} from '../../../mx/icon/icon.module';
import {UserModule} from '../../components/user/user.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {ImportModule} from '../../../dialogs/import/import.module';
import {UserInfoModule} from "../../components/user-info/user-info.module";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    UserModule,
    MatDialogModule,
    MatButtonModule,
    ImportModule,
    UserInfoModule
  ],
  declarations: [
    UserDialogComponent
  ],
  exports: [
    UserDialogComponent
  ]
})

export class UserDialogModule {
}
