<h1
  class="text-center"
  mat-dialog-title
>Api key info</h1>
<div mat-dialog-content>
  <div class="api-instructions">
    <h5>Step-by-Step Guide to Using Your API Key:</h5>
    <ol>
      <li>When making an HTTP request, add the API Key to the request header under the name
        <strong>'X-Maxtaf-Api-Key'</strong>.
      </li>
      <li>Also, include your Project ID in the request header under the name <strong>'X-Project-Id'</strong>.</li>
    </ol>

    <p>Example of usage:</p>
    <pre>curl '{{apiRequestUrl}}<span style="color: red;">case_name</span>' \
      -H "X-Project-Id: <span [matTooltip]="'You can find Project ID in project settings.'"
                              style="color: red;">{{tokensService.getProjectId()}}</span>" \
      -H "X-Maxtaf-Api-Key: <span [matTooltip]="'You can set the API key in user settings.'" style="color: red;">your_api_key</span>" \
      -H "Accept: application/json" \
      -F 'runDetails={{curlyBrace}}
      "syncAsync": "SYNC"
      };type=application/json'
    </pre>
  </div>

  <div class="security-warning fs-12 alert alert-warning alert-dismissible fade show">
    <h4>Security Warning:</h4>
    <p>Your API key is a unique identifier that allows access to your projects, and it's essential to keep it
      confidential. Do not share your API key publicly or transmit it through unsecured channels. Ensure that the API
      key is stored in a secure environment and is only accessible to authorized individuals. Failure to safeguard your
      API key may result in unauthorized access to your projects and data.</p>

    <p><strong>Remember:</strong> Your API key are sensitive information that enables you to access your services.
      Handle them responsibly.</p>
  </div>
</div>
<div mat-dialog-actions>

  <button
    [mat-dialog-close]="false"
    color="primary"
    mat-button>
    OK
  </button>


</div>
