import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiDiffComponent} from './ai-diff.component';
import {ErrorModule} from '../../../mx/error/error.module';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatOptionModule} from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MxDiffCodeModule} from '../../../mx/mx-diff-code/mx-diff-code.module';


@NgModule({
  declarations: [AiDiffComponent],
  imports: [
    CommonModule,
    ErrorModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MxDiffCodeModule
  ],
  exports: [
    AiDiffComponent
  ]
})
export class AiDiffModule {
}
