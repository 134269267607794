import {Directive, HostBinding} from '@angular/core';

/**
 * @hidden
 */
@Directive({
  selector: 'mx-options-title'
})
export class MxOptionsTitleDirectives {
  @HostBinding('class.igx-expansion-panel__header-title')
  public cssClass = `igx-expansion-panel__header-title`;
}

/**
 * @hidden
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'mx-options-directive'
})
export class MxOptionsBodyDirectives {
  @HostBinding('class.igx-expansion-panel__header-description')
  public cssClass = `igx-expansion-panel__header-description`;
}
