import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PageParams} from '../../../mx/util/params/PageParams';
import {CaseConstService, CaseSearchParams} from '../../shared/services/case-const.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {MxTable} from '../../../mx/util/list/MxTable';
import {ImportInfoDetails} from '../../../dialogs/import/ImportInfoDetails';
import {FileService} from '../../../../shared/services/file.service';

@Component({
  selector: 'app-import-case-info-list',
  templateUrl: './import-case-info-list.component.html',
  styleUrls: ['./import-case-info-list.component.css']
})
export class ImportCaseInfoListComponent extends MxTable<ImportInfoDetails> implements OnInit, OnDestroy {

  idName: string = 'casesId';
  @Input('displayedColumns') displayedColumns: string[] = ['select', 'import-case-date', 'current-max', 'completed'];
  @Input('multiSelect') multiSelect = true;

  pageParams: PageParams;
  searchParams: CaseSearchParams;

  constructor(
    private router: Router,
    private fileService: FileService,
    public dialog: MatDialog
  ) {
    super();
    this.pageParams = CaseConstService.getDefaultPageParams();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  setFormat(runDate: Date) {
    const startFormat = moment.localeData(navigator.language).longDateFormat('lll');
    const format = startFormat.substr(0, startFormat.substr(0, startFormat.indexOf(':')).lastIndexOf(' ')) + ', h:mm:ss a';
    return moment(runDate).format(format);
  }

  getItems() {
    this.startLoadingSpinner();
    this.setItems([]);
    this.fileService.getImportInfoCases(this.searchParams, this.pageParams)
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          if (res) {
            this.setItems(res.content);
            this.setPageInfo(res);
          } else {
            this.setItems([]);
          }
        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );
  }

  selectAllToggle($event) {
    if ($event) {
      this.masterToggle(this.pagesInfo.totalPages, this.pagesInfo.sizeOfElementsOnPage);
    } else {
      return null;
    }
  }


}
