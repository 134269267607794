import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaxtafTokensStorageService {

  public PROJECT_TOKEN = 'X-Project-Id';
  public USER_TOKEN = 'X-User-Id';
  public USER_PROJECT_TOKEN = 'X-User-Project-Id';
  public FIREBASE_TOKEN = 'X-Firebase-Auth';
  public FIREBASE_REFRESH_TOKEN = 'X-Firebase-Refresh-Token';
  public USER_TIMEZONE_ID = 'X-User-TimeZone-Id';
  public API_KEY = 'X-Maxtaf-Api-Key';
  private NGX_FIREBASE_ACCESS_TOKEN = 'accessToken';
  private NGX_FIREBASE_REFRESH_TOKEN = 'refreshToken';

  constructor() {
  }

  public clearSession() {
    this.removeFireToken();
    this.removeFireRefreshToken();
    this.removeUserProjectID();
    this.removeProjectId();
    this.removeUserId();
    this.removeApiKey();
    localStorage.clear();
    sessionStorage.clear();
  }

  public clearForSelectProject() {
    this.removeUserProjectID();
    this.removeProjectId();
  }

  public getFireToken(): string {
    return localStorage.getItem('X-Firebase-Auth');
  }

  public getFireRefreshToken(): string {
    return localStorage.getItem('X-Firebase-Refresh-Token');
  }

  public getUserProjectID(): string {
    return sessionStorage.getItem(this.USER_PROJECT_TOKEN);
  }

  public getProjectId(): string {
    return sessionStorage.getItem(this.PROJECT_TOKEN);
  }

  public getUserId(): string {
    return localStorage.getItem(this.USER_TOKEN);
  }

  public getApiKey(): string {
    return localStorage.getItem(this.API_KEY);
  }

  public getNgIdleLastActive(): string {
    return localStorage.getItem('ng2Idle.main.expiry');
  }

  public setFireToken(token: string) {
    localStorage.setItem(this.FIREBASE_TOKEN, token);
  }

  public setRefreshToken(token: string) {
    localStorage.setItem(this.FIREBASE_REFRESH_TOKEN, token);
  }

  public setFireRefreshToken(token: string) {
    localStorage.setItem(this.FIREBASE_REFRESH_TOKEN, token);
  }

  public setIdle(status: string) {
    localStorage.setItem('X-Idle-' + status, new Date() + '');
  }

  public setUserProjectID(userProjectID: string) {
    sessionStorage.setItem(this.USER_PROJECT_TOKEN, userProjectID);
  }

  public setProjectId(projectID: string) {
    sessionStorage.setItem(this.PROJECT_TOKEN, projectID);
  }

  public setUserId(userID: string) {
    localStorage.setItem(this.USER_TOKEN, userID);
  }

  public setApiKey(apiKey: string) {
    localStorage.setItem(this.API_KEY, apiKey);
  }

  public removeFireToken() {
    localStorage.removeItem('X-Firebase-Auth');
  }

  public removeFireRefreshToken() {
    localStorage.removeItem(this.FIREBASE_REFRESH_TOKEN);
  }

  public removeUserProjectID() {
    sessionStorage.removeItem(this.USER_PROJECT_TOKEN);
  }

  public removeProjectId() {
    sessionStorage.removeItem(this.PROJECT_TOKEN);
  }

  public removeUserId() {
    localStorage.removeItem(this.USER_TOKEN);
  }

  public removeApiKey() {
    localStorage.removeItem(this.API_KEY);
  }

  /**/
  /**
   * Get access token
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable<string> {
    const token: string = localStorage.getItem(this.NGX_FIREBASE_ACCESS_TOKEN) as string;
    return of(token);
  }

  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getRefreshToken(): Observable<string> {
    const token: string = localStorage.getItem(this.NGX_FIREBASE_REFRESH_TOKEN) as string;
    return of(token);
  }

  /**
   * Set access token
   * @returns {TokenStorage}
   */
  public setAccessToken(token: string) {
    localStorage.setItem(this.NGX_FIREBASE_ACCESS_TOKEN, token);
  }

  /**
   * Set refresh token
   * @returns {TokenStorage}
   */

  /**
   * Remove tokens
   */
  public clear() {
    localStorage.removeItem(this.NGX_FIREBASE_ACCESS_TOKEN);
    localStorage.removeItem(this.NGX_FIREBASE_REFRESH_TOKEN);
  }

  /**/
}
