<div *ngIf="showErrorAlert"
     [class.full-width]="fullWidth"
     [class.show-in-dialog]="showInDialog"
     [ngClass]="{
     'clean-design': cleanDesign,
      'error-color': !infoColors,
       'info-color': infoColors
       }" class="error-message">
  <div [class.stick-to-top-of-bottom-component]="hasMoreDetails"
       [ngClass]="{'clean-design': cleanDesign, 'alert-danger': !infoColors}"
       class="alert alert-dismissible fade show">
    <h5 *ngIf="hasMoreDetails" class="alert-heading">{{ errorTitle }}</h5>
    <hr *ngIf="hasMoreDetails">
    <overlay-scrollbars
      #navTreeScrolling
      [ngStyle]="{'width': '100%'}"
      [options]="{ scrollbars: { autoHide: 'l' } }"
    >
      <p class="mb-0">{{ errorMessage }}</p>
    </overlay-scrollbars>
    <button (click)="hideErrorAlert()" *ngIf="!removeClose" aria-label="Close" class="close" data-dismiss="alert"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>


  <mat-expansion-panel #errorMessageExpansionPanel *ngIf="hasMoreDetails" [ngClass]="{'clean-design': cleanDesign}"
                       hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mx-icon *ngIf="!errorMessageExpansionPanel.expanded" class="white_arrows mr-3" mx="collapse"></mx-icon>
        <mx-icon *ngIf="errorMessageExpansionPanel.expanded" class="white_arrows mr-3" mx="expand"></mx-icon>
        More details
      </mat-panel-title>

      <mat-panel-description *ngIf="errorMessageExpansionPanel.expanded" class="mr-0" style="text-align: right">
        <button (click)="copyToClipboard(); $event.stopPropagation()" mat-button>
          <mx-icon mx="copy"></mx-icon>
          <span>Copy to clipboard</span>
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <overlay-scrollbars
      #navTreeScrolling
      [options]="{ scrollbars: { autoHide: 'l' } }"
    >
    <pre>
      {{ moreDetails }}
    </pre>
    </overlay-scrollbars>
  </mat-expansion-panel>
</div>
