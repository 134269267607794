<h1 mat-dialog-title>Are you sure you want to download this file?</h1>

<div mat-dialog-content>

</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" id="closeButton" mat-button>{{exportSuccess || spinner ? 'Close' : 'Cancel'}}</button>
  <button (click)="export()" *ngIf="!spinner" color="primary" id="exportButton" mat-button>Download</button>
  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>

