import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddProjectParamDialogComponent} from './add-project-param-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatCheckboxModule,
    MatButtonModule,
  ],
  declarations: [
    AddProjectParamDialogComponent
  ],
  exports: [
    AddProjectParamDialogComponent
  ]
})

export class AddProjectParamDialogModule {
}
