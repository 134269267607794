import {Component, Inject, OnInit} from '@angular/core';
import {GlobalExportService} from '../../shared/services/global-export.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {saveAs} from 'file-saver';
import {FileService} from '../../../../shared/services/file.service';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';

@Component({
  selector: 'app-export-log-dialog',
  templateUrl: './export-log-dialog.component.html',
  styleUrls: ['./export-log-dialog.component.css']
})
export class ExportLogDialogComponent implements OnInit {
  exportSuccess = false;
  spinner = false;
  exportError = false;
  error: any = undefined;

  constructor(public globalExportService: GlobalExportService,
              public fileService: FileService,
              public userProjectConfigService: UserProjectConfigService,
              public dialogRef: MatDialogRef<ExportLogDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {}) {
  }

  ngOnInit(): void {
  }


  closeDialog() {
    this.dialogRef.close();

  }

  export() {
    this.spinner = true;

    this.fileService.exportFile(this.userProjectConfigService.getLogs('bxlna')).subscribe(
      res => {
        this.exportSuccess = true;
        this.spinner = false;

        const contentDisposition = res.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();

        const blob = new Blob([res.body], {type: 'application/text'});
        saveAs(blob, fileName);
        this.closeDialog();
      },
      error => {
        this.spinner = false;
        console.error(error);
        this.showError(error);
      }
    );


  }

  showError(error) {
    this.error = error;
    this.exportError = true;
  }
}
