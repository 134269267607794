import {CompileResult} from '../../../cases/shared/models/compile-result';

export class CompileTabDetails {

  constructor(public isCompiled: boolean,
              public isCurrentlyBeingCompiled, // !compileSpinner
              public compileResult: CompileResult,
  ) {
  }

  hasCompileError(): boolean {
    return this.compileResult != undefined && !this.isCompiled && !this.isCurrentlyBeingCompiled;
  }

}
