import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {ConfigService} from '../../../../shared/services/config.service';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {catchError, filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {StorageConfigService} from '../../../../shared/services/storage-config.service';

@Injectable({
  providedIn: 'root'
})
export class RemoteFilesService {

  constructor(
    private apiService: ApiService,
    private config: ConfigService,
    private storageConfigService: StorageConfigService,
    private http: HttpClient
  ) {
  }

  deleteFile(pathWithName: string) {
    return this.apiService.delete(this.storageConfigService.deleteFile(pathWithName));
  }

  getFileNames(params) {
    return this.apiService.get(this.storageConfigService.getFileNames(), params);
  }

  createNewFolder(path: string, folderName: string, overwrite: boolean = false) {
    return this.apiService.post(this.storageConfigService.createFolder(path, folderName, overwrite), '{}');
  }

  copyFile(
    copyFrom: string,
    copyFromProjectId: string,
    copyTo: string,
    copyToProjectId: string,
    move: boolean,
    overwrite: boolean
  ) {
    let uri = this.storageConfigService.copyOrMoveFile(copyFrom, copyFromProjectId, copyTo, move, overwrite) + (copyToProjectId == null ? '' : '&X-Project-Id=' + copyToProjectId);
    return this.apiService.post(uri, '{}');
  }

  transferFile(
    copyFrom: string,
    copyFromProjectId: string,
    copyTo: string,
    copyToProjectId: string,
    move: boolean,
    overwrite: boolean
  ) {
    let uri = this.storageConfigService.transferFile(copyFrom, copyFromProjectId, copyTo, move, overwrite) + (copyToProjectId == null ? '' : '&X-Project-Id=' + copyToProjectId);
    return this.apiService.post(uri, '{}');
  }

  renameFile(
    path: string,
    oldName: string,
    newName: string,
    overwrite: boolean
  ) {
    return this.apiService.put(this.storageConfigService.renameFile(path, oldName, newName, overwrite), '{}');
  }

  createFile(pathWithName?: string, overwrite: boolean = false) {
    return this.apiService.post(this.storageConfigService.createFile(pathWithName, overwrite), '{}');
  }

  unzip(pathWithZipName: string, unzipLocation?: string, overwrite: boolean = false) {
    return this.apiService.post(this.storageConfigService.unzipFile(pathWithZipName, unzipLocation, overwrite), '{}');
  }

  zip(zipPath: string, pathWithName: string) {
    return this.apiService.post(this.storageConfigService.zipFile(zipPath, pathWithName), '{}');
  }

  updateFile(pathWithName: string, content: string) {
    return this.apiService.put(this.storageConfigService.updateFile(pathWithName), content);
  }

  getFile(pathWithName: string, linkWithCase: boolean = false, projectId) {
    return this.apiService.get(this.storageConfigService.getFile(pathWithName, linkWithCase, projectId));
  }

  cloneGitProject(url) {
    const formdata: FormData = new FormData();

    formdata.append('url', url);

    const headers = new HttpHeaders({
      Accept: 'application/json'
    });

    const req = new HttpRequest('POST', this.storageConfigService.cloneGitProject(), formdata, {
      headers,
      withCredentials: true,
      responseType: 'text'
    });


    return this.http.request(req).pipe(
      catchError(error => {
        console.error('error: ', error);

        if (error && error.status === 401) {
          // this.redirectIfUnauth(error);
        } else {
          // this.displayError(error);
        }
        throw error;
      }));
  }

  uploadFile(file: File, uploadPath: string, overwrite: boolean = true) {
    let queryParams = '';
    if (overwrite != undefined) {
      queryParams = queryParams + 'overwrite=' + overwrite + '&';
    }

    const formdata: FormData = new FormData();

    formdata.append('importFile', file);
    formdata.append('uploadPath', uploadPath);

    const headers = new HttpHeaders({
      Accept: 'application/json'
    });

    const req = new HttpRequest('POST', this.storageConfigService.uploadFile(queryParams), formdata, {
      headers,
      withCredentials: true,
      reportProgress: true,
      responseType: 'text'
    });


    return this.http.request(req).pipe(
      catchError(error => {
        console.error('error: ', error);

        if (error && error.status === 401) {
          // this.redirectIfUnauth(error);
        } else {
          // this.displayError(error);
        }
        throw error;
      }));
  }

  downloadFilesOrFolders(filePaths: string[]): Observable<any> {
    const headers = new HttpHeaders({
      Accept: 'application/text',
      'Content-Type': 'application/text'
    });

    let requestParameters = '?';
    for (let i = 0; i < filePaths.length; i++) {
      requestParameters += 'pathsWithNames=' + filePaths[i];
      if (i != filePaths.length - 1) {
        requestParameters += '&';
      }
    }

    const req = new HttpRequest('GET', this.storageConfigService.downloadFile(requestParameters), {}, {
      headers,
      withCredentials: true,
      reportProgress: true,
      responseType: 'blob'
    });


    return this.http.request(req).pipe(
      filter(response => response instanceof HttpResponse),
      map((response: HttpResponse<any>) => response),
      catchError(error => {
        console.error('error: ', error);

        if (error && error.status === 401) {
          // this.redirectIfUnauth(error);
        } else {
          // this.displayError(error);
        }
        throw error;
      }));
  }

  getStoragePasswordAsJSON() {
    return this.apiService.get(this.storageConfigService.getStoragePasswordAsJSON(), null);
  }

}
