import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {InvitationUser} from '../models/invitationUser';
import {Params} from '../../../mx/util/params/params';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';

@Injectable({
  providedIn: 'root'
})
export class InvitationUserService {

  constructor(
    private apiService: ApiService,
    private userProjectConfigService: UserProjectConfigService
  ) {
  }

  invitationUser(invitationUser: InvitationUser) {
    return this.apiService.post(this.userProjectConfigService.invitationUser(), invitationUser);
  }

  checkRequest(email: string, invCode: string) {
    return this.apiService.post(this.userProjectConfigService.invitationUserCheck(email, invCode), '{}');
  }

  refreshRequest(email: string) {
    return this.apiService.post(this.userProjectConfigService.invitationUserRefresh(email), '{}');
  }

  getAllUserRequests(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.userProjectConfigService.getUserRequests(), params);
  }

  deleteRequests(email: string, params?: any) {
    return this.apiService.delete(this.userProjectConfigService.removeRequest(email), params);
  }

}
