import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from '../../shared/models/project';
import {ProjectService} from '../../shared/services/project.service';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {GlobalEventsManagerService} from '../../../../shared/services/global-events-manager.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-dialog-project.component.html',
  styleUrls: ['./create-dialog-project.component.css']
})
export class CreateDialogProjectComponent implements OnInit, OnDestroy {

  @Input('type') type = '';
  automaticallyGenerateId = true;

  showErrorAlert = false;
  error;

  project: Project;
  spinner = false;
  autoCreate = false;

  firstCaseOrSuiteId: string;
  firstCaseOrSuiteType: string;
  fileLocation: string;

  constructor(
    private projectService: ProjectService,
    private maxtafTokensStorageService: MaxtafTokensStorageService,
    private globalEventsManager: GlobalEventsManagerService,
    public dialogRef: MatDialogRef<CreateDialogProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: string,
      project: Project,
      autoCreate: boolean;
      firstCaseOrSuiteId: string,
      firstCaseOrSuiteType: string,
      fileLocation: string,
      error
    }
  ) {
    dialogRef.disableClose = true;
    this.type = data.type;
    if (this.data.project) {
      this.project = this.data.project;
    } else {
      this.project = new Project();
    }

    if (this.data.error) {
      this.showError(this.data.error);
    }

    if (this.data.firstCaseOrSuiteId) {
      this.firstCaseOrSuiteId = this.data.firstCaseOrSuiteId;
    }

    if (this.data.fileLocation) {
      this.fileLocation = this.data.fileLocation;
    }

    if (this.data.firstCaseOrSuiteType) {
      this.firstCaseOrSuiteType = this.data.firstCaseOrSuiteType;
    }

    if (this.data.autoCreate) {
      if (this.project.name == undefined || this.project.name == '') {
        this.project.name = 'public project';
      }
      this.autoCreate = this.data.autoCreate;
    }

    if (this.autoCreate) {
      this.addProject(this.project, true);
    }
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelCreateProjectButton').click();
    }
  }

  // ENTER shortcut for creating the test
  createShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('createProjectConfirmButton').click();
    }
  }

  ngOnInit(): void {
    this.globalEventsManager.setGrayScreen(false);
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.createShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.createShortcutListener, false);
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  closeDialog(project: Project): void {
    this.dialogRef.close(project);
  }

  save() {
    this.addProject(this.project, this.automaticallyGenerateId);
  }

  addProject(project: Project, automaticallyGenerateId) {
    this.spinner = true;
    this.showErrorAlert = false;

    let newProject = {
      id: project.id,
      name: project.name,
      firstCaseOrSuiteType: null,
      firstCaseOrSuiteId: null,
      firstCaseOrSuiteProjectId: null,
      firstFileFileLocation: null,
      firstFileFileLocationProjectId: null
    };

    if (this.firstCaseOrSuiteId != undefined) {
      newProject.firstCaseOrSuiteType = this.firstCaseOrSuiteType;
      newProject.firstCaseOrSuiteId = this.firstCaseOrSuiteId;
      newProject.firstCaseOrSuiteProjectId = this.maxtafTokensStorageService.getProjectId();
    }

    if (this.fileLocation != undefined) {
      newProject.firstFileFileLocation = this.fileLocation;
      newProject.firstFileFileLocationProjectId = this.maxtafTokensStorageService.getProjectId();
    }


    this.projectService.addProject(newProject, automaticallyGenerateId, this.autoCreate).subscribe(
      userProject => {

        this.showErrorAlert = false;

        this.closeDialog(userProject);
      },
      error => {

        this.showError(error);
        this.spinner = false;
      }
    );
  }

}
