import {Injectable} from '@angular/core';
import {SearchParam} from '../../../mx/util/params/searchParam';
import {PageParams} from '../../../mx/util/params/PageParams';

@Injectable({
  providedIn: 'root'
})
export class CompileInfoCaseInfoConstService {


  constructor() {
  }

  public static initCompileInfoCaseInfoDialogParams(size = 10): CompileInfoCaseInfoDialogParams {
    return {
      page: CompileInfoCaseInfoConstService.getDefaultPageParams(size),
      search: CompileInfoCaseInfoConstService.getDefaultSearchParams(),
    };
  }

  public static getDefaultSearchParams(): CompileInfoCaseInfoConstParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      caseName: new SearchParam(null, null),
      errorMessage: new SearchParam(null, null)
    };
  }

  public static getDefaultPageParams(size = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('compileCaseIndex,asc', 'compileCaseIndex,asc')
    };
  }
}

export class CompileInfoCaseInfoDialogParams {
  page: PageParams;
  search: CompileInfoCaseInfoConstParams;
}

export class CompileInfoCaseInfoConstParams {
  exactSearch: SearchParam;
  caseName: SearchParam;
  errorMessage: SearchParam;
}
