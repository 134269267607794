import {Component, Input, OnInit} from '@angular/core';

// https://materialdesignicons.com/

@Component({
  selector: 'mx-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent implements OnInit {

  icon = 'help';
  spinner = false;
  color = 'inherit';
  hoverColor: string = undefined;
  @Input('diameter') diameter = 20;
  @Input('marginBottom') marginBottom = false;
  private primaryColor = '#1e88e5';
  private accentColor = '#acabaa';
  private warnColor = '#e53e3a';
  private whiteColor = '#ffffff';
  private blackColor = '#000000';
  private greenColor = '#4dd965';
  private purpleColor = '#311b92';
  private yellowColor = '#ffba00';
  private orangeColor = '#f09410';
  // private orangeColor = '#ec800a';
  private disabledColor = '#b7b7b7';

  constructor() {
  }

  @Input('icon') set _setIcon(icon: string) {
    if (icon != undefined) {
      this.setIcon(icon.toLowerCase());
    }
  }

  @Input('color') set _setColor(color: string) {
    if (color != undefined) {
      this.setColor(color.toLowerCase());
    }
  }

  @Input('hoverColor') set _setHoverColor(hoverColor: string) {
    if (hoverColor != undefined) {
      this.setHoverColor(hoverColor.toLowerCase());
    }
  }

  @Input('mx') set _setIconFromMx(icon: string) {
    if (icon != undefined) {
      this.setIconFormMaxtafCode(icon.toLowerCase());
    }
  }

  ngOnInit(): void {
  }

  hoverAdd(event) {
    if (this.hoverColor != undefined) {
      event.target.style.color = this.hoverColor;
    }
  }

  hoverRemove(event) {
    if (this.hoverColor != undefined) {
      event.target.style.color = this.color;
    }
  }

  private setIcon(icon: string) {
    this.icon = 'mdi-' + icon;
  }

  private setColor(color: string) {
    if (color == 'primary') {
      color = this.primaryColor;
    }

    if (color == 'accent') {
      color = this.accentColor;
    }

    if (color == 'warn') {
      color = this.warnColor;
    }

    if (color == 'white') {
      color = this.whiteColor;
    }

    if (color == 'black') {
      color = this.blackColor;
    }

    if (color == 'green') {
      color = this.greenColor;
    }

    if (color == 'purple') {
      color = this.purpleColor;
    }

    if (color == 'yellow') {
      color = this.yellowColor;
    }

    if (color == 'orange') {
      color = this.orangeColor;
    }

    if (color == 'disabled') {
      color = this.disabledColor;
    }

    this.color = color;
  }

  private setHoverColor(hoverColor: string) {
    if (hoverColor == 'primary') {
      hoverColor = this.primaryColor;
    }

    if (hoverColor == 'accent') {
      hoverColor = this.accentColor;
    }

    if (hoverColor == 'warn') {
      hoverColor = this.warnColor;
    }

    if (hoverColor == 'white') {
      hoverColor = this.whiteColor;
    }

    if (hoverColor == 'black') {
      hoverColor = this.blackColor;
    }

    if (hoverColor == 'green') {
      hoverColor = this.greenColor;
    }

    if (hoverColor == 'yellow') {
      hoverColor = this.yellowColor;
    }

    this.hoverColor = hoverColor;
  }

  private setIconFormMaxtafCode(icon: string) {
    if (icon == 'spinner') {
      this.spinner = true;
      return;
    }

    if (icon == 'menu') {
      this.setIcon('menu');
      return;
    }

    if (icon == 'list') {
      this.setIcon('format-list-bulleted');
      return;
    }

    if (icon == 'checkbox-blank') {
      this.setIcon('checkbox-blank-outline');
      return;
    }

    if (icon == 'reset') {
      this.setIcon('backup-restore');
      return;
    }

    if (icon == 'checkbox-marked') {
      this.setIcon('checkbox-marked');
      return;
    }

    if (icon == 'home') {
      this.setIcon('home');
      return;
    }

    if (icon == 'cases') {
      this.setIcon('clipboard-multiple-outline');
      return;
    }

    if (icon == 'case') {
      this.setIcon('clipboard-outline');
      return;
    }
    if (icon == 'page-objects') {
      this.setIcon('clipboard-text-multiple-outline');
      return;
    }

    if (icon == 'transform-to-case') {
      this.setIcon('briefcase-upload');
      return;
    }

    if (icon == 'transform-to-page') {
      // this.setIcon('clipboard-arrow-up-outline');
      // this.setIcon('page-layout-footer');
      // this.setIcon('arrow-up-bold-box');
      this.setIcon('clipboard-arrow-up');

      return;
    }

    if (icon == 'down') {
      this.setIcon('arrow-down');
      return;
    }

    if (icon == 'up') {
      this.setIcon('arrow-up');
      return;
    }

    if (icon == 'suites') {
      this.setIcon('briefcase-outline');
      return;
    }

    if (icon == 'suite') {
      this.setIcon('briefcase-outline');
      return;
    }

    if (icon == 'run-sh') {
      // this.setIcon('play-outline');
      this.setIcon('play-circle-outline');
      // this.setIcon('script-text-play-outline');
      // this.setIcon('script-text-play');
      // this.setIcon('play-circle-outline');
      // this.setIcon('teddy-bear');
      // this.setIcon('play-box-outline');
      // this.setIcon('playlist-play');
      return;
    }

    if (icon == 'runs') {
      this.setIcon('run');
      return;
    }

    if (icon == 'completed-runs') {
      this.setIcon('exit-run');
      return;
    }

    if (icon == 'list-tab') {
      this.setIcon('playlist-plus');
      return;
    }

    if (icon == 'import-info') {
      this.setIcon('progress-upload');
      // this.setIcon('table-arrow-up');
      return;
    }

    if (icon == 'workspace') {
      this.setIcon('feather');
      return;
    }

    if (icon == 'tune') {
      this.setIcon('tune');
      return;
    }
    if (icon == 'pencil') {
      this.setIcon('pencil');
      return;
    }

    if (icon == 'change-view') {
      this.setIcon('swap-horizontal');
      return;
    }

    if (icon == 'schedule') {
      this.setIcon('alarm');
      return;
    }

    if (icon == 'create-schedule') {
      this.setIcon('alarm-plus');
      return;
    }


    if (icon == 'active-schedule') {
      this.setIcon('alarm-check');
      return;
    }


    if (icon == 'pause-schedule') {
      this.setIcon('alarm-off');
      return;
    }

    if (icon == 'settings') {
      this.setIcon('cog');
      return;
    }

    if (icon == 'user-settings') {
      this.setIcon('account-cog');
      return;
    }

    if (icon == 'users') {
      this.setIcon('account-group-outline');
      return;
    }

    if (icon == 'user-avatar') {
      this.setIcon('account-tie');
      return;
    }

    if (icon == 'user-details') {
      this.setIcon('account-details');
      return;
    }

    if (icon == 'user') {
      this.setIcon('account-circle');
      return;
    }

    if (icon == 'log-out') {
      this.setIcon('power');
      return;
    }

    if (icon == 'project') {
      this.setIcon('chart-bubble');
      return;
    }

    if (icon == 'create') {
      this.setIcon('plus-circle-outline');
      return;
    }

    if (icon == 'add') {
      this.setIcon('plus');
      return;
    }

    if (icon == 'add-circle') {
      this.setIcon('plus-circle-outline');
      return;
    }

    if (icon == 'refresh') {
      this.setIcon('refresh');
      return;
    }

    if (icon == 'export') {
      this.setIcon('cloud-download');
      return;
    }

    if (icon == 'import') {
      this.setIcon('cloud-upload');
      return;
    }

    if (icon == 'download') {
      this.setIcon('cloud-download');
      return;
    }

    if (icon == 'upload') {
      this.setIcon('cloud-upload');
      return;
    }

    if (icon == 'trash') {
      this.setIcon('delete-sweep');
      return;
    }

    if (icon == 'restore') {
      this.setIcon('delete-restore');
      return;
    }

    if (icon == 'delete') {
      this.setIcon('delete');
      return;
    }

    if (icon == 'delete-logs') {
      this.setIcon('table');
      // this.setIcon('table-remove');
      return;
    }

    if (icon == 'stop-all') {
      this.setIcon('stop-circle-outline');
      return;
    }

    if (icon == 'stop') {
      this.setIcon('stop-circle-outline');
      return;
    }

    if (icon == 'run') {
      this.setIcon('play-circle-outline');
      return;
    }

    if (icon == 'run-compile') {
      this.setIcon('play-circle-outline');
      return;
    }

    if (icon == 'run-save') {
      this.setIcon('play-circle-outline');
      return;
    }

    if (icon == 'run-compile-save') {
      this.setIcon('play-circle-outline');
      return;
    }

    if (icon == 'compile') {
      this.setIcon('cogs');
      return;
    }

    if (icon == 'compile-info') {
      this.setIcon('table-cog');
      return;
    }

    if (icon == 'compile-save') {
      this.setIcon('cogs');
      return;
    }

    if (icon == 'save') {
      this.setIcon('content-save');
      return;
    }

    if (icon == 'discard') {
      this.setIcon('close');
      return;
    }

    if (icon == 'uncompiledwarning') {
      this.setIcon('cog-sync-outline');
      return;
    }

    if (icon == 'unsavedwarning') {
      this.setIcon('file-alert-outline');
      return;
    }

    if (icon == 'params') {
      this.setIcon('tune');
      return;
    }

    if (icon == 'verify-email') {
      this.setIcon('email-outline');
      return;
    }

    if (icon == 'file-properties') {
      this.setIcon('file-question-outline');
      return;
    }

    if (icon == 'create-file') {
      this.setIcon('file-plus');
      return;
    }

    if (icon == 'file-edit-circle') {
      this.setIcon('pencil-circle-outline');
      return;
    }

    if (icon == 'code-lookup') {
      this.setIcon('card-search-outline');
      return;
    }

    if (icon == 'filter') {
      this.setIcon('magnify');
      return;
    }

    if (icon == 'system-report') {
      this.setIcon('finance');
      return;
    }

    if (icon == 'clear') {
      this.setIcon('restore');
      return;
    }

    if (icon == 'cancel') {
      this.setIcon('close');
      return;
    }

    if (icon == 'close') {
      this.setIcon('close');
      return;
    }

    if (icon == 'allure') {
      this.setIcon('chart-bar');
      return;
    }

    if (icon == 'success-run') {
      this.setIcon('check-circle-outline');
      return;
    }

    if (icon == 'queue-run') {
      this.setIcon('dots-horizontal-circle-outline');
      return;
    }

    if (icon == 'failed-to-start-run') {
      this.setIcon('close-octagon-outline');
      return;
    }

    if (icon == 'fail-run') {
      this.setIcon('close-circle-outline');
      return;
    }

    if (icon == 'stopped-run') {
      this.setIcon('stop-circle-outline');
      return;
    }

    if (icon == 'wait-run') {
      this.setIcon('power-sleep');
      return;
    }

    if (icon == 'unknown-run') {
      this.setIcon('help-circle-outline');
      return;
    }

    if (icon == 'open-video') {
      this.setIcon('video-outline');
      return;
    }

    if (icon == 'open-script') {
      // this.setIcon('align-vertical-distribute');
      // this.setIcon('adjust');
      this.setIcon('approximately-equal');
      return;
    }

    if (icon == 'info') {
      this.setIcon('information-outline');
      return;
    }

    if (icon == 'duplicate') {
      this.setIcon('content-duplicate');
      return;
    }

    if (icon == 'copy') {
      this.setIcon('content-copy');
      return;
    }

    if (icon == 'paste-to-ai') {
      this.setIcon('message-arrow-right-outline');
      return;
    }

    if (icon == 'cut') {
      this.setIcon('content-cut');
      return;
    }

    if (icon == 'paste') {
      this.setIcon('content-paste');
      return;
    }

    if (icon == 'edit') {
      this.setIcon('pencil');
      return;
    }

    if (icon == 'rename') {
      this.setIcon('pencil');
      return;
    }

    if (icon == 'build-params') {
      this.setIcon('eye');
      return;
    }

    if (icon == 'expand') {
      this.setIcon('chevron-down');
      return;
    }

    if (icon == 'open-arrow') {
      this.setIcon('chevron-down');
      return;
    }

    if (icon == 'close-arrow') {
      this.setIcon('chevron-up');
      return;
    }

    if (icon == 'right-arrow') {
      this.setIcon('chevron-right');
      return;
    }

    if (icon == 'left-arrow') {
      this.setIcon('chevron-left');
      return;
    }

    if (icon == 'collapse') {
      this.setIcon('chevron-right');
      return;
    }

    if (icon == 'open') {
      this.setIcon('open-in-app');
      return;
    }

    if (icon == 'open-in-new') {
      this.setIcon('open-in-new');
      return;
    }

    if (icon == 'folder') {
      this.setIcon('folder-outline');
      return;
    }

    if (icon == 'folder-open') {
      this.setIcon('folder-open-outline');
      return;
    }

    if (icon == 'create-folder') {
      this.setIcon('folder-plus');
      return;
    }

    if (icon == 'folder-search') {
      this.setIcon('folder-search');
      return;
    }

    if (icon == 'success') {
      this.setIcon('checkbox-marked-circle');
      return;
    }

    if (icon == 'interrupted') {
      this.setIcon('alert-octagon-outline');
      return;
    }

    if (icon == 'wait') {
      this.setIcon('dots-horizontal-circle');
      return;
    }

    if (icon == 'fail') {
      this.setIcon('close-circle');
      return;
    }

    if (icon == 'blank-circle') {
      this.setIcon('checkbox-blank-circle');
      return;
    }

    if (icon == 'minus') {
      this.setIcon('minus');
      return;
    }

    if (icon == 'check') {
      this.setIcon('check-outline');
      return;
    }

    if (icon == 'support') {
      this.setIcon('face-agent');
      return;
    }

    if (icon == 'tutorial') {
      this.setIcon('cast-education');
      return;
    }

    if (icon == 'information') {
      this.setIcon('information-outline');
      return;
    }

    if (icon == 'documentation') {
      this.setIcon('bookshelf');
      return;
    }

    if (icon == 'goback') {
      this.setIcon('undo');
      return;
    }

    if (icon == 'learn-more') {
      this.setIcon('lightbulb-on-outline');
      return;
    }

    if (icon == 'link') {
      this.setIcon('arrow-right-bold-hexagon-outline');
      return;
    }

    if (icon == 'dashboard') {
      this.setIcon('view-dashboard');
      return;
    }

    if (icon == 'tree-node-expanded') {
      this.setIcon('plus-box-outline');
      return;
    }

    if (icon == 'tree-node-collapsed') {
      this.setIcon('minus-box-outline');
      return;
    }

    if (icon == 'zip') {
      this.setIcon('folder-zip');
      return;
    }

    if (icon == 'unzip') {
      this.setIcon('folder-zip');
      return;
    }

    if (icon == 'options') {
      this.setIcon('dots-vertical');
      return;
    }

    if (icon == 'assets') {
      // this.setIcon('clipboard-file-outline');
      this.setIcon('cube-outline');
      return;
    }

    if (icon == 'workspace-project') {
      this.setIcon('file-multiple-outline');
      return;
    }

    if (icon == 'workspace-other-project') {
      this.setIcon('view-grid-outline');
      return;
    }

    if (icon == 'terminal') {
      this.setIcon('console');
      return;
    }

    if (icon == 'restart') {
      this.setIcon('restart');
      return;
    }

    if (icon == 'code-history') {
      this.setIcon('history');
      return;
    }

    if (icon == 'share') {
      this.setIcon('share-variant');
      return;
    }

    if (icon == 'close-fullscreen') {
      this.setIcon('fullscreen-exit');
      return;
    }

    if (icon == 'fullscreen') {
      this.setIcon('fullscreen');
      return;
    }

    if (icon == 'mouse') {
      this.setIcon('mouse');
      return;
    }

    if (icon == 'main-tab') {
      this.setIcon('book-information-variant');
      return;
    }

    if (icon == 'script-tab') {
      this.setIcon('script-text-outline');
      return;
    }

    if (icon == 'schedule-tab') {
      this.setIcon('alarm');
      return;
    }

    if (icon == 'run-tab') {
      this.setIcon('run');
      return;
    }

    if (icon == 'ai') {
      // this.setIcon('message-reply-text');
      // this.setIcon('message-text-outline');
      this.setIcon('comment-text-outline');
      return;
    }

    if (icon == 'new') {
      this.setIcon('plus');
      return;
    }

    if (icon == 'insert') {
      this.setIcon('arrow-collapse-right');
      return;
    }

    if (icon == 'arrow-collapse-right') {
      this.setIcon('arrow-collapse-right');
      return;
    }

    if (icon == 'arrow-collapse-down') {
      this.setIcon('arrow-collapse-down');
      return;
    }

    if (icon == 'arrow-collapse-left') {
      this.setIcon('arrow-collapse-left');
      return;
    }

    if (icon == 'arrow-collapse-left') {
      this.setIcon('arrow-collapse-left');
      return;
    }

    if (icon == 'diff') {
      this.setIcon('vector-difference');
      return;
    }

    if (icon == 'unlinked-file') {
      // this.setIcon('link-variant');
      // this.setIcon('file-excel-outline');
      this.setIcon('checkbox-blank-outline');
      return;
    }

    if (icon == 'linked-file') {
      // this.setIcon('link-variant-off');
      // this.setIcon('file-link');
      // this.setIcon('file-link-outline');
      this.setIcon('checkbox-marked');
      return;
    }

    this.setIcon('help');
  }
}
