import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiTextComponent} from './ai-text.component';
import {MarkdownModule} from "ngx-markdown";

// import "prismjs/prism"
// import "prismjs/plugins/line-numbers/prism-line-numbers.js"

@NgModule({
  declarations: [AiTextComponent],
  exports: [
    AiTextComponent
  ],
  imports: [
    CommonModule,
    MarkdownModule.forRoot()
    //https://github.com/jfcere/ngx-markdown/tree/version-11.2.0#main-application-module
    // HttpClientModule,
    // +  MarkdownModule.forRoot({ loader: HttpClient }),
  ]
})
export class AiTextModule {
}
