import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PasswordValidation} from '../../../../shared/validators/equal-validator.directive';
import {AuthMaxtafService} from '../../../../shared/services/auth-maxtaf.service';

@Component({
  selector: 'app-edit-password-dialog',
  templateUrl: './edit-password-dialog.component.html',
  styleUrls: ['./edit-password-dialog.component.css']
})
export class EditPasswordDialogComponent implements OnInit {

  className = 'EditPasswordDialogComponent';

  hidePasswordButton = true;
  hideConfirmPasswordButton = true;
  hideCurrentPasswordButton = true;

  spinner = false;
  message: string;
  color = 'red';
  showButtonOk = false;

  form: FormGroup;

  constructor(private authService: AuthMaxtafService,
              public dialogRef: MatDialogRef<EditPasswordDialogComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: {}) {
  }

  ngOnInit(): void {
    this.setFormFields();
  }

  setFormFields() {
    this.form = this.formBuilder.group({
      oldPassword: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(5)])]
    }, {validator: PasswordValidation.MatchPassword});
  }

  tryChangePassword() {
    if (!this.denySubmitIfValidationErrorsExist()) {
      return;
    }
    this.removeOldMessagesAndStartSpinner();
    this.changePassword();
  }

  denySubmitIfValidationErrorsExist(): boolean {
    if (this.form.get('oldPassword').invalid) {
      return false;
    }
    if (this.form.get('password').invalid) {
      return false;
    }
    if (this.form.get('confirmPassword').invalid) {
      return false;
    }

    return true;
  }

  changePassword() {
    this.authService.changePassword(this.createPasswordChanger())
      .subscribe(res => {
        this.processingFeedbacks(res.result);
      }, error => {
        this.setErrorMessagesAndStopSpinner(error.error.text);
      });
  }

  createPasswordChanger() {
    return {
      oldPassword: this.form.get('oldPassword').value,
      newPassword: this.form.get('password').value
    };
  }

  processingFeedbacks(result) {
    if (this.isPasswordChanged(result)) {
      this.setSuccessMessages();
      this.showButtonOkAndHideChangePasswordButton();
    } else {
      this.setServerErrorMessage();
    }
    this.stopSpinner();
  }

  setErrorMessagesAndStopSpinner(result) {
    if (this.isWrongPassword(result)) {
      this.setBadCurrentPasswordMessage();
    } else {
      this.setServerErrorMessage();
    }
    this.stopSpinner();
  }

  isPasswordChanged(result): boolean {
    return result == 'success';
  }

  isWrongPassword(result): boolean {
    return result == 'Bad credentials';
  }

  removeOldMessagesAndStartSpinner() {
    this.message = '';
    this.spinner = true;
  }

  setSuccessMessages() {
    this.message = 'password is changed';
    this.color = 'green';
  }

  showButtonOkAndHideChangePasswordButton() {
    this.showButtonOk = true;
  }

  setBadCurrentPasswordMessage() {
    this.message = 'Bad current password';
    this.color = 'red';
  }

  setServerErrorMessage() {
    this.message = 'server error';
    this.color = 'red';
  }

  stopSpinner() {
    this.spinner = false;
  }
}
