import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ProjectParams} from '../../shared/models/projectParams';
import {ProjectParamsService} from '../../shared/services/project-params.service';
import {DeleteDialogComponent} from '../../../dialogs/delete-dialog/delete-dialog.component';
import {MxTable} from '../../../mx/util/list/MxTable';
import {PageParams} from '../../../mx/util/params/PageParams';
import {ProjectParamsSearchParams} from '../../shared/services/user-const.service';
import {YesNoDialogComponent} from "../../../dialogs/yes-no-dialog/yes-no-dialog.component";

@Component({
  selector: 'app-project-params-list',
  templateUrl: './project-params-list.component.html',
  styleUrls: ['./project-params-list.component.css']
})
export class ProjectParamsListComponent extends MxTable<ProjectParams> implements OnInit {

  displayedColumns = ['name', 'value', 'description', 'remove', 'options'];
  idName = 'name';

  pageParams: PageParams;
  searchParams: ProjectParamsSearchParams;

  configurationParams = false;

  constructor(
    private projectParamService: ProjectParamsService,
    public dialog: MatDialog
  ) {
    super();
  }

  @Input('configuration') set _setConfigurationParams(configurationParams) {
    this.configurationParams = configurationParams;
    if (configurationParams) {
      this.displayedColumns = ['name', 'value', 'description', 'options'];
    } else {
      this.displayedColumns = ['name', 'value', 'description', 'remove'];
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  getItems() {
    this.startLoadingSpinner();
    this.setItems([]);
    if (this.configurationParams) {
      this.projectParamService.getAllConfigurationParam(this.searchParams, this.pageParams)
        .subscribe(
          res => {
            this.stopLoadingSpinner();
            if (res) {
              this.setItems(res.content);
              this.setPageInfo(res);
            } else {
              this.setItems([]);
            }
          },
          error => {
            this.showError(error);
            this.stopLoadingSpinner();
          }
        );
    } else {
      this.projectParamService.getAll(this.searchParams, this.pageParams)
        .subscribe(
          res => {
            this.stopLoadingSpinner();
            if (res) {
              this.setItems(res.content);
              this.setPageInfo(res);
            } else {
              this.setItems([]);
            }
          },
          error => {
            this.showError(error);
            this.stopLoadingSpinner();
          }
        );
    }

  }

  removeProjectParam(element: ProjectParams) {
    this.createDeleteDialog(element.name);
  }

  createDeleteDialog(name: string): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '600px',
      data: {type: 'Project param'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteProjectParams(name);
      }
    });
  }

  isOverflow(el: HTMLElement): boolean {
    let curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') {
      el.style.overflow = 'hidden';
    }
    let isOverflowing = el.clientWidth < el.scrollWidth
      || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;

  }

  setDefaultValue(element: ProjectParams) {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        body: '',
        title: 'Are you sure you want to reset \'' + element.name + '\' param?',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetConfigurationParam(element);
      }
    });


  }

  /*
  openAddProjectParamDialog(projectParam?: ProjectParams) {

    let dialogRef = this.dialog.open(AddProjectParamDialogComponent, {
      width: '500px',
      data: {projectParam: projectParam}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.getProjectParams();
    });
  }


  openExportDialog() {
    let dialogRef = this.dialog.open(ExportProjectParamsComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openImportDialog() {
    let dialogRef = this.dialog.open(ImportComponent, {
      width: '500px',
      data: {type: "project params"}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getProjectParams();
    });
  }
  */

  resetConfigurationParam(element: ProjectParams) {
    this.projectParamService.setDefaultValue(element.name)
      .subscribe(
        res => {
          this.refreshItems();
        },
        error => {
          console.error(error);
          this.error.emit(error);
        }
      );
  }

  private deleteProjectParams(name: string) {
    this.projectParamService.deleteProjectParam(name)
      .subscribe(
        res => {
          this.refreshItems();
        },
        error => {
          console.error(error);
          this.error.emit(error);
        }
      );
  }
}
