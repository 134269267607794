<h1 mat-dialog-title>{{heading}}</h1>

<div mat-dialog-content>
  <div *ngIf="exportSuccess" class="fs-12 alert alert-success alert-dismissible fade show" role="alert">
    Export finished with success.
    <button (click)="exportSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <app-error-message *ngIf="exportError" [error]="error" [showInDialog]="true" class="fs-12"></app-error-message>

  <mat-radio-group [(ngModel)]="exportType" aria-label="Select an option"
                   style="width:100%; text-align: center">
    <mat-radio-button [disabled]="!checkPaths.json.enabled" [value]="'JSON'" class="mr-3" matTooltip="MaxTAF Cloud">
      JSON
    </mat-radio-button>
    <mat-radio-button [disabled]="!active || !checkPaths.xml.enabled" [value]="'XML'" class="mr-3" matTooltip="Maximo">
      XML
    </mat-radio-button>
    <mat-radio-button *ngIf="selected.length === 1" [disabled]="!active || !checkPaths.template.enabled" [value]="'TEMPLATE'"
                      class="mr-3" matTooltip="MaxTAF Template">TEMPLATE
    </mat-radio-button>
    <mat-radio-button [disabled]="!checkPaths.transfer.enabled" [value]="'TRANSFER'" matTooltip="MaxTAF Transfer">
      TRANSFER
    </mat-radio-button>
  </mat-radio-group>

  <div *ngIf="exportType === 'TEMPLATE' && selected.length === 1" class="mt-3">
    <p>File will be saved in workspace/templates directory.</p>
    <label>Template name</label>
    <input [(ngModel)]="templateName" type="text">
    <label>Template description</label>
    <input [(ngModel)]="templateDescription" type="text">
  </div>

  <br *ngIf="!hideAdvancedSettings && exportType !== 'TEMPLATE'">
  <br *ngIf="!hideAdvancedSettings && exportType !== 'TEMPLATE'">

  <mat-form-field *ngIf="exportType === 'TRANSFER'" class="example-form">
    <mat-label>Projects</mat-label>
    <mat-select [formControl]="projects" multiple>
      <mat-select-trigger>
        {{projects.value ? projects.value[0].name : ''}}
        <span *ngIf="projects.value?.length > 1" class="example-additional-selection">
        (+{{projects.value.length - 1}} {{projects.value?.length === 2 ? 'other' : 'others'}})
      </span>
      </mat-select-trigger>
      <mat-option *ngFor="let project of projectsList" [value]="project">{{project.name}} - [{{project.id}}]
      </mat-option>

    </mat-select>
  </mat-form-field>

  <br *ngIf="exportType === 'TRANSFER'">
  <mat-checkbox (change)="overwriteCase = !overwriteCase" *ngIf="exportType === 'TRANSFER'" [checked]="overwriteCase"
                id="overwrite2Check"
                type="checkbox">Overwrite case data with import file?
  </mat-checkbox>

  <br *ngIf="exportType === 'TRANSFER'">
  <br *ngIf="exportType === 'TRANSFER'">

  <div *ngIf="!hideAdvancedSettings && exportType !== 'TEMPLATE'" [@.disabled]="disableAnimation"
       style="margin-left: 2rem">
    <!--    <mat-expansion-panel>-->
    <!--      <mat-expansion-panel-header>-->
    <!--        <mat-panel-title>-->
    <!--          Advanced settings-->
    <!--        </mat-panel-title>-->
    <!--      </mat-expansion-panel-header>-->

    <mat-checkbox [(ngModel)]="onlyArchivedOrActive"
                  [disabled]="exportType != 'JSON' || useFilter || this.selected.length != 0"
                  matTooltip="{{exportType == 'JSON'? (onlyArchivedOrActive ? 'Export without ' + (active ? 'archived' : 'active') + ' ' + exportType + 's': 'Export archived and active ' + exportType + 's'): 'Only available for JSON'}}">
      Only {{selected.length == 1 ? 'this ' + exportType.toLowerCase() + '' : selected.length > 1 ? 'selected' : active ? 'active' : 'archived'}}
    </mat-checkbox>
    <br>
    <!--      <mat-checkbox [disabled]="jsonOrXmlorTemplate != 'JSON'" [jsonOrXmlorTemplate]="jsonOrXml == 'JSON'" [(ngModel)]="classFile"-->
    <!--                    matTooltip="Only available for JSON">Class file-->
    <!--      </mat-checkbox>-->
    <br>
    <mat-checkbox (change)="useFilter == true ? onlyArchivedOrActive = true : null" [(ngModel)]="useFilter"
                  [disabled]="this.selected.length != 0">Use Filter
    </mat-checkbox>


    <!--    </mat-expansion-panel>-->
  </div>

  <br>

</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" id="closeButton" mat-button>{{exportSuccess || spinner ? 'Close' : 'Cancel'}}</button>
  <button (click)="export()" *ngIf="!spinner" color="primary" id="exportButton" mat-button>Export</button>
  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>
</div>

