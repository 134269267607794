import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {CheckPath} from '../../layout/mx/options/CheckPath';
import {ConfigService} from './config.service';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CheckRolesService {

  constructor(private apiService: ApiService,
              private configService: ConfigService) {
  }

  checkPaths(checkPathsObject): Observable<CheckPath[]> {
    const checkPaths: CheckPath[] = this.transferCheckPathsObjectToArray(checkPathsObject);
    return this.apiService.post(this.configService.getCheckPathsUrl(), checkPaths).pipe(
      map(response => response.body)
    );
  }

  transferCheckPathsObjectToArray(checkPathsObject): CheckPath[] {
    const checkPaths: CheckPath[] = [];

    Object.keys(checkPathsObject).forEach(key => {
      if (checkPathsObject[key] != undefined) {
        checkPathsObject[key].key = key;
        checkPaths.push(checkPathsObject[key]);
      }
    });

    return checkPaths;
  }

  transferCheckPathsArrayToObject(checkPathsArray: CheckPath[], checkPathsObject?) {
    if (checkPathsObject == undefined) {
      checkPathsObject = {};
    }

    checkPathsArray.forEach(checkPaths => {
      checkPathsObject[checkPaths.key] = checkPaths;
    });

    return checkPathsObject;
  }
}
