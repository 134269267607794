<overlay-scrollbars
  #navTreeScrolling
  [ngStyle]="{'height': height, 'max-height': maxHeight ? maxHeight : 'none'}"
  [options]="{ scrollbars: { autoHide: 'l' } }"
>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

    <!--  file-->
    <mat-tree-node (contextmenu)="openContextMenu($event, node); $event.preventDefault();" (dblclick)="clickedFile(node)" (fileDropped)="onFileDropped($event, node)"
                   *matTreeNodeDef="let node"
                   [ngClass]="{ 'background-highlight': activeNode === node }" appDnd
                   class="nav-tree-file"
                   matTreeNodePadding
                   matTreeNodePaddingIndent="20">
      <!--      (click)="activeNode = node;"-->
      <!--      <button class="button-folder-expand-collapse" mat-icon-button disabled></button>-->
      <app-nav-tree-file-icon [extension]="node.typeOfFile"
                              class="pointer"></app-nav-tree-file-icon>
      <span class="no-wrap pointer no-select">{{node.name}}</span>
      <mx-icon (click)="openContextMenu($event, node); $event.stopPropagation();" class="context-menu-trigger pointer"
               color="primary"
               mx="options"></mx-icon>
    </mat-tree-node>

    <!--  folder-->
    <mat-tree-node (contextmenu)="openContextMenu($event, node); $event.preventDefault();"
                   (dblclick)="treeControl.isExpanded(node) ? treeControl.collapse(node) : treeControl.expand(node)" (fileDropped)="onFileDropped($event, node)"
                   *matTreeNodeDef="let node; when: hasChild" [ngClass]="{ 'background-highlight': activeNode === node }"
                   appDnd
                   class="nav-tree-folder"
                   matTreeNodePadding
                   matTreeNodePaddingIndent="20">
      <!--                   (click)="activeNode = node;"-->
      <button [attr.aria-label]="'toggle ' + node.name" class="button-folder-expand-collapse"
              mat-icon-button matTreeNodeToggle>
        <mx-icon [color]="treeControl.isExpanded(node) ? 'primary' : 'accent'"
                 [mx]="treeControl.isExpanded(node) ? 'tree-node-collapsed' : 'tree-node-expanded'"
                 class="mat-icon-rtl-mirror folder-expand-collapse"></mx-icon>
      </button>

      <mx-icon *ngIf="!treeControl.isExpanded(node)" class="mr-1 pointer" color="#b0bec5" marginBottom="true"
               mx="folder"></mx-icon>
      <mx-icon *ngIf="treeControl.isExpanded(node)" class="mr-1 pointer" color="#b0bec5" marginBottom="true"
               mx="folder-open"></mx-icon>

      <span class="no-wrap pointer no-select">{{node.name}}</span>

      <mx-icon (click)="openContextMenu($event, node); $event.stopPropagation();" class="context-menu-trigger pointer"
               color="primary"
               mx="options"></mx-icon>

      <mat-spinner *ngIf="node.isLoading"
                   [diameter]="22"
                   [strokeWidth]="3"
                   class="ml-2"
                   mode="indeterminate">

      </mat-spinner>
    </mat-tree-node>

    <!--    <mat-tree-node *matTreeNodeDef="let node; when: isLoadMore">-->
    <!--      <button mat-icon-button disabled></button>-->
    <!--      <button mat-button (click)="loadMore(node)">-->
    <!--        Load more...-->
    <!--      </button>-->
    <!--    </mat-tree-node>-->

    <ng-template #contextMenu let-node>
      <section (clickOutside)="closeContextMenu()" class="node-context-menu">

        <button (click)="createFolder(node)" *ngIf="node.isFolder && isPrimaryProject(node)" [disabled]="!checkPaths.createFolder.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="create-folder"></mx-icon>
          Create Folder
        </button>
        <button (click)="searchFolder(node)" *ngIf="node.isFolder && isPrimaryProject(node)" class="context-menu-button"

                mat-menu-item>
          <mx-icon marginBottom="true" mx="folder-search"></mx-icon>
          Find Files
        </button>
        <button (click)="createFile(node)" *ngIf="node.isFolder && isPrimaryProject(node)" [disabled]="!checkPaths.createFile.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="create-file"></mx-icon>
          Create File
        </button>
        <button (click)="createCase(node)" *ngIf="node.isFolder && isPrimaryProject(node) && canCreateCase(node)" [disabled]="!checkPaths.createCase.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="create-case"></mx-icon>
          Create Case
        </button>
        <button (click)="createPageObject(node)" *ngIf="node.isFolder && isPrimaryProject(node) && canCreateCase(node)" [disabled]="!checkPaths.createCase.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="create-case"></mx-icon>
          Create Page Object
        </button>
        <button (click)="rename(node)" *ngIf="node.level != 0 && isPrimaryProject(node)" [disabled]="!checkPaths.rename.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="rename"></mx-icon>
          Rename
        </button>
        <button (click)="delete(node)" *ngIf="node.level != 0 && isPrimaryProject(node)" [disabled]="!checkPaths.delete.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="delete"></mx-icon>
          Delete
        </button>
        <button (click)="upload(node)" *ngIf="node.isFolder && isPrimaryProject(node)" [disabled]="!checkPaths.upload.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="upload"></mx-icon>
          Upload
        </button>
        <button (click)="download(node)" [disabled]="!checkPaths.download.enabled" class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="download"></mx-icon>
          Download
        </button>
        <button (click)="clickedFile(node)" *ngIf="!node.isFolder" [disabled]="node.typeOfFile == 'zip' && !checkPaths.zip.enabled"
                class="context-menu-button" mat-menu-item>
          <mx-icon marginBottom="true" mx="open"></mx-icon>
          Open
        </button>
        <button (click)="copy(node)" [disabled]="!checkPaths.paste.enabled" class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="copy"></mx-icon>
          Copy
        </button>
        <button (click)="cut(node)" *ngIf="node.level != 0 && isPrimaryProject(node)" [disabled]="!checkPaths.paste.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="cut"></mx-icon>
          Cut
        </button>
        <button (click)="paste(node)" *ngIf="node.isFolder && copyNode != undefined && isPrimaryProject(node)" [disabled]="!checkPaths.paste.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="paste"></mx-icon>
          Paste
        </button>
        <button (click)="zip(node)" *ngIf="node.isFolder && isPrimaryProject(node) && node.level != 0" [disabled]="!checkPaths.zip.enabled"
                class="context-menu-button"
                mat-menu-item>
          <!--                *ngIf="node.isFolder && isPrimaryProject(node)">-->
          <mx-icon marginBottom="true" mx="zip"></mx-icon>
          Zip
        </button>
        <button (click)="unzip(node)" *ngIf="!node.isFolder && node.typeOfFile == 'zip' && isPrimaryProject(node)" [disabled]="!checkPaths.unzip.enabled"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="unzip"></mx-icon>
          Unzip
        </button>
        <button (click)="refresh(node)" *ngIf="node.isFolder" class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="refresh"></mx-icon>
          Refresh
        </button>
        <button (click)="properties(node)" *ngIf="node.level != 0" class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="file-properties"></mx-icon>
          Properties
        </button>
        <button (click)="openPageInNewWindow(this.configService.getHost() + '/terminal?path='+node.pathWithName, 800, 400)"
                *ngIf="!node.isFolder && node.typeOfFile == 'sh' && isAdmin && canBePrivate"
                class="context-menu-button"
                mat-menu-item>
          <mx-icon marginBottom="true" mx="run-sh"></mx-icon>
          Run
        </button>
        <!--        <button class="context-menu-button" mat-menu-item (click)="cleanMaven(node)"-->
        <!--                [disabled]="!checkPaths.cleanMaven.enabled"-->
        <!--                *ngIf="!node.isFolder && node.name == 'pom.xml'">-->
        <!--          <mx-icon marginBottom="true" mx="clean"></mx-icon>-->
        <!--          Clean (Maven)-->
        <!--        </button>-->
        <!--        <button class="context-menu-button" mat-menu-item (click)="compileMaven(node)"-->
        <!--                [disabled]="!checkPaths.compileMaven.enabled"-->
        <!--                *ngIf="!node.isFolder && node.name == 'pom.xml'">-->
        <!--          <mx-icon marginBottom="true" mx="compile"></mx-icon>-->
        <!--          Compile (Maven)-->
        <!--        </button>-->
        <!--        <button class="context-menu-button" mat-menu-item (click)="installMaven(node)"-->
        <!--                [disabled]="!checkPaths.installMaven.enabled"-->
        <!--                *ngIf="!node.isFolder && node.name == 'pom.xml'">-->
        <!--          <mx-icon marginBottom="true" mx="install"></mx-icon>-->
        <!--          Install (Maven)-->
        <!--        </button>-->
      </section>
    </ng-template>

  </mat-tree>
</overlay-scrollbars>
<!--  </div>-->
<!--</ngx-simplebar>-->
