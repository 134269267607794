import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {IconModule} from '../../../mx/icon/icon.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {_MatMenuDirectivesModule, MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {OverlayscrollbarsModule} from 'overlayscrollbars-ngx';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {RouterModule} from '@angular/router';
import {NoticeBoardModule} from '../../../mx/notice-board/notice-board.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {UserDialogModule} from '../../../users/dialogs/user-dialog/user-dialog.module';
import {SelectCreateProjectModule} from '../../../users/dialogs/select-create-project/select-create-project.module';
import {AngularSplitModule} from 'angular-split';
import {AiWrapperModule} from '../../../ai/components/ai-wrapper/ai-wrapper.module';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatTooltipModule,
    MatButtonModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    MatSidenavModule,
    OverlayscrollbarsModule,
    MatListModule,
    MatExpansionModule,
    RouterModule,
    NoticeBoardModule,
    MatToolbarModule,
    UserDialogModule,
    SelectCreateProjectModule,
    AngularSplitModule,
    AiWrapperModule
  ],
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ]
})

export class HeaderModule {
}
