import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ImportInfoDetails} from '../../../dialogs/import/ImportInfoDetails';
import {CaseImportInfoDialogParams} from '../import-case-info-dialog/import-case-info-const.service';
import {FileService} from '../../../../shared/services/file.service';

@Component({
  selector: 'app-import-case-info-delete-dialog',
  templateUrl: './import-case-info-delete-dialog.component.html',
  styleUrls: ['./import-case-info-delete-dialog.component.css']
})
export class ImportCaseInfoDeleteDialogComponent implements OnInit {


  title = '';
  disableAnimation = true;
  disableSecondButton = false;
  hideAdvancedSettings = false;

  id = undefined;
  spinner = false;
  error = undefined;
  showErrorAlert = false;

  params: CaseImportInfoDialogParams;
  selected: ImportInfoDetails[] = [];
  deleteType = 1;

  constructor(
    public dialogRef: MatDialogRef<ImportCaseInfoDeleteDialogComponent>,
    private router: Router,
    private fileService: FileService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      id: string,
      params: CaseImportInfoDialogParams;
      selected: ImportInfoDetails[],
    }
  ) {
    if (data.params && data.params != null) {
      this.params = data.params;
    }

    if (data.selected != null && data.selected.length > 0) {
      this.selected = data.selected;
      this.deleteType = 1;
    } else {
      this.deleteType = 2;
    }


    if ((this.selected == null || this.selected.length == 0) && this.params == undefined) {
      this.hideAdvancedSettings = true;
    }

  }

  ngOnInit(): void {
  }

  deleteDeleteDialog() {
    this.fileService.deleteCasesInfos(this.selected.map(e => e.casesId), this.params.search, this.params.page)
      .subscribe(
        res => {
          this.dialogRef.close();
        },
        error => {
          this.showError(error);
        }
      );
  }

  private showError(error: any) {
    console.error(error);
  }

}
