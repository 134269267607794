import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-ai-input',
  templateUrl: './ai-input.component.html',
  styleUrls: ['./ai-input.component.css']
})
export class AiInputComponent implements OnInit {
  @ViewChild('textareaElement') textareaElement: ElementRef<HTMLTextAreaElement>;
  value: string = '';
  minRows: number = 3;
  maxRows: number = 10;
  row: number = 3;
  @Output('rows') rowsEmitter = new EventEmitter<number>();
  @Input('message') message = '';
  @Input('ai') ai = false;
  @Input('isRunActive') isRunActive = false;
  @Input('disableInput') disableInput = false;
  @Input('inputActive') inputActive = false;
  @Input('showLinkCase') showLinkCase: boolean = false;
  linkCase: boolean = false;
  @Output('linkCase') linkCaseEmitter = new EventEmitter<boolean>();
  @Output('inputActiveChanges') inputActiveChanges = new EventEmitter<boolean>();
  @Output('stopRequest') stopRequestEmitter = new EventEmitter<boolean>();
  @Input('inputHeight') inputHeight = 88;
  @Output('askQuestion') askQuestion = new EventEmitter<string>();

  constructor(private cdRef: ChangeDetectorRef) {
  }
  // @ViewChild('autosize') autosize: CdkTextareaAutosize;

  adjustTextareaHeight(): void {
    const textarea: HTMLTextAreaElement = this.textareaElement.nativeElement;
    textarea.style.height = 'auto';
    const lineBreaks = (textarea.value.match(/\n/g) || []).length + 1;
    let rows = lineBreaks < this.minRows ? this.minRows : lineBreaks;
    rows = rows > this.maxRows ? this.maxRows : rows;
    textarea.rows = rows;

    textarea.scrollTop = textarea.scrollHeight; // Scroll to the bottom

    this.row = rows;
    this.rowsEmitter.emit(this.row);

    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        // If Shift + Enter is pressed, add a new line
        const textarea: HTMLTextAreaElement = this.textareaElement.nativeElement;
        const cursorPos = textarea.selectionStart;
        this.value = this.value.slice(0, cursorPos) + '\n' + this.value.slice(cursorPos);
        event.preventDefault();

        this.adjustTextareaHeight();

        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd = cursorPos + 1;
          textarea.scrollTop = textarea.scrollHeight; // Scroll to the bottom
        }, 0);
      } else {
        // If Enter is pressed, execute the askQuestionEvent function
        this.askQuestionEvent();
        event.preventDefault();
      }
    }
  }

  askQuestionEvent() {
    this.askQuestion.emit(this.value);
    this.value = '';
    this.inputHeight = 88;
    this.inputActive = false;
    this.inputActiveChanges.emit(this.inputActive);
  }

  public addToPrompt(addToPrompt: string) {
    this.value += addToPrompt;
    this.inputActive = true;
    this.cdRef.detectChanges();
    this.adjustTextareaHeightWithValue();
    this.cdRef.detectChanges();
  }

  adjustTextareaHeightWithValue(): void {
    const textarea: HTMLTextAreaElement = this.textareaElement.nativeElement;
    textarea.style.height = 'auto';
    const lineBreaks = (this.value.match(/\n/g) || []).length + 1;
    let rows = lineBreaks < this.minRows ? this.minRows : lineBreaks;
    rows = rows > this.maxRows ? this.maxRows : rows;
    textarea.rows = rows;

    this.row = rows;
    this.rowsEmitter.emit(this.row);

    this.cdRef.detectChanges();
  }

  focusOnInput() {
    this.inputActive = true;
    this.inputActiveChanges.emit(this.inputActive);
  }

  focusOutInput() {
    this.inputActive = false;
    this.inputActiveChanges.emit(this.inputActive);
  }

  stopEvent() {
    this.stopRequestEmitter.emit(true);
  }

  clickedLinkCode() {
    this.linkCase = !this.linkCase;
    this.linkCaseEmitter.emit(this.linkCase);
  }
}
