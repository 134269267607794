import {Injectable} from '@angular/core';
import {MaxtafFileDetails} from '../models/maxtafFileDetails';
import {CompileTabDetails} from '../models/compileTabDetails';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {CaseTab} from '../../../cases/shared/models/CaseTab';

@Injectable({
  providedIn: 'root'
})
export class LocalSaveWorkspaceTabsSessionStorageService {

  public WORKSPACE_TABS = 'MX-Workspace-tabs';
  public WORKSPACE_SELECTED_TAB = 'MX-Workspace-selected-tab';

  public CASES_TABS_LENGTH = 'MX-Cases-tabs-length';
  public PAGE_OBJECTS_TABS_LENGTH = 'MX-Page-Objects-tabs-length';

  constructor(private tokenService: MaxtafTokensStorageService) {
  }

  saveCasesTabsLength(length: number, projectId?: string) {
    if (projectId == undefined) {
      projectId = this.tokenService.getProjectId();
    }
    // TODO sessionStorage
    sessionStorage.setItem(this.CASES_TABS_LENGTH + '-' + projectId, JSON.stringify(length));
  }

  savePageObjectsTabsLength(length: number, projectId?: string) {
    if (projectId == undefined) {
      projectId = this.tokenService.getProjectId();
    }

    // TODO sessionStorage
    sessionStorage.setItem(this.PAGE_OBJECTS_TABS_LENGTH + '-' + projectId, JSON.stringify(length));
  }

  getPageObjectsTabsLength(projectId?: string): number {
    if (projectId == undefined) {
      projectId = this.tokenService.getProjectId();
    }
    // TODO sessionStorage
    let length: number;
    try {
      length = JSON.parse(sessionStorage.getItem(this.PAGE_OBJECTS_TABS_LENGTH + '-' + projectId));
    } catch (e) {
      length = 0;
    }

    length = length == undefined ? 0 : length;

    return length;
  }

  getCasesTabsLength(projectId?: string): number {
    if (projectId == undefined) {
      projectId = this.tokenService.getProjectId();
    }
    // TODO sessionStorage
    let length: number;
    try {
      length = JSON.parse(sessionStorage.getItem(this.CASES_TABS_LENGTH + '-' + projectId));
    } catch (e) {
      length = 0;
    }

    length = length == undefined ? 0 : length;

    return length;
  }

  // saveCasesTabsCaseId(caseId: string, projectId?: string) {
  //   if (projectId == undefined) {
  //     projectId = this.tokenService.getProjectId();
  //   }
  //   // TODO sessionStorage
  //   sessionStorage.setItem(this.CASES_TABS_CASE_ID + '-' + projectId, JSON.stringify(caseId));
  // }
  //
  // getCasesTabsCaseId(projectId?: string): number {
  //   if (projectId == undefined) {
  //     projectId = this.tokenService.getProjectId();
  //   }
  //   // TODO sessionStorage
  //   const caseId: number = JSON.parse(sessionStorage.getItem(this.CASES_TABS_CASE_ID + '-' + projectId));
  //   return caseId == undefined ? 0 : caseId;
  // }

  hasData(projectId?: string): boolean {
    if (projectId == undefined) {
      projectId = this.tokenService.getProjectId();
    }
    // TODO sessionStorage
    return sessionStorage.getItem(this.WORKSPACE_TABS + '-' + projectId) != undefined;
  }

  saveSelectedTab(selectedTab: number, projectId?: string) {
    if (projectId == undefined) {
      projectId = this.tokenService.getProjectId();
    }
    // TODO sessionStorage
    sessionStorage.setItem(this.WORKSPACE_SELECTED_TAB + '-' + projectId, JSON.stringify(selectedTab));
  }

  getSelectedTab(projectId?: string): number {
    if (projectId == undefined) {
      projectId = this.tokenService.getProjectId();
    }
    // TODO sessionStorage
    const selectedTab: number = JSON.parse(sessionStorage.getItem(this.WORKSPACE_SELECTED_TAB + '-' + projectId));
    return selectedTab == undefined ? 0 : selectedTab;
  }

  saveTabs(tabs: MaxtafFileDetails[], projectId?: string) {
    if (projectId == undefined) {
      projectId = this.tokenService.getProjectId();
    }
    // TODO sessionStorage
    sessionStorage.setItem(this.WORKSPACE_TABS + '-' + projectId, JSON.stringify(tabs));
  }

  saveCaseTabs(tabs: CaseTab[], id) {
    sessionStorage.setItem(id, JSON.stringify(tabs));
  }


  getCaseTabs(oldTabs: CaseTab[]): CaseTab[] {
    // const tempTabs: MaxtafFileDetails[] = JSON.parse(sessionStorage.getItem(id));
    const tabs: CaseTab[] = [];

    if (oldTabs != undefined) {
      oldTabs.forEach((tab: CaseTab) => {
        tabs.push(new CaseTab(
          tab.testCase,
          tab.compileResult,
          tab.compileError,
          tab.activeCode,
          tab.selectLine,
          tab.isTransferCase,
          tab.caseParams,
          tab.projectId
        ));
      });
    } else {
      return [];
    }

    return tabs;
  }

  getTabs(projectId?: string): MaxtafFileDetails[] {
    if (projectId == undefined) {
      projectId = this.tokenService.getProjectId();
    }
    // TODO sessionStorage
    const tempTabs: MaxtafFileDetails[] = JSON.parse(sessionStorage.getItem(this.WORKSPACE_TABS + '-' + projectId));
    const tabs: MaxtafFileDetails[] = [];

    if (tempTabs != undefined) {
      tempTabs.forEach(tab => {
        tabs.push(new MaxtafFileDetails(
          tab.details,
          tab.isSaved,
          new CompileTabDetails(
            tab.compileDetails.isCompiled,
            tab.compileDetails.isCurrentlyBeingCompiled,
            tab.compileDetails.compileResult
          ),
          tab.projectId,
          tab.activeCode,
          false,
          tab.allowEdit,
          tab.childTabs,
          tab.runId
        ));
      });
    }

    return tabs;
  }
}
