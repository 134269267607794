import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImageService} from '../../shared/services/image.service';

@Component({
  selector: 'app-image-viewer-workspace',
  templateUrl: './image-mx-viewer.component.html',
  styleUrls: ['./image-mx-viewer.component.css']
})
export class ImageMxViewerComponent implements OnInit {

  public image: any;

  @Input() pathWithName: string;
  @Input() optionalHeight: string = undefined;
  @Input() isInWorkspace = true;
  @Input() runDetail = 0;
  @Output('clickedImage') clickedImageEvent = new EventEmitter<string>();
  showSpinner = true;
  private doc: Document;

  constructor(private imageService: ImageService) {
  }

  ngOnInit(): void {
    this.imageService.getWorkspaceImage(this.pathWithName)
      .subscribe(image => this.createImage(image),
        err => this.handleImageRetrievalError(err));
  }

  openImage() {
    if (!this.isInWorkspace) {
      // let myWindow = window.open();
      // myWindow.document.write('<title>' + this.runDetail + ': Screenshot</title>');
      // myWindow.document.write(`<img src="${this.image}" alt="">`);
      // myWindow.stop();

      this.clickedImageEvent.emit(this.image);
    }
  }

  private createImage(image: Blob) {
    if (image && image.size > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.image = reader.result;
        this.showSpinner = false;
      }, false);

      reader.readAsDataURL(image);
    } else {
      this.showSpinner = false;
    }
  }

  private handleImageRetrievalError(err: Error) {
    console.error(err);
    this.showSpinner = false;
    console.error('Problem retrieving profile photo.');
  }


}
