export class Params {
  constructor(searchParams, prefix: string = '') {

    if (searchParams != undefined) {
      Object.keys(searchParams).forEach(key => {
        this[prefix + key] = searchParams[key].value;
      });
    }
  }
}
