<div class="row">

  <div class="col-md">
    <mat-form-field class="form-field-full-width">
      <mat-select [(value)]="search.exactSearch" placeholder="Filter done as exact match, or not">
        <mat-option value="Contains">Contains</mat-option>
        <mat-option value="Exact">Exact</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md">
    <button (click)="filterRecords()" class="mr-1 mb-1" color="primary" mat-button mat-raised-button>
      <mx-icon marginBottom="true" mx="filter"></mx-icon>
      <span class="pl-1">Filter</span>
    </button>
    <button (click)="clearFilter()" class="mr-1 mb-1" color="primary" mat-button mat-raised-button>
      <mx-icon marginBottom="true" mx="clear"></mx-icon>
      <span class="pl-1">Clear</span>
    </button>
    <button (click)="clickedClose()" class="mb-1" color="primary" mat-button mat-raised-button>
      <mx-icon marginBottom="true" mx="close"></mx-icon>
      <span class="pl-1">Close</span>
    </button>
  </div>

</div>

<div (keyup.enter)="filterRecords()"
     class="row">

  <div class="col-md">
    <mat-form-field class="form-field-full-width">
      <input [(ngModel)]="search.name" [disabled]="lockSearchParams.includes('name')" matInput placeholder="Name..."
             type="text">
    </mat-form-field>
  </div>

  <div class="col-md">
    <mat-form-field class="form-field-full-width">
      <input [(ngModel)]="search.value" [disabled]="lockSearchParams.includes('value')" matInput placeholder="Value..."
             type="text">
    </mat-form-field>
  </div>

</div>
