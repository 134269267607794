import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ImportCaseInfoDialogModule} from './import-case-info-dialog.module';
import {
  CaseImportInfoDialogParams,
  ImportCaseInfoConstParams,
  ImportCaseInfoConstService
} from './import-case-info-const.service';
import {PageInfo} from '../../../../shared/models/pageInfo';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {ImportInfoDetails} from '../../../dialogs/import/ImportInfoDetails';
import {ImportCaseInfoListComponent} from '../import-case-info-list/import-case-info-list.component';
import {CheckPath} from '../../../mx/options/CheckPath';
import {ClickedTableItem} from '../../../mx/util/list/ClickedTableItem';
import {PageParams} from '../../../mx/util/params/PageParams';
import {ImportCaseInfoOptionsPaths} from '../import-case-info-option/import-case-info-option.component';
import {
  ImportCaseInfoDetailsDialogComponent
} from '../import-case-info-details-dialog/import-case-info-details-dialog.component';
import {
  ImportCaseInfoDeleteDialogComponent
} from '../import-case-info-delete-dialog/import-case-info-delete-dialog.component';

@Component({
  selector: 'app-import-case-info-dialog',
  templateUrl: './import-case-info-dialog.component.html',
  styleUrls: ['./import-case-info-dialog.component.css']
})
export class ImportCaseInfoDialogComponent implements OnInit {
  pagesInfo: PageInfo = new PageInfo();
  importInfoDetails: ImportInfoDetails[] = [];
  @ViewChild(ImportCaseInfoListComponent) importCaseInfoList: ImportCaseInfoListComponent;
  params: CaseImportInfoDialogParams;

  constructor(
    public dialogRef: MatDialogRef<ImportCaseInfoDialogModule>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialog: MatDialog,
    private cloudConfigService: CloudConfigService
  ) {
    this.params = ImportCaseInfoConstService.initImportCaseDialogParams();
  }

  ngOnInit(): void {
  }


  getImportCaseInfoListOptionsCheckPathObject(): ImportCaseInfoOptionsPaths {
    return {
      refresh: new CheckPath('GET', this.cloudConfigService.getImportInfoCase()),
      delete: new CheckPath('DELETE', this.cloudConfigService.getImportInfoCase())
    };
  }

  filter(caseInfoConstParams: ImportCaseInfoConstParams) {
    this.importCaseInfoList.getItems();
  }

  refreshImportCaseInfo() {
    this.importCaseInfoList.getItems();
  }

  deleteImportCaseInfo() {
    const dialogRef = this.dialog.open(ImportCaseInfoDeleteDialogComponent, {
      width: '400px',
      data: {
        params: this.params,
        selected: this.importInfoDetails,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // if (result) {
      this.refreshImportCaseInfo();
      // }
    });

  }

  selected(importInfoDetails: ImportInfoDetails[]) {
    this.importInfoDetails = importInfoDetails.slice();
  }

  clickedImportCaseInfo(importInfoDetails: ClickedTableItem<ImportInfoDetails>) {

    const dialogRef = this.dialog.open(ImportCaseInfoDetailsDialogComponent, {
      width: '600px',
      data: {
        importInfoDetails: importInfoDetails.item
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });

  }

  setPageInfoChanges(pagesInfo: PageInfo) {
    this.pagesInfo = pagesInfo;
  }

  pageParamsChange(pageParams: PageParams) {
    this.params.page = pageParams;
  }

  private showError(error: any) {
    console.error(error);
  }

}
