<div class="notice-board">

  <div *ngIf="aiKey == null || aiKey.trim() == ''" [matTooltip]="'Requests/Max'" class="data-container">
    <span class="notice-board-title">AI Chat: </span>
    <span [ngClass]="{
      'green-runs': maxAiRequests > currentAiRequests,
      'red-runs': maxAiRequests <= currentAiRequests
      }" class="notice-board-title ml-2">{{currentAiRequests}}/{{maxAiRequests}} </span>
  </div>

  <a (click)="openActiveRuns()" [matTooltip]="'Active/Max'" class="data-container">
    <span class="notice-board-title">Runs: </span>
    <span [ngClass]="{
      'green-runs': maxParallelRunsCount > currentParallelRunsCount,
      'red-runs': maxParallelRunsCount <= currentParallelRunsCount
      }" class="notice-board-title ml-2">{{currentParallelRunsCount}}/{{maxParallelRunsCount}} </span>
  </a>

  <div *ngIf="lspServerIndicator != null" class="data-container">
    <span class="notice-board-title">Language server:</span>
    <mx-icon *ngIf="lspServerIndicator !== 'initializing'"
             [color]="
                lspServerIndicator === 'initializing' ? 'yellow' :
                lspServerIndicator === 'active' ? 'green' : 'warn'
              "
             [marginBottom]="false"
             [matMenuTriggerFor]="lsp"
             [matTooltip]="lspServerIndicator"
             [mx]="lspServerIndicator === 'active' ? 'success' :
                 lspServerIndicator === 'initializing' ? 'wait' : 'fail'"
             class="notice-board-icon"
    >
    </mx-icon>

    <div *ngIf="lspServerIndicator === 'initializing'" class="initializing-anim-box">
      <mat-progress-bar class="initializing-anim" mode="buffer"></mat-progress-bar>
    </div>
  </div>

  <mat-menu #lsp="matMenu" class="px-3">
    <!--    <p class="warning-message pt-1" *ngIf="currentParallelRunsCount != 0">Restarting the engine will stop {{currentParallelRunsCount}} active cases mid-execution.</p>-->
    <!--    <p>In development</p>-->

    <button (click)="restartLsp()" class="px-3" mat-menu-item>
      <mx-icon [color]="'primary'" mx="restart"></mx-icon>
      <span [ngClass]="" class="menu-text-primary">Restart Lsp server</span>
    </button>
  </mat-menu>

  <div class="data-container">
    <span class="notice-board-title">Engine:</span>

    <mx-icon *ngIf="engineIndicator !== 'INITIALIZING'"
             [color]="
                engineIndicator === 'INITIALIZING' ? 'yellow' :
                engineIndicator === 'RUNNING' ? 'green' : 'warn'
              "

             [matMenuTriggerFor]="engine"
             [matTooltip]="engineIndicator? 'Engine is active.' : 'Engine is inactive.'"
             [mx]="engineIndicator === 'RUNNING' ? 'success' :
                 engineIndicator === 'INITIALIZING' ? 'wait' : 'fail'"
             class="notice-board-icon"
    >
    </mx-icon>

    <div *ngIf="engineIndicator === 'INITIALIZING'" class="initializing-anim-box">
      <mat-progress-bar class="initializing-anim" mode="buffer"></mat-progress-bar>
    </div>

  </div>

  <mat-menu #engine="matMenu" class="px-3">
    <p *ngIf="currentParallelRunsCount != 0" class="warning-message pt-1">Restarting the engine will
      stop {{currentParallelRunsCount}} active cases mid-execution.</p>

    <button (click)="restartEngine()" class="px-3" mat-menu-item>
      <mx-icon [color]="'primary'" mx="restart"></mx-icon>
      <span [ngClass]="" class="menu-text-primary">Restart Engine</span>
    </button>
  </mat-menu>

  <div *ngIf="bridgeIndicator" class="data-container">
    <span class="notice-board-title">Bridge:</span>
    <mx-icon [color]="'green'"
             [matTooltip]="'Bridge is active.'"
             [mx]="'success'"
             class="notice-board-icon"
    >
    </mx-icon>
  </div>
</div>
