<div [ngStyle]="{ 'padding': type === 'properties' ? '0' : null }" mat-dialog-content>

  <div *ngIf="type == 'input'">
    <input [(ngModel)]="value" type="text">
  </div>

  <div *ngIf="type == 'textarea'">
    <mat-form-field class="w-100">
      <overlay-scrollbars [options]="{ scrollbars: { autoHide: 'n' } }" style="max-height: 300px !important;">
        <textarea [(ngModel)]="value" [matTextareaAutosize]="true" class="overflow-hidden" matInput
                  placeholder=""></textarea>
      </overlay-scrollbars>
    </mat-form-field>
  </div>

  <div *ngIf="type == 'number'">
    <input OnlyNumber="true" [(ngModel)]="value" matInput maxlength="6" placeholder="" type="number">
  </div>

  <div *ngIf="type == 'properties'">
    <mat-tab-group [(selectedIndex)]="activeTab" class="params-tab-group">

      <!-- Table View -->
      <mat-tab class="params-table-tab" label="Table View">
        <overlay-scrollbars [options]="{ scrollbars: { autoHide: 'n' } }" class="params-input-scrollbars">
          <mat-table #tableRef (keydown)="enterKeyListener($event)" [dataSource]="parsedProperties"
                     class="params-table">

            <!-- Key Column -->
            <ng-container matColumnDef="key">
              <th *matHeaderCellDef class="params-table-column" mat-header-cell></th>
              <td *matCellDef="let element" class="params-table-column" mat-cell>
                <div class="input-container">
                  <input (ngModelChange)="updateValueFromProperties()" [(ngModel)]="element.key" class="params-table-input"
                         matInput
                         placeholder="Enter Key"/>
                </div>
              </td>
            </ng-container>

            <!-- Value Column -->
            <ng-container matColumnDef="value">
              <th *matHeaderCellDef class="params-table-column" mat-header-cell></th>
              <td *matCellDef="let element" class="params-table-column" mat-cell>
                <div class="input-container">
                  <input (ngModelChange)="updateValueFromProperties()" [(ngModel)]="element.value" class="params-table-input"
                         matInput
                         placeholder="Enter Value"/>
                </div>
              </td>
            </ng-container>

            <!-- Delete Button Column -->
            <ng-container matColumnDef="delete">
              <th *matHeaderCellDef class="params-table-column delete" mat-header-cell></th>
              <td *matCellDef="let element; let i = index;" class="params-table-column delete" mat-cell>
                <button (click)="deleteRow(i)" class="params-table-delete-button" mat-button>
                  <mx-icon color="rgba(0,0,0,0.6)" mx="delete"></mx-icon>
                </button>
              </td>
            </ng-container>


            <tr *matRowDef="let row; columns: ['key', 'value', 'delete'];" class="params-table-row" mat-row></tr>
            <tfoot>
            <tr class="params-table-row">
              <td class="params-table-column">
                <input (input)="handleFooterInput()" class="params-table-input" matInput placeholder="Enter Key"/>
              </td>
              <td class="params-table-column">
                <input (input)="handleFooterInput()" class="params-table-input" matInput placeholder="Enter Value"/>
              </td>
            </tr>
            </tfoot>
          </mat-table>
        </overlay-scrollbars>
      </mat-tab>

      <!-- Text View -->
      <mat-tab label="Text View">
        <!--        <mat-form-field class="w-100">-->
        <overlay-scrollbars [options]="{ scrollbars: { autoHide: 'n' } }"
                            class="params-input-scrollbars params-edit-textview-container">
            <textarea (ngModelChange)="updateValueFromProperties()" [(ngModel)]="value" [matTextareaAutosize]="true" class="overflow-hidden params-textview-input"
                      matInput placeholder=""></textarea>
        </overlay-scrollbars>
        <!--        </mat-form-field>-->
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div mat-dialog-actions>
  <button *ngIf="name=='Params:'" color="primary" mat-button>
    <a href="https://www.maxtaf.com/guides/mtc-guide/?version=Cloud#caseParams" target="_blank">
      <mx-icon mx="information"></mx-icon>
    </a>
  </button>

  <button (click)="cancel()" mat-button>Cancel</button>
  <button (click)="save()" color="primary" id="okButton" mat-button>Save</button>
</div>
