import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {Params} from '../../../mx/util/params/params';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {


  constructor(
    private apiService: ApiService,
    private userProjectConfigService: UserProjectConfigService
  ) {
  }

  getAll(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.userProjectConfigService.getProjects(), params);
  }

  getProjectsWithCreateCaseRole(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.userProjectConfigService.getProjectsWithCreateCaseRole(), params);
  }

  getProjects(id) {
    return this.apiService.get(this.userProjectConfigService.getProject(id));
  }

  canBePrivate(id) {
    return this.apiService.get(this.userProjectConfigService.canBePrivate(id));
  }

  addProject(body: any, automaticallyGenerateId, clearNameOfIllegalCharacters = false) {
    return this.apiService.post(this.userProjectConfigService.createProject(automaticallyGenerateId, clearNameOfIllegalCharacters), body);
  }

  updateProject(id: string, body: any) {
    return this.apiService.put(this.userProjectConfigService.updateProject(id), body);
  }

  deleteProject(id: string) {
    return this.apiService.delete(this.userProjectConfigService.deleteProject(id));
  }


  startEngineOrResetShutdownTimer(projectId: string) {
    return this.apiService.post(this.userProjectConfigService.startEngineOrResetShutdownTimer(projectId), null);
  }

  restartEngine(projectId: string) {
    return this.apiService.post(this.userProjectConfigService.startEngineOrResetShutdownTimer(projectId, true), null);
  }

  getEngineStatus(projectId: string) {
    return this.apiService.get(this.userProjectConfigService.getEngineStatus(projectId), null);
  }

  isLicensed(projectId: string) {
    return this.apiService.get(this.userProjectConfigService.isLicensed(projectId), null);
  }


  restartLspEngine(projectId: string, language: string) {
    return this.apiService.post(this.userProjectConfigService.restartEngineLsp(projectId), language);
  }
}
