import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserParamsFilterComponent} from './user-params-filter.component';
import {IconModule} from "../../../mx/icon/icon.module";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [UserParamsFilterComponent],
  exports: [
    UserParamsFilterComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class UserParamsFilterModule {
}
