import {NgModule} from '@angular/core';
import {CreateNewFolderDialogComponent} from './create-new-folder-dialog.component';
import {ErrorModule} from '../../../mx/error/error.module';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  imports: [
    ErrorModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatCheckboxModule
  ],
  declarations: [
    CreateNewFolderDialogComponent
  ], exports: [
    CreateNewFolderDialogComponent
  ]
})

export class CreateNewFolderDialogModule {
}
