import {Component, Input, OnInit} from '@angular/core';
import {MxFilter} from '../../../mx/filter/MxFilter';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {UserService} from '../../../users/shared/services/user.service';

@Component({
  selector: 'app-filter-case',
  templateUrl: './filter-case.component.html',
  styleUrls: ['./filter-case.component.css']
})
export class FilterCaseComponent extends MxFilter implements OnInit {


  @Input('pageObject') pageObject: boolean = false;
  search: any;

  isLicensed = false;

  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private maxtafTokensStorageService: MaxtafTokensStorageService
  ) {
    super();

    projectService.isLicensed(maxtafTokensStorageService.getProjectId()).subscribe(
      res => {
        this.isLicensed = res;
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnInit(): void {

  }

  searchDefaultValues() {
    if (
      this.searchParams != undefined &&
      this.searchParams.showPageObjects != undefined &&
      this.searchParams.showCases != undefined
    ) {

      return {
        name: '',
        caseType: '',
        description: '',
        params: '',
        code: '',
        archived: '',
        fileLocation: '',
        anyFileLocation: '',
        showPageObjects: this.searchParams.showPageObjects.defaultValue,
        showCases: this.searchParams.showCases.defaultValue,
        exactSearch: 'Contains',
        showTrashRecords: false,
        showActiveRecords: true
      };
    } else {
      return {
        name: '',
        caseType: '',
        description: '',
        params: '',
        code: '',
        archived: '',
        fileLocation: '',
        anyFileLocation: '',
        showPageObjects: true,
        showCases: true,
        exactSearch: 'Contains',
        showTrashRecords: false,
        showActiveRecords: true
      };
    }
  }

  changeCases($event) {
    this.search.showCases = $event.checked;

  }

  changePageObject($event) {
    this.search.showPageObjects = $event.checked;
  }


}
