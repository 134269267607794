import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {YesNoDialogComponent} from './yes-no-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
  declarations: [
    YesNoDialogComponent
  ],
  exports: [
    YesNoDialogComponent
  ]
})

export class YesNoDialogModule {
}
