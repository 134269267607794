import {CaseAndSuiteParent} from '../../../suites/shared/models/case-and-suite-parent';

export class CompileError {
  // errorMessage: string = '';
  // showCompiling: boolean = false;
  // showCompileSuccess: boolean = false;
  // showCompileError: boolean = false;
  //
  // xmlCompileError: string = "";
  // javaCompileError: string = "";
  // pythonCompileError: string = "";

  compileError: string;
  transpileError: string;
  testCase: CaseAndSuiteParent;
}
