<h1 mat-dialog-title>Export unauthorized requests</h1>

<div mat-dialog-content>
  <div *ngIf="exportSuccess" class="fs-12 alert alert-success alert-dismissible fade show" role="alert">
    Export finished with success.
    <button (click)="exportSuccess=false" aria-label="Close" class="close" data-dismiss="alert" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--  <app-error-message class="fs-12" *ngIf="exportError" [error]="error" [showInDialog]="true"></app-error-message>-->

</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" mat-button>{{spinner || exportSuccess ? 'Close' : 'Cancel'}}</button>

  <button *ngIf="spinner" color="primary" mat-button>
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner [diameter]="20"
                   class="whiteSpinner"
                   style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"></mat-spinner>
    </mat-icon>
  </button>

  <button (click)="export()" *ngIf="!spinner" color="primary" mat-button>Export</button>
</div>

