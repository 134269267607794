import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MxHtmlViewerComponent} from './mx-html-viewer.component';

@NgModule({
  declarations: [MxHtmlViewerComponent],
  exports: [
    MxHtmlViewerComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class MxHtmlViewerModule {
}
