<div mat-dialog-content class="dialog-content">
  <app-error-message
    *ngIf="showErrorAlert"
    [error]="error"
  ></app-error-message>

  <mat-list>
    <div style="border-top: 1px solid rgba(0, 0, 0, 0.12);"></div>
    <mat-list-item *ngFor="let item of list" class="list-item cursor-pointer" (click)="closeDialog(item)">
      <app-nav-tree-file-icon [extension]="item.uri.substring(item.uri.lastIndexOf('.')+1)"
                              class="cursor-pointer"></app-nav-tree-file-icon>
      <div class="file-info cursor-pointer">
        <span class="file-name cursor-pointer">{{ item.uri }}</span>
        <span class="file-location cursor-pointer">{{ item.range.start.line }}:{{ item.range.start.character }}</span>
      </div>
    </mat-list-item>
  </mat-list>
</div>

<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null" class="mr-3">Cancel</button>
</div>
