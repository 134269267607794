import {Component, Inject, OnInit} from '@angular/core';
import {MaxtafFile} from '../../shared/models/maxtafFile';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RemoteFilesService} from '../../shared/services/remote-files.service';
import {EditDialogComponent} from '../../../dialogs/edit-dialog/edit-dialog.component';

@Component({
  selector: 'app-file-properties-dialog',
  templateUrl: './file-properties-dialog.component.html',
  styleUrls: ['./file-properties-dialog.component.css']
})
export class FilePropertiesDialogComponent implements OnInit {

  updateError = false;
  error;

  file = new MaxtafFile();
  edit = true;
  projectId = '?';
  pathWithName: string = undefined;

  constructor(
    public dialog: MatDialog,
    public storageService: RemoteFilesService,
    private remoteFileService: RemoteFilesService,
    public dialogRef: MatDialogRef<FilePropertiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      file: MaxtafFile,
      edit: boolean,
      projectId: string,
      pathWithName: string
    }
  ) {
    this.file = data.file;

    if (data.edit != undefined) {
      this.edit = data.edit;
    }

    if (data.projectId != undefined) {
      this.projectId = data.projectId;
    }

    if (data.pathWithName != undefined) {
      this.pathWithName = data.pathWithName;
      this.getFile();
    }
  }

  ngOnInit(): void {
  }

  getFile() {
    this.remoteFileService.getFile(this.pathWithName, false, this.projectId).subscribe(
      res => {
        this.file = res;
      }, error => {
        this.showError(error);
      }
    );
  }

  openEditDialog(type, name, nameField) {
    this.updateError = false;
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == null) {
        return;
      }

      this.updateError = false;

      let changedCase;

      changedCase[nameField] = result;

      const body = {
        id: undefined
      };
      Object.assign(body, changedCase);
      body.id = 0;
    });
  }

  update() {

  }

  showError(error) {
    this.error = error;
    this.updateError = true;
  }
}
