import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GlobalExportService} from '../../shared/services/global-export.service';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-export-project-dialog',
  templateUrl: './export-project-dialog.component.html',
  styleUrls: ['./export-project-dialog.component.css']
})
export class ExportProjectDialogComponent implements OnInit {
  exportSuccess = false;
  spinner = false;
  exportError = false;
  error: any = undefined;

  constructor(public globalExportService: GlobalExportService,
              public dialogRef: MatDialogRef<ExportProjectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {}) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close();

  }

  export() {
    this.spinner = true;

    this.globalExportService.globalExport().subscribe(
      res => {
        this.exportSuccess = true;
        this.spinner = false;
        const contentDisposition = res.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();

        const blob = new Blob([res.body], {type: 'application/text'});
        saveAs(blob, fileName);
        this.closeDialog();
      },
      error => {
        this.spinner = false;
        console.error(error);
        this.showError(error);
      }
    );


  }

  showError(error) {
    this.error = error;
    this.exportError = true;
  }
}
