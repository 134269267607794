<h1 class="ml-1 mr-1" mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>
  <app-error-message *ngIf="securityMessageError" [error]="error" [showInDialog]="true"
                     class="fs-12"></app-error-message>
  <p>{{body}}</p>
  <br>
  <mat-form-field class="w-100">
    <input [(ngModel)]="securityMessage" [placeholder]="'Please type in the project ID to continue'" matInput
           type="text">
    <button (click)="securityMessage=''" *ngIf="securityMessage" aria-label="Clear" mat-button mat-icon-button
            matSuffix>
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button (click)="closeDialog()" mat-button>Cancel</button>
  <button (click)="submit()" color="primary" mat-button>{{submitButtonText}}</button>
</div>
