import {Component, Inject, OnInit} from '@angular/core';
import {Project} from "../../../projects/shared/models/project";
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MaxtafTokensStorageService} from "../../../../shared/services/maxtaf-tokens-storage.service";
import {FileService} from "../../../../shared/services/file.service";
import {TemplateService} from "../../../../shared/services/template.service";
import {CloudConfigService} from "../../../../shared/services/cloud-config.service";
import {ProjectService} from "../../../projects/shared/services/project.service";
import {TransferExportService} from "../../../../shared/services/transfer-export.service";
import {CheckRolesService} from "../../../../shared/services/check-roles.service";
import {PageParams} from "../../../mx/util/params/PageParams";
import {SearchParam} from "../../../mx/util/params/searchParam";
import {CheckPath} from "../../../mx/options/CheckPath";
import {saveAs} from 'file-saver';
import {UserProjectConfigService} from "../../../../shared/services/user-project-config.service";
import {AiConfigService} from "../../shared/services/ai-config.service";

class ExportAiChatCheckPaths {
  json = new CheckPath();
  xml = new CheckPath();
  transfer = new CheckPath();
  template = new CheckPath();
}

@Component({
  selector: 'app-export-ai-chat',
  templateUrl: './export-ai-chat.component.html',
  styleUrls: ['./export-ai-chat.component.css']
})
export class ExportAiChatComponent implements OnInit {


  checkPaths: ExportAiChatCheckPaths;

  className = 'ExportCaseDialogComponent';

  heading = 'Export AI Chat';

  active = true;

  paramsFilter;

  selected: string[] = [];

  url: string;

  // JSON XML TEMPLATE ...
  exportType = 'JSON';

  onlyArchivedOrActive = true;
  useFilter = false;

  templateName = '';
  templateDescription = '';

  error;
  exportError = false;
  exportSuccess = false;

  hideAdvancedSettings = false;
  overwriteCase = false;

  projectsList: Project[] = [];
  projects = new FormControl();

  // Workaround for angular component issue #13870
  disableAnimation = true;

  spinner = false;

  constructor(
    public dialog: MatDialog,
    public tokensStoriage: MaxtafTokensStorageService,
    private fileService: FileService,
    private templateService: TemplateService,
    private cloudConfigService: CloudConfigService,
    private userProjectConfigService: UserProjectConfigService,
    private aiConfigService: AiConfigService,
    private projectService: ProjectService,
    private transferService: TransferExportService,
    public checkRolesService: CheckRolesService,
    public dialogRef: MatDialogRef<ExportAiChatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      heading: string,
      active: boolean,
      paramsFilter,
      selected: string[]
    }
  ) {
    dialogRef.disableClose = true;

    this.checkPaths = this.getExportCaseCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );

    if (this.data.active != undefined) {
      this.active = this.data.active;
    }

    if (this.data.paramsFilter != undefined) {
      this.paramsFilter = this.data.paramsFilter;
      this.useFilter = true;
    }

    if (this.data.selected != undefined) {
      this.selected = this.data.selected;
    }

    this.getProjectsOfUser();
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = function (e) {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('closeButton').click();
    }
  };

  // ENTER shortcut for exporting the test
  exportShortcutListener = function (e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('exportButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.exportShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.exportShortcutListener, false);
  }


  export() {

    this.exportCases();

  }

  exportCases() {
    this.exportSuccess = false;
    this.exportError = false;

    this.setUrl();

    this.fileService.exportFile(this.url).subscribe(
      res => {
        this.exportSuccess = true;

        const contentDisposition = res.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();

        const blob = new Blob([res.body], {type: 'application/text'});
        saveAs(blob, fileName);
        this.closeDialog();
      },
      error => {
        console.error(error);
        this.showError(error);
      }
    );

  }


  closeDialog(): void {
    this.dialogRef.close();
  }

  showError(error) {
    this.error = error;
    this.exportError = true;
  }

  getProjectsOfUser() {
    const pageParams: PageParams = new PageParams(
      new SearchParam(0, 0),
      new SearchParam(100, 100),
      new SearchParam('name,asc', 'name,asc')
    );

    const afterFilter = {
      allowedPath: new SearchParam(this.cloudConfigService.addCase(), this.cloudConfigService.addCase()),
      allowedPathType: new SearchParam('POST', 'POST'),
    };

    this.projectService.getAll(afterFilter, pageParams).subscribe(
      (res: Project[]) => {
        this.projectsList = res['content'];
        this.projectsList = this.projectsList.filter(item => item.id != this.tokensStoriage.getProjectId());
      },
      error => {
        this.projectsList = [];
        console.error(error);
        this.showError(error);
      }
    );
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  setUrl() {
    this.url = this.aiConfigService.export();
  }

  private getExportCaseCheckPaths(): ExportAiChatCheckPaths {
    return {
      json: new CheckPath('GET', this.cloudConfigService.exportCases('json')),
      xml: new CheckPath('GET', this.cloudConfigService.exportCases('xml')),
      template: new CheckPath('GET', this.cloudConfigService.exportTemplate()),
      transfer: new CheckPath('POST', this.cloudConfigService.exportCases('transfer')),
    };
  }
}
