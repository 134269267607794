import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CheckPath} from '../../../mx/options/CheckPath';
import {MxOptionAbstract} from '../../../mx/options/MxOptionAbstract';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {UserService} from '../../shared/services/user.service';


export class ProjectMainTabOptionsPaths {
  restartEngine: CheckPath;
  export: CheckPath;
  import: CheckPath;
}

@Component({
  selector: 'app-project-main-tab-options',
  templateUrl: './project-main-tab-options.component.html',
  styleUrls: ['./project-main-tab-options.component.css']
})
export class ProjectMainTabOptionsComponent extends MxOptionAbstract<ProjectMainTabOptionsPaths> implements OnInit {

  @Input('hasSelected') hasSelected = false;
  @Input('showDelete') showDelete = false;
  @Input('showRestore') showRestore = false;
  @Input('lockFilterParams') lockFilterParams = [];

  @Input('allowShadow') allowShadow = true;
  @Input('allowEdit') edit = true;
  @Input('showButtons') showButtons = true;
  @Input('showButtonText') showButtonText = true;
  @Input('showTooltip') showTooltip = false;
  @Input('smallHeight') smallHeight = false;
  @Input('iconMarginBottom') iconMarginBottom = true;

  @Output('restartEngine') restartEngineEvent = new EventEmitter<null>();
  @Output('export') exportEvent = new EventEmitter<null>();
  @Output('unauthorizedRequests') unauthorizedRequestsEvent = new EventEmitter<null>();
  @Output('import') importEvent = new EventEmitter<null>();

  constructor(public checkRolesService: CheckRolesService,
              public userService: UserService) {
    super(checkRolesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  export() {
    this.exportEvent.emit();
  }

  import() {
    this.importEvent.emit();
  }

  restartEngine() {
    this.restartEngineEvent.emit();
  }

  unauthorizedRequests() {
    this.unauthorizedRequestsEvent.emit();
  }
}
