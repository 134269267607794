export class CompileInfo {
  constructor(
    public id: string = '',
    public maxNumber: number = 0,
    public currentNumber: number = 0,
    public completed: boolean = false,
    public skipped: string = '',
    public errors: string = '',
    public error: string = '',
    public user: string = '',
    public createdDate: string = '',
    public updatedDate: string = ''
  ) {
  }
}
