import {NgModule} from '@angular/core';
import {ListCaseComponent} from './list-case.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';
import {SpinnerForTableModule} from '../../../../shared/util-components/spinner-for-table/spinner-for-table.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatInputModule} from '@angular/material/input';
import {IconModule} from '../../../mx/icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    SpinnerForTableModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatInputModule,
    IconModule
  ],
  declarations: [
    ListCaseComponent
  ],
  exports: [
    ListCaseComponent
  ]
})

export class ListCaseModule {
}
