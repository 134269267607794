import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImportComponent} from '../../../dialogs/import/import.component';
import {UserProject} from '../../shared/models/userProject';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.css']
})
export class UserDialogComponent implements OnInit {

  userProject: UserProject;
  userProjectDetailsShow: boolean;
  userDetailsShow: boolean;
  allowEditName: boolean;
  showApiKey: boolean = false;

  constructor(public dialogRef: MatDialogRef<ImportComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                userProject: any,
                userProjectDetailsShow: boolean,
                userDetailsShow: boolean,
                showApiKey: boolean,
                allowEditName: boolean
              }) {
    this.userProject = data.userProject;
    this.showApiKey = data.showApiKey;
    this.userProjectDetailsShow = data.userProjectDetailsShow == undefined ? true : data.userProjectDetailsShow;
    this.userDetailsShow = data.userDetailsShow == undefined ? true : data.userDetailsShow;
    this.allowEditName = data.allowEditName == undefined ? false : data.allowEditName;
  }

  ngOnInit(): void {
  }

}
