<mx-options
  #mxOptions
  [allowShadow]="allowShadow"
  [defaultColors]="defaultColors"
  [iconMarginBottom]="iconMarginBottom"
  [showButtonText]="showButtonText"
  [showButtons]="showButtons"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
>

  <mx-options-header>

    <!--    &lt;!&ndash;      filter&ndash;&gt;-->
    <!--    <app-filter-by-->
    <!--      class="filter-by mr-1 ml-2"-->
    <!--      *ngIf="showButtons"-->
    <!--      [searchParams]="searchParams"-->
    <!--      [disabled]="!checkPaths.filter.enabled"-->
    <!--      (searchParamsChange)="newParams($event)"-->
    <!--      (filter)="filter()"-->
    <!--      [isSearchCollapsed]="mxOptions.isSearchCollapsed"-->
    <!--      (isSearchCollapsedChange)="mxOptions.toggleBody();"-->
    <!--    ></app-filter-by>-->

    <button (click)="mxOptions.toggleBody();"
            *ngIf="showButtons"
            [disabled]="!checkPaths.refresh.enabled"
            [matTooltip]="!showTooltip? undefined : 'Filter'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="filter"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Filter</span>
    </button>

    <!--      refresh-->
    <button (click)="refresh()"
            *ngIf="showButtons"
            [disabled]="!checkPaths.refresh.enabled"
            [matTooltip]="!showTooltip? undefined : 'Refresh'"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="refresh"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Refresh</span>
    </button>

    <!--      delete-->
    <button (click)="delete()" *ngIf="showButtons && !forDialog"
            [disabled]="!checkPaths.delete.enabled"
            [matTooltip]="!showTooltip? undefined : 'Delete'"
            id="deleteButton"
            mat-button>
      <mx-icon [marginBottom]="iconMarginBottom" mx="delete"></mx-icon>
      <span *ngIf="showButtonText" class="px-1">Delete</span>
    </button>

  </mx-options-header>

  <mx-options-body>
    <div class="filter">
      <app-import-case-info-filter
        (close)="mxOptions.closeBody();"
        (filter)="filter()"
        (searchParamsChange)="newParams($event)"
        [lockSearchParams]="lockFilterParams"
        [searchParams]="searchParams"
      ></app-import-case-info-filter>
    </div>

  </mx-options-body>
</mx-options>
