import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleConfigService {

  constructor(
    private configService: ConfigService
  ) {
  }

  private DEFAULT_PATH_VAR = this.configService.getDefaultPathVar();


  private API_URL = this.configService.getHostURL() + this.configService.getPrefix() + '/schedule';

  private CASE_PATH = 'cases';
  private SUITE_PATH = 'suites';
  private JOB_PATH = 'jobs';
  private PAUSE_PATH = 'pause';
  private RESUME_PATH = 'resume';
  private EXPORT_PATH = 'export';
  private IMPORT_PATH = 'import';
  private SCHEDULE_PATH = 'schedule';
  private CASES_OR_SUITES_PATH = 'casesOrSuites';

  private CASE_URL = this.API_URL + '/' + this.CASE_PATH;
  private SUITE_URL = this.API_URL + '/' + this.SUITE_PATH;
  private EXPORT_URL = this.API_URL + '/' + this.EXPORT_PATH;
  private IMPORT_URL = this.API_URL + '/' + this.IMPORT_PATH;
  private JOB_URL = this.API_URL + '/' + this.JOB_PATH;

  public runCaseScheduler() {
    return this.CASE_URL;
  }

  public runSuiteScheduler() {
    return this.SUITE_URL;
  }

  public getJobs() {
    return this.JOB_URL + '?zoneId=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public getJob(jobId: string = this.DEFAULT_PATH_VAR) {
    return this.JOB_URL + '/' + jobId + '?zoneId=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public removeJob(id?: string) {
    return this.JOB_URL + '?jobKey=' + id;
  }

  public pauseJob(id?: string) {
    return this.JOB_URL + '/' + this.PAUSE_PATH + '?jobKey=' + id;
  }

  public resumeJob(id?: string) {
    return this.JOB_URL + '/' + this.RESUME_PATH + '?jobKey=' + id;
  }

  public updateScheduler(id: string = this.DEFAULT_PATH_VAR) {
    return this.JOB_URL + '/' + id;
  }

  public importSchedules() {
    return this.IMPORT_URL;
  }

  public exportSchedules() {
    return this.EXPORT_URL;
  }

  getImportInfoSchedules(id) {
    return this.API_URL + '/import/info/schedule/' + id;
  }

  getImportInfoSchedule() {
    return this.API_URL + '/import/info/schedule';
  }
}
