import {Component, Input, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-ai-title',
  templateUrl: './ai-title.component.html',
  styleUrls: ['./ai-title.component.css']
})
export class AiTitleComponent implements OnInit {

  @Input('message') message = '';
  @Input('ai') ai = false;

  constructor(public snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
  }

  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.message;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.openSnackBar('Successfully copied to clipboard', 'OK', 5);
  }

  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }

}
