import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TemplateService} from '../../../../shared/services/template.service';
import {CaseService} from '../../../cases/shared/services/case.service';
import {SuiteService} from '../../../suites/shared/services/suite.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-ai-diff',
  templateUrl: './ai-diff.component.html',
  styleUrls: ['./ai-diff.component.css']
})
export class AiDiffComponent implements OnInit {

  showErrorAlert = false;
  error;

  code1;
  language1;
  code2;
  language2;

  constructor(
    private router: Router,
    private templateService: TemplateService,
    private caseService: CaseService,
    private suiteService: SuiteService,
    public dialogRef: MatDialogRef<AiDiffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      code1: string,
      language1: string,
      code2: string,
      language2: string,
    }
  ) {
    dialogRef.disableClose = true;

    this.code1 = data.code1;
    this.language1 = data.language1;

    this.code2 = data.code2;
    this.language2 = data.language2;

  }

  ngAfterViewInit(): void {
    // this.createDiffEditor();
  }

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.createShortcutListener, false);

  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for creating the test
  createShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('createButton').click();
    }
  };

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.createShortcutListener, false);
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }


}
