import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiDeleteChatSessionsComponent} from './ai-delete-chat-sessions.component';
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [AiDeleteChatSessionsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule
  ]
})
export class AiDeleteChatSessionsModule {
}
