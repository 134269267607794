<section [ngClass]="{'ai-color': ai, 'user-color': !ai}"
         class="main-container ai-title-wrapper"
>
  <!--    <p class="text-box" [innerHTML]="message"></p>-->

  <markdown
    [data]="message"
    class="markdown-style"
    lineNumbers
  >

  </markdown>

</section>
